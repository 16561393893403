import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { vendorProductReturn, vendorProductReturnSearch } from "../../../store/adminSlice";
import moment from "moment";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

const ProductsReturnd = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(vendorProductReturn(id));
  }, [dispatch, id]);

  useEffect(() => {
    if(search!=""){
      dispatch(vendorProductReturnSearch({id:id,search:search}));
    } else {
      dispatch(vendorProductReturn(id));
    }
  }, [search, dispatch, id]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Shop List", path: "/super-admin/shop-list/" },
            {
              name: "Shop Details",
              path: -1,
            },
            {
              name: "Products Returned",
              path: `/super-admin/shop-list/products-returnd/${id}`,
            },
          ]}
        />

        {/* search */}
        <div className="relative flex items-center w-full">
          <img
            className="absolute left-3"
            src="/assets/icons/search.svg"
            alt=""
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs"
            type="search"
            placeholder="Search Products..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left">PRODUCT </span>
                <span className="w-[15%] text-left">ORDER ID</span>
                <span className="w-[15%] text-left">PRICE</span>
                <span className="w-[15%] text-left">STATUS</span>

                <span className="w-[15%] text-left">REASON</span>
                <span className="w-[20%] text-left">COMMENT</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.data?.returned_orders &&
                data.data.returned_orders.length > 0 ? (
                data.data.returned_orders.map((prodct) => (
                  <div
                    key={prodct.order_id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[20%] text-left  gap-3 font-[600] pl-6 flex items-center ">
                      <img
                        src={prodct.product_image}
                        className="w-10 h-10 text-center rounded-lg "
                        alt=""
                      />
                      <div className="flex flex-col py-3">
                        <span>{prodct.product_name}</span>
                        <span className="text-[#6B6B6B] text-sm font-[500]">
                          {/* Category: {item.category} */}
                        </span>
                      </div>
                    </div>
                    <span className="w-[15%] text-left">
                      #{prodct.order_id}
                    </span>
                    <span className="w-[15%] text-left">${prodct.price}</span>
                    <div className="w-[15%] text-left">
                      <div className="flex flex-col">
                        <span>Returned</span>
                        <span>
                          {moment(prodct.order_date, "MMMM D, YYYY").format(
                            "YYYY-MM-DD"
                          )}{" "}
                          {prodct.order_time}
                        </span>
                      </div>
                    </div>{" "}
                    <span className="w-[20%] text-left">{prodct.reason}</span>
                    <span className="text-sm font-[600] text-[#18120F]">
                      {prodct.comments}
                    </span>
                  </div>
                ))
              ) : (
                "No Returned Orders found."
              )}
            </div>
          </div>
        </div>

        {/* Pagination */}
      </div>
    </>
  );
};

export default ProductsReturnd;
