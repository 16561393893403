import React from "react";
import Root from "../Pages/Root";
import authRoutes from "./authRoutes";
import boosterRoutes from "./boosterRoutes";
import brandsRoutes from "./brandsRoutes";
import categoriesRoutes from "./categoriesRoutes";
import customerReturnRoutes from "./customerReturnRoutes";
import homeRoutes from "./homeRoutes";
import hotspotRoutes from "./hotspotRoutes";
import mallManagementRoute from "./mallManagementRoute";
import manageReviewRoutes from "./manageReviewRoutes";
import offerManagementRoutes from "./offerManagementRoutes";
import orderManagementRoutes from "./orderManagementRoutes";
import productManagementRoutes from "./productManagementRoutes";
import roleManagementRoute from "./roleManagementRoute";
import shopListsRoutes from "./shopListsRoutes";
import superAdminInfoRoutes from "./superAdminInfoRoutes";
import userListRoutes from "./userListRoutes";
import vendorRequestsRoutes from "./vendorRequests";

import ProtectedRoute from "../ProtectedRoute";
import storeBoosterRoutes from "./storeBoosterRoutes";
import premiumProductsRoutes from "./premiumProductsRoutes";
import bannerAdsRoutes from "./bannerAdsRoutes";
import chatRoutes from "./chatRoutes";
import heroSectionRoutes from "./heroSectionRoutes";
import countriesRoutes from "./countiresRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes,
      {
        path: "/super-admin",
        element: <ProtectedRoute />, // Wraps only the protected routes
        children: [
          ...heroSectionRoutes,
          ...homeRoutes,
          ...hotspotRoutes,
          ...categoriesRoutes,
          ...brandsRoutes,
          ...shopListsRoutes,
          ...userListRoutes,
          ...offerManagementRoutes,
          ...superAdminInfoRoutes,
          ...productManagementRoutes,
          ...orderManagementRoutes,
          ...customerReturnRoutes,
          ...manageReviewRoutes,
          ...boosterRoutes,
          ...roleManagementRoute,
          ...mallManagementRoute,
          ...vendorRequestsRoutes,
          ...storeBoosterRoutes,
          ...premiumProductsRoutes,
          ...bannerAdsRoutes,
          ...chatRoutes,
          ...countriesRoutes,
        ],
      },
    ],
  },
];
export default mainRoutes;
