import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  clearMessage,
  mainCategory,
  mainCategoryCreate,
  subCategoriesCreate,
  subCategoriesList,
  subSubCategoriesCreate,
  subSubCategoriesList,
} from "../../../../../store/adminSlice";
import { toast } from "react-toastify";
import BreadCrums from "../../../../Common/BreadCrums";

export default function MainShopCreateCategory() {
  const dispatch = useDispatch();
  const { categories, error, message, loading, subCategories } = useSelector(
    (state) => state.admin
  );

  const initialImageSlots = Array(3).fill(null);
  const initialImageSlotsOne = Array(2).fill(null);
  const [imageSlots, setImageSlots] = useState(initialImageSlots);

  const [categoryName, setCategoryName] = useState("");

  const [imageSectionHeading, setImageSectionHeading] =
    useState("ICON AND IMAGES");

  const [selectedParentCategory, setSelectedParentCategory] = useState("None");
  const [selectedSubCategory, setSelectedSubCategory] = useState("None");

  const [forSubCategory, setForSubCategory] = useState("0");
  const [forSubSubCategory, setForSubSubCategory] = useState("0");

  const labels = [
    { text: "Icon", required: true },
    { text: "Category Image", required: true },
    { text: "Shelf Image", required: false },
  ];

  useEffect(() => {
    dispatch(mainCategory("all"));
    dispatch(subCategoriesList());
  }, []);

  useEffect(() => {
    if (forSubCategory != "0" && forSubSubCategory == "0") {
      setImageSlots(initialImageSlotsOne);
      setImageSectionHeading("ICON AND IMAGES");
    } else if (forSubCategory != "0" && forSubSubCategory != "0") {
      setImageSlots(null);
      setImageSectionHeading("");
    } else {
      setImageSlots(initialImageSlots);
      setImageSectionHeading("ICON AND IMAGES");
    }
  }, [forSubCategory, forSubSubCategory]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message) {
      toast.success(message);
      dispatch(mainCategory());
      dispatch(subCategoriesList());
      setCategoryName("");
      setSelectedSubCategory("None");
      setSelectedParentCategory("None");
      setForSubCategory("0");
      setForSubSubCategory("0");
      setImageSlots(initialImageSlots);
    }
    dispatch(clearMessage());
  }, [error, message]);

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSlots = [...imageSlots];
        newImageSlots[index] = e.target.result;
        setImageSlots(newImageSlots);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = (index) => {
    const newImageSlots = [...imageSlots];
    newImageSlots[index] = null;
    setImageSlots(newImageSlots);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (
      forSubCategory == "0" &&
      forSubSubCategory == "0" &&
      categoryName != ""
    ) {
      formData.append("name", categoryName);
      formData.append("type", "main-shop");
      if (imageSlots[1]) {
        formData.append("image", dataURLtoFile(imageSlots[1], "image.png"));
      }
      if (imageSlots[2]) {
        formData.append("icon", dataURLtoFile(imageSlots[0], "icon.png"));
      }
      if (imageSlots[0]) {
        formData.append(
          "shelf_image",
          dataURLtoFile(imageSlots[2], "shelf_image.png")
        );
      }
      // console.log("1");

      dispatch(mainCategoryCreate(formData));
    } else if (
      forSubCategory != "0" &&
      forSubSubCategory == "0" &&
      categoryName != ""
    ) {
      formData.append("category_id", parseInt(forSubCategory));
      formData.append("name", categoryName);
      formData.append("status", 0);
      formData.append("bane", 0);
      if (imageSlots[0]) {
        formData.append("image", dataURLtoFile(imageSlots[1], "image.png"));
      }
      if (imageSlots[1]) {
        formData.append("icon", dataURLtoFile(imageSlots[0], "icon.png"));
      }

      dispatch(subCategoriesCreate(formData));
    } else if (
      forSubCategory != "0" &&
      forSubSubCategory != "0" &&
      categoryName != ""
    ) {
      formData.append("category_id", parseInt(forSubCategory));
      formData.append("sub_category_id", parseInt(forSubSubCategory));
      formData.append("name", categoryName);
      formData.append("status", 0);

      dispatch(subSubCategoriesCreate(formData));
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <form id="uploadForm" enctype="multipart/form-data" method="post">
      <div className="xl:px-4 flex flex-col gap-4 w-full">
        {/* top bar */}
        <div className="flex justify-between items-center w-full">
          <BreadCrums
            breadCrum={[
              {
                name: " Main Shop Category",
                path: "/super-admin/main-categories/",
              },
              {
                name: "Create Category",
                path: "/super-admin/main-categories/create-category",
              },
            ]}
          />

            <button
            disabled={loading}
            onClick={handleSubmit}
            className="py-2 md:py-[15px] px-5 h-12 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
            {!loading ? (
              "Publish"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
            </button>
        </div>

        {/* create form  */}
        <div className="flex flex-col gap-2">
          <p className="font-semibold text-lg">Create Category</p>

          <div className="flex flex-col md:flex-row  border border-[#EBF0ED] p-4 gap-10 md:gap-4 lg:gap-10 rounded-lg">
            {/* 1st div  */}

            <div className="w-full flex flex-col gap-4">
              {/* name  */}
              <div className="flex flex-col gap-1 w-full">
                <label
                  className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                  htmlFor="product name "
                >
                  Category Name
                </label>
                <input
                  className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                  type="text"
                  name="name"
                  id=""
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                  placeholder="Name..."
                />
              </div>

              {/* parent category  */}

              <div className="relative flex flex-col gap-2">
                <label
                  className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                  htmlFor="budgetCategory"
                >
                  Parent Category
                </label>
                <div className="relative w-full">
                  <div className="w-full flex justify-between items-center bg-[#FAFAFA]  border border-[#EBF0ED]  rounded-lg ">
                    <select
                      value={selectedParentCategory}
                      placeholder="None"
                      className="bg-[#FAFAFA] w-full py-3 px-3   outline-[#EBF0ED] text-xs text-[#6B6B6B] placeholder:text-xs placeholder:text-[#6B6B6B]"
                      onChange={(e) => {
                        setSelectedParentCategory(e.target.value);
                        setForSubCategory(e.target.value);
                      }}
                    >
                      <option value="0">none</option>
                      {categories?.categories?.length > 0 &&
                        categories?.categories.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
              </div>

              {/* sub category  */}
              {forSubCategory !== "0" && (
                <div className="relative flex flex-col gap-2">
                  <label
                    className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                    htmlFor="budgetCategory"
                  >
                    Sub-Category
                  </label>
                  <div className="relative w-full">
                    <div className="w-full flex justify-between items-center bg-[#FAFAFA]  border border-[#EBF0ED]  rounded-lg ">
                      <select
                        value={selectedSubCategory}
                        placeholder="None"
                        className="bg-[#FAFAFA] w-full py-3 px-3   outline-[#EBF0ED] text-xs text-[#6B6B6B] placeholder:text-xs placeholder:text-[#6B6B6B]"
                        onChange={(e) => {
                          setSelectedSubCategory(e.target.value);
                          setForSubSubCategory(e.target.value);
                        }}
                      >
                        <option value="0">none</option>
                        {subCategories?.subcategories?.length > 0 &&
                          subCategories?.subcategories.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {/* product image  */}
            </div>

            {/* 2nd div  */}
            <div className="w-full flex flex-col gap-4 ">
              <div className="flex flex-col gap-2">
                <h1 className="text-[#6B6B6B]  font-semibold text-xs">
                  {imageSectionHeading}
                </h1>

                <div className=" grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-2 lg:gap-4">
                  {imageSlots?.map((image, index) => (
                    <div
                      key={index}
                      className="flex flex-col items-center w-full md:w-28"
                    >
                      <div
                        className={`relative w-full h-[90px] border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0] `}
                      >
                        {image ? (
                          <div className="w-full h-full relative">
                            <img
                              src={image}
                              alt={`Uploaded-${index}`}
                              className="w-full h-full object-cover rounded-lg"
                            />
                            {/* Delete Button */}
                            <button
                              className="absolute top-1 right-1 bg-white border border-[#EBF0ED] rounded-full w-4 h-4 flex justify-center items-center"
                              onClick={() => handleImageDelete(index)}
                            >
                              <svg
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.9748 1.00017L1.00002 5.975M1 1L5.9748 5.9748"
                                  stroke="black"
                                  stroke-width="1.07"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        ) : (
                          <label className="cursor-pointer flex w-full h-full items-center justify-center">
                            <input
                              type="file"
                              accept="image/*"
                              className="hidden"
                              onChange={(e) => handleImageUpload(e, index)}
                            />
                            <span className="flex bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                              +
                            </span>
                          </label>
                        )}
                      </div>
                      {/* Label with red star for required images */}
                      <div className="text-sm font-normal text-gray-500 mt-2">
                        {labels[index].text}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
