import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useState } from "react";

export default function Countries() {
  const [selectedCountry, setSelectedCountry] = useState("");

  const countries = [
    { id: 1, name: "Pakistan" },
    { id: 2, name: "India" },
    { id: 3, name: "Japan" },
    { id: 4, name: "China" },
    { id: 5, name: "Sri Lanka" },
    { id: 6, name: "Poland" },
    { id: 7, name: "UAE" },
    { id: 8, name: "United Kingdom" },
  ];

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  return (
    <div className="md:px-4 flex flex-col gap-8 mb-10">
      <BreadCrums
        breadCrum={[
          {
            name: "Countries",
          },
        ]}
      />

      <div className="flex flex-col gap-4">
        <div className="w-full flex item-center gap-4">
          <div className="w-full">
            <select
              list="countryList"
              placeholder="Select Country..."
              className="w-full px-4 cursor-pointer py-2 md:py-[10px] text-xs md:text-sm rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px]"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              {/* <datalist id="countryList" className=""> */}
              {countries.map((country, index) => (
                <option key={index} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            {/* </datalist> */}
          </div>
          <button className="bg-[#C19A6B] py-[8px] md:py-[10px] px-5 md:px-9 text-white rounded-lg font-semibold tracking-widest text-[10px] md:text-xs">
            Submit
          </button>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto ">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            {/* Table Header */}
            <div className="min-w-[800px] md:min-w-[768px] lg:min-w-full">
              <div className="flex  items-center justify-between gap-2 p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
                <span className="w-[20%] text-left">COUNTRY</span>

                <span className="w-[15%] text-left text-nowrap">STATUS</span>

                <span className="w-[10%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              <div className="flex w-full justify-between items-center  p-4 border-t  font-semibold text-sm ">
                <span className="w-[20%] text-left ">Germany</span>

                <span className="w-[15%] text-left ">
                  <span
                    className="bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                    //   className={`${
                    //     status === "Active"
                    //       ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                    //       : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                    //   }`}
                  >
                    Active
                  </span>
                </span>

                <Link className="w-[10%] text-left ">
                  <img src="/assets/icons/view.svg" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
