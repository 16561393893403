import CountriesPage from "../Pages/My-Shop/Countries-Pages/CountriesPage";

const countriesRoutes = [
  {
    path: "/super-admin/countries",

    children: [
      {
        index: true,
        element: <CountriesPage />,
      },
    ],
  },
];

export default countriesRoutes;
