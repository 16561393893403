import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  premiumProductDelete,
  premiumProductList,
  premiumProductListSearch,
} from "../../../store/adminSlice";
import { toast } from "react-toastify";
import moment from 'moment';

export default function SingleActiveOffer() {
  const [activeRole, setActiveRole] = useState("All");
  const dispatch = useDispatch();
  const { data, error, message } = useSelector((state) => state.admin);
  const location = useLocation();
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  useEffect(() => {
    if (data.data && data.data.pagination?.total) {
      let totalLength = data.data.pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      // console.log(data.data.pagination.total);
      setTotalPages(totalPages);
    }
  }, [data]);

  useEffect(() => {
    if (search === "") {
      dispatch(premiumProductList({}));
    } else {
      dispatch(premiumProductListSearch(search));
    }
  }, [search, dispatch]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(premiumProductList({ status, page }));
    }
  };

  const DateTimeDisplay = (liveEndDateTime) => {
    const now = moment();
    const endTime = moment(liveEndDateTime);    
    const isToday = now.isSame(endTime, 'day');
    const displayTime = isToday ? endTime.format('HH:mm')+' today' : endTime.format('YYYY-MM-DD');
  
    return (
      <div>
        {displayTime}
      </div>
    );
  };

  useEffect(() => {
    dispatch(premiumProductList({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(premiumProductList({ status }));
  }, [status, dispatch]);

  useEffect(() => {
    // console.log(error, message);
    if (
      message === "Premium product and associated bids deleted successfully"
    ) {
      toast.success(message);
    }
  }, [error, message]);

  const handleDelete = (id) => {
    // console.log("del", id);
    dispatch(premiumProductDelete(id)).then(() => {
      dispatch(premiumProductList({}));
    });
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }
    // console.log(totalNumberOfPages);
    // console.log(tempStart, tempEnd);
    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3 ">
        {/* Header */}
        <div className="flex flex-col gap-1 md:gap-0 md:flex-row justify-between md:items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "Marketing Solutions",
                path: "/super-admin/premium-products",
              },
              {
                name: "Premium Product Category List",
                path: "/super-admin/premium-products",
              },
              {
                name: "Offer",
                path: "/super-admin/premium-products/single-active-offer",
              },
            ]}
          />
          <div className="flex justify-end">
            <Link
              to="/super-admin/premium-products/create-offer"
              className="text-xs md:text-sm px-5 md:px-9 py-2 md:py-[10px] bg-[#C19A6B] text-white rounded-lg"
            >
              Create Offer
            </Link>
          </div>
        </div>

        {/* search  */}
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
            type="search"
            placeholder="Search…"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* filter */}

        <div className="flex gap-2 md:gap-6 md:py-3 text-xs md:text-sm">
          <NavLink
            to={"/super-admin/premium-products/single-active-offer"}
            onClick={() => setActiveRole("All")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            All
            <span
              className={` font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.length > 0
                ? data.endedPremiumOffers +
                  data.activePremiumOffers +
                  data.upcomingPremiumOffers
                : 0}
            </span>
          </NavLink>
          <NavLink
            to={
              "/super-admin/premium-products/single-active-offer?status=active"
            }
            onClick={() => setActiveRole("Active")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Active"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            ACTIVE
            <span
              className={` font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Active"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.length > 0 ? data.activePremiumOffers : 0}
            </span>
          </NavLink>
          <NavLink
            // to={"/super-admin/premium-products/single-active-offer"}
            to={
              "/super-admin/premium-products/single-active-offer?status=upcoming"
            }
            onClick={() => setActiveRole("Upcomming")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Upcomming"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            UPCOMING
            <span
              className={` font-semibold px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Upcomming"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.length > 0 ? data.upcomingPremiumOffers : 0}
            </span>
          </NavLink>
          <NavLink
            to={
              "/super-admin/premium-products/single-active-offer?status=ended"
            }
            onClick={() => setActiveRole("Ended")}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Ended" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ENDED
            <span
              className={` font-semibold px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Ended" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              {data?.data?.length > 0 ? data.endedPremiumOffers : 0}
            </span>
          </NavLink>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto ">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[12.5%] text-left">TITLE</span>

                <span className="w-[12.5%] text-left">STATUS</span>
                <span className="w-[10%] text-left">HIGHEST PAID</span>
                <span className="w-[15%] text-left">REGISTERATION STATUS </span>
                <span className="w-[25%] text-left">OFFER LIVE PERIOD </span>
                <span className="w-[25%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              {/* {data && data.data && data.data.allPremium.slice(0, 5).map((req) => ( */}
              {data && data?.data?.length > 0
                ? data.data.slice(0, 15).map((req) => (
                    <div
                      key={req.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                    >
                      <span className="w-[12.5%] text-left">{req.title}</span>

                      <div className="w-[12.5%] text-left">
                        <span
                          className={`${
                            req.live_status === "Active"
                              ? "border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-3  font-[400] rounded-lg"
                              : req.live_status === "Upcoming"
                              ? "border border-[#FEC600] bg-[#FFFBF0] text-[#FEC600] py-1 px-3  font-[400] rounded-lg"
                              : "border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-3  font-[400] rounded-lg"
                          }`}
                        >
                          {req.live_status}
                        </span>
                      </div>
                      <span className="w-[12.5%] text-left">
                        ${req.highest_bid}
                      </span>
                      <div className="w-[12.5%] text-left">
                        <span className="border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282] px-2 py-[3px] rounded-xl">
                          {req.registration_status}
                        </span>
                      </div>
                      <div className="w-[25%] text-left flex items-center gap-1 md:gap-2 text-nowrap">
                        <span className="border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-2 md:px-3  font-[600] rounded-lg">
                          {DateTimeDisplay(req.livestartdatetime)}
                        </span>{" "}
                        to{" "}
                        <span className="border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-2 md:px-3  font-[600] rounded-lg">
                          {DateTimeDisplay(req.liveenddatetime)}
                        </span>
                      </div>

                      <div className="w-[25%] text-left flex items-center gap-2">
                        <Link
                          to={`/super-admin/premium-products/offer-request-list/${req.id}`}
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src="/assets/icons/view.svg"
                            alt=""
                          />
                        </Link>
                        {/* <Link to="">
                  <img src="/assets/icons/ban.svg" alt="" />
                  </Link> */}
                        <span onClick={(e) => handleDelete(req.id)}>
                          <img
                            src="/assets/icons/manage-products/delete.svg"
                            alt=""
                            className="w-7 h-7 md:w-9 md:h-9"
                          />
                        </span>
                        <Link to="/super-admin/premium-products/offer-stats">
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src="/assets/icons/Edit1.svg"
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/super-admin/premium-products/update-premium-controller/${req.id}`}
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src="/assets/icons/edits.svg"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  ))
                : "No Result Found"}
            </div>
          </div>
        </div>

        {data?.data?.length > 10?(
          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              {/* SHOWING {startIndex + 1} - {endIndex}  */}
            </span>
            <div className="flex items-center justify-between gap-2 w-fit h-[40px] bg-white rounded-lg px-2">
              {/* Previous Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ):<></>}
      </div>
    </>
  );
}
