import React, { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./booster.css";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, updateAdminBooster } from "../../../store/adminSlice";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function BoosterSettingUpdate() {
  const {id}=useParams()
  const [packageType, setPackageType] = useState("");

  const [packageName, setPackageName] = useState("");

  const [timeSpan, setTimeSpan] = useState("");

  const [dailyBudget, setDailyBudget] = useState("");
  const navigate = useNavigate();
  const [packageDropdownOpen, setPackageDropdownOpen] = useState(false);
  const { loading, message ,error} = useSelector((state) => state.admin);
  const dispatch = useDispatch();


  const packages = ["Basic", "Moderate", "Premium"];


  const togglePackage = () => setPackageDropdownOpen(!packageDropdownOpen);

  const handlePackageNameChange = (e) => {
    setPackageName(e.target.value);
  };

  const handleDailyBudgetChange = (e) => {
    setDailyBudget(e.target.value);
  };



  const handlePackageSelect = (option) => {
    setPackageType(option);
    setPackageDropdownOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateAdminBooster({package_name:packageType,name:packageName,time_span:timeSpan,price:dailyBudget,id}))
  };
  useEffect(() => {
    
    if (message) {
      toast.success(message)
      dispatch(clearMessage()) 
      navigate("/super-admin/product-boosters/settings")
    } else if (error) {
      toast.error(error)
      dispatch(clearMessage()) 
      // navigate("/super-admin/product-boosters/settings")
    }
  }, [message,error, dispatch, navigate])

  return (
    <>
      <div className="px-3 ">
        {/* cards */}
        <div className="flex gap-10 w-1/3">
          {/* product 1 */}
          <div className="w-full">
            <form
              onSubmit={handleSubmit}
              className="border py-6 flex flex-col gap-5 px-4 border-[#EBF0ED] rounded-lg"
            >
              <h2 className={`px-2 text-[1.25rem] font-semibold `}>
                Product Booster
              </h2>

              {/* Package type  */}
              <div className="relative flex flex-col gap-2">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="budgetCategory"
                >
                  Package
                </label>
                <div className="relative w-full">
                  <input
                    className={`bg-[#FAFAFA] cursor-pointer rounded-lg w-full py-2 px-4 outline-none`}
                    type="text"
                    readOnly
                    placeholder="Basic"
                    value={packageType}
                    onClick={togglePackage}
                  />
                  <RiArrowDropDownLine
                    onClick={togglePackage}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#828282] w-6 h-6"
                  />

                  {packageDropdownOpen && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                      {packages.map((option) => (
                        <div
                          key={option}
                          className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handlePackageSelect(option)} // Updated onClick event
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Name */}
              <div className="flex flex-col gap-2">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="packageName"
                >
                  Package Name
                </label>
                <input
                  className={`bg-[#FAFAFA] rounded-lg w-full py-2 px-4 outline-none `}
                  type="text"
                  name="packageName"
                  placeholder="Basic"
                  value={packageName}
                  onChange={handlePackageNameChange}
                />
              </div>
              {/* Time Span */}
              <div className="relative flex flex-col gap-2">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="budgetCategory"
                >
                  Time Span
                </label>
                <div className="relative w-full">
                <input
                    className={`bg-[#FAFAFA] cursor-pointer rounded-lg w-full py-2 px-4 outline-none`}
                    type="number"
                    value={timeSpan}
                    // readOnly
                    onChange={(e)=>setTimeSpan(e.target.value)}
                    placeholder="1 day"
                  />
                  {/* <RiArrowDropDownLine
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#828282] w-6 h-6"
                    onClick={toggleTimeSpan}
                  />

                  {timeSpanDropdownOpen && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                      {timeSpanOptions.map((option) => (
                        <div
                          key={option}
                          className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleTimeSpanSelect(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )} */}
                </div>
              </div>
              {/* Daily Budget */}
              <div className="flex flex-col gap-2 numberInput">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="dailyBudget"
                >
                  1 Product Per Day
                </label>
                <input
                  className={`bg-[#FAFAFA] rounded-lg w-full py-2 px-4 outline-none `}
                  type="number"
                  name="dailyBudget"
                  placeholder="$10"
                  value={dailyBudget}
                  onChange={handleDailyBudgetChange}
                />
              </div>

              <div className="text-end">
                <button disabled={loading?true:false} className="bg-[#C19A6B] py-[0.65rem] px-9 rounded-lg text-center text-white">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
