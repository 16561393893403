import Countries from "../../../Components/My-Shop-Components/Countries/Countries";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function CountriesPage() {
  return (
    <MyShopLayout>
      <Countries />
    </MyShopLayout>
  );
}
