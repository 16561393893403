import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import SingleActiveOffer from "../../../Components/My-Shop-Components/PremiumProducts-Components/SingleActiveOffer";

export default function SingleActiveOfferPage() {
  return (
    <>
      <MyShopLayout>
        <SingleActiveOffer />
      </MyShopLayout>
    </>
  );
}
