import React from "react";
// import { IoIosTrendingUp } from "react-icons/io";
// import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import BoosterProductEarningChart from "../../Graphs/Product-Booster-Graph/BoosterProductEarningChart";
import BoosterProductSpendingChart from "../../Graphs/Product-Booster-Graph/BoosterProductSpendingChart copy";
import BreadCrums from "../../Common/BreadCrums";

export default function BoosterProductsDetails() {
  // const [earningFilterValue, setEarningFilterValue] = useState("Current");
  // const [earningFilterDropdownOpen, setEarningFilterDropdownOpen] =
  //   useState(false);
  // const [boosterFilterValue, setBoosterFilterValue] = useState("Current");
  // const [boosterFilterDropdownOpen, setBoosterFilterDropdownOpen] =
  //   useState(false);

  // const graphSummaryDropDownValues = ["Current", "Months", "Years"];

  // const toggleEarningDropdown = () => {
  //   setEarningFilterDropdownOpen(!earningFilterDropdownOpen);
  // };

  // const handleEarningSelect = (value) => {
  //   setEarningFilterValue(value);
  //   setEarningFilterDropdownOpen(false);
  // };

  // const toggleBoosterDropdown = () => {
  //   setBoosterFilterDropdownOpen(!earningFilterDropdownOpen);
  // };

  // const handleBoosterSelect = (value) => {
  //   setBoosterFilterValue(value);
  //   setBoosterFilterDropdownOpen(false);
  // };

  return (
    <div className="xl:px-4 flex flex-col gap-6 pb-2">
      <BreadCrums
        breadCrum={[
          {
            name: "Marketing Solutions",
            path: "/super-admin/product-boosters",
          },
          {
            name: "Booster Products",
            path: "/super-admin/product-boosters/stats",
          },
          {
            name: "Booster Details",
            path: "/super-admin/product-boosters/booster-details",
          },
          {
            name: " Booster Products Details",
            path: "/super-admin/product-boosters/booster-product-details",
          },
        ]}
      />

      {/* graphs  */}
      <div className="w-full flex flex-col md:flex-row justify-between gap-2 md:gap-12 items-center">
        {/* graph 1  */}
        <BoosterProductEarningChart />

        {/* graph 2  */}
        <BoosterProductSpendingChart />
      </div>

      <div className="flex justify-between md:justify-start md:gap-4">
        <div className="w-full md:w-fit flex flex-col gap-6 text-left p-2 md:p-7 shadow-sm rounded-lg md:min-w-52 max-w-full">
          <span className="font-semibold text-sm md:text-base lg:text-xl text-nowrap">
            Total Impressions
          </span>
          <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
            63
          </span>
        </div>

        <div className="w-full md:w-fit flex flex-col gap-6 text-left p-2 md:p-7 shadow-sm rounded-lg md:min-w-52 max-w-full">
          <span className="font-semibold text-sm md:text-base lg:text-xl">
            Total Clicks
          </span>
          <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
            60
          </span>
        </div>

        <div className="w-full md:w-fit flex flex-col gap-6 text-left p-2 md:p-7 shadow-sm rounded-lg md:min-w-52 max-w-full">
          <span className="font-semibold text-sm md:text-base lg:text-xl">
            Bought
          </span>
          <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
            60
          </span>
        </div>
      </div>
    </div>
  );
}
