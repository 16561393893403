import MainShopCategories from "../../../../../Components/My-Shop-Components/Manage-Categories/Manage-Main-Shop-Categories/Main-Categories/MainShopCategories";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function ManageMainShopCategoriesPage() {
  return (
    <MyShopLayout>
      <MainShopCategories />
    </MyShopLayout>
  );
}
