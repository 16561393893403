import BoosterDetails from "../../../Components/My-Shop-Components/Product-Boosters-Components/BoosterDetails";

import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function BoosterDetailsPage() {
  return (
    <MyShopLayout>
      <BoosterDetails />
    </MyShopLayout>
  );
}
