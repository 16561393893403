import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { storeListById } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";

const MallmanagmentStoreInMall = () => {
  const [orders, setOrders] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { id } = useParams();
  const { data} = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  // const fetchData = async () => {
  //   try {
  //     dispatch(storeListById(id));
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  useEffect(() => {
    dispatch(storeListById(id));
  }, [dispatch, id]);
  // Pagination control
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, orders.length);
  // const paginatedProducts = orders.slice(startIndex, endIndex);

  const shouldShowPagination = orders.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }
  useEffect(() => {}, [data]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "All Malls",
              path: "/super-admin/mall-managment",
            },
            {
              name: "Store List",
              path: "/super-admin/mall-managment/store",
            },
          ]}
        />

        {/* search */}
        <div className="flex justify-between gap-5">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
              type="search"
              placeholder="Search Stores..."
            />
          </div>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                {" "}
                <span className="w-[25%] text-left">STORE</span>
                <span className="w-[25%] text-left">TOTAL PRODUCT</span>
                <span className="w-[25%] text-left">OWNER NAME</span>
                <span className="w-[25%] text-left">ACTION</span>
              </div>

              {/* Table Data Row */}

              {data?.vendors?.length > 0 &&
                data?.vendors?.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                    >
                      <div className="flex  items-center gap-4 w-[25%] text-left">
                        <img
                          src={imagePath + item.shop_picture}
                          alt=""
                          className="w-7 h-7 md:w-10 md:h-10 rounded-lg"
                        />

                        <span className="">{item.user_name}</span>
                      </div>
                      <span className="w-[25%] text-left">
                        {item.product_count}
                      </span>

                      <span className="w-[25%] text-left ">
                        {item.store_name}
                      </span>

                      <span to="" className="w-[25%] text-left">
                        <img
                          src="/assets/icons/manage-products/view.svg"
                          alt=""
                          className="w-7 h-7 md:w-9 md:h-9 "
                        />
                      </span>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        {shouldShowPagination && (
          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} - {endIndex} OF {orders.length} RESULTS
            </span>
            <div className="flex items-center justify-between w-[265px] h-[40px] bg-white rounded-lg px-2">
              {/* Previous Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {paginationIndexes.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                        : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
              {/* Next Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MallmanagmentStoreInMall;
