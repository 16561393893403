import { Link } from "react-router-dom";
import BreadCrums from "../../../Common/BreadCrums";

export default function ManageReviews() {
  return (
    <>
      <div className="px-5 w-full flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Order & Reviews",
              path: "/super-admin/manage-reviews",
            },
            {
              name: "Manage Reviews",
              path: "/super-admin/manage-reviews",
            },
          ]}
        />

        <div className="flex items-center justify-between gap-4 mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Order …"
            />
          </div>

          <div className="flex items-center justify-center gap-2 p-4 rounded-lg w-[87px] h-[38px] bg-[#FAFAFA] border border-[#EBF0ED] ">
            <span>
              <img
                src="/assets/icons/order-management/export.svg"
                alt=""
                className="max-w-[9px] h-[18px]"
              />
            </span>
            <span className="font-semibold text-xs text-[#828282]">Export</span>
          </div>
        </div>

        <div className="flex items-center justify-between mt-2">
          <div className="flex gap-6">
            <div className="flex items-center gap-2 text-[#828282] hover:text-[#C19A6B] uppercase">
              <Link
                to="/dashboard/orders"
                className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]"
              >
                All Reviews
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px] bg-[#EBF0ED] border-[0.75px]  rounded-[5.75px]">
                <span className="text-[10.56px] ">(90)</span>
              </span>
            </div>

            <div className="flex items-center gap-2 text-[#828282] hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm ">With Images/Videos</Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  bg-[#EBF0ED] border-[0.75px]   rounded-[5.75px]">
                <span className="text-[10.56px]">(27)</span>
              </span>
            </div>

            <div className="flex items-center gap-2 text-[#828282]  hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]">
                Text Only
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  border-[0.75px] bg-[#EBF0ED] rounded-[5.75px]">
                <span className="text-[10.56px] ">(27)</span>
              </span>
            </div>
            <div className="flex items-center gap-2 text-[#828282]  hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]">
                Approved
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  border-[0.75px] bg-[#EBF0ED] rounded-[5.75px]">
                <span className="text-[10.56px] ">(27)</span>
              </span>
            </div>
            <div className="flex items-center gap-2 text-[#828282]  hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]">
                Replied
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  border-[0.75px] bg-[#EBF0ED] rounded-[5.75px]">
                <span className="text-[10.56px] ">(27)</span>
              </span>
            </div>
          </div>

          <div className="flex items-center justify-center gap-2 p-4 rounded-lg w-[86px] h-[21px] bg-[#FAFAFA] border border-[#EBF0ED] ">
            <span>
              <img src="/assets/icons/order-management/filters.svg" alt="" />
            </span>
            <span className="font-semibold text-xs text-[#828282]">Filter</span>
          </div>
        </div>

        {/* table */}
        <table className="w-full bg-white  border border-gray-300 border-separate rounded-lg mt-2">
          {/* Table Header */}
          <thead className=" w-full ">
            <tr className="flex w-full justify-between items-center gap-4  p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
              <th className="w-[110px] text-left">ORDER ID</th>
              <th className="w-[200px] text-left">PRODUCT</th>
              <th className="w-[200px] text-left">REVIEW</th>
              <th className="w-[120px] text-left">RATING</th>
              <th className="w-[100px] text-left">BUYER</th>
              <th className="w-[100px] text-left">ACTION</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="w-full">
            <tr
              // key={index}
              className="flex w-full justify-between items-center gap-4  p-4 border-b  font-semibold text-sm "
            >
              <td className="w-[110px]  ">#YU34692</td>
              <td className="w-[200px] ">
                <span className="flex items-center gap-3">
                  <span>
                    <img
                      src="/assets/images/shoes.png"
                      alt=""
                      className="max-w-10 h-10 rounded-[10px]"
                    />
                  </span>
                  <span className="flex flex-col">
                    <span className="font-semibold text-sm">Product Name</span>
                    <span className="font-medium text-sm text-[#6B6B6B]">
                      Category: Name
                    </span>
                  </span>
                </span>
              </td>

              <td className="w-[200px] flex flex-col gap-1 items-center">
                <span>Lorem ipsum dolor sit amet consectetur. </span>
                <span className="text-[#1983FF]">
                  Reply: Lorem ipsum dolor sit amet consectetur.
                </span>
              </td>
              <td className="w-[120px] flex flex-col ">
                <img
                  src="assets/icons/order-management/Rating.svg"
                  alt=""
                  className="max-w-[110px]"
                />
              </td>
              <td className="w-[100px]">Bairam Frootan</td>
              <td className="w-[100px]">
                <img src="assets/icons/order-management/Action.svg" alt="" />
              </td>
            </tr>
          </tbody>
        </table>

        {/* Pagination */}
      </div>
    </>
  );
}
