import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearMessage,
  mainCategory,
  subCategoriesList,
  subSubCategoriesList,
  subSubCategoryUpdate,
} from "../../../../../store/adminSlice";
import BreadCrums from "../../../../Common/BreadCrums";

export default function MainShopUpdateSubSubCategory() {
  const [categoryName, setCategoryName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { subSubCategories, error, message, loading } = useSelector(
    (state) => state.admin
  );
  const [temp, setTemp] = useState();

  useEffect(() => {
    dispatch(subSubCategoriesList());
    // dispatch(subCategoriesList());
    // dispatch(mainCategory())
  }, []);

  useEffect(() => {
    if (message && message === "SubSubCategory deleted successfully") {
      toast.success(message);
    }
    // console.log(message, error);
    dispatch(clearMessage());
  }, [error, message]);

  useEffect(() => {
    if (subSubCategories.subSubCategories) {
      setTemp(
        subSubCategories.subSubCategories.find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      );
    }
  }, [subSubCategories]);

  useEffect(() => {
    if (temp) {
      setCategoryName(temp.name);
    }
  }, [temp]);

  useEffect(() => {
    if (error === "The name field format is invalid.") {
      toast.error("The name field format is invalid");
    }
    if (error === "The name has already been taken.") {
      toast.error("The name has already been taken.");
    }
    if (message && message === "SubSubCategory updated successfully") {
      toast.success("SubSubCategory updated successfully");
      navigate(-1);
    }
    dispatch(clearMessage());
  }, [message, error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("_method", "PUT");

    if (categoryName != "") {
      formData.append("name", categoryName);
      dispatch(subSubCategoryUpdate({ formData, id: id }));
    } else {
      toast.error("Input field");
    }
  };

  return (
    <form className="xl:px-4 flex flex-col gap-4 w-full">
      {/* top bar */}
      <div className="flex flex-col md:flex-row gap-3 justify-between items-center w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Main Shop Category",
              path: "/super-admin/main-categories/",
            },
            {
              name: "Main Category",
              path: -2,
            },
            {
              name: "Sub Category",
              path: -1,
            },
            {
              name: "Update Category",
              path: `/super-admin/main-categories/sub-categories/create-category/${id}`,
            },
          ]}
        />

        <div className="flex justify-end w-full md:w-fit">
          {/* submit btn  */}
          {loading ? (
            <button
              disabled={loading ? true : false}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              <p className="animate-bounce">Loading</p>
            </button>
          ) : (
            <button
              disabled={loading ? true : false}
              onClick={handleSubmit}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              Update
            </button>
          )}
        </div>
      </div>

      {/* create form  */}
      <div className="flex flex-col gap-2">
        <p className="font-semibold text-lg">Update Category</p>

        <div className="  border border-[#EBF0ED] p-4 gap-10 rounded-lg">
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Category Name
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="text"
                name="name"
                id=""
                placeholder="name.."
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
