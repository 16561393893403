import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,useParams } from "react-router-dom";
import {
  clearMessage,
  subCategoriesListByID,
  subCategoryDelete,
  subCategorySearch,
} from "../../../../../store/adminSlice";
import BreadCrums from "../../../../Common/BreadCrums";
import { toast } from "react-toastify";

const MainShopSubCategories = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const { subCategories, loading, message, error } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    if (search === "") {
      dispatch(subCategoriesListByID(id));
    } else {
      dispatch(subCategorySearch(search));
    }
  }, [search]);

  useEffect(() => {
    dispatch(subCategoriesListByID(id));
  }, []);

  useEffect(() => {
    if(subCategories.subcategories){
      setData(subCategories.subcategories)
    } else {
      setData(subCategories.SubCategory)
    }
  }, [subCategories]);

  useEffect(() => {
    if (message && message === "Subcategory deleted successfully") {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error, message]);

  const handleSubCategoryDelete = (id) => {
    dispatch(subCategoryDelete(id)).then(() => {
      dispatch(subCategoriesListByID(id));
    });
  };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: " Main Shop Category",
              path: "/super-admin/main-categories/",
            },
            {
              name: "Main Category",
              path: `/super-admin/main-categories/sub-categories/${id}`,
            },
          ]}
        />

        <div className="flex justify-between gap-2">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="w-full py-2 md:py-[15px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs"
              type="search"
              placeholder="Search Sub Categories..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <Link
            to={`/super-admin/main-categories/sub-categories/create-category/${id}`}
            className="py-2 md:py-[15px] px-5 md:px-8  bg-[#C19A6B]  rounded-lg text-white text-center text-nowrap font-semibold text-xs md:text-sm"
          >
            Add Category
          </Link>
        </div>

        {/* table */}

        <div className=" flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[868px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between uppercase">
                <span className="w-[15%] text-left">CATEGORY NAME</span>

                <span className=" w-[12%] text-left">CATEGORY IMAGE</span>

                <span className=" w-[18%] text-left ">
                  Child Category Count
                </span>

                <span className="  w-[18%] text-left ">ACTION</span>
              </div>

              {loading ? (
                  <>
                    <div className="text-center">Loading</div>
                  </>
                ) : (
                  data?.length>0?
                  data.map((item) => (
                        <div
                          key={item.id}
                          className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                        >
                          <span className="w-[15%] text-left">{item.name}</span>

                          <span className="w-[12%] text-left">
                            <img
                              src={item.icon}
                              alt=""
                              className="w-10 h-10"
                            />
                          </span>

                          <span className="w-[18%] text-left">
                            {item.subsubcategory_count}
                          </span>

                          <span className="flex items-center gap-2 w-[18%] text-left">
                            <Link
                              to={`/super-admin/main-categories/sub-sub-categories/${item.id}`}
                            >
                              <img
                                src="/assets/icons/manage-products/view.svg"
                                alt=""
                                className="w-9 h-9"
                              />
                            </Link>
                            <Link
                              to={`/super-admin/main-categories/sub-categories/update-category/${item.id}`}
                            >
                              <img
                                src="/assets/icons/manage-products/edit.svg"
                                alt=""
                                className="w-9 h-9"
                              />
                            </Link>
                            <span
                              onClick={(e) => handleSubCategoryDelete(item.id)}
                            >
                              <img
                                src="/assets/icons/manage-products/delete.svg"
                                alt=""
                                className="w-9 h-9"
                              />
                            </span>
                          </span>
                        </div>
                      ))
                    :"No Sub Category Found")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainShopSubCategories;
