import CustomerReturnsClosedPage from "../Pages/My-Shop/Order-Management-Pages/Customer-Returrns-Page/CustomerReturnsClosedPage";
import CustomerReturnsPage from "../Pages/My-Shop/Order-Management-Pages/Customer-Returrns-Page/CustomerReturnsPage";

const customerReturnRoutes = [


    {
        path: "/super-admin/customer-returns",
        children: [{
                index: true,
                element: < CustomerReturnsPage /> ,
            },
            {
                path: "super-admin/customer-returns/closed",
                element: < CustomerReturnsClosedPage /> ,
            },
        ],
    },

]

export default customerReturnRoutes;