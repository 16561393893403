import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

const ProductManagmentManageProduct = () => {
  const [product, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    // Fetch data from the backend
    const fetchData = async () => {
      try {
        const response = await fetch("/viewoffer.json");
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Pagination control
  const totalPages = Math.ceil(product.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedProducts = product.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <>
      <div className="px-3 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Products",
              path: "/super-admin/product-managment/manage-product",
            },
          ]}
        />

        {/* search */}
        <div className="flex justify-between gap-7 items-center">
          <div className="relative flex items-center w-[60%]">
            <svg
              className="absolute left-3"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z"
                fill="#036666"
              />
            </svg>
            <input
              className="w-full h-12 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
              type="search"
              placeholder="Search product ..."
            />
          </div>
          <div className="flex gap-4 w-[40%]">
            <Link className="px-6 py-[0.95rem] text-center text-sm font-semibold text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Bulk Actions
            </Link>
            <button className="px-6 py-[0.95rem] text-center text-sm font-semibold text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Export Products
            </button>
            <button className="px-6 py-[0.95rem] text-sm font-semibold text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg">
              Add Product
            </button>
          </div>
        </div>
        {/* filter */}

        <div className="flex gap-6 py-2">
          <span className="text-[#C19A6B]  tracking-wide text-sm font-semibold ">
            ALL PRODUCTS
            <span className="text-[#C19A6B] border border-[#C19A6B] bg-[#FAFAFA] rounded-lg p-2 ml-1">
              208
            </span>
          </span>
          <span className="text-[#828282]  tracking-wide text-sm font-semibold ">
            AVAILABLE
            <span className="text-[#828282] border border-#EBF0ED] bg-[#FAFAFA] rounded-lg p-2 ml-1">
              90
            </span>
          </span>
          <span className="text-[#828282]  tracking-wide text-sm font-semibold ">
            DISABLED
            <span className="text-[#828282] border border-#EBF0ED] bg-[#FAFAFA] rounded-lg p-2 ml-1">
              80
            </span>
          </span>
          <span className="text-[#828282]  tracking-wide text-sm font-semibold ">
            DRAFT
            <span className="text-[#828282] border border-#EBF0ED] bg-[#FAFAFA] rounded-lg p-2 ml-1">
              8
            </span>
          </span>
        </div>

        {/* table */}

        <table className="w-full bg-white rounded-lg border border-[#EBF0ED] px-5">
          {/* Table Header */}

          <thead className=" border-b rounded-xl border-[#EBF0ED] h-12 rounded-t-lg">
            <tr className="font-semibold text-[12px] text-[#6B6B6B] py-3">
              <th className="px-4 text-left">
                {" "}
                <input type="checkbox" name="" id="" />{" "}
                <span className="ml-2">PRODUCT </span>
              </th>
              <th className="px-4 text-center">PRICE</th>
              <th className="px-4 text-center">STATUS</th>
              <th className="px-4 text-center">STOCK</th>

              <th className="px-4 text-center">TOTAL EARNING</th>
              <th className="px-4 text-center">ACTION</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {paginatedProducts.slice(0, 7).map((item, index) => (
              <tr
                key={index}
                className="text-[#18120F] text-sm border-b border-[#EBF0ED] h-16 rounded-b-md"
              >
                <td className="px-4 mt-2 text-left flex gap-3 font-[600]">
                  <input type="checkbox" name="" id="" />
                  <img
                    src={item.img}
                    className="w-10 h-10 text-center"
                    alt=""
                  />
                  <div className="flex flex-col">
                    <span>{item.name}</span>
                    <span>{item.category}</span>
                  </div>
                </td>
                <td className="px-4 text-center font-[600]">${item.price}</td>
                <td className="px-4 text-center">
                  <span
                    className={`${
                      item.status === "In Stock"
                        ? "bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                        : "bg-[#FFF3F4] text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl"
                    }`}
                  >
                    {item.status}
                  </span>
                </td>
                <td className="px-4 text-center font-[600]">{item.price}</td>
                <td className="px-4 text-center font-[600]">
                  ${item.totalearning}
                </td>
                <td className="px-4 text-center">
                  <div className="flex gap-2 justify-center">
                    <Link
                      to="/offer-managment/view-product"
                      className="bg-[#FAFAFA] flex items-center justify-center rounded-xl p-2 border border-[#EBF0ED]"
                    >
                      <svg
                        width="17"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 6.69999C16 7.96011 12.6421 12.3999 8.49999 12.3999C4.35785 12.3999 1 7.96011 1 6.69999C1 5.43988 4.35785 1 8.49999 1C12.6421 1 16 5.43988 16 6.69999Z"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.50078 8.68052C9.76878 8.68052 10.482 7.96727 10.482 6.69927C10.482 5.43127 9.76878 4.71802 8.50078 4.71802C7.23278 4.71802 6.51953 5.43127 6.51953 6.69927C6.51953 7.96727 7.23278 8.68052 8.50078 8.68052Z"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>
                    <Link
                      to="/product-managment/edit-product"
                      className="bg-[#FAFAFA] rounded-xl p-2 border border-[#EBF0ED]"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.58358 2.95312C5.53304 3.00143 4.50423 3.11493 3.50078 3.23086C2.93519 3.29756 2.40869 3.55323 2.00653 3.95648C1.60438 4.35973 1.35014 4.88693 1.28498 5.4527C1.14007 6.75441 1 8.10079 1 9.47737C1 10.8552 1.14007 12.2015 1.28498 13.5045C1.41539 14.6637 2.34156 15.5898 3.50078 15.7239C4.80973 15.8748 6.16216 16.0258 7.54598 16.0258C8.93101 16.0258 10.2834 15.8748 11.5924 15.7239C12.1574 15.657 12.6833 15.4016 13.0852 14.9989C13.4871 14.5961 13.7414 14.0696 13.807 13.5045C13.9217 12.5493 14.007 11.5909 14.063 10.6306"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.2823 1.61651L7.8326 6.66395L7.224 9.53182C7.12499 9.99309 7.60679 10.4302 8.0572 10.2877L10.9094 9.39174L15.5052 4.55562C16.2696 3.75261 16.1355 2.40864 15.2118 1.59115C14.3097 0.794184 12.9984 0.805052 12.2823 1.61651Z"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>
                    <div className="bg-[#FAFAFA] rounded-xl p-2 border border-[#EBF0ED]">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.60156 7.61328L3.10261 14.5167C3.18469 15.6477 4.02312 16.597 5.16507 16.7472C7.72603 17.084 10.2771 17.084 12.8381 16.7472C13.98 16.597 14.8184 15.6477 14.9006 14.5167L15.4016 7.61328"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 4.55957H17"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.79688 4.4642L6.42073 2.7758C6.81465 1.70969 7.84418 1 8.99688 1C10.1496 1 11.1791 1.70969 11.573 2.7758L12.1969 4.4642"
                          stroke="#828282"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center my-4">
          <span className="text-sm text-[#6B6B6B] font-semibold">
            SHOWING {startIndex + 1} -{" "}
            {Math.min(startIndex + itemsPerPage, product.length)} OF{" "}
            {product.length} RESULTS
          </span>
          <div className="flex items-center justify-between w-[265px] h-[40px] bg-white  rounded-lg px-2">
            {/* Previous Button */}
            <div className="h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]">
              <svg
                onClick={() => handlePageChange(currentPage - 1)}
                className={`${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed "
                    : "text-blue-500"
                }`}
                disabled={currentPage === 1}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#828282"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {/* Page Numbers */}
            <div className="flex items-center gap-2">
              {Array.from({ length: totalPages }, (_, i) => i + 1)
                .slice(0, 3)
                .map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white h-10 w-10  rounded-xl border border-[#EBF0ED] "
                        : " h-10 w-10 bg-[#FAFAFA]  rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
            </div>

            {/* Next Button */}
            <div className="h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]">
              <svg
                onClick={() => handlePageChange(currentPage + 1)}
                className={`${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                disabled={currentPage === totalPages}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#828282"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductManagmentManageProduct;
