import ActiveOfferRequestPage from "../Pages/My-Shop/Premium-Products-Pages/ActiveOfferRequestPage";
import CreateOfferPage from "../Pages/My-Shop/Premium-Products-Pages/CreateOfferPage";
import MainCategoryStatsPage from "../Pages/My-Shop/Premium-Products-Pages/MainCategoryStatsPage";
import OfferStatsPage from "../Pages/My-Shop/Premium-Products-Pages/OfferStatsPage";
import PremiumOffersPage from "../Pages/My-Shop/Premium-Products-Pages/PremiumOffersPage";
import SingleActiveOfferPage from "../Pages/My-Shop/Premium-Products-Pages/SingleActiveOfferPage";
import UpdatePremiumControllerPage from "../Pages/My-Shop/Premium-Products-Pages/UpdatePremiumControllerPage";

const premiumProductsRoutes = [

    {
        path: "/super-admin/premium-products",

        children: [{
            index: true,
            element: < PremiumOffersPage /> ,
            
        },
        {
            path: "/super-admin/premium-products/main-category-stats",
            element: < MainCategoryStatsPage /> ,
        },
        {
            path: "/super-admin/premium-products/single-active-offer",
            element: < SingleActiveOfferPage /> ,
        },
        {
            path: "/super-admin/premium-products/offer-stats",
            element: < OfferStatsPage /> ,
        },
        {
            path: "/super-admin/premium-products/offer-request-list/:id",
            element: < ActiveOfferRequestPage /> ,
        },
        {
            path: "/super-admin/premium-products/create-offer",
            element: < CreateOfferPage /> ,
        },
        {
            path: "/super-admin/premium-products/update-premium-controller/:id",
            element: < UpdatePremiumControllerPage /> ,
        },
    
    ],
    
    
    },

]

export default premiumProductsRoutes;