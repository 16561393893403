import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { vendorProductList, vendorProductListSearch } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

const VendorProductList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  const [activeRole, setActiveRole] = useState("All");

  useEffect(() => {
    dispatch(vendorProductList({ id: id }));
    // // console.log(data)
  }, [dispatch, id]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeRole]);

  useEffect(() => {
    if (search === "") {
      dispatch(vendorProductList({id:id}));
    } else {
      dispatch(vendorProductListSearch({id:id,search:search}));
    }
  }, [search, dispatch, id]);

  const handleRoleChange = (status) => {
    setActiveRole(status);
    if (status === "All") {
      dispatch(vendorProductList({ id: id }));
    } else {
      dispatch(vendorProductList({ id: id, status: status }));
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  // const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  // console.log(setTotalPages)

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      if (activeRole === "All") {
        dispatch(vendorProductList({ id: id, page: page }));
      } else {
        dispatch(vendorProductList({ page: page, id: id, status: activeRole }));
      }
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Shop List", path: "/super-admin/shop-list/" },
            {
              name: "Shop Details",
              path: -1,
            },
            {
              name: "Vendor Product List",
              path: `/super-admin/shop-list/shop-details/vendor-product-list/${id}`,
            },
          ]}
        />

        {/* search */}
        <div className="relative flex items-center w-full">
          <img
            className="absolute left-3"
            src="/assets/icons/search.svg"
            alt=""
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs"
            type="search"
            placeholder="Search Products..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* filter  */}
        <div className="flex items-center justify-between mt-2 text-xs md:text-sm">
          <div className="flex gap-6">
            <NavLink
              onClick={() => handleRoleChange("All")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
              }`}
            >
              ALL PRODUCTS
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border  rounded-[5.75px] ${
                  activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data && data.data?.total_product
                  ? data.data?.total_product
                  : 0}
              </span>
            </NavLink>

            <NavLink
              onClick={() => handleRoleChange("instock")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "instock"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              AVAILABLE
              <span
                className={`font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border  rounded-[5.75px]  ${
                  activeRole === "instock"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data && data?.data?.available_products
                  ? data.data.available_products
                  : 0}
              </span>
            </NavLink>

            <NavLink
              onClick={() => handleRoleChange("outofstock")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "outofstock"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              OUT OF STOCK
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border rounded-[5.75px] ${
                  activeRole === "outofstock"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data && data?.data?.outofStock_products
                  ? data.data.outofStock_products
                  : 0}
              </span>
            </NavLink>
          </div>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className=" flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                {" "}
                <span className="w-[25%] text-left">PRODUCT </span>
                <span className="w-[15%] text-left">PRICE</span>
                <span className="w-[15%] text-left">STATUS</span>
                <th className="w-[15%] text-left">STOCK</th>
                <span className="w-[15%] text-left">TOTAL EARNING</span>
                <span className="w-[15%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>

                </tr>
              ) : data?.data?.products && data.data.products.length > 0 ? (
                data.data.products.map((prodct) => (
                  <div
                    key={prodct.product_id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[25%] text-left mt-2  flex gap-3 font-[600]">
                      <img
                        src={prodct.image}
                        className="w-10 h-10 text-center rounded-lg"
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span>{prodct.name}</span>
                        <span className="text-[#6B6B6B] text-sm font-[500]">
                          Category: {prodct.category_name}
                        </span>
                      </div>
                    </div>
                    <span className="w-[15%] text-left font-[600]">
                      ${prodct.price}

                    </span>
                    <div className="w-[15%] text-left">
                      <span
                        className={`${
                          prodct.stock_status === "in stock"
                            ? "bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                            : "bg-[#FFF3F4] text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl"
                        }`}
                      >
                        {prodct.stock_status}
                      </span>
                    </div>
                    <span className="w-[15%] text-left font-[600]">
                      {prodct.quantity}
                    </span>
                    <span className="w-[15%] text-left font-[600]">
                      ${prodct.total_earning}
                    </span>
                    <div className="w-[15%] text-left">
                      <div className="flex gap-2 justify-center">
                        <Link
                          // to="/offer-managment/view-product"
                          className="bg-[#FAFAFA] flex items-center justify-center rounded-xl p-2 border border-[#EBF0ED]"
                        >
                          <img
                            className="w-6 h-6"
                            src="/assets/icons/view-b.svg"
                            alt=""
                          />
                        </Link>

                        {/* <div className="bg-[#FAFAFA] rounded-xl p-2 border border-[#EBF0ED]">
                          <img src="/assets/icons/delete-b.svg" alt="" />
                        </div> */}
                      </div>
                    </div>

                  </div>
                ))
              ) : (
                "No Products found."
              )}
            </div>
          </div>

        </div>
        {!loading && data?.data && data?.data?.products?.length > 10 ? (

          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              {/* SHOWING {data.from +"-"+ data.to} OF {data.total}  */}
            </span>
            <div className="flex items-center justify-between w-fit gap-2 h-[40px] bg-white rounded-lg px-2">
              {/* Previous Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>

        ) : (
          <></>
        )}

      </div>
    </>
  );
};

export default VendorProductList;
