import AddNewManagerPage from "../Pages/My-Shop/Role-Managment-page/AddNewManagerPage";
import EditManagerPage from "../Pages/My-Shop/Role-Managment-page/EditManagerPage";
import ManagerListPage from "../Pages/My-Shop/Role-Managment-page/ManagerListPage";
import RoleManagmentAddNewPage from "../Pages/My-Shop/Role-Managment-page/RoleManagmentAddNewPage";
import RoleManagmentSettingPage from "../Pages/My-Shop/Role-Managment-page/RoleManagmentSettingPage";
import RoleManagmentViewPage from "../Pages/My-Shop/Role-Managment-page/RoleManagmentViewPage";

const roleManagementRoute = [
  {
    path: "/super-admin/role-managment",
    children: [
      {
        index: true,
        element: <RoleManagmentAddNewPage />,
      },
      {
        path: "/super-admin/role-managment/setting",
        element: <RoleManagmentSettingPage />,
      },
      {
        path: "/super-admin/role-managment/view",
        element: <RoleManagmentViewPage />,
      },
      {
        path: "/super-admin/role-managment/manager-list",
        element: <ManagerListPage />,
      },
      {
        path: "/super-admin/role-managment/add-new-manager",
        element: <AddNewManagerPage />,
      },
      {
        path: "/super-admin/role-managment/manager-list/edit-manager",
        element: <EditManagerPage />,
      },
    ],
  },
];

export default roleManagementRoute;
