import OfferManagmentCreateOfferPage from "../Pages/My-Shop/Offer-Managment/OfferManagmentCreateOfferPage";
import OfferManagmentEditOfferPage from "../Pages/My-Shop/Offer-Managment/OfferManagmentEditOfferPage";
import OfferManagmentOffersPage from "../Pages/My-Shop/Offer-Managment/OfferManagmentOffersPage";
import OfferManagmentViewOfferPage from "../Pages/My-Shop/Offer-Managment/OfferManagmentViewOfferPage";
import OfferManagmentViewProductPage from "../Pages/My-Shop/Offer-Managment/OfferManagmentViewProductPage";
import OfferStatsPage from "../Pages/My-Shop/Offer-Managment/OfferStatsPage";
import ProductsRegisteredPage from "../Pages/My-Shop/Offer-Managment/ProductsRegisteredPage";
import SellersRegisteredPage from "../Pages/My-Shop/Offer-Managment/SellersRegisteredPage";

const offerManagementRoutes = [
  {
    path: "/super-admin/offer-managment/",
    children: [
      {
        index: true,
        element: <OfferManagmentOffersPage />,
      },
      {
        path: "/super-admin/offer-managment/offer-stats/:id",
        element: <OfferStatsPage />,
      },
      {
        path: "/super-admin/offer-managment/create-offer",
        element: <OfferManagmentCreateOfferPage />,
      },

      {
        path: "/super-admin/offer-managment/view-offer/:id",
        element: <OfferManagmentViewOfferPage />,
      },
      {
        path: "/super-admin/offer-managment/edit-offer/:id",
        element: <OfferManagmentEditOfferPage />,
      },
      {
        path: "/super-admin/offer-managment/view-product/:id",
        element: <OfferManagmentViewProductPage />,
      },
      {
        path: "/super-admin/offer-managment/sellers-registered/:id",
        element: <SellersRegisteredPage />,
      },
      {
        path: "/super-admin/offer-managment/products-registered/:id",
        element: <ProductsRegisteredPage />,
      },
    ],
  },
];

export default offerManagementRoutes;
