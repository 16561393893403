import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import MallmanagmentStoreInMall from "../../../Components/My-Shop-Components/Mall-Managment-Components/MallmanagmentStoreInMall";

export default function MallManagmentStoreInmallPage() {
  return (
    <>
      <MyShopLayout>
        <MallmanagmentStoreInMall />
      </MyShopLayout>
    </>
  );
}
