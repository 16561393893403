import MainShopSubSubCategories from "../../../../../Components/My-Shop-Components/Manage-Categories/Manage-Main-Shop-Categories/Sub-Sub-Categories/MainShopSubSubCategories";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function MainShopSubSubCategoriesPage() {
  return (
    <MyShopLayout>
      <MainShopSubSubCategories />
    </MyShopLayout>
  );
}
