import { useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mallListGetByID } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";

export default function MallDetails() {
  const { id } = useParams();
  const { data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(mallListGetByID(id));
  }, [id, dispatch]);

  return (
    <div className="xl:px-4 flex flex-col gap-4 pb-2">
      <BreadCrums
        breadCrum={[
          {
            name: "All Malls",
            path: "/super-admin/mall-managment",
          },
          {
            name: "Mall Details",
            path: "/super-admin/mall-managment/mall-details",
          },
        ]}
      />

      {/* user info  */}
      <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
        {/* user image and name  */}
        <div className="flex items-center gap-2">
          <span className="">
            <img
              src={data && data.image}
              alt=""
              className="w-14 h-14 rounded-full"
            />
          </span>
        </div>

        {/* user details  */}
        <div className=" w-full flex flex-col md:flex-row justify-between items-start gap-2 md:gap-16">
          {/* 1st  */}
          <div className=" w-full flex flex-col">
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-3">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                MALL NAME :
              </span>
              <span className="font-semibold text-xs">
                {data && data.mall_name}
              </span>
            </div>
            {/* <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-3">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                PHONE NUMBER :
              </span>
              <span className="font-semibold text-xs">
                {data && data.number}
              </span>
            </div> */}
            <div className=" w-full flex justify-between  py-3 border-b border-[#EBF0ED] md:border-0">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                E-MAIL :
              </span>
              <span className="font-semibold text-xs">
                {data && data.email}
              </span>
            </div>
          </div>

          {/* 2nd  */}
          <div className=" w-full flex flex-col">
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-3">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Customer Support Number :
              </span>
              <span className="font-semibold text-xs">
                {data && data.support_number}
              </span>
            </div>
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-3">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Location :
              </span>
              <span className="font-semibold text-xs">
                {data && data.country + "," + data.city}
              </span>
            </div>
            <div className=" w-full flex justify-between  py-3 border-b border-[#EBF0ED] md:border-0">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Stores Registered :
              </span>
              <span className="font-semibold text-xs">
                {data && data.store_count}
              </span>
            </div>
          </div>

          {/* 3rd */}
          <div className=" w-full flex flex-col ">
            <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
              VERIFICATION DOCUMENT :
            </span>
            <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full min-h-[70px]   mt-2">
              <img
                src={data && data.cheque_copy_image? data?.cheque_copy_image:data.mall_legal_document1? data.mall_legal_document1:data.mall_legal_document2? data.mall_legal_document2:data.mall_legal_document3}
                alt=""
                className="w-full max-h-[100px]"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
