import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LuLayoutDashboard, LuSunMedium } from "react-icons/lu";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { CgMenuBoxed } from "react-icons/cg";
import { BiCategory } from "react-icons/bi";
import { VscMegaphone } from "react-icons/vsc";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbUsers } from "react-icons/tb";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";

import "./side-bar.css";
import { logout } from "../../store/adminSlice";

export default function MyShopSidebar() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const { signout } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Automatically open the dropdown of the active nav link
    if (
      location.pathname.startsWith("/super-admin/hotspot-images") ||
      location.pathname.startsWith(
        "/super-admin/hero-section-image-selection"
      ) ||
      location.pathname.startsWith("/super-admin/update-hotspot-images/")
    ) {
      setOpenDropdown("hotSpot");
    } else if (
      location.pathname.startsWith("/super-admin/shop-list/") ||
      location.pathname.startsWith("/super-admin/vendor-requests/") ||
      location.pathname.startsWith("/super-admin/users-list/")
    ) {
      setOpenDropdown("shopList");
    } else if (
      location.pathname.startsWith("/super-admin/main-categories") ||
      location.pathname.startsWith("/super-admin/ad-market-place-categories") ||
      location.pathname.startsWith("/super-admin/auction-categories") ||
      location.pathname.startsWith("/super-admin/brands")
    ) {
      setOpenDropdown("category");
    } else if (
      location.pathname.startsWith("/super-admin/offer-managment") ||
      location.pathname.startsWith("/super-admin/product-boosters") ||
      location.pathname.startsWith("/super-admin/premium-products") ||
      location.pathname.startsWith("/super-admin/store-boosters") ||
      location.pathname.startsWith("/super-admin/banner-ads")
    ) {
      setOpenDropdown("marketing");
    } else if (
      location.pathname.startsWith("/super-admin/mall-managment") ||
      location.pathname.startsWith("/super-admin/mall-managment/mall-request")
    ) {
      setOpenDropdown("mall");
    } else if (
      location.pathname.startsWith("/super-admin/role-managment") ||
      location.pathname.startsWith(
        "/super-admin/role-managment/manager-list"
      ) ||
      location.pathname.startsWith(
        "/super-admin/role-managment/add-new-manager"
      )
    ) {
      setOpenDropdown("roll");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (signout) {
      localStorage.clear();
      navigate("/");
    }
  }, [signout, navigate]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="">
        <div className="w-64 h-[100vh] xl:h-[95vh]  bg-[#C19A6B] rounded-[0.7rem] hide-scrollbar overflow-y-auto">
          {/* shop name */}
          <div className=" px-4 py-4">
            <div className="flex gap-2 items-center mb-4">
              <div>
                <img
                  className="h-16 w-16"
                  src="/assets/images/my-shop-sidebar/shop-profile.svg"
                  alt=""
                />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <div className="text-white font-medium tracking-wide">
                    Shop Name
                  </div>
                </div>
              </div>
            </div>
            {/* hr */}
            <hr
              className="h-[0.1rem]"
              style={{
                borderRadius: "50%",
              }}
            />
          </div>

          <div className="flex flex-col gap-2 px-4">
            {/* dashboard  */}
            <NavLink
              to="/super-admin/dashboard"
              className={({ isActive }) =>
                isActive ? "activeLink" : "navLink"
              }
              end
            >
              <LuLayoutDashboard className="w-4 h-4" />
              Dashboard
            </NavLink>

            {/* hot-spot section  */}
            <div className="flex  flex-col ">
              <div
                onClick={() => toggleDropdown("hotSpot")}
                className={`${
                  location.pathname.startsWith("/super-admin/hotspot-images") ||
                  location.pathname.startsWith(
                    "/super-admin/hero-section-image-selection"
                  ) ||
                  location.pathname.startsWith(
                    "/super-admin/update-hotspot-images/"
                  )
                    ? "activeLink"
                    : "navLink"
                }`}
              >
                <LuSunMedium className="w-4 h-4" />
                <div className="w-full flex justify-between  items-center">
                  Hot-Spot Section
                  <span>
                    {openDropdown === "hotSpot" ? (
                      <RiArrowDropUpLine className="!w-6 !h-6  right-6" />
                    ) : (
                      <RiArrowDropDownLine className="!w-6 !h-6  right-6" />
                    )}
                  </span>
                </div>
              </div>
              {/* hot-spot dropdown  */}
              {openDropdown === "hotSpot" && (
                <>
                  <NavLink
                    to="/super-admin/hotspot-images"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                    end
                  >
                    Hot-Spot List
                  </NavLink>
                  <NavLink
                    to="/super-admin/hero-section-image-selection" //dummy url
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    {" "}
                    Add Hot-Spot
                  </NavLink>
                </>
              )}
            </div>

            {/* shop list section  */}
            <div className="flex  flex-col ">
              <NavLink
                onClick={() => toggleDropdown("shopList")}
                className={`${
                  location.pathname.startsWith("/super-admin/shop-list/") ||
                  location.pathname.startsWith(
                    "/super-admin/vendor-requests/"
                  ) ||
                  location.pathname.startsWith("/super-admin/users-list/")
                    ? "activeLink"
                    : "navLink"
                }`}
              >
                <CgMenuBoxed className="w-4 h-4" />
                <div className="w-full flex justify-between items-center ">
                  Shop & User List
                  {openDropdown === "shopList" ? (
                    <RiArrowDropUpLine className="!w-6 !h-6 right-6" />
                  ) : (
                    <RiArrowDropDownLine className="!w-6 !h-6  right-6" />
                  )}
                </div>
              </NavLink>
              {/* shop list dropdown  */}
              {openDropdown === "shopList" && (
                <>
                  <NavLink
                    to="/super-admin/shop-list/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Shop List
                  </NavLink>
                  <NavLink
                    to="/super-admin/vendor-requests/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Shop Request List
                  </NavLink>
                  <NavLink
                    to="/super-admin/users-list/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    User List
                  </NavLink>
                </>
              )}
            </div>

            {/* CAtegory and brand section  */}
            <div className="flex  flex-col ">
              <span
                onClick={() => toggleDropdown("category")}
                className={`${
                  location.pathname.startsWith(
                    "/super-admin/main-categories"
                  ) ||
                  location.pathname.startsWith(
                    "/super-admin/ad-market-place-categories"
                  ) ||
                  location.pathname.startsWith(
                    "/super-admin/auction-categories"
                  ) ||
                  location.pathname.startsWith("/super-admin/brands")
                    ? "activeLink"
                    : "navLink"
                }`}
              >
                <BiCategory className="w-4 h-4" />
                <div className="w-full flex justify-between items-center ">
                  Categories & Brand
                  {openDropdown === "category" ? (
                    <RiArrowDropUpLine className="!w-6 !h-6  right-6" />
                  ) : (
                    <RiArrowDropDownLine className="!w-6 !h-6  right-6" />
                  )}
                </div>
              </span>
              {/* category and brand dropdown  */}
              {openDropdown === "category" && (
                <>
                  <NavLink
                    to="/super-admin/main-categories/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Main Store
                  </NavLink>
                  <NavLink
                    to="/super-admin/ad-market-place-categories/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Ad Market Place
                  </NavLink>
                  <NavLink
                    to="/super-admin/auction-categories/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Auction
                  </NavLink>
                  <NavLink
                    to="/super-admin/brands/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Brands
                  </NavLink>
                </>
              )}
            </div>

            {/* Marketing Solutions section  */}
            <div className="flex  flex-col ">
              <NavLink
                onClick={() => toggleDropdown("marketing")}
                className={`${
                  location.pathname.startsWith(
                    "/super-admin/offer-managment"
                  ) ||
                  location.pathname.startsWith(
                    "/super-admin/product-boosters"
                  ) ||
                  location.pathname.startsWith(
                    "/super-admin/premium-products"
                  ) ||
                  location.pathname.startsWith("/super-admin/store-boosters") ||
                  location.pathname.startsWith("/super-admin/banner-ads")
                    ? "activeLink"
                    : "navLink"
                }`}
              >
                <VscMegaphone className="w-4 h-4" />
                <div className="w-full flex justify-between items-center">
                  Marketing Solutions
                  {openDropdown === "marketing" ? (
                    <RiArrowDropUpLine className="!w-6 !h-6  right-6" />
                  ) : (
                    <RiArrowDropDownLine className="!w-6 !h-6  right-6" />
                  )}
                </div>
              </NavLink>
              {/* Marketing Solutions dropdown  */}
              {openDropdown === "marketing" && (
                <>
                  <NavLink
                    to="/super-admin/offer-managment/"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Offers
                  </NavLink>
                  <NavLink
                    to="/super-admin/product-boosters"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Product Boosters
                  </NavLink>
                  <NavLink
                    to="/super-admin/premium-products"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Premium Products
                  </NavLink>
                  <NavLink
                    to="/super-admin/store-boosters"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Store Booster
                  </NavLink>
                  <NavLink
                    to="/super-admin/banner-ads"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Banner Ads
                  </NavLink>
                </>
              )}
            </div>

            {/* Mall Management section  */}
            <div className="flex  flex-col ">
              <NavLink
                onClick={() => toggleDropdown("mall")}
                className={`${
                  location.pathname.startsWith("/super-admin/mall-managment") ||
                  location.pathname.startsWith(
                    "/super-admin/mall-managment/mall-request"
                  )
                    ? "activeLink"
                    : "navLink"
                }`}
              >
                <HiOutlineOfficeBuilding className="w-4 h-4" />
                <div className="w-full flex justify-between items-center">
                  Mall Management
                  {openDropdown === "mall" ? (
                    <RiArrowDropUpLine className="!w-6 !h-6  right-6" />
                  ) : (
                    <RiArrowDropDownLine className="!w-6 !h-6  right-6" />
                  )}
                </div>
              </NavLink>
              {/* Mall Management dropdown  */}
              {openDropdown === "mall" && (
                <>
                  <NavLink
                    to="/super-admin/mall-managment"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                    end
                  >
                    Mall List
                  </NavLink>
                  <NavLink
                    to="/super-admin/mall-managment/mall-request"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Mall Requests
                  </NavLink>
                </>
              )}
            </div>

            {/* Roll Management section  */}
            <div className="flex  flex-col ">
              <NavLink
                onClick={() => toggleDropdown("roll")}
                className={`${
                  location.pathname.startsWith("/super-admin/role-managment") ||
                  location.pathname.startsWith(
                    "/super-admin/role-managment/manager-list"
                  ) ||
                  location.pathname.startsWith(
                    "/super-admin/role-managment/add-new-manager"
                  )
                    ? "activeLink"
                    : "navLink"
                }`}
              >
                <TbUsers className="w-4 h-4" />
                <div className="w-full flex items-center justify-between">
                  Role & Managers
                  {openDropdown === "roll" ? (
                    <RiArrowDropUpLine className="!w-6 !h-6  right-6" />
                  ) : (
                    <RiArrowDropDownLine className="!w-6 !h-6  right-6" />
                  )}
                </div>
              </NavLink>
              {/* Roll Management dropdown  */}
              {openDropdown === "roll" && (
                <>
                  <NavLink
                    to="/super-admin/role-managment"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                    end
                  >
                    Roles
                  </NavLink>
                  <NavLink
                    to="/super-admin/role-managment/manager-list"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Managers
                  </NavLink>
                  <NavLink
                    to="/super-admin/role-managment/add-new-manager"
                    className={({ isActive }) =>
                      isActive ? "childActiveLink" : "childLink"
                    }
                  >
                    Add Manger
                  </NavLink>
                </>
              )}
            </div>

            {/* dashboard  */}
            <NavLink
              to="/super-admin/countries"
              className={({ isActive }) =>
                isActive ? "activeLink" : "navLink"
              }
              end
            >
              <LuLayoutDashboard className="w-4 h-4" />
              Countries
            </NavLink>

            {/* logout  */}
            <NavLink onClick={() => handleLogout()} className="navLink">
              <IoIosLogOut className="w-4 h-4" />
              Logout
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
