import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  clearMessage,
  mainCategory,
  mainCategorySearch,
  subCategoriesList,
  subCategoryDelete,
  subCategorySearch,
} from "../../../../../store/adminSlice";
import { imagePath } from "../../../../../functions/URLString";
import BreadCrums from "../../../../Common/BreadCrums";
import { toast } from "react-toastify";

const AdMarketPlaceSubCategories = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { error, message, subCategories, loading, categories } = useSelector(
    (state) => state.admin
  );
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(subCategoriesList());
  }, []);

  useEffect(() => {
    if(subCategories.subcategories){
      const filteredData = subCategories.subcategories.filter((item) => item.category_id == id);
      setData(filteredData);
    } else if (subCategories.SubCategory){
      const filteredData = subCategories.SubCategory.filter((item) => item.category_id == id);
      setData(filteredData);
    }
  }, [subCategories]);

  useEffect(() => {
    if (search === "") {
      dispatch(subCategoriesList());
    } else {
      dispatch(subCategorySearch(search));
    }
  }, [search]);

  useEffect(() => {
    if (message && message === "Subcategory deleted successfully") {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error, message]);

  const handleSubCategoryDelete = (Id) => {
    dispatch(subCategoryDelete(Id.toString())).then(() => {
      dispatch(subCategoriesList());
    });
  };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Ad Market Place",
              path: "/super-admin/ad-market-place-categories/",
            },
            {
              name: "Main Category",
              path: "",
            },
          ]}
        />

        {/* search */}
        <div className="flex justify-between gap-2">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="py-2 md:py-[15px] pl-10 w-full rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Sub Categories..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <Link
            className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white text-center text-nowrap font-semibold text-sm flex justify-center items-center"
            to={`/super-admin/ad-market-place-categories/sub-categories/create-category/${id}`}
          >
            Add Category
          </Link>
        </div>

        {/* table */}

        <div className=" flex justify-center border-gray-500 overflow-auto ">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[868px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between uppercase">
                <span className=" w-[15%] text-left">CATEGORY NAME</span>

                <span className=" w-[12%] text-left">CATEGORY IMAGE</span>

                <span className=" w-[18%] text-left ">
                  Child Category Count
                </span>

                <span className="w-[18%] text-left">ACTION</span>
              </div>

              {loading ? (
                  <>
                    <div className="text-center">Loading</div>
                  </>
                ) : 
                  data?.length>0?
                      data.map((item) => (
                        <div
                          key={item.id}
                          className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                        >
                          <span className="w-[15%] text-left">{item.name}</span>

                          <span className="w-[12%] text-left">
                            <img
                              src={item.image}
                              alt=""
                              className="w-10 h-10"
                            />
                          </span>

                          <span className="w-[18%] text-left ">
                            {item.subcategories_count}
                          </span>

                          <span className="flex items-center gap-2 w-[12%] text-left">
                            {/* <Link
                          
                        >
                          <img
                            src="/assets/icons/manage-products/view.svg"
                            alt=""
                            className="w-9 h-9"
                          />
                        </Link> */}
                            <Link
                              to={`/super-admin/ad-market-place-categories/sub-categories/update-category/${item.id}`}
                              className="cursor-pointer"
                            >
                              <img
                                src="/assets/icons/manage-products/edit.svg"
                                alt=""
                                className="w-9 h-9"
                              />
                            </Link>
                            <span
                              className="cursor-pointer"
                              onClick={(e) => handleSubCategoryDelete(item.id)}
                            >
                              <img
                                src="/assets/icons/manage-products/delete.svg"
                                alt=""
                                className="w-9 h-9"
                              />
                            </span>
                          </span>
                        </div>
                      ))
                    :"Not Found"}
            </div>
          </div>
        </div>

        {/* Pagination */}
        {/* {shouldShowPagination && (
          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} - {endIndex} OF {categories.length}{" "}
              RESULTS
            </span>
            <div className="flex items-center justify-between w-[265px] h-[40px] bg-white rounded-lg px-2">
              Previous Button
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${currentPage === 1 ? "opacity-50" : ""
                  }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                    }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              Page Numbers
              <div className="flex items-center gap-2">
                {paginationIndexes.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded ${currentPage === page
                        ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                        : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
                      }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
              Next Button
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${currentPage === totalPages ? "opacity-50" : ""
                  }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                    }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </>
  );
};

export default AdMarketPlaceSubCategories;
