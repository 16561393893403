import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import OrderView from "../../../../Components/Shop-And-User-List/ShopList/OrderView";

export default function OrderViewPage() {
  return (
    <>
      <MyShopLayout>
        <OrderView />
      </MyShopLayout>
    </>
  );
}
