import MallDetails from "../../../Components/My-Shop-Components/Mall-Managment-Components/MallDetails";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function MallDetailsPage() {
  return (
    <MyShopLayout>
      <MallDetails />
    </MyShopLayout>
  );
}
