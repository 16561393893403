import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UserListOrderView from "../../../../Components/Shop-And-User-List/User-List/UserListOrderView";

export default function UserListOrderViewPage() {
  return (
    <MyShopLayout>
      <UserListOrderView />
    </MyShopLayout>
  );
}
