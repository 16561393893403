import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

export default function SuperAdminInformation() {
  const [activeTab, setActiveTab] = useState("INFORMATION");

  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImage1, setUploadedImage1] = useState(null);

  const fileInputRef = useRef();
  const fileInputRef1 = useRef();

  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleDivClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        setUploadedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleFileChange1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        setUploadedImage1(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null); // Clear the state
  };
  const handleDeleteImage1 = () => {
    setUploadedImage1(null); // Clear the state
  };
  return (
    <>
      <div className="px-3">
        {/* head */}
        <div className="flex justify-between mb-2">
          <BreadCrums
            breadCrum={[
              { name: "Information", path: "/super-admin/profile/information" },
            ]}
          />

          <div>
            <button className="w-[111px] h-11 text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg">
              Update
            </button>
          </div>
        </div>
        {/* filter */}
        <div className="flex gap-6 py-3">
          <Link
            to="/super-admin/profile/"
            onClick={() => setActiveTab("PROFILE")}
            className={`font-semibold text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "PROFILE"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            PROFILE
          </Link>

          <Link
            to="/super-admin/profile/information"
            onClick={() => setActiveTab("INFORMATION")}
            className={`font-semibold text-sm text-center cursor-pointer  px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "INFORMATION"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INFORMATION
          </Link>

          <Link
            to="/super-admin/profile/adresses"
            onClick={() => setActiveTab("ADDRESS")}
            className={`font-semibold text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "ADDRESS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            ADDRESS
          </Link>
        </div>
        <div className="flex flex-col gap-5">
          {/* 1st */}
          <div className="flex flex-col ">
            <h1 className=" text-[#18120F] text-lg font-semibold">
              Bank Information
            </h1>
            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex items-center gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ACCOUNT TITLE
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Khan"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ACCOUNT NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="bingtechs"
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BANK NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Country"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BANK BRANCH
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Select State"
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    BRANCH CODE
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone City"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    IBAN
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Address"
                  />
                </div>
              </div>
              {/* CHEQUE COPY */}
              <div className="py-1">
                <h2 className="text-xs mb-1 font-semibold text-[#6B6B6B]">
                  UPLAOD CHEQUE COPY
                </h2>
                <div className="relative w-[100px] h-[75px]">
                  <div
                    className="w-[100px] h-[75px] bg-[#FEFEFE] border border-dashed border-[#E0E0E0] rounded-lg flex justify-center items-center cursor-pointer"
                    onClick={handleDivClick}
                  >
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                        className="w-full h-full object-cover rounded-lg"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  {uploadedImage && (
                    <button
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center"
                      onClick={handleDeleteImage} // Delete the uploaded image
                    >
                      X
                    </button>
                  )}

                  {/* Hidden file input */}
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange} // Handle file change
                    style={{ display: "none" }} // Keep the input hidden
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 2nd */}
          <div className="flex flex-col ">
            <h1 className=" text-[#18120F] text-lg font-semibold">
              Bussiness Information
            </h1>

            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex items-center gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    FIRST NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your First Name"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    LAST NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Last Name"
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>
              <div className="flex gap-7">
                <div className="w-full">
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      EMAIL ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      EMAIL ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      EMAIL ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Email"
                    />
                  </div>
                  <div className="flex w-full flex-col ">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      EMAIL ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Email"
                    />
                  </div>
                </div>
                <div className="w-full">
                  {/* CHEQUE COPY */}
                  <div className="py-1">
                    <h2 className="text-xs mb-1 font-semibold text-[#6B6B6B]">
                      SOCIAL SECIURITY NUMBER IMAGE
                    </h2>
                    <div className="relative w-[100px] h-[75px]">
                      <div
                        className="w-[100px] h-[75px] bg-[#FEFEFE] border border-dashed border-[#E0E0E0] rounded-lg flex justify-center items-center cursor-pointer"
                        onClick={handleDivClick1}
                      >
                        {uploadedImage1 ? (
                          <img
                            src={uploadedImage1}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded-lg"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      {uploadedImage1 && (
                        <button
                          className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center"
                          onClick={handleDeleteImage1} // Delete the uploaded image
                        >
                          X
                        </button>
                      )}

                      {/* Hidden file input */}
                      <input
                        type="file"
                        ref={fileInputRef1}
                        onChange={handleFileChange1} // Handle file change
                        style={{ display: "none" }} // Keep the input hidden
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
