import React from "react";
import HeroSectionImageSelectionComponent from "../../Components/Hero-Section-Components/HeroSectionImageSelectionComponent";
import MyShopLayout from "../../Super-Admin-Dashboard-Layout/MyShopLayout";

function HeroSectionImageSelection() {
  return (
    <MyShopLayout>
      <HeroSectionImageSelectionComponent />
    </MyShopLayout>
  );
}

export default HeroSectionImageSelection;
