import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import BoosterProductsDetails from "../../../Components/My-Shop-Components/Product-Boosters-Components/BoosterProductsDetails";

export default function BoosterProductsDetailsPage() {
  return (
    <MyShopLayout>
      <BoosterProductsDetails />
    </MyShopLayout>
  );
}
