import axios from 'axios'
import URL from './URLString'

const instance = axios.create({
    baseURL: URL,

})

// Add a request interceptor
instance.interceptors.request.use(
    function (config) {
      // Get token from localStorage
      const token = localStorage.getItem('token');
  
      // If token exists, add it to the request headers
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
  
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

export default instance;