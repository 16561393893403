import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import Chat from "../../../Components/My-Shop-Components/Chat-Components/Chat";

export default function ChatPage() {
  return (
    <>
      <MyShopLayout>
        <Chat />
      </MyShopLayout>
    </>
  );
}
