import { MdKeyboardArrowRight } from "react-icons/md";

import BoosterProductStatsBarCharts from "../../Graphs/Product-Booster-Graph/BooterProductStatsBarChart";

export default function BoosterStats() {
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <div className="w-full flex flex-col md:flex-row gap-8">
        {/* graph  */}
        <div className="w-full md:w-3/4 flex flex-col gap-3">
          <BoosterProductStatsBarCharts />
        </div>

        {/* listed product and categories  */}
        <div className="flex  md:flex-col gap-4">
          <div className="w-full flex flex-col gap-5 md:gap-10 text-left p-2 md:p-7 shadow-md rounded-lg">
            <span className="font-semibold text-sm md:text-base lg:text-xl">
              Total Ads
            </span>
            <div className="flex items-center lg:items-end justify-between gap-10">
              <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
                683
              </span>
              <span className="p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1">
                <MdKeyboardArrowRight className="w-4 h-4 md:w-5 md:h-5" />
              </span>
            </div>
          </div>

          <div className="w-full flex flex-col gap-5 md:gap-10 text-left p-2 md:p-7 shadow-md rounded-lg">
            <span className="font-semibold text-sm md:text-base lg:text-xl">
              Pending Ad Requests
            </span>
            <div className="flex items-center lg:items-end justify-between gap-10">
              <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
                13
              </span>
              <span className="p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1">
                <MdKeyboardArrowRight className="w-4 h-4 md:w-5 md:h-5" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
