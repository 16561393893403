import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import SinleBannerAdDetails from "../../../Components/My-Shop-Components/BannerAd-Components/SinleBannerAdDetails";

export default function SingleBannerAdDetailsPage() {
  return (
    <>
      <MyShopLayout>
        <SinleBannerAdDetails />
      </MyShopLayout>
    </>
  );
}
