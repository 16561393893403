import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import VendorProductList from "../../../../Components/Shop-And-User-List/ShopList/VendorProductList";

export default function VendorProductListPage() {
  return (
    <>
      <MyShopLayout>
        <VendorProductList />
      </MyShopLayout>
    </>
  );
}
