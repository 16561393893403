import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  userListAffiliateProductSearch,
  usersListAffiliateProduct,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

const UserAffiliateProducts = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(usersListAffiliateProduct(id));
  }, [dispatch, id]);

  // Search reviews based on search input
  useEffect(() => {
    if (search) {
      dispatch(userListAffiliateProductSearch({ id, query: search }));
    } else {
      dispatch(usersListAffiliateProduct(id));
    }
  }, [search, dispatch, id]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* breadcrums */}
        <BreadCrums
          breadCrum={[
            { name: "User", path: "/super-admin/users-list/" },
            {
              name: "User Profile States",
              path: `/super-admin/users-list/user-profile-stats/${id}`,
            },
            {
              name: "Affiliate Products",
              path: `/super-admin/users-list/user-Affiliate-products/${id}`,
            },
          ]}
        />

        {/* search */}
        <div className="relative flex items-center w-full">
          <img
            className="absolute left-3"
            src="/assets/icons/search.svg"
            alt=""
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
            type="search"
            placeholder="Search Affiliate Products..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[25%] text-left">PRODUCT </span>

                <span className="w-[15%] text-left">PRICE</span>
                <span className="w-[10%] text-left">COMMISSION</span>
                <span className="w-[10%] text-left">STOCK</span>

                <span className="w-[20%] text-left">TOTAL EARNING</span>
                <span className="w-[20%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.products?.length > 0 ? (
                data?.products?.map((prodct) => (
                  <div
                    key={prodct.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[25%] text-left flex gap-3  ">
                      <img
                        src={prodct.image}
                        className="w-10 h-10 text-center rounded-lg"
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span>{prodct.name}</span>
                        <span className="text-[#6B6B6B]  font-[500]">
                          Category: {prodct.category}
                        </span>
                      </div>
                    </div>
                    <span className="w-[15%] text-left ">${prodct.price}</span>
                    <span className="w-[10%] text-left ">
                      ${prodct.commission}
                    </span>
                    <span className="w-[10%] text-left ">{prodct.stock}</span>
                    <span className="w-[20%] text-left ">
                      ${prodct.earning}
                    </span>
                    <div className="w-[20%] text-left">
                      <div className="flex gap-2 justify-center">
                        <Link
                          // to="/offer-managment/view-product"
                          className="bg-[#FAFAFA] flex items-center justify-center rounded-xl p-2 border border-[#EBF0ED]"
                        >
                          <img src="/assets/icons/view-b.svg" alt="" />
                        </Link>

                        {/* <div className="bg-[#FAFAFA] rounded-xl p-2 border border-[#EBF0ED]">
                        <img src="/assets/icons/delete-b.svg" alt="" />
                      </div> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="text-center font-bold tracking-wide">
                      No Affiliate Products Found
                    </div>
                  </td>
                </tr>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAffiliateProducts;
