import React from "react";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import MainCategoryDetails from "../../../../../Components/My-Shop-Components/Manage-Categories/Manage-Main-Shop-Categories/Main-Categories/MainCategoryDetails";

export default function MainShopCategoryDetailsPage() {
  return (
    <MyShopLayout>
      <MainCategoryDetails />
    </MyShopLayout>
  );
}
