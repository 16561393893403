import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

import ProductManagmentAddProduct from "../../../Components/My-Shop-Components/Product-Managment-Components/ProductManagmentAddProduct";

export default function ProductManagmentAddProductPage() {
  return (
    <>
      <MyShopLayout>
        <ProductManagmentAddProduct />
      </MyShopLayout>
    </>
  );
}
