import React, { useState } from "react";
import BreadCrums from "../../Common/BreadCrums";

export default function AddNewManager() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const roleOptions = ["Manager", "CEO", "CTO"];

  const togglebudget = () => setDropdownOpen(!dropdownOpen);

  const handleRoleSelect = (option) => {
    setRole(option);

    setDropdownOpen(false);
  };
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* head */}
        <div className="flex flex-col md:flex-row gap-1 md:gap-0 md:items-center justify-between">
          <BreadCrums
            breadCrum={[
              { name: "Role Managment", path: "/super-admin/role-managment" },
              {
                name: "Managers",
                path: "/super-admin/role-managment/manager-list",
              },
              {
                name: "Create Managers",
              },
            ]}
          />
          <div className="flex justify-end">
            <button className="px-5 md:px-9 py-2 md:py-[14px] bg-[#C19A6B] rounded-xl text-xs text-white">
              Save
            </button>
          </div>
        </div>

        <div>
          <h2 className="text-[#18120F] text-base md:text-lg font-semibold">
            New Manager
          </h2>
        </div>
        <div className="border border-[#EBF0ED] flex flex-col gap-1 md:gap-3 rounded-lg p-3 md:p-5">
          <div className="flex flex-col md:flex-row items-center gap-1 md:gap-4">
            <div className="w-full flex flex-col gap-1 md:gap-2 ">
              <label
                className="text-[#6B6B6B] text-xs font-semibold"
                htmlFor=""
              >
                FULL NAME
              </label>
              <input
                className="border border-[#EBF0ED] outline-none rounded-xl bg-[#FAFAFA] p-2 md:p-[10px] text-xs"
                type="text"
                value={name}
                placeholder="Enter Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="w-full flex flex-col gap-1 md:gap-2 ">
              <label
                className="text-[#6B6B6B] text-xs font-semibold"
                htmlFor=""
              >
                EMAIL ADDRESS
              </label>
              <input
                className="border border-[#EBF0ED] outline-none rounded-xl bg-[#FAFAFA] p-2 md:p-[10px] text-xs "
                type="text"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-1 md:gap-4">
            <div className="w-full flex flex-col gap-1 md:gap-2 ">
              <label
                className="text-[#6B6B6B] text-xs font-semibold"
                htmlFor=""
              >
                PASSWORD
              </label>
              <input
                className="border border-[#EBF0ED] outline-none rounded-xl bg-[#FAFAFA] p-2 md:p-[10px] text-xs"
                type="password"
                placeholder="******"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="w-full relative flex flex-col gap-1 md:gap-2">
              <label
                className="text-[#6B6B6B] text-xs font-semibold"
                htmlFor=""
              >
                ROLES
              </label>
              <div className="relative w-full">
                <input
                  className="bg-[#FAFAFA] cursor-pointer text-[#c5c4c4] rounded-lg w-full py-2 px-4 outline-none text-xs"
                  type="text"
                  value={role}
                  readOnly
                  onClick={togglebudget}
                  placeholder="Select Role"
                />
                <img
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#828282]"
                  src="/assets/icons/down.svg"
                  alt=""
                />

                {dropdownOpen && (
                  <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                    {roleOptions.map((option) => (
                      <div
                        key={option}
                        className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleRoleSelect(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-[#6B6B6B] text-xs font-semibold mb-1">
              PROFILE IMAGE
            </h2>
            <div className="w-[91px] h-[91px] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded-xl bg-[#FEFEFE] relative">
              {image ? (
                <div className="relative cursor-pointer">
                  <img
                    src={image}
                    alt="Uploaded"
                    className="w-full h-full object-cover rounded-xl"
                    onClick={() => document.getElementById("fileInput").click()}
                  />
                  <input
                    id="fileInput"
                    type="file"
                    onChange={handleImageUpload}
                    className="hidden"
                    accept="image/*"
                  />
                </div>
              ) : (
                <label className="w-full h-full flex items-center justify-center cursor-pointer">
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    className="hidden"
                    accept="image/*"
                  />
                  <div className="p-1 bg-[#D9D9D9] rounded-full">
                    <img src="/assets/icons/plus1.svg" alt="Add" />
                  </div>
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
