import VenderRequestsListPage from "../Pages/My-Shop/Vendor-Requests-Pages/VenderRequestsListPage";
import VendorRequestDetailsPage from "../Pages/My-Shop/Vendor-Requests-Pages/VendorRequestDetailsPage";

const vendorRequestsRoutes = [
  {
    path: "/super-admin/vendor-requests/",
    children: [
      {
        index: true,
        element: <VenderRequestsListPage />,
      },
      {
        path: "/super-admin/vendor-requests/request-details/:id",
        element: <VendorRequestDetailsPage />,
      },
    ],
  },
];

export default vendorRequestsRoutes;
