import OrderViewPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/OrderViewPage";
import ProductReviewsPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/ProductReviewsPage";
import ProductsReturndPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/ProductsReturndPage";
import ShopDetailsPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/ShopDetailsPage";
import ShopListPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/ShopListPage";
import VendorAffilliateProductListPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/VendorAffilliateProductListPage";
import VendorOrderListPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/VendorOrderListPage";
import VendorProductListPage from "../Pages/My-Shop/Shop-And-User-List/Shop-List/VendorProductListPage";

const shopListsRoutes = [


    {
        path: "/super-admin/shop-list/",
        children: [
            {
                index: true,
                element: < ShopListPage /> ,
            },
            {
                path: "/super-admin/shop-list/shop-details/:id",
                element: < ShopDetailsPage /> ,
            },
            {
                path: "/super-admin/shop-list/shop-details/vendor-product-list/:id",
                element: < VendorProductListPage /> ,
            },
            {
                path: "/super-admin/shop-list/vendor-affilliate-product-list/:id",
                element: < VendorAffilliateProductListPage /> ,
            },
            {
                path: "/super-admin/shop-list/products-returnd/:id",
                element: < ProductsReturndPage /> ,
            },
            {
                path: "/super-admin/shop-list/products-reviews/:id",
                element: < ProductReviewsPage /> ,
            },
            {
                path: "/super-admin/shop-list/vendor-order-list/:id",
                element: < VendorOrderListPage /> ,
            },
            {
                path: "/super-admin/shop-list/vendor-order-view/:id",
                element: < OrderViewPage /> ,
            },
        ],
    },
    
]

export default shopListsRoutes;