import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

// Sample data for different years
const dataYears = {
  2021: [
    { name: "January", uv: 2000, pv: 2400, amt: 2400 },
    { name: "February", uv: 1500, pv: 1398, amt: 2210 },
    { name: "March", uv: 1000, pv: 9800, amt: 2290 },
    { name: "April", uv: 2780, pv: 3908, amt: 2000 },
    { name: "May", uv: 1890, pv: 4800, amt: 2181 },
    { name: "June", uv: 2390, pv: 3800, amt: 2500 },
    { name: "July", uv: 3490, pv: 4300, amt: 2100 },
    { name: "August", uv: 800, pv: 2400, amt: 2400 },
    { name: "September", uv: 2000, pv: 1400, amt: 2200 },
    { name: "October", uv: 2780, pv: 3908, amt: 2000 },
    { name: "November", uv: 1890, pv: 4800, amt: 2181 },
    { name: "December", uv: 2490, pv: 4300, amt: 2100 },
  ],
  2022: [
    { name: "January", uv: 2200, pv: 2400, amt: 2400 },
    { name: "February", uv: 1700, pv: 1398, amt: 2210 },
    { name: "March", uv: 1200, pv: 9800, amt: 2290 },
    { name: "April", uv: 2980, pv: 3908, amt: 2000 },
    { name: "May", uv: 2090, pv: 4800, amt: 2181 },
    { name: "June", uv: 2590, pv: 3800, amt: 2500 },
    { name: "July", uv: 3690, pv: 4300, amt: 2100 },
    { name: "August", uv: 1000, pv: 2400, amt: 2400 },
    { name: "September", uv: 2200, pv: 1400, amt: 2200 },
    { name: "October", uv: 2980, pv: 3908, amt: 2000 },
    { name: "November", uv: 2090, pv: 4800, amt: 2181 },
    { name: "December", uv: 2690, pv: 4300, amt: 2100 },
  ],
  2023: [
    { name: "January", uv: 200, pv: 2400, amt: 2400 },
    { name: "February", uv: 500, pv: 1398, amt: 2210 },
    { name: "March", uv: 700, pv: 9800, amt: 2290 },
    { name: "April", uv: 580, pv: 3908, amt: 2000 },
    { name: "May", uv: 690, pv: 4800, amt: 2181 },
    { name: "June", uv: 790, pv: 3800, amt: 2500 },
    { name: "July", uv: 890, pv: 4300, amt: 2100 },
    { name: "August", uv: 900, pv: 2400, amt: 2400 },
    { name: "September", uv: 2200, pv: 1400, amt: 2200 },
    { name: "October", uv: 2980, pv: 3908, amt: 2000 },
    { name: "November", uv: 2090, pv: 4800, amt: 2181 },
    { name: "December", uv: 2690, pv: 4300, amt: 2100 },
  ],
  // Add more years as needed
};

const CategoriesEarningChart = () => {
  const [selectedYear, setSelectedYear] = useState("2021");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const data = dataYears[selectedYear];

  const uvValues = data.map((entry) => entry.uv);
  const minValue = Math.min(...uvValues);
  const maxValue = Math.max(...uvValues);
  const tickCount = 4; // You can adjust this value to control the number of ticks

  // Calculate tick positions
  const tickInterval = (maxValue - minValue) / (tickCount - 1);
  const yAxisTicks = Array.from(
    { length: tickCount },
    (_, i) => minValue + tickInterval * i
  );

  const axisLabelStyle = {
    fontSize: "12px", // Adjust the font size
    fontFamily: "Cascadia, sans-serif", // Specify the font family
    fill: "#6B6B6B", // Specify the text color
  };

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div
        className="w-full h-[18rem] lg:h-[24.5rem] rounded-2xl pt-2 flex flex-col gap-2"
        style={{ boxShadow: "0px 20px 20px 0px #AAAAAA0F" }}
      >
        <div className="md:mt-7 ms-6 md:ms-9 flex justify-between me-5 md:me-7">
          <div className="font-semibold tracking-wide text-xl">
            Total Earnings
          </div>
          <div
            className="py-[7px] px-4 bg-[#FAFAFA] rounded-xl text-sm text-[#6B6B6B] border border-[#EBF0ED] flex gap-2 items-center cursor-pointer relative"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {selectedYear}
            <span>
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.375 5.375C5.19922 5.375 5.04297 5.31641 4.92578 5.19922L1.17578 1.44922C0.921875 1.21484 0.921875 0.804688 1.17578 0.570312C1.41016 0.316406 1.82031 0.316406 2.05469 0.570312L5.375 3.87109L8.67578 0.570312C8.91016 0.316406 9.32031 0.316406 9.55469 0.570312C9.80859 0.804688 9.80859 1.21484 9.55469 1.44922L5.80469 5.19922C5.6875 5.31641 5.53125 5.375 5.375 5.375Z"
                  fill="#6B6B6B"
                />
              </svg>
            </span>
            {isDropdownOpen && (
              <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                {Object.keys(dataYears).map((year) => (
                  <div
                    key={year}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleYearSelect(year)}
                  >
                    {year}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="mt-2 ms-6 md:ms-9 flex justify-between me-5 md:me-7">
          <div className="font-bold tracking-wide text-2xl">$340,345</div>
          <div className="py-[7px] px-4 bg-[#FAFAFA] rounded-xl text-sm text-[#15CAB8] border border-[#15CAB8] flex gap-2 items-center">
            <span>
              <svg
                width="22"
                height="12"
                viewBox="0 0 22 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.375 1.46875C13.375 1.01172 13.7266 0.625 14.2188 0.625H20.4062C20.8633 0.625 21.25 1.01172 21.25 1.46875V7.65625C21.25 8.14844 20.8633 8.5 20.4062 8.5C19.9141 8.5 19.5625 8.14844 19.5625 7.65625V3.50781L12.8125 10.2227C12.4961 10.5742 11.9688 10.5742 11.6523 10.2227L7.75 6.32031L2.40625 11.6289C2.08984 11.9805 1.5625 11.9805 1.24609 11.6289C0.894531 11.3125 0.894531 10.7852 1.24609 10.4336L7.15234 4.52734C7.46875 4.21094 7.99609 4.21094 8.3125 4.52734L12.25 8.46484L18.3672 2.3125H14.2188C13.7266 2.3125 13.375 1.96094 13.375 1.46875Z"
                  fill="#15CAB8"
                />
              </svg>
            </span>
            +12%
          </div>
        </div>

        {/* chart */}
        <ResponsiveContainer
          className="rounded-3xl bg-white"
          width="100%"
          height="100%"
          style={{ boxShadow: "0px 20px 20px 0px #AAAAAA0F" }}
        >
          <AreaChart
            data={data}
            margin={{
              top: 20, // Add top margin
              right: 20,
              left: 10, // Add left margin
              bottom: 20, // Add bottom margin
            }}
          >
            <XAxis
              dataKey="name"
              tickFormatter={(tick) => tick.substring(0, 3)} // Display only first three characters of each month
              axisLine={false} // Remove X axis line
              tickLine={false} // Remove tick lines
              dy={10} // Adjust the vertical distance of the axis labels from the axis
              tick={{ ...axisLabelStyle }} // Apply custom style to X axis labels
            />
            <YAxis
              tickFormatter={(value) => `${value / 1000}K`}
              axisLine={false} // Remove Y axis line
              tickLine={false} // Remove tick lines
              dx={-10} // Adjust the horizontal distance of the axis labels from the axis
              tick={{ ...axisLabelStyle }} // Apply custom style to Y axis labels
            />
            {/* Add reference lines */}
            {yAxisTicks.map((tick, index) => (
              <ReferenceLine
                key={index}
                y={tick}
                stroke="rgba(0, 0, 0, 0.024)"
                strokeDasharray="9 8"
              />
            ))}
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#15CAB8" // Custom line color
              fill="url(#colorUv)" // Custom area color
              strokeWidth={3} // Custom line thickness
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(21, 202, 184, 0.2)" />
                <stop offset="92.64%" stopColor="rgba(21, 202, 184, 0.00002)" />
                <stop offset="100%" stopColor="rgba(36, 191, 74, 0.00002)" />
                <stop offset="100%" stopColor="rgba(255, 255, 255, 0.2)" />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default CategoriesEarningChart;
