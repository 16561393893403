import React, { useEffect, useState } from "react";
import "./store-booster.css";

import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addStoreBoosterAdmin,
  clearMessage,
  listBoosterPostedByAdmin,
  storeBoosterDelete,
} from "../../../store/adminSlice";

export default function StoreBoosterSettings() {
  const [packageType, setPackageType] = useState("");

  const [packageName, setPackageName] = useState("");

  const [timeSpan, setTimeSpan] = useState("");
  const [temp, setTemp] = useState("");

  const [dailyBudget, setDailyBudget] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [timeSpanDropdownOpen, setTimeSpanDropdownOpen] = useState(false);
  const [packageDropdownOpen, setPackageDropdownOpen] = useState(false);

  const timeSpanOptions = ["1 day", "3 days", "1 week", "3 weeks", "1 month"];

  const packages = ["Basic", "Moderate", "Premium"];

  const { data, loading, error, message } = useSelector((state) => state.admin);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message) {
      toast.success(message);
      dispatch(listBoosterPostedByAdmin());
      setPackageName("");
      setPackageType("");
      setTimeSpan("");
      setTemp("");
      setDailyBudget("");
    }
    dispatch(clearMessage());
  }, [data, error, message, dispatch]);

  useEffect(() => {
    dispatch(listBoosterPostedByAdmin());
  }, [dispatch]);

  useEffect(() => {
    // console.log(data);
  }, [data]);

  const toggleTimeSpan = () => setTimeSpanDropdownOpen(!timeSpanDropdownOpen);

  const togglePackage = () => setPackageDropdownOpen(!packageDropdownOpen);

  const handlePackageNameChange = (e) => {
    setPackageName(e.target.value);
  };

  const handleTimeSpanSelect = (option) => {
    if (option === "1 day") {
      setTimeSpan("1 day");
      setTemp(1);
    }
    if (option === "3 days") {
      setTimeSpan("3 days");
      setTemp(3);
    }
    if (option === "1 week") {
      setTimeSpan("1 week");
      setTemp(7);
    }
    if (option === "3 weeks") {
      setTimeSpan("3 weeks");
      setTemp(21);
    }
    if (option === "1 month") {
      setTimeSpan("1 month");
      setTemp(30);
    }

    setTimeSpanDropdownOpen(false);
  };

  const handlePackageSelect = (option) => {
    setPackageType(option);
    setPackageDropdownOpen(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
    setDailyBudget(value ? `$${value}` : ""); // Add dollar sign if there is a value
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      packageType === "" ||
      packageName === "" ||
      timeSpan === "" ||
      dailyBudget.replace(/[^0-9.]/g, "") === ""
    ) {
      toast.error("Please fill all fields");
    } else {
      const formData = new FormData();

      formData.append("package_name", packageType);
      formData.append("name", packageName);
      formData.append("time_span", temp);
      formData.append("price", dailyBudget.replace(/[^0-9.]/g, ""));

      dispatch(addStoreBoosterAdmin(formData));
    }
  };

  const handleDelete = (id) => {
    // console.log("del", id);
    dispatch(storeBoosterDelete(id)).then(() => {
      dispatch(listBoosterPostedByAdmin());
    });
  };

  return (
    <>
      <div className="xl:px-4 ">
        {/* cards */}
        <div className="flex gap-10 w-full lg:w-1/3">
          {/* product 1 */}
          <div className="w-full">
            <form
              onSubmit={handleSubmit}
              className="border py-2 md:py-6 flex flex-col gap-1 md:gap-5 px-4 border-[#EBF0ED] rounded-lg"
            >
              <h2
                className={`md:px-2 text-lg md:text-[1.25rem] font-semibold `}
              >
                Store Booster
              </h2>

              {/* Package type  */}
              <div className="relative flex flex-col gap-2">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="budgetCategory"
                >
                  Package
                </label>
                <div className="relative w-full">
                  <input
                    className={`bg-[#FAFAFA] cursor-pointer text-xs md:text-base rounded-lg w-full py-2 px-4 outline-none`}
                    type="text"
                    readOnly
                    placeholder="Basic"
                    value={packageType}
                    onClick={togglePackage}
                  />
                  <RiArrowDropDownLine
                    onClick={togglePackage}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#828282] w-6 h-6"
                  />

                  {packageDropdownOpen && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                      {packages.map((option) => (
                        <div
                          key={option}
                          className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handlePackageSelect(option)} // Updated onClick event
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* Name */}
              <div className="flex flex-col gap-2">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="packageName"
                >
                  Package Name
                </label>
                <input
                  className={`bg-[#FAFAFA] rounded-lg text-xs md:text-base w-full py-2 px-4 outline-none  `}
                  type="text"
                  name="packageName"
                  placeholder="Basic"
                  value={packageName}
                  onChange={handlePackageNameChange}
                />
              </div>
              {/* Time Span */}
              <div className="relative flex flex-col gap-2">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="budgetCategory"
                >
                  Time Span
                </label>
                <div className="relative w-full">
                  <input
                    className={`bg-[#FAFAFA] cursor-pointer text-xs md:text-base rounded-lg w-full py-2 px-4 outline-none`}
                    type="text"
                    value={timeSpan}
                    readOnly
                    onClick={toggleTimeSpan}
                    placeholder="1 day"
                  />
                  <RiArrowDropDownLine
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#828282] w-6 h-6"
                    onClick={toggleTimeSpan}
                  />

                  {timeSpanDropdownOpen && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                      {timeSpanOptions.map((option) => (
                        <div
                          key={option}
                          className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleTimeSpanSelect(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* Daily Budget */}
              <div className="flex flex-col gap-2 numberInput">
                <label
                  className="text-[#828282] text-xs font-[500]"
                  htmlFor="dailyBudget"
                >
                  1 Product Per Day
                </label>
                <input
                  className={`bg-[#FAFAFA] rounded-lg text-xs md:text-base w-full py-2 px-4 outline-none    `}
                  type="text"
                  name="dailyBudget"
                  placeholder="$10"
                  value={dailyBudget}
                  onChange={handleInputChange}
                />
              </div>

              <div className="text-end">
                <button
                  disabled={loading ? true : false}
                  className="bg-[#C19A6B] py-2 md:py-[0.65rem] px-5 md:px-9 rounded-lg text-xs md:text-base text-center text-white"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* preview */}
        <div className="py-4">
          <h2 className="md:text-[1.38rem] font-bold">Preview</h2>
          <div className="grid grid-cols-3 gap-1 lg:gap-10">
            {/* for basic card */}

            {data.boosters &&
              data.boosters.map((i) => (
                <Link className="relative border w-full py-3 lg:py-6 flex  flex-row md:items-start items-center justify-between max-w-[900px] md:gap-5 px-2 md:px-4 border-[#EBF0ED] rounded-2xl">
                  <div
                    className="flex flex-col justify-center items-center md:flex-row cursor-pointer gap-1 md:gap-4"
                    onClick={() =>
                      navigate(
                        `/super-admin/store-boosters/settings/update/${i.id}`
                      )
                    }
                  >
                    <img
                      src="/assets/icons/rocket.svg"
                      className="w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20"
                      alt=""
                    />
                    <div className="flex justify-center items-center md:justify-normal md:items-start flex-col">
                      <span className="text-sm text-[#18120F] font-[400]">
                        {i.name}
                      </span>
                      <span className="text-lg lg:text-[1.3rem] text-[#18120F] font-semibold">
                        {i.package_name ? i.package_name : "Boost Your Sales!"}
                      </span>
                      <span className="text-[#C19A6B] text-center md:text-start text-[10px] lg:text-base font-bold tracking-wide">
                        {i.price} per day for {i.time_span} days
                      </span>
                    </div>
                  </div>
                  <div>
                    <button
                      disabled={loading ? true : false}
                      onClick={(e) => handleDelete(i.id)}
                      className="hidden md:flex h-full z-50"
                    >
                      <p className="flex -mt-3 left-1 cursor-pointer hover:bg-gray-300 h-fit w-fit rounded-full px-1 md:px-4 p-1 md:p-2">
                        X
                      </p>
                    </button>
                  </div>
                  <div
                    disabled={loading ? true : false}
                    onClick={(e) => handleDelete(i.id)}
                    className="absolute md:hidden  top-4 right-2"
                  >
                    <p className="flex -mt-3 left-1 cursor-pointer hover:bg-gray-300 h-fit w-fit rounded-full px-1 md:px-4 p-1 md:p-2">
                      X
                    </p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
