import ProductManagmentAddProductPage from "../Pages/My-Shop/Product-Managment/ProductManagmentAddProductPage";
import ProductManagmentEditProductPage from "../Pages/My-Shop/Product-Managment/ProductManagmentEditProductPage";
import ProductManagmentManageProductPage from "../Pages/My-Shop/Product-Managment/ProductManagmentManageProductPage";

const productManagementRoutes = [

    {
        path: "/super-admin/product-managment/",
        children: [{
                index: true,
                element: < ProductManagmentAddProductPage /> ,
            },
            {
                path: "/super-admin/product-managment/manage-product",
                element: < ProductManagmentManageProductPage /> ,
            },
            {
                path: "/super-admin/product-managment/edit-product",
                element: < ProductManagmentEditProductPage /> ,
            },
        ],
    },

]

export default productManagementRoutes;