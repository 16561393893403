import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import RoleManagmentView from "../../../Components/My-Shop-Components/Role-Managment-Components/RoleManagmentView";

export default function RoleManagmentViewPage() {
  return (
    <>
      <MyShopLayout>
        <RoleManagmentView />
      </MyShopLayout>
    </>
  );
}
