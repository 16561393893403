import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import OfferManagmentCreateOffers from "../../../Components/My-Shop-Components/Offer-Managment-Components/OfferManagmentCreateOffers";

export default function OfferManagmentCreateOfferPage() {
  return (
    <>
      <MyShopLayout>
        <OfferManagmentCreateOffers />
      </MyShopLayout>
    </>
  );
}
