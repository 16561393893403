import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { vendorProductReviews, vendorProductReviewsSearch } from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

export default function ProductReviews() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(vendorProductReviews(id));
  }, [dispatch, id]);

  useEffect(() => {
    if(search!=""){
      dispatch(vendorProductReviewsSearch({id:id,search:search}));
    } else {
      dispatch(vendorProductReviews(id));
    }
  }, [search, dispatch, id]);

  const generateStarRating = (rating) => {
    const stars = [];
    const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
    const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
        );
      } else {
        stars.push(
          <img
            key={i}
            src={unfilledStar}
            alt="unfilled star"
            className="w-4 h-4"
          />
        );
      }
    }
    return stars;
  };

  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Shop List", path: "/super-admin/shop-list/" },
            {
              name: "Shop Details",
              path: -1,
            },
            {
              name: "Products Review",
              path: `/super-admin/shop-list/products-reviews/${id}`,
            },
          ]}
        />

        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
            type="search"
            placeholder="Search product..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className=" flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[15%] text-left">ORDER ID</span>
                <span className="w-[20%] text-left">PRODUCT</span>
                <span className="w-[20%] text-left">REVIEW</span>
                <span className="w-[15%] text-left">RATING</span>
                <span className="w-[15%] text-left">CUSTOMER</span>
                <span className="w-[15%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.reviews && data.reviews.length > 0 ? (
                data.reviews.map((prodct) => (
                  <div
                    // key={index}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <span className="w-[15%] text-left ">
                      #{prodct.order_uid}
                    </span>
                    <div className="w-[20%] text-left ">
                      <span className="flex items-center gap-3">
                        <span>
                          <img
                            src={prodct.product_image}
                            alt=""
                            className="max-w-10 h-10 rounded-[10px]"
                          />
                        </span>
                        <span className="flex flex-col">
                          <span className="font-semibold text-sm">
                            {prodct.product_name}
                          </span>
                          <span className="font-medium text-sm text-[#6B6B6B]">
                            Category: {prodct.name}
                          </span>
                        </span>
                      </span>
                    </div>

                    <span className="w-[20%] text-left flex flex-col gap-1 items-start">
                      <span>{prodct.review} </span>
                    </span>
                    <span className="w-[15%] text-left flex gap-1 items-center">
                      {generateStarRating(Number(prodct.rating))}
                    </span>

                    <span className="w-[15%] text-left">
                      {prodct.user_name}
                    </span>
                    <span className="w-[15%] text-left">
                      <img
                        className="w-7 h-7 md:w-9 md:h-9"
                        src="/assets/icons/More.svg"
                        alt=""
                      />
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-center font-bold tracking-wide">
                  No Reviews Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
