import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, NavLink } from "react-router-dom";
import { viewOffer } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import moment from "moment";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

export default function OfferManagmentViewOffer() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  const [activeRole, setActiveRole] = useState("All");

  useEffect(() => {
    dispatch(viewOffer({ arg1: "status", arg2: "", arg3: id }));
    localStorage.setItem("offer_id", id);
  }, [dispatch, id]);

  // const handleRoleChange = (role) => {
  //   setActiveRole(role);
  // };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* Header */}
        <BreadCrums
          breadCrum={[
            { name: "Offers", path: "/super-admin/offer-managment/" },
            {
              name: "View Offers",
              path: "/super-admin/offer-managment/edit-offer/",
            },
          ]}
        />

        {/* hero */}
        {loading ? (
          <div className="flex justify-center py-4">
            <Loader />
          </div>
        ) : (
          data.offer && (
            <div className=" md:border flex flex-col md:flex-row gap-1 md:border-[#EBF0ED] rounded-xl  p-4 mt-4">
              <div className="w-full flex justify-center">
                <img src={data.offer?.image} alt="" />
              </div>
              <div className="w-full text-center md:text-start py-2 md:px-4">
                <h2 className="text-[#18120F] text-lg font-semibold">
                  {data.offer.title}
                </h2>
                <p className="text-[#828282] text-[14px] font-[500] mt-3 px-1">
                  {data.offer.description}
                </p>
              </div>
              <div className="w-full flex items-center justify-center md:border-l md:border-l-[#EBF0ED]">
                <div className="flex flex-col items-center gap-2 md:gap-5">
                  <div className="flex flex-col justify-center md:justify-start items-center md:items-center gap-2">
                    <h2 className="text-[16px] text-center font-semibold">
                      Offer Live Period
                    </h2>
                    <div className="flex gap-2  justify-center md:justify-start ">
                      <span className="bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl">
                        {moment(data.offer.livestartdate).format(
                          "DD MMMM YYYY"
                        )}
                      </span>
                      <span>to</span>
                      <span className="bg-[#FFF3F4] text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl">
                        {moment(data.offer.liveenddate).format("DD MMMM YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center  gap-2 mt-3">
                    <h2 className="text-[16px] font-semibold">
                      Registeration Closes In
                    </h2>
                    <span className="text-sm   py-[0.189rem] bg-[#FAFAFA] border border-[#EBF0ED] rounded-xl text-[#828282]">
                      {data.offer.registeration_close_in}
                    </span>
                  </div>
                  <div className="px-4 text-center flex flex-col gap-2">
                    <h2 className="text-[16px] font-semibold">Statistics</h2>
                    <span className="text-sm px-2 py-[0.189rem] bg-[#FFFBF6] border border-[#C19A6B] rounded-xl text-[#C19A6B]">
                      {data.offer.product_count} Sellers |{" "}
                      {data.offer.seller_count} Products
                    </span>
                  </div>
                  <Link
                    to={`/super-admin/offer-managment/offer-stats/${id}`}
                    className="font-bold text-xs py-1 px-2 border border-[#C19A6B] rounded-full text-[#C19A6B]"
                  >
                    View More
                  </Link>
                </div>
              </div>
            </div>
          )
        )}

        {/* search */}
        <div>
          <h2 className="text-[18px] font-[600] text-[#18120F]">Seller List</h2>
          {/* <div className="relative flex items-center w-full px-2">
            <svg
              className="absolute left-5"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4106 11.7609L9.25223 8.60246C9.93576 7.7775 10.3129 6.71684 10.3129 5.5619C10.3129 2.8749 8.09729 0.659299 5.41028 0.659299C2.69971 0.659299 0.53125 2.8749 0.53125 5.5619C0.53125 8.27247 2.72328 10.4645 5.41028 10.4645C6.54165 10.4645 7.60231 10.0874 8.45084 9.40384L11.6092 12.5623C11.7271 12.6801 11.8685 12.7272 12.0335 12.7272C12.1749 12.7272 12.3164 12.6801 12.4106 12.5623C12.6463 12.3501 12.6463 11.9966 12.4106 11.7609ZM1.66262 5.5619C1.66262 3.48772 3.3361 1.79067 5.43385 1.79067C7.50803 1.79067 9.20509 3.48772 9.20509 5.5619C9.20509 7.65965 7.50803 9.33313 5.43385 9.33313C3.3361 9.33313 1.66262 7.65965 1.66262 5.5619Z"
                fill="#C19A6B"
              />
            </svg>

            <input
              className="w-full h-12 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
              type="search"
              placeholder="Search product..."
            />
          </div> */}
        </div>
        {/* filter */}

        <div className="flex gap-1 md:gap-6 md:py-5 text-xs md:text-sm">
          <NavLink
            onClick={() => {
              setActiveRole("All");
              dispatch(viewOffer({ arg1: "status", arg2: "", arg3: id }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ALL OFFERS
            <span
              className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data?.data?.total_vendors
                ? data?.data?.total_vendors
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Pending");
              dispatch(
                viewOffer({ arg1: "status", arg2: "pending", arg3: id })
              );
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Pending"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            Pending
            <span
              className={`font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl ${
                activeRole === "Pending"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data?.data?.pending_vendors
                ? data?.data?.pending_vendors
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Approved");
              dispatch(
                viewOffer({ arg1: "status", arg2: "approved", arg3: id })
              );
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Approved"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            APPROVED
            <span
              className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl ${
                activeRole === "Approved"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data?.data?.approved_vendors
                ? data?.data?.approved_vendors
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Rejected");
              dispatch(
                viewOffer({ arg1: "status", arg2: "rejected", arg3: id })
              );
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "Rejected"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            REJECTED
            <span
              className={`font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl ${
                activeRole === "Rejected"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data?.data?.rejected_vendors
                ? data?.data?.rejected_vendors
                : 0}
              )
            </span>
          </NavLink>
        </div>
        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[25%] text-left">SELLER NAME</span>
                <span className="w-[25%] text-left">STATUS</span>
                <span className="w-[25%] text-left">PRODUCTS</span>

                <span className="w-[25%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.data?.vendors?.length > 0 ? (
                data?.data?.vendors?.map((item) => {
                  return (
                    <div
                      key={item.vendor_id}
                      className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="w-[25%] text-left flex gap-3 items-center">
                        <span>
                          <img
                            className="h-16 w-16 rounded-md"
                            src={item.shop_picture}
                            alt=""
                          />
                        </span>
                        <span>{item.vendor_name}</span>
                      </div>
                      <div className="w-[25%] text-left">
                        <span
                          className={`${
                            item.status === "1"
                              ? "bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                              : item.status === "0"
                              ? "bg-[#FFFBF0] text-sm px-2 py-[0.189rem] border border-[#FEC600] text-[#FEC600] rounded-xl"
                              : "bg-[#FFF3F4] text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl"
                          }`}
                        >
                          {item.status === "0"
                            ? "Pending"
                            : item.status === "1"
                            ? "Approved"
                            : "Rejected"}
                        </span>
                      </div>
                      <span className="w-[25%] text-left">
                        {item.product_count}
                      </span>

                      <div className="w-[25%] text-left">
                        <div className="flex gap-2 justify-center">
                          <Link
                            to={`/super-admin/offer-managment/view-product/${item.vendor_id}?status=${item.status}`}
                            className="bg-[#FAFAFA] flex items-center justify-center rounded-xl p-2 border border-[#EBF0ED]"
                          >
                            <svg
                              width="17"
                              height="14"
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 6.69999C16 7.96011 12.6421 12.3999 8.49999 12.3999C4.35785 12.3999 1 7.96011 1 6.69999C1 5.43988 4.35785 1 8.49999 1C12.6421 1 16 5.43988 16 6.69999Z"
                                stroke="#828282"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.50078 8.68052C9.76878 8.68052 10.482 7.96727 10.482 6.69927C10.482 5.43127 9.76878 4.71802 8.50078 4.71802C7.23278 4.71802 6.51953 5.43127 6.51953 6.69927C6.51953 7.96727 7.23278 8.68052 8.50078 8.68052Z"
                                stroke="#828282"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <tr className="text-center font-bold tracking-wide py-4">
                  <td colSpan={6}>No Seller</td>
                </tr>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
