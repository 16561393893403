import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { premiumProductBids } from "../../../store/adminSlice";

export default function ActiveOfferRequestList() {
  const [products, setProducts] = useState([]);
  // const [activeRole, setActiveRole] = useState("Approved");
  const [availableCount, setAvailableCount] = useState(0);
  // console.log(availableCount)
  const [upcomingCount, setUpcomingCount] = useState(0);
  // console.log(upcomingCount)
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data} = useSelector((state) => state.admin);

  // Filter products based on active role
  // const filteredProducts = products.filter(
  //   (item) => item.status === activeRole
  // );

  useEffect(() => {
    // Update counts when products change
    const Approved = products.filter(
      (item) => item.status === "Approved"
    ).length;
    const Pending = products.filter((item) => item.status === "Pending").length;
    setAvailableCount(Approved);
    setUpcomingCount(Pending);
  }, [products]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/requests.json");
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    dispatch(premiumProductBids(id));
  }, [dispatch, id]);

  useEffect(() => {
    // console.log(data);
    // // console.log(data.data.allPremium)
  }, [data]);

  // Pagination control

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3 ">
        {/* Header */}
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solutions",
              path: "/super-admin/premium-products",
            },
            {
              name: "Premium Product Category List",
              path: "/super-admin/premium-products",
            },
            {
              name: "Offer",
              path: "/super-admin/premium-products/single-active-offer",
            },
            {
              name: "Request List",
              path: "/super-admin/premium-products/offer-request-list",
            },
          ]}
        />

        {/* filter */}

        {/* <div className="flex gap-6 py-3">
          <NavLink
            onClick={() => setActiveRole("Approved")}
            className={`flex items-center font-semibold text-sm gap-2 text-[#828282] uppercase ${
              activeRole === "Approved"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            APPROVED
            <span
              className={`text-sm font-semibold px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Approved"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {availableCount}
            </span>
          </NavLink>
          <NavLink
            onClick={() => setActiveRole("Pending")}
            className={`flex items-center font-semibold text-sm gap-2 text-[#828282] uppercase ${
              activeRole === "Pending"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            PENDING
            <span
              className={`text-sm font-semibold px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Pending"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {upcomingCount}
            </span>
          </NavLink>
        </div> */}

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[25%] text-left">VENDOR</span>

                <span className="w-[25%] text-leftt">PRODUCT</span>
                <span className="w-[25%] text-left">BID</span>

                {/* <span className="w-[25%] text-left">ACTION</span> */}
              </div>

              {/* Table Body */}
              {/* {data && data.data && data.data.slice(0, 5).map((req) => ( */}
              {data && data.data && Array.isArray(data.data) ? (
                data.data.slice(0, 15).map((req) => (
                  <div
                    key={req.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                  >
                    <div className="w-[25%] text-left flex gap-2 items-center">
                      <img
                        className="w-7 h-7 md:w-10 md:h-10 rounded-full"
                        src={req.shop_picture}
                        alt=""
                      />
                      <span>{req.store_name}</span>
                    </div>

                    <div className="w-[25%] text-left flex items-center gap-2 ">
                      <img
                        className="w-7 h-7 md:w-10 md:h-10 rounded-lg"
                        src={req.product_image}
                        alt=""
                      />
                      <span>{req.product_name}</span>
                    </div>
                    <span className="w-[25%] text-left">${req.bid}</span>

                    {/* <div className="w-[25%] text-left flex items-center gap-2">
                      <img src="/assets/icons/view.svg" alt="" />

                      <img src="/assets/icons/ban.svg" alt="" />
                    </div> */}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {/* Pagination */}
      </div>
    </>
  );
}
