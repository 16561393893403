import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UserProductsReviewed from "../../../../Components/Shop-And-User-List/User-List/UserProductsReviewed";

export default function UserProductsReviewedPage() {
  return (
    <MyShopLayout>
      <UserProductsReviewed />
    </MyShopLayout>
  );
}
