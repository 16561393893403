import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  vendorRequestsDetailsSearch,
  vendorRequestsList,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";

export default function VendorRequestsList() {
  const [requests, setRequests] = useState([]);
  const [search, setSearch] = useState("");
  const vendorRequestsLists = useSelector((state) => state.admin.data);
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    if (vendorRequestsLists?.total) {
      let totalLength = vendorRequestsLists.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [vendorRequestsLists]);


  useEffect(() => {
    if (search) {
      dispatch(vendorRequestsDetailsSearch(search));
    } else {
      dispatch(vendorRequestsList());
    }
  }, [search,dispatch]);

  useEffect(() => {
    setRequests(vendorRequestsLists);
  }, [vendorRequestsLists]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(vendorRequestsList(page));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Vendor Request List",
          },
        ]}
      />
      <div className="relative flex items-center w-full ">
        <img
          src="/assets/icons/manage-products/search.svg"
          alt=""
          className="absolute left-3 text-[#C19A6B]"
        />

        {/* search  */}
        <input
          className="px-8 py-2 md:py-3 text-xs md:text-sm w-full rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] "
          type="search"
          placeholder="Search…"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="flex justify-center border-gray-500 overflow-auto">
        {/* table */}
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}

            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[10%] text-left">Image</span>

              <span className="w-[15%] text-left">Shop Name</span>
              <span className="w-[20%] text-left">Email</span>
              <span className="w-[15%] text-left">Vendor Name</span>
              <span className="w-[20%] text-left">Status</span>
              <span className="w-[10%] text-left">Action </span>
            </div>

            {/* Table Body */}

            {loading ? (
              <div className="flex justify-center items-center h-[300px]">
                <Loader />
              </div>
            ) : requests?.data?.length > 0 ? (
              requests?.data.map((req) => (
                <div
                  key={req.id}
                  className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                >
                  <span className="w-[10%] ">
                    <img
                      src={req.shop_picture}
                      alt=""
                      className="rounded-[20px] w-10 h-10"
                    />
                  </span>

                  <span className="w-[15%]  ">{req.store_name}</span>
                  <span className="w-[20%] ">{req.email}</span>
                  <span className="w-[15%]  ">{req.name}</span>
                  <span className="w-[20%]">
                    <span className="px-[15px] py-[5px] rounded-[7.5px] border text-[#FEC600] border-[#FEC600] bg-[#FFFBF0] font-normal">
                      {req.status === "0" && "Pending"}
                    </span>
                  </span>

                  <span className="w-[10%] ">
                    <Link
                      to={`/super-admin/vendor-requests/request-details/${req.user_id}`}
                    >
                      <img
                        src="/assets/icons/manage-products/view.svg"
                        alt=""
                      />
                    </Link>
                  </span>
                </div>
              ))
            ) : (
              <h1>No Result Found</h1>
            )}
          </div>
        </div>
      </div>
      {requests?.data?.length>10?(
        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
            SHOWING {requests.from + "-" + requests.to} OF {requests.total}
          </span>
          <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
            {/* Previous Button */}
            <div
              className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                currentPage === 1 ? "opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <svg
                className={`${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {/* Page Numbers */}
            <div className="flex items-center gap-2">
              {
                <Pagination
                  totalNumberOfPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              }
            </div>
            {/* Next Button */}
            <div
              className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                currentPage === totalPages ? "opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <svg
                className={`${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      ):(<></>)}
    </div>
  );
}
