import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, createBrand } from "../../../../store/adminSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BreadCrums from "../../../Common/BreadCrums";

export default function CreateBrand() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector((state) => state.admin);

  const initialImageSlots = Array(1).fill(null);
  const [imageSlots, setImageSlots] = useState(initialImageSlots);

  const [brandName, setBrandName] = useState("");
  // const [brandDescription, setBrandDescription] = useState("");
  const [img, setImg] = useState(null);

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      setImg(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSlots = [...imageSlots];
        newImageSlots[index] = e.target.result;
        setImageSlots(newImageSlots);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = (index) => {
    const newImageSlots = [...imageSlots];
    newImageSlots[index] = null;
    setImageSlots(newImageSlots);
  };

  const handleBrandName = (e) => {
    setBrandName(e.target.value);
  };

  // const handleBrandDescription = (e) => {
  //   setBrandDescription(e.target.value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (brandName === "" || img === null) {
      toast.error("please fill all fields");
    } else {
      const formData = new FormData();

      formData.append("name", brandName);
      // formData.append("description", brandDescription);
      formData.append("logo", img);
      dispatch(createBrand(formData));
    }
  };

  // handling messages and navigation
  useEffect(() => {
    if (message) {
      toast.success(message);
      navigate(`/super-admin/brands/`);
    }

    if (error) {
      // Check if error is an object with nested error structure
      if (typeof error === "object" && Object.keys(error).length > 0) {
        const firstKey = Object.values(error)[0];
        if (Array.isArray(firstKey) && firstKey.length > 0) {
          toast.error(firstKey[0]);
        } else {
          toast.error("An unknown error occurred.");
        }
      }
      // Check if error is a string message
      else if (typeof error === "string") {
        toast.error(error);
      }
      // Handle other cases if necessary
      else {
        toast.error("An unknown error occurred.");
      }
    }
    dispatch(clearMessage());
  }, [error, message, loading]);

  return (
    <form
      onSubmit={handleSubmit}
      className="xl:px-4 flex flex-col gap-4 w-full"
    >
      {/* top bar */}
      <div className="flex justify-between items-center w-full">
        <BreadCrums
          breadCrum={[
            { name: "Brand", path: "/super-admin/brands/" },
            { name: "Create Brand", path: "/super-admin/brands/create-brands" },
          ]}
        />

        {loading ? (
          <button
            disabled={loading ? true : false}
            className="py-[14px] px-9 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs tracking-widest"
          >
            <p className="animate-bounce">Loading</p>
          </button>
        ) : (
          <button
            disabled={loading ? true : false}
            onClick={handleSubmit}
            className="py-2 md:py-[14px] px-5 md:px-9 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs tracking-widest"
          >
            Publish
          </button>
        )}
      </div>

      <div className="flex  flex-col  border border-[#EBF0ED] p-2 md:p-4 gap-4 md:gap-10 rounded-lg">
        {/* 1st div  */}
        <div className="w-full flex flex-col gap-4">
          {/* name  */}
          <div className="flex flex-col gap-1 w-full">
            <label
              className="text-[#6B6B6B]  font-semibold text-xs uppercase"
              htmlFor="product name "
            >
              Brand Name
            </label>
            <input
              className="bg-[#FAFAFA] rounded-lg w-full p-2 md:p-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
              type="text"
              name="name"
              id=""
              placeholder="name"
              value={brandName}
              onChange={handleBrandName}
              maxLength={30}
            />
          </div>
          {/* <div className="flex flex-col gap-1 w-full">
            <label
              className="text-[#6B6B6B]  font-semibold text-xs uppercase"
              htmlFor="product name "
            >
              Description
            </label>
            <textarea
              name="review"
              id=""
              cols="30"
              rows="10"
              className="bg-[#F9FAFB] w-full h-[181px] px-4 py-3 rounded-[8px] outline-[#828282] font-normal text-sm resize-none"
              placeholder="Description..."
              value={brandDescription}
              onChange={handleBrandDescription}
              maxLength={250}
            ></textarea>
          </div> */}
        </div>

        {/* 2nd div  */}
        <div className="w-full flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-[#6B6B6B]  font-semibold text-xs">IMAGE</h1>

            <div className=" grid grid-cols-3 gap-4">
              {imageSlots.map((image, index) => (
                <div key={index} className="flex flex-col items-center w-28">
                  <div
                    className={`relative w-full h-[90px] border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0] `}
                  >
                    {image ? (
                      <div className="w-full h-full relative">
                        <img
                          src={image}
                          alt={`Uploaded-${index}`}
                          className="w-full h-full object-cover rounded-lg"
                        />
                        {/* Delete Button */}
                        <button
                          className="absolute top-1 right-1 bg-white border border-[#EBF0ED] rounded-full w-4 h-4 flex justify-center items-center"
                          onClick={() => handleImageDelete(index)}
                        >
                          <svg
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.9748 1.00017L1.00002 5.975M1 1L5.9748 5.9748"
                              stroke="black"
                              stroke-width="1.07"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                        <input
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={(e) => handleImageUpload(e, index)}
                        />
                        <span>+</span>
                      </label>
                    )}
                  </div>
                  {/* Label with red star for required images */}
                  <div className="text-sm font-normal text-gray-500 mt-2">
                    Image
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
