import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import { vendorOrdersList, vendorOrdersListSearch } from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

export default function VendorOrderList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  const [activeRole, setActiveRole] = useState("");

  useEffect(() => {

    dispatch(vendorOrdersList(id));
    // console.log(data);

  }, [dispatch, id, data]);

  useEffect(() => {
    if(search!="") setActiveRole("");
    if(search!=""){
      dispatch(vendorOrdersListSearch({id:id,search:search}));
    } else {
      dispatch(vendorOrdersList({id:id}));
    }
  }, [search, dispatch, id]);

  useEffect(() => {
    dispatch(vendorOrdersList({id:id,status:activeRole}));
  }, [activeRole, dispatch, id]);

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Shop List", path: "/super-admin/shop-list/" },
            {
              name: "Shop Details",
              path: -1,
            },
            {
              name: "Shop Order",
              path: `/super-admin/shop-list/vendor-order-list/${id}`,
            },
          ]}
        />

        {/* search */}
        <div className="flex items-center justify-between gap-4 mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs"
              type="search"
              placeholder="Search Products..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* filter  */}
        <div className="flex items-center justify-between mt-2 text-xs md:text-sm">
          <div className="flex gap-3 md:gap-6">
            <NavLink

              onClick={() => handleRoleChange("All")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"

              }`}
            >
              ALL ORDERS
              <span

                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "ALL PRODUCTS"

                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data?.data?.allOrders?.length>0? data?.data?.completedOrdersCount+data?.data?.cancelledOrdersCount+data?.data?.pendingOrdersCount+data?.data?.activeOrdersCount : 0}
              </span>
            </NavLink>

            <NavLink

              onClick={() => handleRoleChange("completed")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "completed"

                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              DELIVERED
              <span

                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "completed"

                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data?.data && data.data.completedOrdersCount}
              </span>
            </NavLink>

            <NavLink
              onClick={() => handleRoleChange("in progress")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "in progress"

                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              PENDING
              <span

                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "in progress"

                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data?.data && data.data.pendingOrdersCount}
              </span>
            </NavLink>
            <NavLink

              onClick={() => handleRoleChange("canceled")}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "canceled"

                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              CANCELLED
              <span

                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-[5.75px] ${
                  activeRole === "canceled"

                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {!loading && data?.data && data.data.cancelledOrdersCount}
              </span>
            </NavLink>
          </div>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between ">
                <span className="w-[12%] text-left">ORDER ID</span>
                <span className="w-[12%] text-left">CUSTOMER</span>
                <span className="w-[12%] text-left">AMOUNT</span>
                <span className="w-[12%] text-left">PAYMENT METHOD</span>
                <span className="w-[12%] text-left">ORDER DATE</span>
                <span className="w-[12%] text-left">PAYMENT STATUS</span>
                <span className="w-[12%] text-left">ORDER STATUS</span>
                <span className="w-[12%] text-left">ACTION</span>
              </div>


              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>

                  </td>
                </tr>
              ) : data?.data?.allOrders && data.data.allOrders.length > 0 ? (
                data.data.allOrders.map((order) => (
                  <div
                    key={order.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <span className="w-[12%] text-left ">
                      {order.order_uid}
                    </span>


                    <span className="w-[12%] text-left">{order.customer}</span>
                    <span className="w-[12%] text-left ">${order.amount} </span>
                    <span className="w-[12%] text-left">
                      {order.payment_method}

                    </span>
                    <span className="w-[12%] text-left">{order.date}</span>
                    <div className="w-[12%] text-left">
                      <span
                        // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                        className={`${
                          order.payment_status === "completed"
                            ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                            : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                        }`}
                      >
                        {order.payment_status}
                      </span>
                    </div>

                    <div className="w-[12%] text-left">
                      <span
                        // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                        className={`${
                          order.status === "1"
                            ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                            : order.status === "2"
                            ? "bg-[#F1F7FF] text-sm py-1 px-3 border border-[#44A6E9] text-[#44A6E9] rounded-lg"
                            : order.status === "0"
                            ? "bg-[#FFFBF0] text-sm py-1 px-3 border border-[#FEC600] text-[#FEC600] rounded-lg"
                            : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#6e3c3a] rounded-lg"
                        }`}
                      >
                        {order.status == 0
                          ? "pending"
                          : order.status == 1
                          ? "active"
                          : "disabled"}
                      </span>
                    </div>

                    <div className="w-[12%] text-left ">
                      <div className="flex gap-2 ">
                        <Link
                          to={`/super-admin/shop-list/vendor-order-view/${order.id}`}
                        >
                          <img src="/assets/icons/view.svg" alt="" />
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center font-bold tracking-wide">
                  No Orders Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
