import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import ProductManagmentManageProduct from "../../../Components/My-Shop-Components/Product-Managment-Components/ProductManagmentManageProduct";

export default function ProductManagmentManageProductPage() {
  return (
    <>
      <MyShopLayout>
        <ProductManagmentManageProduct />
      </MyShopLayout>
    </>
  );
}
