import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  activeOrDissableUser,
  usersList,
  usersListSearch,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import Loader from "../../Common/Loader";
import { toast } from "react-toastify";
import BreadCrums from "../../Common/BreadCrums";

const UserList = () => {
  const [activeRole, setActiveRole] = useState("All");
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  useEffect(() => {
    if (search) {
      dispatch(usersListSearch(search));
    } else {
      dispatch(usersList());
    }
  }, [search, dispatch]);

  // handling role for active or disbale states
  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  // active disable
  const handleActiveOrDisable = (id, name, current_status) => {
    if (current_status === "0") {
      dispatch(
        activeOrDissableUser({ arg1: "status", arg2: "ban", arg3: id })
      ).then(() => {
        if (activeRole === "All") {
          dispatch(usersList());
        }
        if (activeRole === "active") {
          dispatch(usersList({ arg1: "status", arg2: "active" }));
        }
        if (activeRole === "disabled") {
          dispatch(usersList({ arg1: "status", arg2: "banned" }));
        }
        toast.success(
          `${name} has been ${
            current_status === "0" ? "disabled" : "activated"
          } successfully.`
        );
      });
    } else {
      dispatch(
        activeOrDissableUser({ arg1: "status", arg2: "active", arg3: id })
      ).then(() => {
        if (activeRole === "All") {
          dispatch(usersList());
        }
        if (activeRole === "active") {
          dispatch(usersList({ arg1: "status", arg2: "active" }));
        }
        if (activeRole === "disabled") {
          dispatch(usersList({ arg1: "status", arg2: "banned" }));
        }
        toast.success(
          `${name} has been ${
            current_status === "0" ? "disabled" : "activated"
          } successfully.`
        );
      });
    }
  };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* Header */}
        <BreadCrums breadCrum={[{ name: "User List", path: "" }]} />

        {/* Search and Add Product */}
        <div className="flex justify-between gap-6">
          <div className="relative flex items-center w-full">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />
            <input
              className="w-full py-2 md:py-3  pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
              type="search"
              placeholder="Search User by name or email ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        {/* filter  */}
        <div className="flex items-center justify-between mt-2 text-xs md:text-sm">
          <div className="flex gap-6">
            <NavLink
              onClick={() => {
                handleRoleChange("All");
                dispatch(usersList());
              }}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
              }`}
            >
              ALL USERS
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl ${
                  activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
                }`}
              >
                {/* {Array.isArray(data?.users) ? data.users.length : 0} */}(
                {!loading && data?.total_user_count
                  ? data?.total_user_count
                  : 0}
                )
              </span>
            </NavLink>

            <NavLink
              onClick={() => {
                handleRoleChange("active");
                dispatch(usersList({ arg1: "status", arg2: "active" }));
              }}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "active"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              ACTIVE
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl  ${
                  activeRole === "active"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                (
                {!loading && data?.active_user_count
                  ? data?.active_user_count
                  : 0}
                )
              </span>
            </NavLink>

            <NavLink
              onClick={() => {
                handleRoleChange("disabled");
                dispatch(usersList({ arg1: "status", arg2: "banned" }));
              }}
              className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
                activeRole === "disabled"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              DISABLED
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px]   bg-[#FAFAFA] border   rounded-lg md:rounded-xl  ${
                  activeRole === "disabled"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                ({!loading && data?.banned_users ? data?.banned_users : 0})
              </span>
            </NavLink>
          </div>
        </div>

        {/* Table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[14%] text-left uppercase">IMAGE</span>

                <span className="w-[14%] text-left uppercase">NAME</span>
                <span className="w-[14%] text-left uppercase">Email</span>
                <span className="w-[14%] text-left uppercase">
                  ORDER (PLACED)
                </span>
                <span className="w-[14%] text-left uppercase">
                  MONTHLY SPEND
                </span>
                <span className="w-[15%] text-left uppercase text-nowrap">
                  MONTHLY EARNING
                </span>
                <span className="w-[14%] text-left uppercase">Action</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.users?.length > 0 ? (
                data?.users?.map((user) => (
                  <div
                    key={user.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <span className="w-[14%] text-left uppercase">
                      <img
                        src={user.image}
                        alt=""
                        className="w-7 h-7 md:w-10 md:h-10 rounded-full"
                      />
                    </span>

                    <span className="w-[14%] text-left uppercase">
                      {user.name}
                    </span>
                    <span className="w-[14%] text-left uppercase">
                      {user.email}{" "}
                    </span>
                    <span className="w-[14%] text-left uppercase ">
                      {user.orders}{" "}
                    </span>
                    <span className="w-[14%] text-left uppercase">
                      ${user.total_spend}
                    </span>
                    <span className="w-[14%] text-left uppercase">
                      ${user.total_earning}
                    </span>

                    <div className="w-[14%] text-left uppercase">
                      <div className="flex gap-2 ">
                        <Link
                          to={`/super-admin/users-list/user-profile-stats/${user.id}`}
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9 "
                            src="/assets/icons/manage-products/view.svg"
                            alt=""
                          />
                        </Link>
                        {user?.ban === "0" ? (
                          <span
                            title="disable"
                            className="cursor-pointer"
                            onClick={() =>
                              handleActiveOrDisable(
                                user.id,
                                user.name,
                                user.ban
                              )
                            }
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9 "
                              src="/assets/icons/bulk.svg"
                              alt=""
                            />
                          </span>
                        ) : (
                          <span
                            title="active"
                            className="cursor-pointer"
                            onClick={() =>
                              handleActiveOrDisable(
                                user.id,
                                user.name,
                                user.ban
                              )
                            }
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9 "
                              src="/assets/icons/make-active.svg"
                              alt=""
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center font-bold tracking-wide">
                  No Users Found
                </div>
              )}
            </div>
          </div>
        </div>

        {/* pagination start here */}

        {/* pagination end here */}
      </div>
    </>
  );
};

export default UserList;
