import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { mallRequestList } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";

export default function MallRequest() {
  const { data, loading, message } = useSelector((state) => state.admin);
  const [requests, setRequests] = useState([]);
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      dispatch(mallRequestList());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (data?.malls) setRequests(data?.malls);
  }, [data]);

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Mall Requests",
            path: "/super-admin/mall-managment/mall-request",
          },
        ]}
      />

      {/* table */}
      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[25%] text-left">MALL</span>

              <span className="w-[25%] text-left">Email</span>
              <span className="w-[25%] text-left">LOCATION</span>
              <span className="w-[25%] text-left">Action </span>
            </div>

            {/* Table Body */}
            {requests?.length > 0 &&
              requests?.map((req) => (
                <div
                  key={req.id}
                  className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                >
                  <div className="w-[25%] text-left flex items-center gap-2">
                    <img
                      src={imagePath + req.mall_image}
                      alt=""
                      className="rounded-lg w-10 h-10"
                    />
                    <span>{req.mall_name}</span>
                  </div>

                  <span className="w-[25%] text-left">{req.email}</span>
                  <span className="w-[25%] text-left ">
                    {req.country + ", " + req.city}
                  </span>

                  <div className="w-[25%] text-left ">
                    <Link
                      to={
                        "/super-admin/mall-managment/mall-request-details/" +
                        req.mall_id
                      }
                    >
                      <img
                        src="/assets/icons/manage-products/view.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
