import React from "react";
import MyShopLayout from "../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UpdateHeroSectionImageSelectionComponent from "../../Components/Hero-Section-Components/UpdateHeroSectionImageSelectionComponent";

function UpdateHeroSectionImageSelection() {
  return (
    <MyShopLayout>
      <UpdateHeroSectionImageSelectionComponent />
    </MyShopLayout>
  );
}

export default UpdateHeroSectionImageSelection;
