import CustomerReturnsClosed from "../../../../Components/My-Shop-Components/Orders-Management/Customer-Returns/CustomerReturnsClosed";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function CustomerReturnsClosedPage() {
  return (
    <>
      <MyShopLayout>
        <CustomerReturnsClosed />
      </MyShopLayout>
    </>
  );
}
