import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosTrendingUp } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { RiArrowDropDownLine } from "react-icons/ri";

import "./bannerAdDetails.css";
import BannerAdsDetailsBarCharts from "../../Graphs/Banner-Ads-Graphs/BannerAdsDetailsBarChart";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { fetchBannerstatistics } from "../../../store/adminSlice";

export default function BannerAdDetails() {
  const [activeTab, setActiveTab] = useState("stats");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(fetchBannerstatistics());
  }, []);
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Marketing Solutions", path: "/super-admin/banner-ads" },
            {
              name: "Banner Ad Detail",
              path: "/super-admin/banner-ads/banner-ad-details",
            },
          ]}
        />

        {/* tabs */}
        <div className="flex items-center text-xs md:text-sm gap-3">
          <Link
            to="/super-admin/banner-ads"
            onClick={() => handleTabClick("list")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "list"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            LIST
          </Link>
          <Link
            to=""
            onClick={() => handleTabClick("stats")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "stats"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STATS
          </Link>
          <Link
            to="/super-admin/banner-ads/banner-ad-setting"
            onClick={() => handleTabClick("settings")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "settings"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            SETTINGS
          </Link>
        </div>

        {/*  */}
        <div className="flex flex-col gap-4">
          {/* graph  */}
          <div className="w-full flex flex-col md:flex-row gap-8">
            {/* graph  */}
            <div className="w-full md:w-3/4 ">
              <BannerAdsDetailsBarCharts data={data} />
            </div>
            {/* listed product and categories  */}
            <div className="flex flex-row md:flex-col gap-4">
              <div className="w-full flex flex-col gap-5 md:gap-10 text-left p-3 md:p-7 shadow-md rounded-lg">
                <span className="font-semibold text-base md:text-xl">
                  Total Ads
                </span>
                <div className="flex items-end justify-between gap-10">
                  <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
                    {data?.total_ads}
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-5 md:gap-10 text-left p-3 md:p-7 shadow-md rounded-lg">
                <span className="font-semibold text-base md:text-xl text-nowrap">
                  Pending Ad Requests
                </span>
                <div className="flex items-end justify-between gap-10">
                  <span className="font-bold text-[30px] md:text-[50px] lg:text-[76px] leading-[4rem]">
                    {data?.pending_ads_request}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
