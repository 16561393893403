import React from "react";

import AuctionSubCategories from "../../../../../Components/My-Shop-Components/Manage-Categories/Auction-Categories/Sub-Categories/AuctionSubCategories";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AuctionSubCategoriesPage() {
  return (
    <MyShopLayout>
      <AuctionSubCategories />
    </MyShopLayout>
  );
}
