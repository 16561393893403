import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearMessage,
  mainCategory,
  subCategoriesList,
  subSubCategoriesCreate,
} from "../../../../../store/adminSlice";
import { toast } from "react-toastify";
import BreadCrums from "../../../../Common/BreadCrums";

export default function MainShopCreateSubSubCategory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, message, loading, categories, subCategories } = useSelector(
    (state) => state.admin
  );
  const [headCategory, setHeadCategory] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [branchCategory, setBranchCategory] = useState();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message) {
      toast.success(message);
      navigate(-1);
    }
    dispatch(clearMessage());
  }, [error, message]);

  useEffect(() => {
    dispatch(subCategoriesList());
    dispatch(mainCategory());
    // console.log(headCategory, branchCategory);
  }, []);

  useEffect(() => {
    if (subCategories.subcategories) {
      setBranchCategory(
        subCategories.subcategories.find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      );
    }
  }, [subCategories]);

  useEffect(() => {
    if (categories.categories && branchCategory) {
      setHeadCategory(
        categories.categories.find(
          (item) => parseInt(item.id) === parseInt(branchCategory.category_id)
        )
      );
    }
  }, [branchCategory]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (categoryName === "") {
      toast.error("Input all fields");
    } else {
      formData.append("sub_category_id", id.toString());
      formData.append("name", categoryName);

      dispatch(subSubCategoriesCreate(formData));
    }
  };

  return (
    <form className="xl:px-4 flex flex-col gap-4 w-full">
      {/* top bar */}
      <div className="flex flex-col md:flex-row gap-3 justify-between items-center w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Main Shop Category",
              path: "/super-admin/main-categories/",
            },
            {
              name: "Main Category",
              path: -2,
            },
            {
              name: "Sub Category",
              path: -1,
            },
            {
              name: "Create Sub-Sub Category",
              path: `/super-admin/main-categories/sub-categories/create-category/${id}`,
            },
          ]}
        />

        <div className="flex justify-end w-full md:w-fit">
          {/* submit btn  */}
          <button
              onClick={handleSubmit}
              disabled={loading}
              className="py-2 md:py-[15px] px-5 h-12 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
            {!loading ? (
              "Publish"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
            </button>
        </div>
      </div>

      {/* create form  */}
      <div className="flex flex-col gap-2">
        <p className="font-semibold text-lg">Create Category</p>

        <div className="  border border-[#EBF0ED] p-4 gap-10 rounded-lg">
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Category Name
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="text"
                name="name"
                id=""
                placeholder="name..."
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
