import React, { useState } from "react";
import { Link } from "react-router-dom";
import Notifications from "../Common/Notifications/Notifications";
import { useSelector } from "react-redux";
export default function TheHeader() {
  const [notification, setNotification] = useState(false);

  const { token } = useSelector((state) => state.admin);

  const handleMSG = () => {
    const url = `${process.env.REACT_APP_CHAT_LINK}${token}`;
    
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="flex justify-between items-center bg-white z-50">
        <div>
          <img
            src="/assets/images/header/wizalot.svg"
            className="w-[182px] h-10 xl:h-16"
            alt=""
          />
        </div>

        <div className="pr-2">
          <div className="flex gap-4 items-center">
            <Link
              onClick={handleMSG}
              className="bg-[#F7F7F7] relative h-11 w-11 rounded-full flex items-center justify-center"
            >
              <img src="/assets/images/header/chat2.svg" className="" alt="" />
              <div className="absolute top-1 right-0 w-2 h-2 bg-[#C19A6B] rounded-full"></div>
            </Link>
            <div
              onClick={() => setNotification(!notification)}
              className="bg-[#F7F7F7] cursor-pointer relative h-11 w-11 rounded-full flex items-center justify-center"
            >
              <img src="/assets/images/header/notify.svg" alt="" />
              <div className="absolute top-1 right-0 w-2 h-2 bg-[#C19A6B] rounded-full"></div>
            </div>
          </div>
        </div>
      </div>
      <Notifications
        notification={notification}
        setNotification={setNotification}
      />
    </>
  );
}
