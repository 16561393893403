import MainShopCreateSubSubCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Manage-Main-Shop-Categories/Sub-Sub-Categories/MainShopCreateSubSubCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function MainShopCreateSubSubCategoryPage() {
  return (
    <MyShopLayout>
      <MainShopCreateSubSubCategory />
    </MyShopLayout>
  );
}
