import AccountStatementPage from "../Pages/My-Shop/Finance-Management-Pages/Account-Statement-Page/AccountStatementPage";
import TransactionsPage from "../Pages/My-Shop/Finance-Management-Pages/Transactions-Page/TransactionsPage";
import ManageReviewsPage from "../Pages/My-Shop/Order-Management-Pages/Manage-Reviews-Page/ManageReviewsPage";

const manageReviewRoutes = [

    {

        path: "/super-admin/manage-reviews",
        children: [
            {
                index: true,
                element: < ManageReviewsPage /> ,
            },
            {
                path: "/super-admin/manage-reviews/account-statement",
                element: < AccountStatementPage /> ,
            },
            {
                path: "/super-admin/manage-reviews/transactions",
                element: < TransactionsPage /> ,
            },
        ]
    }


]

export default manageReviewRoutes;