import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MdKeyboardArrowRight } from "react-icons/md";
import "./stats.css";
import CategoryStatsBarCharts from "../../Graphs/Premium-Product-Graphs/CategoryStatsBarChart";
import BreadCrums from "../../Common/BreadCrums";

export default function MainCategoryStats() {
  const [activeTab, setActiveTab] = useState("stats");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solutions",
              path: "/super-admin/premium-products",
            },
            {
              name: "Premium Product Category List",
              path: "/super-admin/premium-products",
            },
            {
              name: "Premium Product Category Details",
              path: "/super-admin/premium-products/main-category-stats",
            },
          ]}
        />

        {/* tabs */}
        <div className="flex items-center text-xs md:text-sm gap-3">
          <Link
            to="/super-admin/premium-products"
            onClick={() => handleTabClick("list")}
            className={`px-2 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "list"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            LIST
          </Link>
          <Link
            to="/super-admin/premium-products/main-category-stats"
            onClick={() => handleTabClick("stats")}
            className={`px-2 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "stats"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STATS
          </Link>
        </div>

        {/*  */}

        <div className=" flex flex-col gap-4">
          {/* graph  */}
          <div className="w-full flex flex-col md:flex-row gap-8">
            {/* graph  */}
            <div className="w-full lg:w-3/4 flex flex-col gap-3">
              <CategoryStatsBarCharts />
            </div>
            {/* listed product and categories  */}
            <div className="flex md:flex-col gap-4">
              <div className="w-full flex flex-col gap-5 md:gap-7 lg:gap-10 text-left p-2 md:p-4 lg:p-7 shadow-md rounded-lg">
                <span className="font-semibold  text-nowrap text-sm md:text-base lg:text-xl">
                  Total Bidding Amount
                </span>
                <div className="flex items-center md:items-end justify-between md:gap-10">
                  <span className="font-bold text-[30px] lg:text-[50px] leading-[4rem]">
                    $683k
                  </span>
                  <span className="p-2 md:p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1">
                    <MdKeyboardArrowRight className="w-4 h-4 md:w-5 md:h-5" />
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-5 md:gap-7 lg:gap-10 text-left  p-2 md:p-4 lg::p-7 shadow-md rounded-lg">
                <span className="font-semibold text-sm md:text-base lg:text-xl">
                  Highest Bid
                </span>
                <div className="flex items-center md:items-end justify-between md:gap-10">
                  <span className="font-bold text-[30px] lg:text-[50px] leading-[4rem]">
                    $135
                  </span>
                  <span className="p-2 md:p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1">
                    <MdKeyboardArrowRight className="w-4 h-4 md:w-5 md:h-5" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
