import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  fetchAdminBoosterProduct,
  updateBoosterProductt,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";

export default function BoosterDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requests, setRequests] = useState([]);

  const productBoosterList = useSelector((state) => state.admin.data);

  const { message, error } = useSelector((state) => state.admin);
  // const fetchData = async () => {
  //   try {
  //     dispatch(fetchAdminBoosterProduct(id));
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const changeBoosterStatus = (status) => {
    dispatch(updateBoosterProductt({ id, status }));
  };

  useEffect(() => {
    dispatch(fetchAdminBoosterProduct(id));
  }, [dispatch, id]);

  useEffect(() => {
    setRequests(productBoosterList);
  }, [productBoosterList]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/super-admin/product-boosters");
    }
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
  }, [message, error, dispatch, navigate]);

  return (
    <div className="xl:first-line:px-4 flex flex-col gap-4 pb-2">
      {/* name */}
      <div className="flex justify-between w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solutions",
              path: "/super-admin/product-boosters",
            },
            {
              name: "Booster Products",
              path: "/super-admin/product-boosters",
            },
            {
              name: "Booster Details",
              path: "/super-admin/product-boosters/booster-details",
            },
          ]}
        />
        {requests.booster && requests.booster.booster_status == 0 && (
          <div className="flex items-center gap-3">
            <button
              className="py=7 md:py-[10px] px-5 md:px-9 border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg text-[#828282] font-semibold text-xs md:text-sm"
              onClick={(e) => changeBoosterStatus(2)}
            >
              Reject
            </button>
            <button
              className="py-2 md:py-[10px] px-5 md:px-9  bg-[#C19A6B] rounded-lg text-white font-semibold text-xs md:text-sm"
              onClick={(e) => changeBoosterStatus(1)}
            >
              Approve
            </button>
          </div>
        )}
      </div>

      {/* table  */}

      <div className="flex justify-center border-gray-500 overflow-auto ">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className=" flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[30%] text-left">Product</span>

              <span className=" w-[20%] text-left">Price</span>
              <span className=" w-[25%] text-left ">Status</span>

              <span className=" w-[25%] text-left">Stoke</span>

              {/* <th className="  w-[100px] text-left ">ACTION</th> */}
            </div>

            {/* Table Data Row */}

            {requests.booster &&
              requests.booster.products.map((product) => {
                return (
                  <div
                    // key={item.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[30%]  flex items-center gap-2">
                      {" "}
                      <span className="flex gap-3 items-center ">
                        <img
                          src={imagePath + product.image}
                          alt=""
                          className="w-10 h-10 rounded-[10px]"
                        />

                        <span className="flex flex-col gap-1 ">
                          <span className="text-sm font-semibold">
                            {product.product_name}
                          </span>
                          <span className="font-medium text-sm text-[#6B6B6B]">
                            Category: {product.category_name}
                          </span>
                        </span>
                      </span>
                    </div>

                    <span className="w-[20%] text-left">${product.price}</span>

                    <div className="w-[25%] text-left">
                      <span
                        className="text-[#15CA32] font-medium text-sm border border-[#15CA32] bg-[#F6FFF7] py-[5px] px-[15px] rounded-[10px]"
                        // {`
                        // ${
                        //   item.status === "Approved"
                        //     ? "bg-[#F6FFF7] text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                        //     : item.status === "Pending"
                        //     ? "bg-[#FFFBF0] text-sm px-2 py-[0.189rem] border border-[#FEC600] text-[#FEC600] rounded-xl"
                        //     : "bg-[#FFF3F4] text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl"
                        // }`}
                      >
                        {product.stock}
                      </span>
                    </div>

                    <span className="w-[25%] text-left">
                      {product.quantity}
                    </span>

                    {/* <td className="flex items-center gap-2 w-[100px]">
              <Link>
                <img
                  src="/assets/icons/manage-products/view.svg"
                  alt=""
                  className="w-9 h-9"
                />
              </Link>
            </td> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
