import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

import SuperAdminProfileSetting from "../../../Components/My-Shop-Components/Super-Admin-Profile-Components/SuperAdminProfileSetting";

export default function SuperAdminProfileSettingPage() {
  return (
    <>
      <MyShopLayout>
        <SuperAdminProfileSetting />
      </MyShopLayout>
    </>
  );
}
