import ForgotPasswordPage from "../Pages/ForgotPasswordPage";
import LoginPage from "../Pages/LoginPage";
import NewPasswordPage from "../Pages/NewPasswordPage";



const authRoutes = [
    {
        
        index: true,
        element: <LoginPage />
    },
    { 
        path: "/forgot-password",
        element: <ForgotPasswordPage />
    },
    { 
        path: "/new-password",
        element: <NewPasswordPage />
    },
];

export default authRoutes;