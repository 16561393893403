import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import OfferManagmentViewProduct from "../../../Components/My-Shop-Components/Offer-Managment-Components/OfferManagmentViewProduct";

export default function OfferManagmentViewProductPage() {
  return (
    <>
      <MyShopLayout>
        <OfferManagmentViewProduct />
      </MyShopLayout>
    </>
  );
}
