import React, { useEffect, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  activeOrDissableShop,
  approveMalls,
  clearMessage,
  mallList,
  mallRequestDetails,
  rejectMalls,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";

export default function MallRequestDetails() {
  const { id } = useParams();
  const { data, message, loading } = useSelector((state) => state.admin);
  const [request, setRequest] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mallRequestDetails(id));
  }, [id]);
  useEffect(() => {
    if (data?.mall_details) {
      setRequest(data?.mall_details[0]);
    }

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [data, message]);
  const handleActiveOrDisable = (id, status) => {
    dispatch(activeOrDissableShop({ id, status }));
    dispatch(mallRequestDetails(id));
  };

  return (
    <div className="xl:px-4 flex flex-col gap-4 pb-2">
      {/* name */}
      <div className="flex flex-col md:flex-row gsp-1 md:gap-0 justify-between w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Mall Requests",
              path: "/super-admin/mall-managment/mall-request",
            },
            {
              name: "Mall Request Details",
              path: "/super-admin/mall-managment/mall-request-details",
            },
          ]}
        />

        {/* buttons  */}

        <div className="flex items-center gap-3 justify-end">
          <button
            className="py-2 md:py-[10px] px-5 md:px-9 border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg text-[#828282] font-semibold text-xs md:text-sm"
            onClick={(e) => handleActiveOrDisable(id, "banned")}
          >
            Reject
          </button>
          <button
            className="py-2 md:py-[10px] px-5 md:px-9  bg-[#C19A6B] rounded-lg text-white font-semibold text-xs md:text-sm"
            onClick={(e) => handleActiveOrDisable(id, "approve")}
          >
            Approve
          </button>
        </div>
      </div>

      {/* main div  */}
      <div className="w-full flex flex-col gap-4 ">
        {/* user info  */}
        <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
          {/* user image and name  */}
          <div className="flex items-center gap-2">
            <span className="">
              <img
                src={request?.mal_image && imagePath + request.mal_image}
                alt=""
                className="w-14 h-14 rounded-full"
              />
            </span>
          </div>

          {/* user details  */}
          <div className=" w-full flex flex-col md:flex-row justify-between items-start gap-1 md:gap-16">
            {/* 1st  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  MALL NAME :
                </span>
                <span className="font-semibold text-xs">
                  {request?.mall_name && request?.mall_name}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  PHONE NUMBER :
                </span>
                <span className="font-semibold text-xs">
                  {request?.mall_phone && request?.mall_phone}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2 border-b border-[#EBF0ED] md:border-0">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  E-MAIL :
                </span>
                <span className="font-semibold text-xs">
                  {request?.email && request?.email}
                </span>
              </div>
            </div>

            {/* 2nd  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  CUSTOMER SUPPORT NUMBER :
                </span>
                <span className="font-semibold text-xs">
                  {request?.social_security_number &&
                    request?.social_security_number}
                </span>
              </div>
            </div>

            {/* 3rd */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  LOCATION :
                </span>
                <span className="font-semibold text-xs">
                  {request?.country && request?.country + ", " + request?.city}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  POSTAL CODE :
                </span>
                <span className="font-semibold text-xs">
                  {request?.postal_code && request?.postal_code}
                </span>
              </div>
              <div className=" w-full flex justify-between gap-5  py-2">
                <p className="font-semibold text-xs text-nowrap text-[#6B6B6B] uppercase">
                  ADDRESS :
                </p>
                <span className="font-semibold  text-end text-xs">
                  {request?.address && request?.address}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*Verification Information  */}
        <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
          <p className="font-semibold text-base md:text-lg text-nowrap">
            Verification Information
          </p>

          {/*Verification  details  */}
          <div className=" w-full flex justify-between items-start gap-2 md:gap-16">
            {/* 1st  */}
            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-[8px] md:text-xs  text-[#6B6B6B] uppercase text-nowrap">
                VERIFICATION DOCUMENT :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full min-h-[70px]   mt-2">
                <img
                  src={
                    request?.mall_legal_document1 &&
                    imagePath + request?.mall_legal_document1
                  }
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>

            {/* 2nd  */}

            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-[8px] md:text-xs  text-[#6B6B6B] uppercase text-nowrap">
                VERIFICATION DOCUMENT :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full min-h-[70px]   mt-2">
                <img
                  src={
                    request?.mall_legal_document2 &&
                    imagePath + request?.mall_legal_document2
                  }
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>

            {/* 3rd */}
            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-[8px] md:text-xs  text-[#6B6B6B] uppercase text-nowrap">
                VERIFICATION DOCUMENT :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full min-h-[70px] mt-2">
                <img
                  src={
                    request?.mall_legal_document3 &&
                    imagePath + request?.mall_legal_document3
                  }
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>
          </div>
        </div>

        {/* bank Information  */}
        <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
          <p className="font-semibold md:text-lg">Bank In formation</p>

          {/* bank details  */}
          <div className=" w-full flex flex-col md:flex-row justify-between items-start md:gap-16">
            {/* 1st  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Account Title :
                </span>
                <span className="font-semibold text-xs">
                  {request?.bank_account_title && request?.bank_account_title}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Account Number :
                </span>
                <span className="font-semibold text-xs">
                  {request?.bank_account_number && request?.bank_account_number}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2 border-b border-[#EBF0ED] md:border-0">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Bank Name :
                </span>
                <span className="font-semibold text-xs">
                  {request?.bank_account_name && request?.bank_account_name}
                </span>
              </div>
            </div>

            {/* 2nd  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Branch Name :
                </span>
                <span className="font-semibold text-xs">
                  {" "}
                  {request?.bank_branch_name && request?.bank_branch_name}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Branch Code :
                </span>
                <span className="font-semibold text-xs">
                  {request?.bank_branch_code && request?.bank_branch_code}
                </span>
              </div>
              <div
                className=" w-full flex justify-between  py-2 border-b border-[#EBF0ED] md:border-0 mb-1 md:mb-0
              "
              >
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  IBAN :
                </span>
                <span className="font-semibold text-xs">
                  {request?.iban && request?.iban}
                </span>
              </div>
            </div>

            {/* 3rd */}
            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Cheque Copy Image :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full min-h-[70px] mt-2">
                <img
                  src={
                    request?.cheque_copy_image &&
                    imagePath + request?.cheque_copy_image
                  }
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
