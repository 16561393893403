import OrderManagementPage from "../Pages/My-Shop/Order-Management-Pages/Order-Management-Page/OrderManagementPage";

const orderManagementRoutes = [

    {
        path: "/super-admin/orders-management",

        children: [{
            index: true,
            element: < OrderManagementPage /> ,
        }, ],
    },

]

export default orderManagementRoutes;