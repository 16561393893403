import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  activeOrDissableShop,
  clearMessage,
  mallDelete,
  mallList,
  mallSearch,
  // searchIT,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";
import DeletePopup from "../../DeletePopup";
import BreadCrums from "../../Common/BreadCrums";

const MallManagmentAllMall = () => {
  // const [orders, setOrders] = useState([]);

  const dispatch = useDispatch();
  const { data, loading,message } = useSelector(
    (state) => state.admin
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(mallList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(mallSearch(search));
  }, [search, dispatch]);

  // State for delete popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  // const [selectedMallId, setSelectedMallId] = useState(null);
  const [activeRole, setActiveRole] = useState("All");

  // Handle delete button click
  // const handleDeleteClick = (id) => {
  //   setSelectedMallId(id);
  //   setShowDeletePopup(true); // Show the delete popup
  // };

  // Handle delete confirmation in popup
  const deleteMall = (id) => {
    dispatch(mallDelete(id)).then(() => {
      dispatch(mallList()).then(() => {
        // Adjust the current page if necessary
        if (startIndex >= data.malls.length - 1) {
          setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        }
        toast.success("Mall deleted successfully!");
      });
    });
    setShowDeletePopup(false); // Hide the popup after confirmation
  };

  // Handle delete cancellation in popup
  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Hide the popup on cancel
  };

  const handleStatusChange = (role) => {
    setActiveRole(role);
    dispatch(mallList({ status: role }));
  };

  // Pagination control
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages =
    data && data.malls ? Math.ceil(data.malls.length / itemsPerPage) : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex =
    data && data.malls
      ? Math.min(startIndex + itemsPerPage, data.malls.length)
      : 0;
  // const paginatedProducts =
  //   data && data.malls ? data.malls.slice(startIndex, endIndex) : [];

  const shouldShowPagination =
    data && data.malls && data.malls.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleActiveOrDisable = (id, status) => {
    dispatch(activeOrDissableShop({ id, status }));
    dispatch(mallList());
  };
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [message, dispatch]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "All Malls",
              path: "/super-admin/mall-managment",
            },
          ]}
        />

        {/* search */}
        <div className="flex justify-between gap-5">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
              type="search"
              placeholder="Search Malls..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="font-semibold text-base md:text-lg">Joined Malls</div>

        {/* filter  */}
        <div className="flex items-center justify-between gap-4 text-xs md:text-sm">
          <div className="flex gap-6">
            <NavLink
              onClick={() => handleStatusChange("All")}
              className={`flex items-center font-semibold gap-2 text-[#828282] uppercase ${
                activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
              }`}
            >
              All Malls
              <span
                className={`font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg md:rounded-xl ${
                  activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
                }`}
              >
                {data?.data?.AllMalls}
              </span>
            </NavLink>

            <NavLink
              onClick={() => handleStatusChange("banned")}
              className={`flex items-center font-semibold gap-2 text-[#828282] uppercase ${
                activeRole === "Banned"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              Banned
              <span
                className={`font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg md:rounded-xl ${
                  activeRole === "Banned"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {data?.data?.bannedMalls}
              </span>
            </NavLink>
          </div>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-xs md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[25%] text-left">MALL</span>

                <span className="w-[25%] text-left">STORE COUNT</span>

                <span className="w-[25%] text-left">LOCATION</span>

                <span className="w-[25%] text-left">ACTION</span>
              </div>

              {/* Table Data Row */}

              {loading
                ? "Loading..."
                : data?.data?.data?.length > 0 &&
                  data?.data?.data?.map((item) => (
                    <div
                      key={item.id}
                      className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="flex items-center gap-3 w-[25%] text-left">
                        <img
                          src={item.image}
                          alt=""
                          className="w-10 h-10 rounded-lg"
                        />

                        <span className="  font-semibold text-[#18120F]  ">
                          {item.mall_name}
                        </span>
                      </div>
                      <span className="w-[25%] text-left  font-semibold text-[#18120F]">
                        {item.active_vendor_count}
                      </span>

                      <span className="w-[25%] text-left  font-semibold text-[#18120F] ">
                        {item.city}, {item.country}
                      </span>

                      <div className="flex items-center gap-2 w-[25%] text-left">
                        <Link
                          to={
                            "/super-admin/mall-managment/mall-details/" +
                            item.id
                          }
                        >
                          <img
                            src="/assets/icons/manage-products/view.svg"
                            alt=""
                            className="w-7 h-7 md:w-9 md:h-9"
                          />
                        </Link>
                        <Link
                          to={"/super-admin/mall-managment/store/" + item.id}
                        >
                          <img
                            src="/assets/icons/list.svg"
                            alt=""
                            className="w-7 h-7 md:w-9 md:h-9"
                          />
                        </Link>
                        {item.status === "1" ? (
                          <span
                            title="disable"
                            className="cursor-pointer"
                            onClick={() =>
                              handleActiveOrDisable(item.id, "reject")
                            }
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/bulk.svg"
                              alt=""
                            />
                          </span>
                        ) : (
                          <span
                            title="active"
                            className="cursor-pointer"
                            onClick={() =>
                              handleActiveOrDisable(item.id, "approve")
                            }
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/make-active.svg"
                              alt=""
                            />
                          </span>
                        )}
                        <img
                          className="w-7 h-7 md:w-9 md:h-9"
                          src="/assets/icons/delete1.svg"
                          alt=""
                          onClick={(e) => deleteMall(item.id)}
                        />
                        {/* <button
                            className="cursor-pointer focus:outline-none text-red-500 hover:text-red-700"
                            onClick={() => handleDeleteClick(item.id)}
                          >
                            <img
                              src="/assets/icons/manage-products/delete.svg"
                              alt="Delete"
                              className="w-9 h-9"
                            />
                          </button> */}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        {shouldShowPagination && (
          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} - {endIndex} OF {data.malls.length}{" "}
              RESULTS
            </span>
            <div className="flex items-center justify-between w-[265px] h-[40px] bg-white rounded-lg px-2">
              {/* Previous Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {paginationIndexes.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                        : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
              {/* Next Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>

      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          // onDeleteConfirmation={handleDeleteConfirmation} // Directly pass the function
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </>
  );
};

export default MallManagmentAllMall;
