import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  userListReturnProductSearch,
  usersListProductReturn,
} from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import { imagePath } from "../../../functions/URLString";
import BreadCrums from "../../Common/BreadCrums";

const UserProductsReturned = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      dispatch(userListReturnProductSearch({ id, query: search }));
    } else {
      dispatch(usersListProductReturn(id));
    }
  }, [search, dispatch, id]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* breadcrums */}
        <BreadCrums
          breadCrum={[
            { name: "User", path: "/super-admin/users-list/" },
            {
              name: "User Profile States",
              path: `/super-admin/users-list/user-profile-stats/${id}`,
            },
            {
              name: "Products Returned",
              path: `/super-admin/users-list/user-products-returned/${id}`,
            },
          ]}
        />

        {/* search */}
        <div className="relative flex items-center w-full">
          <img
            className="absolute left-3"
            src="/assets/icons/search.svg"
            alt=""
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
            type="search"
            placeholder="Search Return Products..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left">PRODUCT </span>

                <span className="w-[15%] text-left">ORDER ID</span>
                <span className="w-[10%] text-left">PRICE</span>
                <span className="w-[20%] text-left">STATUS</span>

                <span className="w-[15%] text-left">REASON</span>
                <span className="w-[20%] text-left">COMMENT</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.returned_orders?.length > 0 ? (
                data?.returned_orders?.map((prodct) => (
                  <div
                    key={prodct.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                  >
                    <div className=" w-[20%] text-left  gap-3 font-[600] flex items-center ">
                      <img
                        src={prodct.product_image}
                        className="w-10 h-10 text-center rounded-lg "
                        alt=""
                      />
                      <div className="flex flex-col ">
                        <span>{prodct.product_name}</span>
                        <span className="text-[#6B6B6B] text-sm font-[500]">
                          Category: {prodct.category_name}
                        </span>
                      </div>
                    </div>
                    <span className="w-[15%] text-left font-[600] ">
                      #{prodct.order_uid}
                    </span>
                    <span className="w-[10%] text-left font-[600] ">
                      ${prodct.price}
                    </span>
                    <div className="w-[20%] text-left font-[600] ">
                      <div className="flex flex-col">
                        <span>{prodct.payment_status}</span>
                        <span>
                          {prodct.created_at_formatted}
                          {"   "} {prodct.created_at_time}
                        </span>
                      </div>
                    </div>
                    <span className="w-[15%] text-left font-[600] ">
                      {prodct.reason}
                    </span>
                    <span className=" w-[20%] text-left  ">
                      <span className="text-sm font-[600] text-[#18120F]">
                        {prodct.comment}
                      </span>
                    </span>
                  </div>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="text-center font-bold tracking-wide">
                      No Return Orders Found
                    </div>
                  </td>
                </tr>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProductsReturned;
