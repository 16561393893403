import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateVendorStatus,
  vendorRequestsDetails,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";

import Loader from "../../Common/Loader";

import BreadCrums from "../../Common/BreadCrums";

export default function VendorRequestDetails() {
  const { id } = useParams();
  // console.log(id, "id----");
  const [requests, setRequests] = useState([]);
  const vendorRequestDetails = useSelector((state) => state.admin.data);
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const updateStatus = async (status) => {
    try {
      dispatch(updateVendorStatus({ id, status }));
      toast.success(`Vendor ${status}ed Successfully`);
      navigate("/super-admin/vendor-requests/");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    dispatch(vendorRequestsDetails(id));
  }, [id, dispatch]);

  useEffect(() => {
    setRequests(vendorRequestDetails);
  }, [vendorRequestDetails]);

  return loading ? (
    <div className="flex justify-center items-center h-screen">
      <Loader />
    </div>
  ) : requests.length ? (
    <div className="xl:px-4 flex flex-col gap-4 pb-2">
      {/* name */}
      <div className="flex flex-col md:flex-row gap-4 justify-between w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Vendor Request List",
              path: "/super-admin/vendor-requests/",
            },
            {
              name: "Vendor Request Details",
              path: "",
            },
          ]}
        />

        {/* buttons  */}

        <div className="flex items-center justify-end gap-3">
          <button
            className="py-[7px] md:py-[10px] px-5 md:px-9 border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg text-[#828282] font-semibold text-xs md:text-sm"
            onClick={(e) => updateStatus("reject")}
          >
            Reject
          </button>
          <button
            className="py-[7px] md:py-[10px] px-5 md:px-9 bg-[#C19A6B] rounded-lg text-white font-semibold text-xs md:text-sm"
            onClick={(e) => updateStatus("accept")}
          >
            Approve
          </button>
        </div>
      </div>

      {/* main div  */}
      <div className="w-full flex flex-col gap-4 ">
        {/* user info  */}
        <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
          {/* user image and name  */}
          <div className="flex items-center gap-2">
            <span className="">
              <img
                src="/assets/images/user.png"
                alt=""
                className="w-14 h-14 rounded-full"
              />
            </span>
            <span className=" flex flex-col">
              <span className="font-semibold text-base">User Name</span>
              <span className="font-semibold text-xs text-[#6B6B6B]">
                {requests[0].user_name}
              </span>
            </span>
          </div>

          {/* user details  */}
          <div className=" w-full flex flex-col lg:flex-row justify-between items-start gap-4 lg:gap-16">
            {/* 1st  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Full Name :
                </span>
                <span className="font-semibold text-xs">
                  {" "}
                  {requests[0].user_name}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  E-mail :
                </span>
                <span className="font-semibold text-xs">
                  {" "}
                  {requests[0].user_email}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  E-mail :
                </span>
                <span className="font-semibold text-xs">
                  {" "}
                  {requests[0].personal_email}
                </span>
              </div>
            </div>

            {/* 2nd  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Store Name :
                </span>
                <span className="font-semibold text-xs">
                  {" "}
                  {requests[0].store_name}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Store Type :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].store_type_name}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Style Type :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].style_type_name}
                </span>
              </div>
            </div>

            {/* 3rd */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Location :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].city_name +
                    ", " +
                    requests[0].city_name +
                    ", " +
                    requests[0].country_name}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Postal Code :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].postal_code}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Address :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].address}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*Verification Information  */}
        <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
          <p className="font-semibold text-lg">Verification Information</p>

          {/*Verification  details  */}
          <div className=" w-full flex flex-col lg:flex-row justify-between items-start gap-4 lg:gap-16">
            {/* 1st  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Social Security Number :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].postal_code}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  NIC Number :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].nid_card_number}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Tax Identification Number :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].tax_identification_number}
                </span>
              </div>
            </div>

            {/* 2nd  */}

            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                ID Card Front Image :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full md:w-1/2 lg:w-full  min-h-[70px]   mt-2">
                <img
                  src={imagePath + "/" + requests[0].idcard_front_picture}
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>

            {/* 3rd */}
            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                ID Card Back Image :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full md:w-1/2 lg:w-full min-h-[70px] mt-2">
                <img
                  src={imagePath + "/" + requests[0].idcard_back_picture}
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>
          </div>
        </div>

        {/* bank Information  */}
        <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
          <p className="font-semibold text-lg">Bank Information</p>

          {/* bank details  */}
          <div className=" w-full flex flex-col lg:flex-row justify-between items-start gap-4 lg:gap-16">
            {/* 1st  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Account Title :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].account_title}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Account Number :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].account_number}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Bank Name :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].bank_name}
                </span>
              </div>
            </div>

            {/* 2nd  */}
            <div className=" w-full flex flex-col">
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Branch Name :
                </span>
                <span className="font-semibold text-xs">
                  {" "}
                  {requests[0].bank_branch}
                </span>
              </div>
              <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  Branch Code :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].branch_code}
                </span>
              </div>
              <div className=" w-full flex justify-between  py-2">
                <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                  IBAN :
                </span>
                <span className="font-semibold text-xs">
                  {requests[0].iban}
                </span>
              </div>
            </div>

            {/* 3rd */}
            <div className=" w-full flex flex-col ">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Cheque Copy Image :
              </span>
              <span className=" border-2 border-dashed border-[#E0E0E0] bg-[#FEFEFE] rounded-[5px]  w-full md:w-1/2 lg:w-full min-h-[70px] mt-2">
                <img
                  src={imagePath + "/" + requests[0].chequebook_picture}
                  alt=""
                  className="w-full max-h-[100px]"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <h1 className="px-4">No Result found</h1>
  );
}
