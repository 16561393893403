import AdMarketPlaceUpdateCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Ad-Market-Place-Categories/Main-Categoires/AdMarketPlaceUpdateCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AdMarketPlaceUpdateCategoryPage() {
  return (
    <MyShopLayout>
      <AdMarketPlaceUpdateCategory />
    </MyShopLayout>
  );
}
