import VendorRequestsList from "../../../Components/My-Shop-Components/Vender-Requests/VendorRequestsList";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function VenderRequestsListPage() {
  return (
    <>
      <MyShopLayout>
        <VendorRequestsList />
      </MyShopLayout>
    </>
  );
}
