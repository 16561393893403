import React, { useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

export default function SuperAdminProfileSetting() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPostalCode, setSelectedPostalCode] = useState("");

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);
  const [isPostalCodeDropdownVisible, setPostalCodeDropdownVisible] =
    useState(false);

  const [activeTab, setActiveTab] = useState("PROFILE");

  const countryOptions = [
    "USA",
    "Canada",
    "UK",
    "Australia",
    "India",
    "USA",
    "Canada",
    "UK",
    "Australia",
    "India",
  ];
  const cityOptions = ["New York", "Toronto", "London", "Sydney", "Delhi"];
  const postalCodeOptions = ["10001", "M5A 1A1", "SW1A 1AA", "2000", "110001"];

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
    setPostalCodeDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    setPostalCodeDropdownVisible(false);
  };

  const togglePostalCodeDropdown = () => {
    setPostalCodeDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    setCityDropdownVisible(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country);
    setCountryDropdownVisible(false);
  };

  const selectCity = (city) => {
    setSelectedCity(city);
    setCityDropdownVisible(false);
  };

  const selectPostalCode = (postalCode) => {
    setSelectedPostalCode(postalCode);
    setPostalCodeDropdownVisible(false);
  };

  return (
    <>
      <div className="px-3">
        {/* head */}
        <div className="flex justify-between mb-2">
          <BreadCrums
            breadCrum={[{ name: "Profile", path: "/super-admin/profile/" }]}
          />

          <div>
            <button className="w-[111px] h-11 text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg">
              Update
            </button>
          </div>
        </div>
        {/* filter */}
        <div className="flex gap-6 py-3">
          <span
            onClick={() => setActiveTab("PROFILE")}
            className={`font-semibold text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "PROFILE"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            PROFILE
          </span>

          <Link
            to="/super-admin/profile/information"
            onClick={() => setActiveTab("INFORMATION")}
            className={`font-semibold text-sm text-center cursor-pointer  px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "INFORMATION"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INFORMATION
          </Link>

          <Link
            to="/super-admin/profile/adresses"
            onClick={() => setActiveTab("ADDRESS")}
            className={`font-semibold text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "ADDRESS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            ADDR
          </Link>
        </div>
        {/* Profile Section */}
        <div className="flex gap-6">
          {/* Left */}
          <div className="w-[35%] flex flex-col gap-4 flex-1">
            <div className=" border border-[#EBF0ED] rounded-lg p-3">
              <div className="flex items-center gap-2 mb-4">
                <div className="relative">
                  <img
                    className="w-14 h-14"
                    src="/assets/images/img/Mask.png"
                    alt=""
                  />
                  <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                    <svg
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h2 className="font-semibold text-base text-[#18120F]">
                    Helena Brauer
                  </h2>
                  <h2 className="text-[12px] font-semibold text-[#6B6B6B]">
                    ADMIN
                  </h2>
                </div>
              </div>
              <div>
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    FULL NAME :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    Helena Brauer
                  </p>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    MOBILE :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    +92-321-9196978
                  </p>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    E-MAIL :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    example@gmail.com
                  </p>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    LOCATION :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    California, USA
                  </p>
                </div>
              </div>
            </div>

            {/* Social Accounts */}
            <div>
              <h1 className="text-[#18120F] text-lg font-semibold">
                Social Accounts
              </h1>
              <div className=" border border-[#EBF0ED] rounded-lg p-3">
                <div className="flex flex-col ga-2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 mb-1 font-semibold"
                    htmlFor="instagram"
                  >
                    INSTAGRAM
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    name="instagram"
                    placeholder="Enter Your Username"
                  />
                </div>
                <div className="flex flex-col ga-2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                    htmlFor="tiktok"
                  >
                    TIKTOK
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    name="tiktok"
                    placeholder="Enter Your Username"
                  />
                </div>
                <div className="flex flex-col ga-2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                    htmlFor="twitter"
                  >
                    TWITTER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    name="twitter"
                    placeholder="Enter Your Username"
                  />
                </div>
              </div>
            </div>
            {/* Shipping  */}
            <div>
              <h1 className="text-[#18120F] text-lg font-semibold">Shipping</h1>
              <div className=" border border-[#EBF0ED] rounded-lg p-3">
                <div className="flex flex-col ga-2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 mb-1 font-semibold"
                    htmlFor="instagram"
                  >
                    FREE SHIPPING AMOUNT
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    name="instagram"
                    placeholder="$400"
                  />
                </div>
                <div className="flex flex-col ga-2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                    htmlFor="tiktok"
                  >
                    EXPRESS SHIPPING AMOUNT
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    name="tiktok"
                    placeholder="$70"
                  />
                </div>
                <div className="flex flex-col ga-2">
                  <label
                    className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold"
                    htmlFor="twitter"
                  >
                    STANDARD SHIPPING AMOUNT
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg  h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    name="twitter"
                    placeholder="$20"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right */}
          <div className="w-[65%] flex flex-col gap-5 flex-2">
            {/* Edit Profile Section */}
            <div>
              <h1 className=" text-[#18120F] text-lg font-semibold">
                Edit Profile
              </h1>
              <div className="w-full border border-[#EBF0ED] rounded-lg p-3">
                {/* Name Inputs */}
                <div className="flex items-center gap-2">
                  <div className="flex w-full flex-col gap-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      FIRST NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your First Name"
                    />
                  </div>
                  <div className="flex w-full flex-col gap-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      LAST NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Last Name"
                    />
                  </div>
                </div>

                {/* Contact Inputs */}
                <div className="flex items-center gap-2 my-2">
                  <div className="flex w-full flex-col gap-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      PHONE NUMBER
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Phone Number"
                    />
                  </div>
                  <div className="flex w-full flex-col gap-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      EMAIL ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                      type="text"
                      placeholder="Enter Your Email"
                    />
                  </div>
                </div>

                {/* Country, City, Postal Code Inputs */}
                <div className="relative flex gap-2 items-center ">
                  {/* Country */}
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      COUNTRY
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedCountry}
                        readOnly
                        onClick={toggleCountryDropdown}
                        placeholder="Select Your Country"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleCountryDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isCountryDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                          {countryOptions.map((country) => (
                            <div
                              key={country}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectCountry(country)}
                            >
                              {country}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* City */}
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      CITY
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedCity}
                        readOnly
                        onClick={toggleCityDropdown}
                        placeholder="Select Your City"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleCityDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isCityDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {cityOptions.map((city) => (
                            <div
                              key={city}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectCity(city)}
                            >
                              {city}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Postal Code */}
                <div className="flex w-full gap-2 my-2">
                  <div className="w-full">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      POSTAL CODE
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                        type="text"
                        value={selectedPostalCode}
                        readOnly
                        onClick={togglePostalCodeDropdown}
                        placeholder="Select Your Postal Code"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={togglePostalCodeDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {isPostalCodeDropdownVisible && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {postalCodeOptions.map((postalCode) => (
                            <div
                              key={postalCode}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => selectPostalCode(postalCode)}
                            >
                              {postalCode}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex w-full flex-col ga-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      ADDRESS
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none "
                      type="text"
                      placeholder="Enter Your Address"
                    />
                  </div>
                </div>
                <div className="flex w-full flex-col ga-2">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ABOUT ME
                  </label>
                  <textarea
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[60px] px-2 border border-[#EBF0ED] outline-none "
                    type="text"
                    placeholder="Text"
                  />
                </div>
              </div>
            </div>

            {/* Change Password Section */}
            <div className="w-full items-center">
              <h1 className="text-[#18120F] text-lg font-semibold">
                Change Password
              </h1>
              <div className=" border border-[#EBF0ED] rounded-lg p-3">
                {/* Current and New Password Inputs */}
                <div className="  flex items-center gap-2">
                  <div className=" w-full flex flex-col ga-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      CURRENT PASSWORD
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="password"
                      placeholder="Current Password"
                    />
                  </div>
                  <div className="w-full flex flex-col ga-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                      NEW PASSWORD
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="password"
                      placeholder="New Password"
                    />
                  </div>
                </div>

                {/* Repeat Password */}
                <div className=" flex items-center gap-2 my-2">
                  <div className="w-1/2 flex flex-col ga-2">
                    <label className="text-[#6B6B6B] text-[12px] ml-1 mb-1 font-semibold">
                      REPEAT PASSWORD
                    </label>
                    <input
                      className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                      type="password"
                      placeholder="Repeat Password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
