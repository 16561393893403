import AdMarketPlaceCreateSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Add-Market-Categories-Pages/Ad-Market-Place-Sub-Categories-Page/AdMarketPlaceCreateSubCategoryPage";
import AdMarketPlaceSubCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Add-Market-Categories-Pages/Ad-Market-Place-Sub-Categories-Page/AdMarketPlaceSubCategoriesPage";
import AdMarketPlaceUpdateSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Add-Market-Categories-Pages/Ad-Market-Place-Sub-Categories-Page/AdMarketPlaceUpdateSubCategoryPage";
import AdMarketPlaceCreateCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Add-Market-Categories-Pages/Main-Categories-Pages/AdMarketPlaceCreateCategoriesPage";
import AdMarketPlaceMainCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Add-Market-Categories-Pages/Main-Categories-Pages/AdMarketPlaceMainCategoriesPage";
import AdMarketPlaceUpdateCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Add-Market-Categories-Pages/Main-Categories-Pages/AdMarketPlaceUpdateCategoryPage";

import AuctionMainCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Auction-Categories-Pages/Main-Categories-Pages/AuctionMainCategoriesPage";

import MainShopCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Main-Categories-Pages/MainShopCategoriesPage";
import MainShopCreateCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Main-Categories-Pages/MainShopCreateCategoriesPage";
import MainShopUpdateCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Main-Categories-Pages/MainShopUpdateCategoryPage";

import MainShopCreateSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Sub-Categories-Pages/MainShopCreateSubCategoryPage";
import MainShopSubCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Sub-Categories-Pages/MainShopSubCategoriesPage";
import MainShopUpdateSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Sub-Categories-Pages/MainShopUpdateSubCategoryPage";
import MainShopCreateSubSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Sub-Sub-Categories-Pages/MainShopCreateSubSubCategoryPage";
import MainShopSubSubCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Sub-Sub-Categories-Pages/MainShopSubSubCategoriesPage";
import MainShopUpdateSubSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Sub-Sub-Categories-Pages/MainShopUpdateSubSubCategoryPage copy";
import AuctionCreateMainCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Auction-Categories-Pages/Main-Categories-Pages/AuctionCreateMainCategoryPage";
import AuctionUpdateCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Auction-Categories-Pages/Main-Categories-Pages/AuctionUpdateCategoryPage";
import AuctionSubCategoriesPage from "../Pages/My-Shop/Manage-Products-Page/Auction-Categories-Pages/Sub-Categories-Page/AuctionSubCategoriesPage";
import AuctionCreateSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Auction-Categories-Pages/Sub-Categories-Page/AuctionCreateSubCategoryPage";
import AuctionUpdateSubCategoryPage from "../Pages/My-Shop/Manage-Products-Page/Auction-Categories-Pages/Sub-Categories-Page/AuctionUpdateSubCategoryPage";
import MainShopCategoryDetailsPage from "../Pages/My-Shop/Manage-Products-Page/Main-Shop-Category-Pages/Main-Categories-Pages/MainShopCategoryDetailsPage";

const categoriesRoutes = [
  {
    path: "/super-admin/main-categories/",
    children: [
      {
        index: true,
        element: <MainShopCategoriesPage />,
      },
      {
        path: "/super-admin/main-categories/details-category",
        element: <MainShopCategoryDetailsPage />,
      },
      {
        path: "/super-admin/main-categories/create-category",
        element: <MainShopCreateCategoriesPage />,
      },
      {
        path: "/super-admin/main-categories/update-category/:id",
        element: <MainShopUpdateCategoryPage />,
      },

      // for sub categories

      {
        path: "/super-admin/main-categories/sub-categories/:id",
        element: <MainShopSubCategoriesPage />,
      },
      {
        path: "/super-admin/main-categories/sub-categories/create-category/:id",
        element: <MainShopCreateSubCategoryPage />,
      },
      {
        path: "/super-admin/main-categories/sub-categories/update-category/:id",
        element: <MainShopUpdateSubCategoryPage />,
      },

      // for sub sub categories

      {
        path: "/super-admin/main-categories/sub-sub-categories/:id",
        element: <MainShopSubSubCategoriesPage />,
      },
      {
        path: "/super-admin/main-categories/sub-sub-categories/create-category/:id",
        element: <MainShopCreateSubSubCategoryPage />,
      },
      {
        path: "/super-admin/main-categories/sub-sub-categories/update-category/:id",
        element: <MainShopUpdateSubSubCategoryPage />,
      },
    ],
  },

  // add market place

  {
    path: "/super-admin/ad-market-place-categories/",
    children: [
      {
        index: true,
        element: <AdMarketPlaceMainCategoriesPage />,
      },
      {
        path: "/super-admin/ad-market-place-categories/create-category",
        element: <AdMarketPlaceCreateCategoriesPage />,
      },
      {
        path: "/super-admin/ad-market-place-categories/update-category/:id",
        element: <AdMarketPlaceUpdateCategoryPage />,
      },

      // sub categories
      {
        path: "/super-admin/ad-market-place-categories/sub-categories/:id",
        element: <AdMarketPlaceSubCategoriesPage />,
      },
      {
        path: "/super-admin/ad-market-place-categories/sub-categories/create-category/:id",
        element: <AdMarketPlaceCreateSubCategoryPage />,
      },
      {
        path: "/super-admin/ad-market-place-categories/sub-categories/update-category/:id",
        element: <AdMarketPlaceUpdateSubCategoryPage />,
      },
    ],
  },
  {
    path: "/super-admin/auction-categories/",
    children: [
      {
        index: true,
        element: <AuctionMainCategoriesPage />,
      },
      {
        path: "/super-admin/auction-categories/create-categories",
        element: <AuctionCreateMainCategoryPage />,
      },
      {
        path: "/super-admin/auction-categories/update-categories/:id",
        element: <AuctionUpdateCategoryPage />,
      },

      // sub categories
      {
        path: "/super-admin/auction-categories/sub-categories/:id",
        element: <AuctionSubCategoriesPage />,
      },
      {
        path: "/super-admin/auction-categories/sub-categories/create-category/:id",
        element: <AuctionCreateSubCategoryPage />,
      },
      {
        path: "/super-admin/auction-categories/sub-categories/update-category/:id",
        element: <AuctionUpdateSubCategoryPage />,
      },
    ],
  },
];

export default categoriesRoutes;
