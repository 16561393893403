import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShopOrderViewDetails } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

export default function OrderView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(ShopOrderViewDetails(id));
    // // console.log(data.data);
  }, [id, dispatch]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="xl:px-4 w-full flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          { name: "Shop List", path: "/super-admin/shop-list/" },
          {
            name: "Shop Details",
            path: -2,
          },
          {
            name: "Shop Order",
            path: -1,
          },
          {
            name: "Shop Order Details",
            path: `/super-admin/shop-list/vendor-order-view/${id}`,
          },
        ]}
      />

      {/* search */}
      <div className="w-full grid grid-cols-2 gap-2 xl:flex justify-between border border-[#EBF0ED] rounded-2xl py-4 px-4 md:px-6">
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Customer Name:</span>
          <span className="text-[#6B6B6B] ml-1">
            {!loading && data?.data && data.data.customer}
          </span>
        </div>
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Order Number:</span>
          <span className="text-[#6B6B6B] ml-1">
            #{!loading && data?.data && data.data.order_number}
          </span>
        </div>{" "}
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Payment Method:</span>
          <span className="text-[#6B6B6B] ml-1">
            {!loading && data?.data && data.data.payment_method}
          </span>
        </div>{" "}
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Placed On:</span>
          <span className="text-[#6B6B6B] ml-1">
            {!loading && data?.data && data.data.created_on}
          </span>
        </div>
        <div className="flex gap-1 text-[10px] md:text-xs  lg:text-base font-semibold">
          <span>Total:</span>
          <span className=" ml-1">
            ${!loading && data?.data && data.data.total}
          </span>
        </div>
      </div>

      {/* table */}

      {loading ? (
        <div className="flex justify-center py-4">
          <Loader />
        </div>
      ) : data?.data && data?.data?.data?.length > 0 ? (
        data.data.data.map((store) => (
          <div className="flex justify-center border-gray-500 overflow-auto">
            <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
              <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
                {/* Table Header */}
                <div className=" flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                  <span className="w-[60%] text-left">{store.vendor}</span>
                  <span className="w-[20%] text-left">QUANTITY</span>
                  <span className="w-[20%] text-left">PRICE</span>
                </div>

                {/* Table Body */}
                {store.products && store.products.length > 0 ? (
                  store.products.map((product) => (
                    <div
                      key={product.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="flex gap-2 W-[60%] text-left">
                        <img
                          className="w-[94px] h-[94px]"
                          src={product.image}
                          alt=""
                        />
                        <span className="mt-2 text-base font-[500]">
                          {product.name}
                        </span>
                      </div>

                      <span className="w-[20%] text-left text-[13px] font-[400] text-[#888888]">
                        Quantity: {product.product_quantity}
                      </span>
                      <span className="w-[20%] text-left  text-base font-semibold">
                        ${product.discount_price}
                      </span>
                    </div>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="3"
                      className="text-center font-bold tracking-wide"
                    >
                      No Products Found
                    </td>
                  </tr>
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center font-bold tracking-wide">
          No Store Found
        </div>
      )}
    </div>
  );
}
