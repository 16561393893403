import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import MainCategoryStats from "../../../Components/My-Shop-Components/PremiumProducts-Components/MainCategoryStats";

export default function MainCategoryStatsPage() {
  return (
    <>
      <MyShopLayout>
        <MainCategoryStats />
      </MyShopLayout>
    </>
  );
}
