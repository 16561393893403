import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import BannerAdSetting from "../../../Components/My-Shop-Components/BannerAd-Components/BannerAdSetting";

export default function BannerAdSettingPage() {
  return (
    <>
      <MyShopLayout>
        <BannerAdSetting />
      </MyShopLayout>
    </>
  );
}
