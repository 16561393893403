import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import CustomerReturns from "../../../../Components/My-Shop-Components/Orders-Management/Customer-Returns/CustomerReturns";

export default function CustomerReturnsPage() {
  return (
    <>
      <MyShopLayout>
        <CustomerReturns />
      </MyShopLayout>
    </>
  );
}
