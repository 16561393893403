import BannerAdDetailsPage from "../Pages/My-Shop/Banner-Ads-Pages/BannerAdDetailsPage";
import BannerAdSettingPage from "../Pages/My-Shop/Banner-Ads-Pages/BannerAdSettingPage";
import BannerAdSingleRequestPage from "../Pages/My-Shop/Banner-Ads-Pages/BannerAdSingleRequestPage";
import BannerAdsRequestsPage from "../Pages/My-Shop/Banner-Ads-Pages/BannerAdsRequestsPage";
import SingleBannerAdDetailsPage from "../Pages/My-Shop/Banner-Ads-Pages/SingleBannerAdDetailsPage";

const bannerAdsRoutes = [

    {
        path: "/super-admin/banner-ads",

        children: [{
            index: true,
            element: <BannerAdsRequestsPage  /> ,
            
        },
        {
            path:"/super-admin/banner-ads/banner-ad-details",
            element: <BannerAdDetailsPage  /> ,
            
        },
        {
            path:"/super-admin/banner-ads/banner-ad-setting",
            element: <BannerAdSettingPage  /> ,
            
        },
        {
            path:"/super-admin/banner-ads/banner-ad-single-request/:id",
            element: <BannerAdSingleRequestPage  /> ,
            
        },
        {
            path:"/super-admin/banner-ads/single-banner-ad-details",
            element: <SingleBannerAdDetailsPage  /> ,
            
        },
      
        
    ],
    
    
    },

]

export default bannerAdsRoutes;