import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UserAffiliateProducts from "../../../../Components/Shop-And-User-List/User-List/UserAffiliateProducts";

export default function UserAffiliateProductsPage() {
  return (
    <MyShopLayout>
      <UserAffiliateProducts />
    </MyShopLayout>
  );
}
