import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  premiumProductCategoryList,
  // premiumProductCategoryListSearch,
} from "../../../store/adminSlice";

export default function PremiumOffers() {
  const [activeTab, setActiveTab] = useState("list");
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.admin);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(premiumProductCategoryList());
  }, [dispatch]);

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      {/* breadcrubs */}
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row justify-between md:items-center">
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solutions",
              path: "/super-admin/premium-products",
            },
            {
              name: "Premium Product Category List",
              path: "/super-admin/premium-products",
            },
          ]}
        />
        <div className="flex justify-end">
          <Link
            to="/super-admin/premium-products/create-offer"
            className="text-xs md:text-sm px-5 md:px-9 py-2 md:py-[10px] bg-[#C19A6B] text-white rounded-lg"
          >
            Create Offer
          </Link>
        </div>
      </div>

      <div className="flex items-center gap-3 text-xs md:text-sm">
        <Link
          to=""
          onClick={() => handleTabClick("list")}
          className={`px-2 md:px-[10px] py-1 md:py-[6px] rounded-xl font-[500] ${
            activeTab === "list"
              ? "border border-[#C19A6B] text-[#C19A6B]"
              : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
          }`}
        >
          LIST
        </Link>
        <Link
          to="/super-admin/premium-products/main-category-stats"
          onClick={() => handleTabClick("stats")}
          className={`px-2 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
            activeTab === "stats"
              ? "border border-[#C19A6B] text-[#C19A6B]"
              : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
          }`}
        >
          STATS
        </Link>
      </div>
      {/* search  */}
      {/* <div className="relative flex items-center w-full ">
        <img
          src="/assets/icons/manage-products/search.svg"
          alt=""
          className="absolute left-3 text-[#C19A6B]"
        />

        <input
          className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
          type="search"
          placeholder="Search…"
        />
      </div> */}

      {/* table */}
      <div className="w-full bg-white  border border-[#EBF0ED] border-separate rounded-lg mt-2 ">
        {/* Table Header */}
        <div className=" flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
          <span className="W-[200PX] text-left">MAIN CATEGORY</span>

          {/* <th className="w-[200px] text-center">TOTAL EARNINGS</th> */}
          <span className="w-[100px] text-left">ACTION </span>
        </div>

        {/* Table Body */}
        <div className="w-full">
          {data && data.data && Object.entries(data.data)?.length
            ? Object.entries(data.data)
                .slice(0, 10)
                .map(([key, req], i) => (
                  <div
                    key={i}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                  >
                    <div className=" w-[200px] ">
                      <div className="flex items-center gap-3">
                        <img
                          src={req.image}
                          alt=""
                          className="rounded-md w-[52px] h-[40px] md:w-[72px] md:h-[60px]"
                        />

                        <span className="flex text-nowrap">{req.name}</span>
                      </div>
                    </div>

                    {/* <td className="w-[200px] text-left">${req.totalearning}</td> */}

                    <div className="w-[100px] flex items-center gap-2">
                      <Link to="/super-admin/premium-products/single-active-offer">
                        <img
                          className="w-7 h-7 md:w-9 md:h-9"
                          src="/assets/icons/view.svg"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                ))
            : "No Result Found"}
        </div>
      </div>
    </div>
  );
}
