import ChatPage from "../Pages/My-Shop/Chat-Page/ChatPage";

const chatRoutes = [
  {
    path: "/super-admin/chat",

    children: [
      {
        index: true,
        element: <ChatPage />,
      },
    ],
  },
];

export default chatRoutes;
