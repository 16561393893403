import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMessage,
  createNewOffer,
  mainShopCategoriesList,
  subCategoriesListByID,
} from "../../../store/adminSlice";

import moment from "moment";
import { toast } from "react-toastify";
import BreadCrums from "../../Common/BreadCrums";
import { useEffect } from "react";

export default function OfferManagmentCreateOffers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, message, mainShopCategories, subCategoriesByID } =
    useSelector((state) => state.admin);

  const [mainCategories, setMainCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);

  const inputRef = useRef(null);
  const [image, setImage] = useState("");

  const [offerTitle, setOfferTitle] = useState("");
  const [offerDescription, setOfferDescription] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectCategoryID, setSelectCategoryID] = useState();
  const [selectSubCategory, setSelectSubCategory] = useState("");
  const [selectSubCategoryID, setSelectSubCategoryID] = useState();
  const [offPricePercentage, setOffPricePercentage] = useState("");
  const [img, setImg] = useState(null);

  // State variables for registration
  const [regstartdate, setRegstartdate] = useState("");
  const [regenddate, setRegenddate] = useState("");
  const [regstarttime, setRegstarttime] = useState("");
  const [regendtime, setRegendtime] = useState("");

  // State variables for live events
  const [livestartdate, setLivestartdate] = useState("");
  const [liveenddate, setLiveenddate] = useState("");
  const [livestarttime, setLivestarttime] = useState("");
  const [liveendtime, setLiveendtime] = useState("");

  // Handler function for date change
  const handleDateChange = (event, setStateFunction) => {
    const date = event.target.value; // Get the date string from the event
    const formattedDate = moment(date).format("DD/MM/YY"); // Format the date for logging purposes
    // console.log("Selected date:", formattedDate);
    setStateFunction(date); // Set the state with the original date string
  };

  // Handler function for time change
  const handleTimeChange = (event, setStateFunction) => {
    const time = event.target.value; // Get the time string from the event
    const formattedTime = moment(time, "HH:mm").format("h:mm A"); // Format the time for logging purposes
    // console.log("Selected time:", formattedTime);
    setStateFunction(time); // Set the state with the original time string
  };

  // State for managing the dropdown's open/close status
  const [dropdown, setDropdown] = useState(false);
  const [subCatdropdown, setSubCatDropdown] = useState(false);
  const [dropdownControl, setDropdownControl] = useState("yes");

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImg(file);

    setImage(event.target.files[0]);
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    if (dropdownControl === "no") {
      setDropdown(!dropdown);
      setSubCatDropdown(false);
    }
  };
  // Toggle dropdown open/close
  const toggleSubCatDropdown = () => {
    if (dropdownControl === "no") {
      setSubCatDropdown(!subCatdropdown);
      setDropdown(false);
    }
  };

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectCategory(category.name);
    setSelectCategoryID(category.id);
    setDropdown(false); // Close the dropdown after selection
  };

  // Handle category selection
  const handleSubCategorySelect = (category) => {
    setSelectSubCategory(category.name);
    setSelectSubCategoryID(category.id);
    setSubCatDropdown(false); // Close the dropdown after selection
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      offerTitle === "" ||
      offerDescription === "" ||
      offPricePercentage === "" ||
      img === null ||
      regstartdate === "" ||
      regenddate === "" ||
      regstarttime === "" ||
      regendtime === "" ||
      livestartdate === "" ||
      liveenddate === "" ||
      livestarttime === "" ||
      liveendtime === ""
    ) {
      toast.error("Please fill all fields");
    } else if (
      dropdownControl === "no" &&
      (!selectCategoryID || !selectSubCategoryID)
    ) {
      toast.error("Please fill all fields");
    } else {
      const formData = new FormData();
      formData.append("title", offerTitle);
      formData.append("description", offerDescription);
      if (dropdownControl === "yes") {
        formData.append("onHome", 1);
      }
      if (dropdownControl === "no") {
        formData.append("onHome", 0);
        formData.append("category_id", selectCategoryID);
        formData.append("sub_category_id", selectSubCategoryID);
      }
      formData.append("percentage", offPricePercentage);
      formData.append("image", img);
      formData.append(
        "regstartdate",
        moment(regstartdate).format("YYYY-MM-DD")
      );
      formData.append("regenddate", moment(regenddate).format("YYYY-MM-DD"));

      formData.append(
        "regstarttime",
        moment(regstarttime, "HH:mm").format("h:mm A")
      );
      formData.append(
        "regendtime",
        moment(regendtime, "HH:mm").format("h:mm A")
      );
      formData.append(
        "livestartdate",
        moment(livestartdate).format("YYYY-MM-DD")
      );
      formData.append("liveenddate", moment(liveenddate).format("YYYY-MM-DD"));
      formData.append(
        "livestarttime",
        moment(livestarttime, "HH:mm").format("h:mm A")
      );
      formData.append(
        "liveendtime",
        moment(liveendtime, "HH:mm").format("h:mm A")
      );
      dispatch(createNewOffer(formData));
    }
  };

  useEffect(() => {
    if (message === "Offer created successfully") {
      toast.success(message);
      // navigate("/super-admin/offer-managment/");
    }
    if (error) {
      // error
      const firstKey = Object.values(error)[0];
      toast.error(firstKey[0]);
    }
    dispatch(clearMessage());
  }, [error, message, loading, dispatch, navigate]);

  // fetching mainCategories
  useEffect(() => {
    dispatch(mainShopCategoriesList());
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!loading && mainShopCategories && mainShopCategories.categories) {
      setMainCategories(mainShopCategories.categories);
    }
  }, [loading, mainShopCategories, dispatch]);

  useEffect(() => {
    dispatch(subCategoriesListByID(selectCategoryID));
  }, [selectCategoryID, dispatch]);

  useEffect(() => {
    if (!loading && subCategoriesByID && subCategoriesByID.subcategories) {
      setSubCategories(subCategoriesByID.subcategories);
    }
  }, [loading, mainShopCategories, dispatch, subCategoriesByID]);

  return (
    <>
      <div className="md:px-4 flex flex-col gap-2">
        <form action="" onSubmit={handleSubmit} encType="multipart/form-data">
          {/* Header */}
          <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-between md:items-center">
            <BreadCrums
              breadCrum={[
                {
                  name: "Marketing Solutions",
                  path: "/super-admin/offer-managment/",
                },
                { name: "Offers", path: "/super-admin/offer-managment/" },
                {
                  name: "Create Offers",
                },
              ]}
            />
            <div className="flex justify-end ">
              <button
                disabled={loading}
                type="submit"
                className="px-5 py-[7px] md:px-9 md:py-[10px] text-nowrap flex items-center justify-center rounded-lg text-white bg-[#C19A6B] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
              >
                {!loading ? "Create Offers" : "Validating Data"}
              </button>
            </div>
          </div>

          {/* offers detail */}
          <div className="border border-[#EBF0ED] rounded-xl px-4 py-4 md:py-8 mt-2">
            <div>
              <h2 className=" md:text-lg font-semibold text-[#18120F]">
                Offer Details
              </h2>
            </div>
            {/* first */}
            <div className="flex flex-col md:flex-row gap-5">
              {/* left */}
              <div className="w-full flex flex-col gap-3">
                <div>
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Offer Title
                  </h2>
                  <input
                    type="text"
                    value={offerTitle}
                    onChange={(e) => {
                      setOfferTitle(e.target.value);
                    }}
                    className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-xs font-[500] p-2 md:p-3"
                    placeholder="Enter Offer Title"
                  />
                </div>
                <div>
                  <h2 className="text-[12px] font-[500] text-[#18120F] ">
                    Offer Description
                  </h2>
                  <textarea
                    type="text"
                    value={offerDescription}
                    onChange={(e) => setOfferDescription(e.target.value)}
                    className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA]  text-xs font-[400] px-3 pt-3 pb-2 md:pb-6"
                    placeholder="Enter Offer Description"
                  />
                </div>
                <div>
                  <h2 className="text-[#18120F] text-xs font-semibold">
                    Display on Home
                  </h2>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="dropdownControl"
                        checked={dropdownControl === "yes"}
                        onChange={() => setDropdownControl("yes")}
                      />
                      <label className="text-sm md:text-base">Yes</label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="dropdownControl"
                        checked={dropdownControl === "no"}
                        onChange={() => setDropdownControl("no")}
                      />
                      <label className="text-sm md:text-base">No</label>
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Category
                  </h2>
                  <div className="relative flex  gap-2">
                    <div className="relative w-full">
                      <input
                        className={`bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none text-xs ${
                          dropdownControl === "yes"
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        type="text"
                        value={selectCategory}
                        readOnly
                        onClick={toggleDropdown}
                        placeholder="Main Category"
                        disabled={dropdownControl === "yes"}
                      />
                      <svg
                        className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 ${
                          dropdownControl === "yes"
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={toggleDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {dropdown && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full overflow-y-auto shadow-lg">
                          {mainCategories &&
                            mainCategories.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCategorySelect(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                    <div className="relative w-full">
                      <input
                        className={`bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none text-xs ${
                          dropdownControl === "yes"
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        type="text"
                        value={selectSubCategory}
                        readOnly
                        onClick={toggleSubCatDropdown}
                        placeholder="Sub Category"
                        disabled={dropdownControl === "yes"}
                      />
                      <svg
                        className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 ${
                          dropdownControl === "yes"
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={toggleSubCatDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {subCatdropdown && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full  overflow-y-auto shadow-lg">
                          {subCategories.length > 0 ? (
                            subCategories.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleSubCategorySelect(option)}
                              >
                                {option.name}
                              </div>
                            ))
                          ) : (
                            <div className="absolute p-5 z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                              No Sub Categories Found
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Offer Off Percentage
                  </h2>
                  <input
                    type="text"
                    value={offPricePercentage}
                    onChange={(e) => {
                      setOffPricePercentage(e.target.value);
                    }}
                    className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-xs font-[400] p-2 md:p-3"
                    placeholder="10%"
                  />
                </div>
              </div>
              {/* right */}
              <div className="w-full">
                <h2 className="text-[0.75rem] font-[500] text-[#18120F] ">
                  Upload Banner Image
                </h2>
                <div
                  onClick={handleImageClick}
                  className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative"
                >
                  {image ? (
                    <img className="" src={URL.createObjectURL(image)} alt="" />
                  ) : (
                    <img
                      className="w-[70px] md:w-[96px] h-[112px] md:h-[138px]"
                      src="/assets/images/img/input-drag.svg"
                      alt=""
                    />
                  )}
                  <input
                    ref={inputRef}
                    onChange={handleImageChange}
                    type="file"
                    style={{ display: "none" }}
                  />
                  {/* <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                          fill="white"
                        />
                      </svg>
           
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* 2nd */}
          <div className="flex flex-col md:flex-row  gap-5 my-6">
            <div className="w-full border border-[#EBF0ED] px-4 py-4 md:py-8 rounded-xl">
              <div className="flex flex-col gap-2">
                <h2 className="text-sm md:text-lg font-semibold text-[#18120F]">
                  Offer Registration Time
                </h2>
                <div className="flex  items-center w-full   gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Date
                    </h2>
                    <input
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="reg_start_date"
                      value={regstartdate}
                      onChange={(event) =>
                        handleDateChange(event, setRegstartdate)
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Time
                    </h2>
                    <input
                      value={regstarttime}
                      onChange={(event) =>
                        handleTimeChange(event, setRegstarttime)
                      }
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="reg_start_time"
                      type="time"
                      id=""
                    />
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Date
                    </h2>

                    <input
                      value={regenddate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="reg_start_date"
                      onChange={(event) =>
                        handleDateChange(event, setRegenddate)
                      }
                      disabledDate={(date) =>
                        regstartdate && date < regstartdate
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Time
                    </h2>
                    <input
                      value={regendtime}
                      onChange={(time) => handleTimeChange(time, setRegendtime)}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none "
                      name="reg_end_time"
                      disabledDate={(time) =>
                        regstarttime &&
                        moment(time, "hh:mm aa").isBefore(
                          moment(regstarttime, "hh:mm aa")
                        )
                      }
                      type="time"
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full border border-[#EBF0ED] px-4 py-4 md:py-8 rounded-xl">
              <div className="flex flex-col gap-2">
                <h2 className="text-sm md:text-lg font-semibold text-[#18120F]">
                  Offer Live Time
                </h2>
                <div className="flex w-full   gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Date
                    </h2>
                    <input
                      value={livestartdate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_start_date"
                      onChange={(event) =>
                        handleDateChange(event, setLivestartdate)
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Time
                    </h2>
                    <input
                      value={livestarttime}
                      onChange={(event) =>
                        handleTimeChange(event, setLivestarttime)
                      }
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_start_time"
                      type="time"
                      id=""
                    />
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Date
                    </h2>
                    <input
                      value={liveenddate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_end_date"
                      onChange={(event) =>
                        handleDateChange(event, setLiveenddate)
                      }
                      disabledDate={(date) =>
                        livestartdate && date < livestartdate
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Time
                    </h2>
                    <input
                      value={liveendtime}
                      onChange={(event) =>
                        handleTimeChange(event, setLiveendtime)
                      }
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none "
                      name="live_end_time"
                      disabledDate={(time) =>
                        livestarttime &&
                        moment(time, "hh:mm aa").isBefore(
                          moment(livestarttime, "hh:mm aa")
                        )
                      }
                      type="time"
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
