import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import HeroSectionAllHotspotImagesTable from "../../../Components/My-Shop-Components/Hero-Section/HeroSectionAllHotspotImagesTable";

export default function HeroSectionAllHotspotImagesPage() {
  return (
    <>
      <MyShopLayout>
        <HeroSectionAllHotspotImagesTable />
      </MyShopLayout>
    </>
  );
}
