import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import SellersRegistered from "../../../Components/My-Shop-Components/Offer-Managment-Components/SellersRegistered";

export default function SellersRegisteredPage() {
  return (
    <>
      <MyShopLayout>
        <SellersRegistered />
      </MyShopLayout>
    </>
  );
}
