import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  listAllBoosters,
  storeBoosterSearch,
  updateStoreBoosterStatusByAdmin,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";

export default function StoreBoostersList() {
  const [search, setSearch] = useState("");
  const [request, setRequest] = useState("");
  const [requestTwo, setRequestTwo] = useState("");
  const [activeRole, setActiveRole] = useState("All");
  const { data, error, message } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const location = useLocation();
  const totalItemPerPages = 10;

  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  useEffect(() => {
    if (data && data.pagination?.total) {
      let totalLength = data.pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      // console.log(data.pagination.total);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(listAllBoosters({ status: activeRole, page: page }));
    }
  };

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  useEffect(() => {
    dispatch(listAllBoosters({ status: status }));
  }, [status, dispatch]);

  useEffect(() => {
    if (
      message === "Booster has been successfully marked active" ||
      message === "Booster has been successfully marked rejected"
    ) {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error, message, dispatch]);

  useEffect(() => {
    if (data.data) {
      setRequest(data);
      setRequestTwo(data.data);
    }
    if (data.StoreBooster) {
      setRequestTwo(data.StoreBooster);
    }
  }, [data]);

  useEffect(() => {
    if (search === "") {
      dispatch(listAllBoosters({}));
    } else {
      dispatch(storeBoosterSearch(search));
    }
  }, [search, dispatch]);

  const handleActiveOrDisable = (status, id) => {
    dispatch(updateStoreBoosterStatusByAdmin({ status, id })).then(() => {
      dispatch(listAllBoosters());
      if (search !== "") {
        dispatch(storeBoosterSearch(search));
      }
    });
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }
    // console.log(totalNumberOfPages);
    // console.log(tempStart, tempEnd);
    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      {/* search */}
      <div className="flex justify-between gap-5">
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-[14px]"
            type="search"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      {/* filter  */}
      <div className="flex gap-1 md:gap-6 text-[10px] md:text-sm">
        <NavLink
          to={"/super-admin/store-boosters"}
          onClick={() => {
            handleRoleChange("All");
          }}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          All Boosters
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {request &&
              request.boostersPendingCount +
                request.boostersApprovedCount +
                request.boostersRejectedCount}
          </span>
        </NavLink>

        <NavLink
          onClick={() => handleRoleChange("Pending")}
          to={"/super-admin/store-boosters?status=pending"}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "Pending" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          Pending
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "Pending" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {request && request.boostersPendingCount}
          </span>
        </NavLink>

        <NavLink
          onClick={() => handleRoleChange("Active")}
          to={"/super-admin/store-boosters?status=active"}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "Active" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          Approved
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "Active" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {request && request.boostersApprovedCount}
          </span>
        </NavLink>

        <NavLink
          to={"/super-admin/store-boosters?status=rejected"}
          onClick={() => handleRoleChange("Rejected")}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "Rejected"
              ? "text-[#C19A6B]"
              : "hover:text-[#C19A6B]"
          }`}
        >
          Rejected
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "Rejected"
                ? "border-[#C19A6B]"
                : "border-[#EBF0ED]"
            }`}
          >
            {request && request.boostersRejectedCount}
          </span>
        </NavLink>
      </div>

      {/* table */}

      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[1098px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center gap-1 p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[20%] text-left">Shop</span>
              <span className=" w-[10%] text-left">Vendor Name</span>

              <span className=" w-[10%] text-left">Category</span>

              <span className=" w-[10%] text-left">Amount</span>

              <span className="w-[25%] text-left ">Booster Up Time</span>

              <span className="w-[10%] text-left">Status</span>

              <span className="w-[15%] text-left ">ACTION</span>
            </div>

            {/* Table Data Row */}

            {requestTwo?.length > 0
              ? requestTwo.map((product) => (
                  <div
                    key={product.id}
                    className="flex text-[10px] md:text-sm items-center justify-between gap-1 font-semibold text-[#18120F] p-2 md:p-4 border-b "
                  >
                    <div className="w-[20%] text-left flex items-center gap-2">
                      {" "}
                      <img
                        src={product.shop_picture}
                        alt=""
                        className="w-7 h-7 md:w-10 md:h-10 rounded-full"
                      />
                      <span className="   ">{product.name}</span>
                    </div>
                    <span className="w-[10%] text-left">
                      {product.vendors_name}
                    </span>
                    <span className="w-[10%] text-left">
                      {product.category_name}
                    </span>

                    <span className="w-[10%] text-left">${product.price}</span>

                    <div className="w-[25%] text-left flex gap-2 items-center">
                      <span className="text-[#15CA32] font-medium  text-nowrap md:text-wrap border border-[#15CA32] bg-[#F6FFF7] py-[3px] px-2 rounded-[10px]">
                        {product.start_date}
                      </span>{" "}
                      to{" "}
                      <span className="text-[#E2362F] font-medium  text-nowrap md:text-wrap border border-[#E2362F] bg-[#FFF3F4] py-[3px] px-2 rounded-[10px]">
                        {product.end_date}
                      </span>
                    </div>

                    <div className="w-[10%] text-left">
                      <span
                        className={` font-medium py-[5px] px-[15px] rounded-lg ${
                          product.status === "1"
                            ? "text-[#15CA32] bg-[#F6FFF7] border border-[#15CA32]"
                            : product.status === "0"
                            ? "text-[#FEC600] bg-[#FFFBF0] border border-[#FEC600]"
                            : "text-[#E2362F] bg-[#FFF3F4] border border-[#E2362F]"
                        }`}
                      >
                        {product.status === "0"
                          ? "pending"
                          : product.status === "1"
                          ? "active"
                          : "disabled"}
                      </span>
                    </div>

                    <div className="flex items-center gap-2 w-[15%] text-left">
                      <Link
                        to={`/super-admin/store-boosters/booster-details/${product.id}`}
                      >
                        <img
                          src="/assets/icons/manage-products/view.svg"
                          alt=""
                          className="w-7 h-7 md:w-9 md:h-9"
                        />
                      </Link>
                      {/* <span onClick={(e) => handleDelete(product.id)}>
                    <img
                      src="/assets/icons/manage-products/delete.svg"
                      alt=""
                      className="w-9 h-9"
                    />
                  </span> */}
                      {product.status.toLowerCase() === "1" ? (
                        <span
                          title="disable"
                          className="cursor-pointer"
                          onClick={() => handleActiveOrDisable("2", product.id)}
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src="/assets/icons/bulk.svg"
                            alt=""
                          />
                        </span>
                      ) : (
                        <span
                          title="active"
                          className="cursor-pointer"
                          onClick={() => handleActiveOrDisable("1", product.id)}
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9"
                            src="/assets/icons/make-active.svg"
                            alt=""
                          />
                        </span>
                      )}
                      <Link to="/super-admin/store-boosters/store-stats">
                        <img
                          src="/assets/icons/view-details.svg"
                          alt=""
                          className="w-7 h-7 md:w-9 md:h-9"
                        />
                      </Link>
                    </div>
                  </div>
                ))
              : "No Result Found"}
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center my-4">
        <span className="text-sm text-[#6B6B6B] font-semibold">
          {/* SHOWING {startIndex + 1} - {endIndex}  */}
        </span>
        <div className="flex items-center justify-between gap-2 w-fit h-[40px] bg-white rounded-lg px-2">
          {/* Previous Button */}
          <div
            className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
              currentPage === 1 ? "opacity-50" : ""
            }`}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <svg
              className={`${
                currentPage === 1
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-blue-500"
              }`}
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L1 7L7 1"
                stroke="#828282"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {/* Page Numbers */}
          <div className="flex items-center gap-2">
            {
              <Pagination
                totalNumberOfPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            }
          </div>
          {/* Next Button */}
          <div
            className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
              currentPage === totalPages ? "opacity-50" : ""
            }`}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <svg
              className={`${
                currentPage === totalPages
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-blue-500"
              }`}
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L7 7L1 1"
                stroke="#828282"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
