import DashboardPage from "../Pages/My-Shop/Home-Page/HomePage";

const homeRoutes = [
  {
    path: "/super-admin/dashboard",
    element: <DashboardPage />,
  },
];

export default homeRoutes;
