import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { productRegistered } from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import { imagePath } from "../../../functions/URLString";

const ProductsRegistered = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading, error, message } = useSelector((state) => state.admin);

  // pagination-state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    dispatch(productRegistered(id));
  }, [dispatch]);

  // pagination Function components
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    // console.log(totalNumberOfPages);
    const pages = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalNumberOfPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalNumberOfPages, startPage + 2);
      } else if (endPage === totalNumberOfPages) {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 rounded ${
            currentPage === i
              ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
              : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
          }`}
        >
          {i}
        </button>
      );
    }
    // console.log(pages);
    return <div className="pagination flex gap-2">{pages}</div>;
  }

  // pagination data handling + handle page change
  useEffect(() => {
    if (data?.pagination && data?.pagination?.pagination?.total) {
      let totalLength = data?.pagination?.pagination?.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(productRegistered({ arg1: "page", arg2: page, arg3: id }));
    }
  };

  // pagination index variable
  const totalOffersCount = data?.pagination?.pagination?.total || 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalOffersCount);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Offers", path: "/super-admin/offer-managment/" },
            {
              name: "Offers States",
              // path: "/super-admin/offer-managment/offer-stats",
            },
            {
              name: "Products Registered",
              path: "/super-admin/offer-managment/products-registered",
            },
          ]}
        />

        {/* search */}
        {/* <div>
          <div className="relative flex items-center gap-2 w-full ">
            <img
              className="absolute left-4"
              src="/assets/icons/manage-products/search.svg"
              alt=""
            />

            <input
              className="w-full py-[10px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Products..."
            />
          </div>
        </div> */}

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[16%] text-left">PRODUCT</span>

                <span className=" w-[16%] text-left">STOCK</span>
                <span className=" w-[16%] text-left">VENDOR</span>

                <span className=" w-[16%] text-left">CATEGORY</span>
                <span className=" w-[16%] text-left">ORIGINAL PRICE</span>
                <span className=" w-[16%] text-left">OFFER PRICE</span>
              </div>

              {/* Table Data Row */}
              {loading ? (
                <div className="flex justify-center py-4">
                  <Loader />
                </div>
              ) : data?.products?.length > 0 ? (
                data?.products?.map((item) => {
                  return (
                    <>
                      <div className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b">
                        <div className="w-[16%] text-left">
                          <img
                            src={imagePath + item.image}
                            alt="Product"
                            className="w-7 h-7 md:w-10 md:h-10 rounded-lg "
                          />
                          <div>
                            <p className="  text-[#18120F] font-semibold ">
                              {item.product_name}
                            </p>
                          </div>
                        </div>
                        <span className=" w-[16%] text-left">
                          {item.quantity}
                        </span>

                        <span className=" w-[16%] text-left">
                          {item.store_name}
                        </span>
                        <span className="w-[16%] text-left">
                          {item.category}
                        </span>

                        <span className=" w-[16%] text-left">
                          ${item.price}
                        </span>
                        <span className=" w-[16%] text-left">
                          ${item.discounted_price}
                        </span>
                      </div>
                    </>
                  );
                })
              ) : (
                <tr className="text-center font-bold tracking-wide py-4">
                  <td colSpan={6}>No Seller Found</td>
                </tr>
              )}
            </div>
          </div>
        </div>

        {/* pagination */}
        {/* Pagination start here */}
        {!loading && data && data?.products?.length > 0 ? (
          <>
            <div className="flex justify-between items-center my-4">
              <span className="text-sm text-[#6B6B6B] font-semibold">
                SHOWING {startIndex + 1}-{endIndex} OF {totalOffersCount}{" "}
                RESULTS
              </span>
              <div className="flex items-center gap-3 bg-white rounded-lg px-2">
                {/* Previous Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <svg
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Page Numbers */}
                <div className="flex items-center gap-2">
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
                {/* Next Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === totalPages ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <svg
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Pagination end here */}
      </div>
    </>
  );
};

export default ProductsRegistered;
