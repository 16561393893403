import MallDetailsPage from "../Pages/My-Shop/Mall-Managment-page/MallDetailsPage";
import MallManagmentStoreInmallPage from "../Pages/My-Shop/Mall-Managment-page/MallManagmentStoreInmallPage";
import MallManagmentallMallPage from "../Pages/My-Shop/Mall-Managment-page/MallManagmentallMallPage";
import MallRequestDetailsPage from "../Pages/My-Shop/Mall-Managment-page/MallRequestDetailsPage";
import MallRequestPage from "../Pages/My-Shop/Mall-Managment-page/MallRequestPage";

const mallManagementRoute = [
  {
    path: "/super-admin/mall-managment",
    children: [
      {
        index: true,
        element: <MallManagmentallMallPage />,
      },
      {
        path: "/super-admin/mall-managment/mall-details/:id",
        element: <MallDetailsPage />,
      },
      {
        path: "/super-admin/mall-managment/store/:id",
        element: <MallManagmentStoreInmallPage />,
      },

      {
        path: "/super-admin/mall-managment/mall-request",
        element: <MallRequestPage />,
      },
      {
        path: "/super-admin/mall-managment/mall-request-details/:id",
        element: <MallRequestDetailsPage />,
      },
    ],
  },
];

export default mallManagementRoute;
