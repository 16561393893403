import ManageReviews from "../../../../Components/My-Shop-Components/Orders-Management/Manage-Reviews/ManageReviews";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function ManageReviewsPage() {
  return (
    <>
      <MyShopLayout>
        <ManageReviews />
      </MyShopLayout>
    </>
  );
}
