import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  listStoreBoosterById,
  updateStoreBoosterStatusByAdmin,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";

export default function StoreBoosterDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { message, data, error } = useSelector((state) => state.admin);

  const changeBoosterStatus = (status) => {
    dispatch(updateStoreBoosterStatusByAdmin({ id, status }));
  };

  useEffect(() => {
    dispatch(listStoreBoosterById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      message === "Booster has been successfully marked active" ||
      message === "Booster has been successfully marked rejected"
    ) {
      toast.success(message);
      navigate("/super-admin/store-boosters");
    }
    if (error) {
      toast.error(error);
      navigate("/super-admin/store-boosters");
    }
    dispatch(clearMessage());
  }, [message, error, dispatch, navigate]);

  return (
    <div className="xl:px-4 flex flex-col gap-4 pb-2">
      {/* name */}
      <div className="flex justify-between w-full">
        {/* <BreadCrums
          breadCrum={[
            { name: "Marketing Solutions", path: "/super-admin/banner-ads" },
            {
              name: "Booster Products",
              path: "/super-admin/product-boosters/stats",
            },
            {
              name: "Booster Details",
              path: "/super-admin/product-boosters/booster-details",
            },
          ]}
        /> */}
        <div className="opacity-0">
          <p>space</p>
        </div>
        {/* // || data.booster.status=="1" || data.booster.status=="2" */}
        {data.booster && data.booster.status === "0" && (
          <div className="flex items-center gap-3">
            <button
              className="py-2 md:py-[10px] px-5 md:px-9 border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg text-[#828282] font-semibold text-xs md:text-sm"
              onClick={(e) => changeBoosterStatus("2")}
            >
              Reject
            </button>
            <button
              className="py-2 md:py-[10px] px-5 md:px-9  bg-[#C19A6B] rounded-lg text-white font-semibold text-xs md:text-sm"
              onClick={(e) => changeBoosterStatus("1")}
            >
              Approve
            </button>
          </div>
        )}
      </div>

      {/* table  */}

      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[1098px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center gap-1 p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[25%] text-left">Shop</span>
              <span className=" w-[15%] text-left">Vendor Name</span>

              <span className=" w-[15%] text-left">Category</span>

              <span className=" w-[15%] text-left">Amount</span>

              <span className="w-[15%] text-left ">Booster Up Time</span>

              <span className=" w-[15%] text-left ">Status</span>
            </div>

            {/* Table Data Row */}
            {data && data.booster ? (
              <div
                key={data.booster.id}
                className="flex text-[10px] md:text-sm items-center justify-between gap-1 font-semibold text-[#18120F] p-2 md:p-4 border-b "
              >
                <div className="w-[25%] text-left  flex items-center gap-2">
                  {" "}
                  <span className=" ">
                    <img
                      src={imagePath + data.booster.shop_picture}
                      alt=""
                      className="w-10 h-10 rounded-full"
                    />
                  </span>
                  <span className="text-sm font-semibold text-[#18120F]  ">
                    {data.booster.user_name}
                  </span>
                </div>
                <span className="w-[15%] text-left">
                  {data.booster.store_name}
                </span>
                <span className="w-[15%] text-left">
                  {data.booster.category_name}
                </span>

                <span className="w-[15%] text-left">${data.booster.price}</span>

                <div className="w-[15%] text-left flex gap-2 items-center">
                  <span className="text-[#15CA32] font-medium text-sm border border-[#15CA32] bg-[#F6FFF7] py-[3px] px-2 rounded-[10px]">
                    {data.booster.start_date}
                  </span>{" "}
                  to{" "}
                  <span className="text-[#E2362F] font-medium text-sm border border-[#E2362F] bg-[#FFF3F4] py-[3px] px-2 rounded-[10px]">
                    {data.booster.end_date}
                  </span>
                </div>

                <div className="w-[100px]">
                  <span
                    className={`text-sm font-medium py-2 px-3 rounded-lg ${
                      data.booster.status === "1"
                        ? "text-[#15CA32] bg-[#F6FFF7] border border-[#15CA32]"
                        : data.booster.status === "0"
                        ? "text-[#FEC600] bg-[#FFFBF0] border border-[#FEC600]"
                        : "text-[#E2362F] bg-[#FFF3F4] border border-[#E2362F]"
                    }`}
                  >
                    {data.booster.status === "0"
                      ? "pending"
                      : data.booster.status === "1"
                      ? "active"
                      : "rejected"}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
