import React from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import BreadCrums from "../../../Common/BreadCrums";

export default function Transactions() {
  return (
    <>
      <div className="px-5 w-full flex flex-col gap-6">
        <BreadCrums
          breadCrum={[
            {
              name: "Finance",
              path: "/super-admin/manage-reviews/account-statement",
            },
            {
              name: "Transactions",
              path: "/super-admin/manage-reviews/transactions",
            },
          ]}
        />

        <div className="flex justify-between w-full">
          <div className="flex gap-4">
            <div>
              <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
                Time Period
              </span>
              <div>{/*Date range picker*/}</div>
            </div>

            <div>
              <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
                Transaction Type
              </span>
              <div className="flex justify-between  bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg items-center w-64 p-2">
                <span className="font-normal text-xs text-[#6B6B6B]">
                  All Transactions
                </span>
                <span>
                  <RiArrowDropDownLine className="!w-6 !h-6" />
                </span>
              </div>
            </div>
          </div>

          <div className="flex gap-4 items-center">
            {/* print button  */}
            <div className="flex items-center justify-center gap-2 p-4 rounded-lg w-[87px] h-[38px] bg-[#FAFAFA] border border-[#EBF0ED] ">
              <span>
                <img
                  src="/assets/icons/order-management/export.svg"
                  alt=""
                  className="max-w-[9px] h-[18px]"
                />
              </span>
              <span className="font-semibold text-xs text-[#828282]">
                Print
              </span>
            </div>

            {/* export button  */}
            <div className="flex items-center justify-center gap-2 p-4 rounded-lg w-[87px] h-[38px] bg-[#FAFAFA] border border-[#EBF0ED] ">
              <span>
                <img
                  src="/assets/icons/order-management/export.svg"
                  alt=""
                  className="max-w-[9px] h-[18px]"
                />
              </span>
              <span className="font-semibold text-xs text-[#828282]">
                Export
              </span>
            </div>
          </div>
        </div>

        {/* table */}
        <table className="w-full bg-white  border border-[#EBF0ED] border-separate rounded-lg mt-2">
          {/* Table Header */}
          <thead className=" w-full ">
            <tr className="flex w-full justify-between items-center gap-4  p-4 border-b text-[#6B6B6B] font-semibold text-[12px] border-gray-300">
              <th className="w-[80px] text-left">DATE</th>
              <th className="w-[150px] text-left">TRANSACTION TYPE</th>
              <th className="w-[150px] text-left">TRANSACTION NUMBER</th>
              <th className="w-[160px] text-left">DETAILS</th>
              <th className="w-[100px] text-left">ORDER NUMBER</th>
              <th className="w-[70px] text-left">AMOUNT</th>
              <th className="w-[70px] text-left">WITHHOLDING</th>
              <th className="w-[70px] text-left">GST</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="w-full">
            <tr className="flex w-full justify-between items-center gap-4  p-4 border-b  font-semibold text-sm ">
              <td className="w-[80px] ">2024-02-12</td>

              <td className="w-[150px]  ">Shipping Fee Paid by Buyer</td>
              <td className="w-[150px] ">#SWE267NZ-407388</td>
              <td className="w-[160px]  ">
                Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit
                amet consectetur.
              </td>
              <td className="w-[100px]">#YU34692</td>
              <td className="w-[70px]">$100.00</td>
              <td className="w-[70px]">$10.00</td>
              <td className="w-[70px]">$15.00</td>
            </tr>
          </tbody>
        </table>

        {/* pagination  */}
      </div>
    </>
  );
}
