import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  userListOrderProductReview,
  userListOrderProductReviewSearch,
} from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

export default function UserProductsReviewed() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");
  // const [userProductReviews, setUserProductReviews] = useState([]);

  // Load reviews by default
  useEffect(() => {
    dispatch(userListOrderProductReview(id));
  }, [dispatch, id]);

  // Search reviews based on search input
  useEffect(() => {
    if (search) {
      dispatch(userListOrderProductReviewSearch({ id, query: search }));
    } else {
      dispatch(userListOrderProductReview(id));
    }
  }, [search, dispatch, id]);

  // Update reviews state when data or loading state changes
  // useEffect(() => {
  //   if (!loading && data && data.products) {
  //     setUserProductReviews(data.products);
  //   } else if (loading) {
  //     setUserProductReviews([]);
  //   }
  // }, [loading, data]);

  const generateStarRating = (rating) => {
    const stars = [];
    const filledStar = "/assets/icons/filled-star.svg"; // replace with the path to your filled star icon
    const unfilledStar = "/assets/icons/unfilled-star.svg"; // replace with the path to your unfilled star icon

    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(
          <img key={i} src={filledStar} alt="filled star" className="w-4 h-4" />
        );
      } else {
        stars.push(
          <img
            key={i}
            src={unfilledStar}
            alt="unfilled star"
            className="w-4 h-4"
          />
        );
      }
    }
    return stars;
  };

  return (
    <>
      <div className="xl:px-4 w-full flex flex-col gap-4">
        {/* breadcrums */}

        <BreadCrums
          breadCrum={[
            { name: "User", path: "/super-admin/users-list/" },
            {
              name: "User Profile States",
              path: `/super-admin/users-list/user-profile-stats/${id}`,
            },
            {
              name: "Products Reviewed",
              path: `/super-admin/users-list/user-products-reviewed/${id}`,
            },
          ]}
        />

        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />

          <input
            className="w-full py-2 md:py-3  pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
            type="search"
            placeholder="Search Product Reviews by name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {loading ? (
          <>
            <div className="flex justify-center">
              <Loader />
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center border-gray-500 overflow-auto">
              <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
                <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
                  {/* Table Header */}
                  <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                    <span className="w-[10%] text-left">ORDER ID</span>
                    <span className="w-[25%] text-left">PRODUCT</span>
                    <span className="w-[20%] text-left">REVIEW</span>
                    <span className="w-[20%] text-left">RATING</span>
                    <span className="w-[15%] text-left">VENDOR</span>
                    <span className="w-[15%] text-left">ACTION</span>
                  </div>

                  {/* Table Body */}

                  {loading ? (
                    <tr>
                      <td colSpan="6">
                        <div className="flex justify-center py-4">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  ) : data?.reviews?.length > 0 ? (
                    data?.reviews?.map((review) => (
                      <div
                        key={review.id}
                        className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b "
                      >
                        <span className="w-[10%] text-left">
                          #{review.order_uid}
                        </span>
                        <div className="flex items-center gap-3 w-[25%] text-left">
                          <span>
                            <img
                              src={review.product_image}
                              alt=""
                              className="w-7 h-7 md:w-10 md:h-10 rounded-md md:rounded-[10px]"
                            />
                          </span>
                          <span className="flex flex-col">
                            <span className="font-semibold ">
                              {review.product_name}
                            </span>
                            <span className="font-medium  text-[#6B6B6B] text-nowrap">
                              Category: {review.category}
                            </span>
                          </span>
                        </div>

                        <span className="w-[20%] text-left flex flex-col gap-1 ">
                          {review.review}
                        </span>
                        <span className="w-[20%] text-left flex gap-1 items-center">
                          {generateStarRating(Number(review.rating))}
                        </span>

                        <span className="w-[15%] text-left">
                          {review.store_name}
                        </span>
                        <span className="w-[15%] text-left flex gap-2">
                          <img src="/assets/icons/More.svg" alt="" />
                          {/* <img src="/assets/icons/Delete1.svg" alt="" /> */}
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="text-center font-bold tracking-wide">
                      No User Review Found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* table */}
      </div>
    </>
  );
}
