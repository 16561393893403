import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UserProfileStats from "../../../../Components/Shop-And-User-List/User-List/UserProfileStats";

export default function UserProfileStatsPage() {
  return (
    <MyShopLayout>
      <UserProfileStats />
    </MyShopLayout>
  );
}
