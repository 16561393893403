import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  mainCategory,
  mainCategoryDelete,
  mainCategorySearch,
} from "../../../../../store/adminSlice";
import BreadCrums from "../../../../Common/BreadCrums";
import { toast } from "react-toastify";

export default function MainShopCategories() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { categories, loading, searchStatus, error, message } = useSelector(
    (state) => state.admin
  );

  useEffect(() => {
    dispatch(mainCategory());
  }, [dispatch]);

  useEffect(() => {
    if (search === "") {
      dispatch(mainCategory());
    } else {
      dispatch(mainCategorySearch(search));
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (message && message === "Category deleted successfully") {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error, message, dispatch]);

  //
  const handleDelete = (id) => {
    // console.log(typeof id);
    dispatch(mainCategoryDelete(id)).then(() => {
      dispatch(mainCategory());
    });
  };

  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (categories?.total) {
      let totalLength = categories.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [categories]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(mainCategory(page));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Main Shop Category",
              path: "/super-admin/main-categories/",
            },
          ]}
        />

        {/* search */}
        <div className="flex justify-between items-center gap-2">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="py-2 md:py-[15px] pl-10 w-full rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs"
              type="search"
              placeholder="Search Categories..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <Link
            className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white text-center text-nowrap font-semibold text-xs md:text-sm"
            to="/super-admin/main-categories/create-category"
          >
            Add Category
          </Link>
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[868px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between uppercase">
                <span className="w-[15%] text-left">CATEGORY NAME</span>

                <span className=" w-[5%] text-left">ICON</span>

                <span className=" w-[12%] text-left">CATEGORY IMAGE</span>

                <span className=" w-[12%] text-left">MY SHELF IMAGE</span>

                <span className=" w-[18%] text-left ">
                  Child Category Count
                </span>

                <span className="  w-[18%] text-left ">ACTION</span>
              </div>

              {search === ""
                ? loading
                  ? "loading"
                  : categories.categories &&
                    categories.categories
                      .filter((item) => item.type === "main-shop")
                      .map((item) => (
                        <div
                          key={item.id}
                          className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                        >
                          <span className="w-[15%]  text-left  ">
                            {item.name}
                          </span>

                          <span className="w-[5%]  text-left">
                            <img
                              src={item.icon}
                              alt="icon"
                              className="w-6 h-6 "
                            />
                          </span>

                          <span className="w-[12%]  text-left">
                            <img
                              src={item.image}
                              alt="category_img"
                              className="w-10 h-10 rounded-lg"
                            />
                          </span>

                          <span className="w-[12%]  text-left">
                            <img
                              src={item.shelf_image}
                              alt="shelf_img"
                              className="w-[60px] h-10 rounded-lg"
                            />
                          </span>

                          <span className="w-[18%]  text-left">
                            {item.subcategories_count}
                          </span>

                          <span className="flex items-center gap-2 w-[18%]  text-left">
                            <Link
                              to={`/super-admin/main-categories/sub-categories/${item.id}`}
                            >
                              <img
                                src="/assets/icons/manage-products/view.svg"
                                alt=""
                                className="w-9 h-9 cursor-pointer"
                              />
                            </Link>
                            <Link to="/super-admin/main-categories/details-category">
                              <img
                                src="/assets/icons/view-details.svg"
                                alt=""
                                className="w-9 h-9 cursor-pointer"
                              />
                            </Link>
                            <Link
                              to={`/super-admin/main-categories/update-category/${item.id}`}
                            >
                              <img
                                src="/assets/icons/manage-products/edit.svg"
                                alt=""
                                className="w-9 h-9 cursor-pointer"
                              />
                            </Link>
                            <span onClick={(e) => handleDelete(item.id)}>
                              <img
                                src="/assets/icons/manage-products/delete.svg"
                                alt=""
                                className="w-9 h-9 cursor-pointer"
                              />
                            </span>
                          </span>
                        </div>
                      ))
                : !searchStatus
                ? "Not found"
                : loading
                ? "loading"
                : categories?.Category?.length > 0
                ? categories.Category.map((item) => (
                    <div
                      key={item.id}
                      className="flex  text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <span className="w-[15%]  text-left ">{item.name}</span>

                      <span className="w-[5%]  text-left">
                        <img
                          src={item.icon}
                          alt="icon"
                          className="w-6 h-6 "
                        />
                      </span>

                      <span className="w-[12%]  text-left">
                        <img
                          src={item.image}
                          alt="category_img"
                          className="w-10 h-10 rounded-lg"
                        />
                      </span>

                      <span className="w-[12%]  text-left">
                        <img
                          src={item.shelf_image}
                          alt="shelf_img"
                          className="w-[60px] h-10 rounded-lg"
                        />
                      </span>

                      <span className="w-[18%]  text-left">
                        {item.subcategories_count}
                      </span>

                      <span className="flex items-center gap-2 w-[18%]  text-left">
                        <Link
                          to={`/super-admin/main-categories/sub-categories/${item.id}`}
                        >
                          <img
                            src="/assets/icons/manage-products/view.svg"
                            alt=""
                            className="w-9 h-9 cursor-pointer"
                          />
                        </Link>
                        <Link to="/super-admin/main-categories/details-category">
                          <img
                            src="/assets/icons/view-details.svg"
                            alt=""
                            className="w-9 h-9 cursor-pointer"
                          />
                        </Link>
                        <Link
                          to={`/super-admin/main-categories/update-category/${item.id}`}
                        >
                          <img
                            src="/assets/icons/manage-products/edit.svg"
                            alt=""
                            className="w-9 h-9 cursor-pointer"
                          />
                        </Link>
                        <span onClick={(e) => handleDelete(item.id)}>
                          <img
                            src="/assets/icons/manage-products/delete.svg"
                            alt=""
                            className="w-9 h-9 cursor-pointer"
                          />
                        </span>
                      </span>
                    </div>
                  ))
                : "No Result Fond"}
            </div>
          </div>
        </div>

        {!loading &&
        
        categories &&
        categories?.categories?.length > 10 ? (
          <div className="flex flex-col md:flex-row justify-between items-center">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING {categories.from + "-" + categories.to} OF{" "}
              {categories.total}
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
