import React, { useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

export default function SuperAdminAdresses() {
  const [activeTab, setActiveTab] = useState("ADDRESS");

  return (
    <>
      <div className="px-3">
        {/* head */}
        <div className="flex justify-between mb-2">
          <BreadCrums
            breadCrum={[
              { name: "Addresses", path: "/super-admin/profile/adresses" },
            ]}
          />

          <div>
            <button className="w-[111px] h-11 text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg">
              Update
            </button>
          </div>
        </div>
        {/* filter */}
        <div className="flex gap-6 py-3">
          <Link
            to="/super-admin/profile/"
            onClick={() => setActiveTab("PROFILE")}
            className={`font-semibold text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "PROFILE"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            PROFILE
          </Link>

          <Link
            to="/super-admin/profile/information"
            onClick={() => setActiveTab("INFORMATION")}
            className={`font-semibold text-sm text-center cursor-pointer  px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "INFORMATION"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            INFORMATION
          </Link>

          <Link
            to="/super-admin/profile/adresses"
            onClick={() => setActiveTab("ADDRESS")}
            className={`font-semibold text-sm text-center cursor-pointer px-[0.625rem] py-2 border rounded-lg ${
              activeTab === "ADDRESS"
                ? "border-[#C19A6B] text-[#C19A6B]"
                : "border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            ADDRESS
          </Link>
        </div>
        <div className="flex flex-col gap-5">
          {/* 1st */}
          <div className="flex flex-col ">
            <h1 className=" text-[#18120F] text-lg font-semibold">
              Warehouse Address
            </h1>
            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex items-center gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Khan"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COMPANY NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="bingtechs"
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    COUNTRY
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Country"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    STATE
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Select State"
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    CITY
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone City"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Address"
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="email"
                    placeholder="Enter Your Phone Email"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 2nd */}
          <div className="flex flex-col ">
            <div className="flex gap-9 items-center">
              <h1 className=" text-[#18120F] text-lg font-semibold">
                Return Address
              </h1>
              <div className="flex items-center gap-2">
                <span className="text-xs font-semibold text-[#6B6B6B]">
                  Copy From Wearhouse
                </span>
                <input type="radio" name="" id="" />
                Yes
                <input type="radio" name="" id="" />
                No
              </div>
            </div>
            <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
              {/* Name Inputs */}
              <div className="flex items-center gap-7">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    FIRST NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your First Name"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    LAST NAME
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Last Name"
                  />
                </div>
              </div>

              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>
              {/* Contact Inputs */}
              <div className="flex items-center gap-7 my-2">
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    PHONE NUMBER
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Phone Number"
                  />
                </div>
                <div className="flex w-full flex-col ">
                  <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                    EMAIL ADDRESS
                  </label>
                  <input
                    className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                    type="text"
                    placeholder="Enter Your Email"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
