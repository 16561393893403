import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { imagePath } from "../../../../functions/URLString";
import {
  brandById,
  clearMessage,
  updateBrand,
} from "../../../../store/adminSlice";
import { toast } from "react-toastify";
import BreadCrums from "../../../Common/BreadCrums";
import Loader from "../../../Common/Loader";

export default function UpdateBrand() {
  const navigate = useNavigate();

  const { id } = useParams();
  const dispatch = useDispatch();

  const [updateData, setUpdateData] = useState(null);

  const { data, loading, error, message } = useSelector((state) => state.admin);
  const [test, setTest] = useState(null);

  useEffect(() => {
    dispatch(brandById(id));
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data && data.Brand) {
      let brand_list = data.Brand;
      setUpdateData(brand_list);
    }
  }, [data, loading]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTest(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setUpdateData((prevData) => ({
          ...prevData,
          logo: null,
          newLogo: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (updateData.name === "") {
      toast.error("Please Fill All Fields");
    } else {
      const formData = new FormData();

      formData.append("name", updateData.name);
      if (updateData.newLogo) {
        formData.append("logo", test);
      }
      //  else {
      //   formData.append("logo", updateData.logo);
      // }
      formData.append("id", updateData.id);
      formData.append("_method", "PUT");

      // formData.append("id", updateData.id)

      // If there's a new logo, convert the base64 image data to a Blob and append it to the FormData
      // if (updateData.newLogo) {
      //   const base64Image = updateData.newLogo.split(",")[1]; // Remove the data URI prefix
      //   const blob = base64ToBlob(base64Image);
      // }

      dispatch(updateBrand(formData));
    }
  };

  // Function to convert base64 data to Blob
  const base64ToBlob = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/png" });
  };

  // handling messages and navigation
  useEffect(() => {
    if (message) {
      toast.success(message);
      navigate(`/super-admin/brands/`);
    }

    if (error) {
      // Check if error is an object with nested error structure
      if (typeof error === "object" && Object.keys(error).length > 0) {
        const firstKey = Object.values(error)[0];
        if (Array.isArray(firstKey) && firstKey.length > 0) {
          toast.error(firstKey[0]);
        } else {
          toast.error("An unknown error occurred.");
        }
      }
      // Check if error is a string message
      else if (typeof error === "string") {
        toast.error(error);
      }
      // Handle other cases if necessary
      else {
        toast.error("An unknown error occurred.");
      }
    }
    dispatch(clearMessage());
  }, [error, message, loading]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <form
        action=""
        onSubmit={handleSubmit}
        method="PUT"
        className="xl:px-4 flex flex-col gap-4 w-full"
      >
        {/* top bar */}
        <div className="flex justify-between items-center w-full">
          <BreadCrums
            breadCrum={[
              { name: "Brand", path: "/super-admin/brands/" },
              {
                name: "Edit Brand",
                path: `/super-admin/brands/update-brands/${id}`,
              },
            ]}
          />

          <button
            disabled={loading ? true : false}
            type="submit"
            className="py-2 md:py-[14px] px-5 md:px-9 bg-[#C19A6B] flex items-center justify-center rounded-lg text-white text-center font-semibold text-xs md:text-sm"
          >
            {!loading ? "Update" : "Updating"}
          </button>
        </div>

        <div className="flex  border border-[#EBF0ED] p-2 md:p-4 gap-4 md:gap-10 rounded-lg">
          {/* 1st div  */}
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Brand Name
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full p-2 md:p-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="text"
                name="name"
                id=""
                placeholder="Electronics"
                value={updateData && updateData.name}
                onChange={(e) =>
                  setUpdateData({ ...updateData, name: e.target.value })
                }
                maxLength={30}
              />
            </div>
            {/* Display the image */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Brand Logo
              </label>
              <div className="w-36 h-28 bg-gray-200 flex justify-center items-center">
                <label htmlFor="imageInput" className="cursor-pointer">
                  {updateData && (updateData.newLogo || updateData.logo) && (
                    <img
                      src={
                        updateData.newLogo
                          ? updateData.newLogo
                          : imagePath + updateData.logo
                      }
                      alt="Brand Logo"
                      className="w-36 h-28 object-cover rounded-lg"
                    />
                  )}
                </label>
                <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
