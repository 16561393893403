import React, { useEffect, useState } from "react";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, deleteHotSpot, getHeroSectionList } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";
import DeletePopup from "../../DeletePopup";

export default function HeroSectionAllHotspotImagesTable() {
  const [hotspotData, setHotspotData] = useState([]);
  const { message, loading, data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHeroSectionList());
  }, []);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedHotspotId, setSelectedHotspotId] = useState(null);

  const handleDeleteClick = (id) => {
    setSelectedHotspotId(id);
    setShowDeletePopup(true);
  };

  const handleDeleteConfirmation = () => {
    dispatch(deleteHotSpot(selectedHotspotId)).then((data) => {
      fetchData();
    });
    setShowDeletePopup(false); // Hide the popup after confirmation
  };
  
    const handleDeleteCancel = () => {
      setShowDeletePopup(false); // Hide the popup on cancel
    };

  useEffect(() => {
    if (message) {
      toast.success(message);
    }
    dispatch(clearMessage())
  }, [message]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="xl:px-4 mt-4 mb-4">
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: " Hero Section",
              },
            ]}
          />

          <Link
            to="/super-admin/hero-section-image-selection"
            className="bg-[#C19A6B] py-[7px] md:py-[14px] px-5 md:px-9 text-white rounded-lg font-semibold tracking-widest text-xs"
          >
            Add New
          </Link>
        </div>
      </div>

      <div className=" xl:px-4 flex justify-center border-gray-500 overflow-auto">
        {/* table */}
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}

            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[15%] text-left">IMAGES</span>
              <span className="w-[15%] text-left">TITLE</span>
              <span className="w-[30%] text-left">DESCRIPTION</span>
              <span className="w-[15%] text-left">ACTION</span>
            </div>

            {/* Table Body */}

            {loading
              ? "loading"
              : data.length > 0 &&
                data.map((item) => (
                  <div
                    key={item._id}
                    className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <span className="w-[15%] text-left ">
                      <img
                        className="h-16 xl:h-24 w-20 md:w-28 rounded-md"
                        src={item.image}
                        alt=""
                      />
                    </span>
                    <span className="w-[15%] text-left">{item.title}</span>
                    <span className="w-[30%] text-left ">
                      {item.description}
                    </span>

                    <span className="flex gap-4 w-[15%] text-left">
                      <Link
                        to={"/super-admin/update-hotspot-images/" + item.id}
                      >
                        <img
                          className="px-2 py-2 border rounded-xl bg-[#FAFAFA]"
                          src="/assets/icons/edit-icon.svg"
                          alt=""
                        />
                      </Link>
                      <img
                        onClick={(e) => handleDeleteClick(item.id)}
                        className="px-2 py-2 border rounded-xl bg-[#FAFAFA] cursor-pointer"
                        src="/assets/icons/delete-icon.svg"
                        alt=""
                      />
                    </span>
                  </div>
                ))}
          </div>
        </div>
      </div>

      {showDeletePopup && (
          <DeletePopup
            isVisible={showDeletePopup}
            onDeleteConfirmation={handleDeleteConfirmation} // Directly pass the function
            onDeleteCancel={handleDeleteCancel}
          />
        )}
    </>
  );
}
