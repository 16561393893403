import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { adminPasswordReset, clearMessage } from "../store/adminSlice";

export default function NewPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, status_code } = useSelector(
    (state) => state.admin
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmNewPassword === "") {
      toast.error("Please fill all the fields");
    } else if (confirmNewPassword !== newPassword) {
      toast.error("Password and new password do not match");
    } else {
      dispatch(
        adminPasswordReset({
          email,
          password: newPassword,
          confirm_password: confirmNewPassword,
          token,
        })
      );
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      if (status_code === 200) {
        navigate("/");
        localStorage.clear();
      }
    }
    if (error) {
      if (typeof error === "object" && Object.keys(error).length > 0) {
        const firstKey = Object.values(error)[0];
        if (Array.isArray(firstKey) && firstKey.length > 0) {
          toast.error(firstKey[0]);
        } else {
          toast.error("An unknown error occurred.");
        }
      } else if (typeof error === "string") {
        toast.error(error);
      } else {
        toast.error("An unknown error occurred.");
      }
    }

    dispatch(clearMessage());
  }, [message, error, dispatch, navigate, status_code]);

  return (
    <div className=" h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          New Password
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Enter your new password and confirm{" "}
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter New Password
              </span>
            </div>
            <input
              type="password"
              placeholder="• • • • • • • • • • • • • • • •"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </label>

          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Confirm New Password
              </span>
            </div>
            <input
              type="password"
              placeholder="• • • • • • • • • • • • • • • •"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </label>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-[#C19A6B] w-full text-[15px] rounded-lg py-3 md:py-4 mt-[1.5rem] text-white font-bold tracking-wider"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
