import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

import SuperAdminAdresses from "../../../Components/My-Shop-Components/Super-Admin-Profile-Components/SuperAdminAdresses";

export default function SuperAdminAdressesPage() {
  return (
    <>
      <MyShopLayout>
        <SuperAdminAdresses />
      </MyShopLayout>
    </>
  );
}
