import BoosterStats from "../Components/My-Shop-Components/Product-Boosters-Components/BoosterStats";
import BoosterSetting from "../Components/My-Shop-Components/Product-Boosters-Components/BoosterSetting";
import ProductBoostersPage from "../Pages/My-Shop/Product-Boosters-page/ProductBoostersPage";
import BoosterSettingUpdate from "../Components/My-Shop-Components/Product-Boosters-Components/BoosterSettingUpdate";
import BoosterProductRequests from "../Components/My-Shop-Components/Product-Boosters-Components/BoosterProductsRequests";

import BoosterDetailsPage from "../Pages/My-Shop/Product-Boosters-page/BoosterDetailsPage";
import BoosterProductsDetailsPage from "../Pages/My-Shop/Product-Boosters-page/BoosterProductsDetailsPage";

const boosterRoutes = [
  {
    path: "/super-admin/product-boosters",

    element: <ProductBoostersPage />,
    children: [
      {
        index: true,
        element: <BoosterProductRequests />,
      },

      {
        path: "/super-admin/product-boosters/stats",
        element: <BoosterStats />,
      },
      {
        path: "/super-admin/product-boosters/settings",
        element: <BoosterSetting />,
      },
      {
        path: "/super-admin/product-boosters/settings/update/:id",
        element: <BoosterSettingUpdate />,
      },
    ],
  },
  {
    path: "/super-admin/product-boosters/booster-details/:id",
    element: <BoosterDetailsPage />,
  },
  {
    path: "/super-admin/product-boosters/booster-product-details",
    element: <BoosterProductsDetailsPage />,
  },
];

export default boosterRoutes;
