import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UserProductsReturned from "../../../../Components/Shop-And-User-List/User-List/UserProductsReturned";

export default function UserProductsReturnedPage() {
  return (
    <MyShopLayout>
      <UserProductsReturned />
    </MyShopLayout>
  );
}
