import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BrandDelete,
  BrandsList,
  brandListSearch,
  clearMessage,
} from "../../../../store/adminSlice";
// import { imagePath } from "../../../../functions/URLString";
import { toast } from "react-toastify";
import DeletePopup from "../../../DeletePopup";
import BreadCrums from "../../../Common/BreadCrums";
import Loader from "../../../Common/Loader";

export default function Brands() {
  const dispatch = useDispatch();
  const { data, loading, error, message } = useSelector((state) => state.admin);
  // const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [brandsList, setBrandsList] = useState([]);

  useEffect(() => {
    dispatch(BrandsList());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data && data.data) {
      let brands_list = data.data;

      setBrandsList(brands_list);
    }
  }, [data, loading]);

  useEffect(() => {
    if (search) {
      dispatch(brandListSearch(search));
    } else {
      dispatch(BrandsList());
    }
  }, [search, dispatch]);

  // State for delete popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState(null);

  // Handle delete button click
  const handleDeleteClick = (id) => {
    setSelectedBrandId(id);
    setShowDeletePopup(true); // Show the delete popup
  };

  // Handle delete confirmation in popup
  const handleDeleteConfirmation = () => {
    dispatch(BrandDelete(selectedBrandId)).then(() => {
      dispatch(BrandsList());
    });
    setShowDeletePopup(false); // Hide the popup after confirmation
  };

  // Handle delete cancellation in popup
  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Hide the popup on cancel
  };

  // handling messages and navigation
  useEffect(() => {
    if (message) {
      toast.success(message);
    }

    if (error) {
      // Check if error is an object with nested error structure
      if (typeof error === "object" && Object.keys(error).length > 0) {
        const firstKey = Object.values(error)[0];
        if (Array.isArray(firstKey) && firstKey.length > 0) {
          toast.error(firstKey[0]);
        } else {
          toast.error("An unknown error occurred.");
        }
      }
      // Check if error is a string message
      else if (typeof error === "string") {
        toast.error(error);
      }
      // Handle other cases if necessary
      else {
        toast.error("An unknown error occurred.");
      }
    }
    dispatch(clearMessage());
  }, [error, message, loading, dispatch]);

  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data?.total) {
      let totalLength = data?.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [brandsList, data?.total]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(BrandsList(page));
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[{ name: "Brand", path: "/super-admin/brands/" }]}
        />

        {/* search */}
        <div className="flex items-center justify-between gap-5">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3"
            />

            <input
              className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
              type="search"
              placeholder="Search Brand by name ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <Link
            className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B] flex items-center justify-center rounded-lg text-white text-center font-semibold text-xs md:text-sm text-nowrap"
            to="/super-admin/brands/create-brands"
          >
            Add Brand
          </Link>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[40%] text-left uppercase"> Brand Name</span>
                <span className="w-[30%] text-left uppercase">Image</span>
                <span className="w-[30%] text-right uppercase">Action</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : brandsList.length > 0 ? (
                brandsList.map((brand) => {
                  return (
                    <>
                      <div
                        key={brand.id}
                        className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                      >
                        <span className="w-[40%] text-left">{brand.name}</span>
                        <span className="w-[30%] text-left">
                          <img
                            src={brand.logo}
                            alt=""
                            className="w-7 h-7 md:w-10 md:h-10 rounded-sm"
                          />
                        </span>

                        <div className=" w-[30%] flex gap-2 justify-end text-right">
                          <Link
                            to={`/super-admin/brands/update-brands/${brand.id}`}
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/manage-products/edit.svg"
                              alt=""
                            />
                          </Link>
                          <button
                            className="cursor-pointer focus:outline-none text-red-500 hover:text-red-700"
                            onClick={() => handleDeleteClick(brand.id)}
                          >
                            <img
                              src="/assets/icons/manage-products/delete.svg"
                              alt="Delete"
                              className="w-7 h-7 md:w-9 md:h-9"
                            />
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <tr className="text-center font-bold tracking-wide py-4">
                  <td colSpan={6}>No Brands</td>
                </tr>
              )}
            </div>
          </div>
        </div>

        {/* Pagination */}

        {showDeletePopup && (
          <DeletePopup
            isVisible={showDeletePopup}
            onDeleteConfirmation={handleDeleteConfirmation} // Directly pass the function
            onDeleteCancel={handleDeleteCancel}
          />
        )}
      </div>
      <div className="px-5">
        {!loading && !search && data && data.data?.length > 0 ? (
          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              SHOWING {data.from ? data.from : 1 + "-" + data.to} OF{" "}
              {data.total}
            </span>
            <div className="flex items-center justify-between w-fit gap-2 h-[40px] bg-white rounded-lg px-2">
              {/* Previous Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                }
              </div>
              {/* Next Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
