import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { fetchBanner } from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";
import moment from "moment";

export default function BannerAdsRequests() {
  const [products, setProducts] = useState([]);
  const [activeRole, setActiveRole] = useState("All");
  const [availableCount, setAvailableCount] = useState(0);
  const [upcomingCount, setUpcomingCount] = useState(0);
  const [registeredCount, setRegisteredCount] = useState(0);
  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector((state) => state.admin);
  const [activeTab, setActiveTab] = useState("list");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Filter products based on active role
  const filteredProducts =
    activeRole === "All"
      ? products
      : products.filter((item) => item.status2 === activeRole);

  const fetchData = async (data) => {
    try {
      dispatch(fetchBanner(data));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // Pagination control
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredProducts.length);
  const paginatedProducts = filteredProducts.slice(startIndex, endIndex);

  const shouldShowPagination =
    filteredProducts.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4 ">
        <BreadCrums
          breadCrum={[
            { name: "Marketing Solutions", path: "/super-admin/banner-ads" },
            {
              name: "Banner Ads Requests",
              path: "/super-admin/banner-ads",
            },
          ]}
        />

        {/* tabs */}
        <div className="flex items-center text-xs md:text-sm gap-3">
          <Link
            to=""
            onClick={() => handleTabClick("list")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "list"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            LIST
          </Link>
          <Link
            to="/super-admin/banner-ads/banner-ad-details"
            onClick={() => handleTabClick("stats")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500]  ${
              activeTab === "stats"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STATS
          </Link>
          <Link
            to="/super-admin/banner-ads/banner-ad-setting"
            onClick={() => handleTabClick("settings")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500]  ${
              activeTab === "settings"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            SETTINGS
          </Link>
        </div>

        {/* search  */}
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3 text-[#C19A6B]"
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
            type="search"
            placeholder="Search…"
          />
        </div>

        {/* filter */}
        <div className="flex gap-1 md:gap-6 md:py-3 text-[9px] md:text-sm">
          <NavLink
            onClick={() => {
              setActiveRole("All");
              fetchData();
            }}
            className={`flex items-center font-semibold gap-[2px] md:gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            All banners
            <span
              className={` font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              {data?.pendingcount + data?.activecount + data?.rejectcount}
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Pending");
              fetchData({ status: "pending" });
            }}
            className={`flex items-center font-semibold gap-[2px] md:gap-2 text-[#828282] uppercase ${
              activeRole === "Pending"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            PENDING
            <span
              className={` font-semibold px-1 md:px-2 py-1   bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Pending"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.pendingcount}
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Active");
              fetchData({ status: "active" });
            }}
            className={`flex items-center font-semibold gap-[2px] md:gap-2 text-[#828282] uppercase ${
              activeRole === "Active"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            APPROVED
            <span
              className={` font-semibold px-1 md:px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Active"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.activecount}
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              setActiveRole("Rejected");
              fetchData({ status: "reject" });
            }}
            className={`flex items-center font-semibold gap-[2px] md:gap-2 text-[#828282] uppercase ${
              activeRole === "Rejected"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            REJECTED
            <span
              className={` font-semibold px-1 md:px-2 py-1    bg-[#FAFAFA] border   rounded-[5.75px] ${
                activeRole === "Rejected"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              {data?.rejectcount}
            </span>
          </NavLink>
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[898px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[15%] text-left">LOCAL AD</span>
                <span className="w-[10%] text-left text-nowrap">
                  STORE LOCATION
                </span>
                <span className="w-[10%] text-left text-nowrap">
                  AD LOCATIOON
                </span>
                <span className="w-[8%] text-left text-nowrap">AD PRICE</span>
                <span className="w-[25%] text-left">TIME</span>
                <span className="w-[10%] text-left">STATUS</span>
                <span className="w-[15%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading
                ? "loading"
                : data?.banners?.length > 0 &&
                  data?.banners?.map((req) => (
                    <div
                      key={req.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="w-[15%] text-left flex items-center gap-2">
                        <img
                          className="rounded-lg w-[42px] md:w-[72px] h-[30px] md:h-[60px]"
                          src={imagePath + req.image}
                          alt=""
                        />
                        <span className="text-nowrap">{req.name}</span>
                      </div>

                      <span className="w-[10%] text-left">
                        {req.store_country_id + ", " + req.store_city_id}
                      </span>
                      <span className="w-[10%] text-left">
                        {req.ad_country_id + ", " + req.ad_city_id}
                      </span>
                      <span className="w-[8%] text-left">${req.price}</span>
                      <div className="w-[25%] text-left flex items-center gap-2 text-nowrap">
                        <span className="border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-3  font-[600] rounded-lg">
                          {moment(req.start_date, "YYYY-MM-DD HH:mm:ss").format(
                            "D MMMM YYYY"
                          )}
                        </span>{" "}
                        to{" "}
                        <span className="border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-3  font-[600] rounded-lg">
                          {moment(req.end_date, "YYYY-MM-DD HH:mm:ss").format(
                            "D MMMM YYYY"
                          )}
                        </span>
                      </div>
                      <div className="w-[10%] text-left">
                        <span
                          className={`${
                            req.status == "1"
                              ? "border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-3  font-[400] rounded-lg"
                              : req.status == "0"
                              ? "border border-[#FEC600] bg-[#FFFBF0] text-[#FEC600] py-1 px-3  font-[400] rounded-lg"
                              : "border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-3  font-[400] rounded-lg"
                          }`}
                        >
                          {req.status == 1
                            ? "Active"
                            : req.status == 0
                            ? "Pending"
                            : "Rejected"}
                        </span>
                      </div>

                      <div className="w-[15%] text-left flex items-center gap-2">
                        <img
                          className="w-7 h-7 md:w-[38px] md:h-[38px]"
                          src="/assets/icons/view.svg"
                          alt=""
                          onClick={(e) =>
                            navigate(
                              "/super-admin/banner-ads/banner-ad-single-request/" +
                                req.id
                            )
                          }
                        />
                        <img
                          className="w-7 h-7 md:w-[38px] md:h-[38px]"
                          src="/assets/icons/link.svg"
                          alt=""
                        />
                        <img
                          className="w-7 h-7 md:w-[38px] md:h-[38px]"
                          src="/assets/icons/Edit1.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        {shouldShowPagination && (
          <div className="flex flex-col md:flex-row justify-between items-center mb-4">
            <span className="text-xs md:text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} - {endIndex} OF {products.length} RESULTS
            </span>
            <div className="flex items-center justify-center gap-3 w-full md:w-[265px] h-[40px] bg-white rounded-lg px-2 mt-4 md:mt-0">
              {/* Previous Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px] cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="6"
                  height="12"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {paginationIndexes.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`py-[6px] px-[12px] md:py-[10px] md:px-[14.5px] text-lg   rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white rounded-xl border border-[#EBF0ED]"
                        : " bg-[#FAFAFA] text-[#828282] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
              {/* Next Button */}
              <div
                className={`py-[12px] px-[12px] md:py-[18px] md:px-[17px]  cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED]"${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="6"
                  height="12"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
