import React from "react";

import StoreStats from "../../../Components/My-Shop-Components/Store-Boosters/StoreStats";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function StoreStatsPage() {
  return (
    <MyShopLayout>
      <StoreStats />
    </MyShopLayout>
  );
}
