import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

import OfferManagmentEditOffer from "../../../Components/My-Shop-Components/Offer-Managment-Components/OfferManagmentEditOffer";

export default function OfferManagmentEditOfferPage() {
  return (
    <>
      <MyShopLayout>
        <OfferManagmentEditOffer />
      </MyShopLayout>
    </>
  );
}
