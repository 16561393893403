import React from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

export default function RoleManagmentAddNew() {
  // const [isEditable, setIsEditable] = useState(false);
  // const [roleValue, setTimeSpan] = useState("Select Existing Role");
  // const [roleDropdown, setDropdown] = useState(false);

  // const roleOptions = ["Manager", "Admin"];
  // const handleBudgetSelect = (option) => {
  //   setTimeSpan(option);

  //   setDropdown(false);
  // };
  // const togglerole = () => setDropdown(!roleDropdown);
  return (
    <>
      <div className="xl:px-4">
        <BreadCrums
          breadCrum={[
            { name: "Role Managment", path: "/super-admin/role-managment" },
          ]}
        />

        {/*  */}
        <div className="my-5 flex flex-col lg:flex-row  gap-4">
          <div className="w-full lg:w-[30%] border border-[#EBF0ED] rounded-lg ">
            <div className="flex items-center justify-between py-[0.7rem] px-2 gap-2">
              <div className="w-full  flex items-center gap-8 px-3">
                <span className="text-xs font-semibold text-[#6B6B6B]">
                  ROLES
                </span>
              </div>
              <div className="w-full">
                <span className="float-start text-xs font-semibold text-[#6B6B6B]">
                  ACTION
                </span>
              </div>
            </div>
            <hr />
            <div className="flex items-center justify-between py-[0.6rem] px-2 gap-2">
              <div className="w-full  flex items-center gap-8 px-3">
                <span className="text-sm font-semibold text-[#18120F]">
                  Manager
                </span>
              </div>
              <div className="w-full flex gap-4">
                <Link
                  to="/super-admin/role-managment/view"
                  className="bg-[#FAFAFA] w-7 h-7 md:w-9 md:h-9 rounded-xl p-2 border border-[#EBF0ED]"
                >
                  <img className="" src="/assets/icons/edite1.svg" alt="" />
                </Link>

                <Link to="/super-admin/role-managment/setting">
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/manage.svg"
                    alt=""
                  />
                </Link>
                <img
                  className="w-7 h-7 md:w-9 md:h-9"
                  src="/assets/icons/delete1.svg"
                  alt=""
                />
              </div>
            </div>
            <hr />
          </div>
          <div className="w-full lg:w-[70%] h-full  border border-[#EBF0ED] py-[0.8rem] rounded-lg ">
            <div className="flex gap-2 px-3">
              <div className="w-full flex flex-col gap-1  ">
                <label
                  htmlFor=""
                  className="text-xs font-semibold text-[#6B6B6B]"
                >
                  TITLE
                </label>
                <input
                  type="text"
                  placeholder="Enter Role Name"
                  className="border outline-none border-[#EBF0ED] text-xs font-[400] text-[#6B6B6B] px-2 rounded-lg bg-[#FAFAFA] py-2"
                />
              </div>
            </div>
            <div className="float-end px-3 py-2">
              <button className="bg-[#C19A6B] text-xs lg:text-sm font-semibold tracking-wide rounded-lg md:rounded-xl py-2 lg:py-[15px] px-5 lg:px-9 text-white">
                Add New Role
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
