import AuctionCreateCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Auction-Categories/Main-Categories/AuctionCreateCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AuctionCreateMainCategoryPage() {
  return (
    <MyShopLayout>
      <AuctionCreateCategory />
    </MyShopLayout>
  );
}
