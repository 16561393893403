import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../functions/axios";
import {
  ADMIN_BRANDS_LIST,
  ADMIN_BRANDS_LIST_SEARCH,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_FORGOT_PASSWORD_VERIFY_OTP,
  ADMIN_LOGIN,
  ADMIN_PASSWORD_RESET,
  ADMIN_PRODUCT_BOOSTER,
  ADMIN_PRODUCT_BOOSTER_SEARCH,
  ALL_OFFERS_LIST,
  ALL_OFFERS_LIST_SEARCH,
  ALL_SELLER_LIST,
  Add_ADMIN_BOOSTER,
  FETCH_ADMIN_BOOSTER_PRODUCT,
  FETCH_BOOSTER_BY_ADMIN,
  HERO_SECTION,
  MALL_ACCEPT_ADMIN,
  MALL_REJECT_ADMIN,
  MALL_REQUEST_TO_ADMIN,
  MALL_REQUEST_TO_ADMIN_DETAILS,
  OFFER_STATS,
  PRODUCT_REGISTERED,
  SELLER_REGISTERED,
  STORE_LIST,
  UPDATE_ADMIN_BOOSTER,
  UPDATE_BOOSTER_STATUS,
  USER_LISTS_ACTIVE_FILTER,
  USER_LISTS_DISABLED_FILTER,
  USER_ORDERS_AFFILIATE_PRODUCT_SEARCH,
  USER_ORDERS_PLACED_SEARCH,
  USER_ORDERS_PLACE_DETAILS,
  USER_ORDERS_PRODUCT_AFFILIATE,
  USER_ORDERS_PRODUCT_RETURN,
  USER_ORDERS_RETURN_SEARCH,
  USER_ORDERS_PRODUCT_REVIEW_SEARCH,
  VIEW_SELLER_PRODUCTS,
  VIEW_SELLER_PRODUCTS_ACCEPT,
  VIEW_SELLER_PRODUCTS_REJECT,
  SEARCH_MALL_LIST,
  ADMIN_USERS,
  ADMIN_USERS_SEARCH,
  ADMIN_USER_BY_ID,
  ADMIN_BRAND_DELETE,
  ADMIN_CREATE_BRAND,
  ADMIN_UPDATE_BRAND,
  ADMIN_BRAND_BY_ID,
  BANNER_LIST,
  BANNER_STATUS,
  BANNER_STATISTICS,
  ADMIN_OFFER_VIEW,
} from "./URLConstant";

const initialState = {
  data: [],
  mainCategories: [],
  status_code: null,
  token: localStorage.getItem("token") || null,
  role: localStorage.getItem("cookies") || null,
  loading: false,
  error: null,
  searchData: [],
  message: null,
  categories: [],
  subCategories: [],
  subSubCategories: [],
  signout: false,
  mainShopCategories: [],
  subCategoriesByID: [],
  sellerData: [],
  searchStatus: true,
};

// --------------- authentication middlewares start here ---------------

// login
export const loginUser = createAsyncThunk(
  "admin/loginUser",
  async (user, { rejectWithValue }) => {
    try {
      const res = await axios.post(ADMIN_LOGIN, user);
      return { data: res.data, status: res.status };
    } catch (err) {
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
//forgot password
export const adminForgotPassword = createAsyncThunk(
  "bannerForgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const res = await axios.post(ADMIN_FORGOT_PASSWORD, email);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// admin verify otp
export const adminForgotPasswordVerifyOTP = createAsyncThunk(
  "adminForgotPasswordVerifyOTP",
  async (otp, { rejectWithValue }) => {
    try {
      const res = await axios.post(ADMIN_FORGOT_PASSWORD_VERIFY_OTP, otp);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// admin password reset
export const adminPasswordReset = createAsyncThunk(
  "adminPasswordReset",
  async (password_detail, { rejectWithValue }) => {
    try {
      const res = await axios.post(ADMIN_PASSWORD_RESET, password_detail);
      return { data: res.data, status: res.status };
    } catch (err) {
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status,
      });
    }
  }
);
// --------------- authentication middlewares end here ---------------

export const mainCategory = createAsyncThunk("/mainCategory", async (page) => {
  const res = await axios.get("/admin/categories?page=" + page);
  return res.data;
});

export const adsStore = createAsyncThunk("/adsStore", async (page) => {
  const res = await axios.get("/admin/categories-adstore?page=" + page);
  return res.data;
});

export const auction = createAsyncThunk("/auction", async (page) => {
  const res = await axios.get("/admin/categories-auction?page=" + page);
  return res.data;
});

//additional main-shop Categories for fetching all main shop categories in create offer
export const mainShopCategoriesList = createAsyncThunk(
  "/mainShopCategoriesList",
  async () => {
    const res = await axios.get("admin/categories?page=all");
    return res.data;
  }
);

export const mainCategoryCreate = createAsyncThunk(
  "/mainCategoryCreate",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("/admin/categories", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// main-category update
export const mainCategoryUpdate = createAsyncThunk(
  "/mainCategoryUpdate",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/admin/categories/${id}`, formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const mainCategoryDelete = createAsyncThunk(
  "/mainCategoryDelete",
  async (id) => {
    const res = await axios.delete("/admin/categories/" + id);
    return res.data;
  }
);
export const deleteHotSpot = createAsyncThunk("/deleteHotSpot", async (id) => {
  const res = await axios.delete(HERO_SECTION + "/" + id);
  return res.data;
});

export const mainCategorySearch = createAsyncThunk(
  "/mainCategorySearch",
  async (search) => {
    const res = await axios.get(`/admin/categories-search?search=${search}`);
    return res.data;
  }
);
export const subCategorySearch = createAsyncThunk(
  "/subCategorySearch",
  async (search) => {
    const res = await axios.get(`/admin/subcategories-search?search=${search}`);
    return res.data;
  }
);
export const subSubCategorySearch = createAsyncThunk(
  "/subSubCategorySearch",
  async (search) => {
    const res = await axios.get(
      `/admin/subsubcategories-search?search=${search}`
    );
    return res.data;
  }
);

// sub-category fetching
export const subCategoriesList = createAsyncThunk(
  "/subCategoriesList",
  async (arg) => {
    if (arg) {
      const res = await axios.get("admin/subcategories?page=" + arg);
      return res.data;
    } else {
      const res = await axios.get("admin/subcategories");

      return res.data;
    }
  }
);
// offer list fetching by id
export const subCategoriesListByID = createAsyncThunk(
  "subCategoriesListByID",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`admin/subcategory/${id}`);

      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// sub-category create
export const subCategoriesCreate = createAsyncThunk(
  "/subCategoriesCreate",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("/admin/subcategories", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// subCategoryUpdate
export const subCategoryUpdate = createAsyncThunk(
  "/subCategoryUpdate",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/admin/subcategories/${id}`, formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// sub-cat delete
export const subCategoryDelete = createAsyncThunk(
  "/subCategoryDelete",
  async (id) => {
    const res = await axios.delete("/admin/subcategories/" + id);
    return res.data;
  }
);

// sub-sub cat fetching
export const subSubCategoriesList = createAsyncThunk(
  "/subSubCategoriesList",
  async (id) => {
    if (id) {
      const res = await axios.get(`/admin/subsubcategories?page=${id}`);
      return res.data;
    } else {
      const res = await axios.get("/admin/subsubcategories");
      return res.data;
    }
  }
);

// sub-sub cat fetching
export const subSubCategoriesById = createAsyncThunk(
  "/subSubCategoriesById",
  async (id) => {
    const res = await axios.get(`/admin/sub-sub-categories/${id}`);
    return res.data;
  }
);

// sub-sub category create
// export const subSubCategoriesCreate = createAsyncThunk(
//   "/subSubCategoriesCreate",
//   async (data) => {
//     const res = await axios.post("/subsubcategories", data);
//     return res.data;
//   }
// );
export const subSubCategoriesCreate = createAsyncThunk(
  "/subSubCategoriesCreate",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await axios.post("/admin/subsubcategories", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// subSubCategoryUpdate update
export const subSubCategoryUpdate = createAsyncThunk(
  "/subSubCategoryUpdate",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/admin/subsubcategories/${id}`,
        formData
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// sub-sub cat delete
export const subSubCategoryDelete = createAsyncThunk(
  "/subSubCategoryDelete",
  async (id) => {
    const res = await axios.delete(`/admin/subsubcategories/${id}`);
    return res.data;
  }
);

export const allOffersList = createAsyncThunk(
  "/allOffersList",
  async ({ page, status }) => {
    if (!page && !status) {
      const res = await axios.get(ALL_OFFERS_LIST);
      return res.data;
    } else if (page && !status) {
      const res = await axios.get(`${ALL_OFFERS_LIST}?page=` + page);
      return res.data;
    } else if (!page && status) {
      const res = await axios.get(`${ALL_OFFERS_LIST}?status=` + status);
      return res.data;
    } else if (page && status) {
      const res = await axios.get(
        `${ALL_OFFERS_LIST}?status=` + status + "&page=" + page
      );
      return res.data;
    }
  }
);

// offer list search
export const allOffersListSearch = createAsyncThunk(
  "allOffersListSearch",
  async (query, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      // Build the URL with the determined query parameter
      const res = await axios.get(`${ALL_OFFERS_LIST_SEARCH}?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// offer view by id
export const viewOffer = createAsyncThunk("/viewOffer", async (arg) => {
  if (arg.arg2) {
    const res = await axios.get(
      `${ADMIN_OFFER_VIEW}/${arg.arg3}?${arg.arg1}=${arg.arg2}`
    );
    return res.data;
  } else {
    const res = await axios.get(`${ADMIN_OFFER_VIEW}/${arg.arg3}`);
    return res.data;
  }
});
export const createNewOffer = createAsyncThunk(
  "createNewOffer",
  async (offerData, { rejectWithValue }) => {
    try {
      const res = await axios.post("admin/offers", offerData);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// offer get by id
export const offerByID = createAsyncThunk("admin/offersByID", async (id) => {
  // const id = formData.get('id'); // Extract id from formData
  // console.log(id);
  const res = await axios.get(`admin/offers/${id}`);
  return res.data;
});

// offers update

export const updateOffer = createAsyncThunk(
  "updateOffer",
  async (offer, { rejectWithValue }) => {
    try {
      const id = offer.get("id");
      const res = await axios.post(`admin/offers/${id}`, offer);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// offer-active
export const activeOffers = createAsyncThunk(
  "activeOffers",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get("/offer-active");
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// offer-upcoming
export const upcomingOffers = createAsyncThunk(
  "upcomingOffers",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get("/offer-registration-not-open");
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// offer-ended
export const endedOffers = createAsyncThunk(
  "endedOffers",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get("/offer-close");
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// all seller
export const allSellerList = createAsyncThunk(
  "/allSellerList",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg.arg3) {
        const response = await axios.get(
          ALL_SELLER_LIST + arg.arg3 + `?${arg.arg1}=` + arg.arg2
        );
        return response.data;
      } else {
        const response = await axios.get(ALL_SELLER_LIST + arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
//seller products view
export const sellerOfferProductsView = createAsyncThunk(
  "/sellerOfferProductsView",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg.arg3) {
        const response = await axios.get(
          VIEW_SELLER_PRODUCTS + arg.arg3 + `?${arg.arg1}=` + arg.arg2
        );
        return response.data;
      } else {
        const response = await axios.get(VIEW_SELLER_PRODUCTS + arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const sellerOfferProductsAccept = createAsyncThunk(
  "sellerOfferProductsAccept",
  async (Product_ids, { rejectWithValue }) => {
    try {
      const res = await axios.put(VIEW_SELLER_PRODUCTS_ACCEPT, Product_ids);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const bannerStatusChange = createAsyncThunk(
  "bannerStatusChange",
  async (args, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        BANNER_STATUS + args.id + "?status=" + args.status
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const sellerOfferProductsReject = createAsyncThunk(
  "sellerOfferProductsReject",
  async (Product_ids, { rejectWithValue }) => {
    try {
      const res = await axios.put(VIEW_SELLER_PRODUCTS_REJECT, Product_ids);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//offer stats
export const offerStats = createAsyncThunk(
  "/offerStats",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(OFFER_STATS + "/" + id);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
//Registered seller
//seller products view
export const sellerRegistered = createAsyncThunk(
  "/sellerRegistered",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg.arg3) {
        const response = await axios.get(
          SELLER_REGISTERED + arg.arg3 + `?${arg.arg1}=` + arg.arg2
        );
        return response.data;
      } else {
        const response = await axios.get(SELLER_REGISTERED + arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
//product seller
export const productRegistered = createAsyncThunk(
  "/productRegistered",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg.arg3) {
        const response = await axios.get(
          PRODUCT_REGISTERED + arg.arg3 + `?${arg.arg1}=` + arg.arg2
        );
        return response.data;
      } else {
        const response = await axios.get(PRODUCT_REGISTERED + arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// create brand
export const createBrand = createAsyncThunk("/createBrand", async (user) => {
  const res = await axios.post(ADMIN_CREATE_BRAND, user);
  return res.data;
});
export const BrandsList = createAsyncThunk(
  "/BrandsList",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const response = await axios.get(
          ADMIN_BRANDS_LIST + "?page=" + arg,
          arg
        );
        return response.data;
      } else {
        const response = await axios.get(ADMIN_BRANDS_LIST + "?page=" + 1, arg);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Brand Delete
export const BrandDelete = createAsyncThunk("/BrandDelete", async (id) => {
  const res = await axios.delete(ADMIN_BRAND_DELETE + id);
  return res.data;
});
// Brand by id
export const brandById = createAsyncThunk("/brandById", async (id) => {
  const res = await axios.get(`${ADMIN_BRAND_BY_ID}/${id}`);
  return res.data;
});
// update brand
export const updateBrand = createAsyncThunk(
  "/updateBrand",
  async (formData) => {
    const id = formData.get("id"); // Extract id from formData
    const res = await axios.post(`${ADMIN_UPDATE_BRAND}/${id}`, formData);
    return res.data;
  }
);
// User-list-search
export const brandListSearch = createAsyncThunk(
  "brandListSearch",
  async (query, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${ADMIN_BRANDS_LIST_SEARCH}?search=${query}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// mall  list
export const mallList = createAsyncThunk("/mallList", async (arg) => {
  if (arg) {
    const res = await axios.get("/admin/mall-list?status=" + arg.status);
    return res.data;
  } else {
    const res = await axios.get("/admin/mall-list");
    return res.data;
  }
});
export const storeListById = createAsyncThunk("/storeListById", async (id) => {
  const res = await axios.get(STORE_LIST + id);
  return res.data;
});
export const mallRequestList = createAsyncThunk(
  "/mallRequestList",
  async () => {
    const res = await axios.get(MALL_REQUEST_TO_ADMIN);
    return res.data;
  }
);
export const mallRequestDetails = createAsyncThunk(
  "/mallRequestDetails",
  async (id) => {
    const res = await axios.get(MALL_REQUEST_TO_ADMIN_DETAILS + id);
    return res.data;
  }
);
export const mallSearch = createAsyncThunk("/mallSearch", async (search) => {
  const res = await axios.get(SEARCH_MALL_LIST + "?search=" + search);
  return res.data;
});
export const mallListGetByID = createAsyncThunk(
  "/mallListGetByID",
  async (id) => {
    const res = await axios.get("/admin/mall-list/" + id);
    return res.data;
  }
);
// mall Delete
export const mallDelete = createAsyncThunk("/mallDelete", async (id) => {
  const res = await axios.delete("/admin/mall-delete/" + id);
  return res.data;
});
// create mall
export const createMall = createAsyncThunk(
  "mall/createMall",
  async (mall, { rejectWithValue }) => {
    try {
      const res = await axios.post("/mall", mall);
      return {
        data: res.data,
        message: "Mall created successfully",
      };
    } catch (error) {
      // You can handle errors here
      return rejectWithValue(error.response.data);
    }
  }
);
// mall get by id
export const mallByID = createAsyncThunk("/mallByID", async (id) => {
  // const id = formData.get('id'); // Extract id from formData
  // // console.log(id)
  const res = await axios.get(`/mall/${id}`);
  return res.data;
});
// update mall
export const updateMall = createAsyncThunk(
  "admin/updateMall",
  async (formData, { rejectWithValue }) => {
    const id = formData.get("id"); // Extract id from formData
    try {
      const res = await axios.post(`/mall/${id}`, formData);
      return res.data; // This will be the payload in the fulfilled case
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data); // Return the error response
      } else {
        return rejectWithValue(error.message); // Return a general error message
      }
    }
  }
);

// create product boosters
export const createProductBooster = createAsyncThunk(
  "/createProductBoosters",
  async (user) => {
    const res = await axios.post("/product-booster", user);
    return res.data;
  }
);

// get product booster
export const productBooster = createAsyncThunk("/productBooster", async () => {
  const res = await axios.get("/product-booster");
  return res.data;
});

// shop-list
export const shopList = createAsyncThunk(
  "/shopList",
  async ({ page, status }) => {
    if (!page && !status) {
      const res = await axios.get("/admin/shops");
      return res.data;
    } else if (page && !status) {
      const res = await axios.get("/admin/shops?page=" + page);
      return res.data;
    } else if (!page && status) {
      const res = await axios.get("/admin/shops?status=" + status);
      return res.data;
    } else if (page && status) {
      const res = await axios.get(
        "/admin/shops?status=" + status + "&page=" + page
      );
      return res.data;
    }
  }
);

export const shopListSearch = createAsyncThunk(
  "/shopListSearch",
  async (search) => {
    const res = await axios.get("/admin/shops-search?search=" + search);
    return res.data;
  }
);

// shop disable | active
export const activeOrDissableShop = createAsyncThunk(
  "activeOrDissableShop",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `/admin/mall-status/${arg.id}?status=${arg.status}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// shop details get by id
export const shopDetailsById = createAsyncThunk(
  "/shopDetailsById",
  async (id) => {
    const res = await axios.get(`/admin/shop-statistics/${id}`);
    return res.data;
  }
);
// vendor product details
export const vendorProductList = createAsyncThunk(
  "/vendorProductList",
  async ({ id, status, page }) => {
    if (id && !status) {
      const res = await axios.get(`/admin/shop-products/${id}`);
      return res.data;
    } else if (id && status) {
      // const res = await axios.get(/admin/shop-products/${id}+"?status="+status);
      const res = await axios.get(
        `/admin/shop-products/${id}?status=${status}`
      );
      return res.data;
    } else if (id && status && page) {
      // const res = await axios.get(`/admin/shop-products/${id}`+"?status="+status+"&page="+page);
      const res = await axios.get(
        `/admin/shop-products/${id}?status=${status}&page=${page}`
      );
      return res.data;
    } else if (id && page) {
      // const res = await axios.get(`/admin/shop-products/${id}`+"?page="+page);
      const res = await axios.get(`/admin/shop-products/${id}?page=${page}`);
      return res.data;
    }
  }
);
// vendor product details search
export const vendorProductListSearch = createAsyncThunk(
  "/vendorProductListSearch",
  async ({ id, search }) => {
    const res = await axios.get(
      `/admin/shop-products-search/${id}?search=${search}`
    );
    return res.data;
  }
);
// vendor product details
export const vendorAffiliateProductList = createAsyncThunk(
  "/vendorAffiliateProductList",
  async (id) => {
    const res = await axios.get(`/admin/shop-affiliate/${id}`);
    return res.data;
  }
);
// vendor product details search
export const vendorAffiliateProductListSearch = createAsyncThunk(
  "/vendorAffiliateProductListSearch",
  async ({ id, search }) => {
    const res = await axios.get(
      `/admin/shop-affiliate-search/${id}?search=${search}`
    );
    return res.data;
  }
);
// vendor product return
export const vendorProductReturn = createAsyncThunk(
  "/vendorProductReturn",
  async (id) => {
    const res = await axios.get(`/admin/shop-returned-orders/${id}`);
    return res.data;
  }
);
// vendorProductReturnSearch
export const vendorProductReturnSearch = createAsyncThunk(
  "/vendorProductReturnSearch",
  async ({ id, search }) => {
    const res = await axios.get(
      `/admin/shop-returned-orders-search/${id}?search=${search}`
    );
    return res.data;
  }
);
// vendor product reviews
export const vendorProductReviews = createAsyncThunk(
  "/vendorProductReviews",
  async (id) => {
    const res = await axios.get(`/admin/shop-reviews/${id}`);
    return res.data;
  }
);
// vendor product reviews search
export const vendorProductReviewsSearch = createAsyncThunk(
  "/vendorProductReviewsSearch",
  async ({ id, search }) => {
    const res = await axios.get(
      `/admin/shop-reviews-search/${id}?search=${search}`
    );
    return res.data;
  }
);
// vendor order list
export const vendorOrdersList = createAsyncThunk(
  "/vendorOrdersList",
  async ({ id, status }) => {
    if (status) {
      const res = await axios.get(`/admin/shop-orders/${id}?status=${status}`);
      return res.data;
    } else {
      const res = await axios.get(`/admin/shop-orders/${id}`);
      return res.data;
    }
  }
);
// vendorOrdersListSearch
export const vendorOrdersListSearch = createAsyncThunk(
  "/vendorOrdersListSearch",
  async ({ id, search }) => {
    const res = await axios.get(
      `/admin/shop-orders-search/${id}?search=${search}`
    );
    return res.data;
  }
);
export const getHeroSectionList = createAsyncThunk(
  "/getHeroSectionList",
  async (id) => {
    const res = await axios.get(HERO_SECTION);
    return res.data;
  }
);

// shop order details
export const ShopOrderViewDetails = createAsyncThunk(
  "ShopOrderViewDetails",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/admin/shop-orders-details/${id}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Vendor Request List
export const vendorRequestsList = createAsyncThunk(
  "admin/vendorRequestsList",
  async (page, { rejectWithValue }) => {
    try {
      if (page) {
        const res = await axios.get("/admin/shoprequest?page=" + page);
        return res.data;
      } else {
        const res = await axios.get("/admin/shoprequest");
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const vendorRequestsDetails = createAsyncThunk(
  "admin/vendorRequestsDetails",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get("/admin/shoprequest/" + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateVendorStatus = createAsyncThunk(
  "admin/rejectVendor",
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `/admin/vendor-status/${id}?status=${status}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const vendorRequestsDetailsSearch = createAsyncThunk(
  "admin/vendorRequestsDetailsSearch",
  async (search, { rejectWithValue }) => {
    try {
      const res = await axios.get("/admin/shoprequest-search?search=" + search);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const approveMalls = createAsyncThunk(
  "admin/approveMalls",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.put(MALL_ACCEPT_ADMIN + "/" + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const rejectMalls = createAsyncThunk(
  "admin/rejectMalls",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.put(MALL_REJECT_ADMIN + "/" + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ********************* USER LISTS API CALLING START HERE *********************

// User-list-fetching

export const usersList = createAsyncThunk(
  "admin/usersList",
  async (arg, { rejectWithValue }) => {
    try {
      if (arg) {
        const res = await axios.get(`${ADMIN_USERS}?${arg.arg1}=${arg.arg2}`);
        return res.data;
      } else {
        const res = await axios.get(ADMIN_USERS);
        return res.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// User-list-active-filter
export const usersListActiveFilter = createAsyncThunk(
  "usersListActiveFilter",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(USER_LISTS_ACTIVE_FILTER);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// User-list-disable-filter
export const usersListDisableFilter = createAsyncThunk(
  "usersListDisableFilter",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axios.get(USER_LISTS_DISABLED_FILTER);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// active-dissable-user
export const activeOrDissableUser = createAsyncThunk(
  "activeOrDissableUser",
  async (arg, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        "admin/user-status/" + arg.arg3 + "?" + arg.arg1 + "=" + arg.arg2
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user-list-count

export const userProfileStatistics = createAsyncThunk(
  "userProfileStatistics",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axios.get(ADMIN_USER_BY_ID + "/" + userId);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user-list-order-placed
export const userListOrderPlaced = createAsyncThunk(
  "userListOrderPlaced",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axios.get("admin/user-order/" + userId);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// user-order-product-review
export const userListOrderProductReview = createAsyncThunk(
  "userListOrderProductReview",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axios.get("admin/user-review/" + userId);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user-product-return
export const usersListProductReturn = createAsyncThunk(
  "usersListProductReturn",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axios.get(USER_ORDERS_PRODUCT_RETURN + userId);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// user-product-Affiliate
export const usersListAffiliateProduct = createAsyncThunk(
  "usersListAffiliateProduct",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axios.get(USER_ORDERS_PRODUCT_AFFILIATE + userId);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// user-order-list-order-view-deatils
export const usersListOrderViewDetails = createAsyncThunk(
  "usersListOrderViewDetails",
  async (userId, { rejectWithValue }) => {
    try {
      const res = await axios.get(USER_ORDERS_PLACE_DETAILS + userId);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// User-list-search

export const usersListSearch = createAsyncThunk(
  "usersListSearch",
  async (query, { rejectWithValue }) => {
    try {
      // const queryParam = query.includes('@') ? `email=${encodeURIComponent(query)}` : `name=${encodeURIComponent(query)}`;
      const queryParam = `search=${encodeURIComponent(query)}`;
      // Build the URL with the determined query parameter
      const res = await axios.get(`${ADMIN_USERS_SEARCH}?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// User-list-order-palced-search
export const usersListOrderPlacedSearch = createAsyncThunk(
  "usersListOrderPlacedSearch",
  async ({ id, query }, { rejectWithValue }) => {
    try {
      const queryParam = `search=${encodeURIComponent(query)}`;
      // Build the URL with the determined query parameter
      const res = await axios.get(
        `${USER_ORDERS_PLACED_SEARCH}/${id}?${queryParam}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// User-list-order-product-review-search
export const userListOrderProductReviewSearch = createAsyncThunk(
  "userListOrderProductReviewSearch",
  async ({ id, query }, { rejectWithValue }) => {
    try {
      // Encode the query parameter
      const encodedQuery = encodeURIComponent(query);

      // Construct the query parameter with both product_name and category_name
      const queryParam = `search=${encodedQuery}`;

      // Build the URL with the determined query parameter
      const res = await axios.get(
        `${USER_ORDERS_PRODUCT_REVIEW_SEARCH}/${id}?${queryParam}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const FetchAdminProductBooster = createAsyncThunk(
  "/FetchAdminProductBooster",
  async ({ status, page }, { rejectWithValue }) => {
    try {
      var response = null;

      if (status && !page) {
        response = await axios.get(ADMIN_PRODUCT_BOOSTER + "?status=" + status);
      } else if (!status && page) {
        response = await axios.get(ADMIN_PRODUCT_BOOSTER + "?page=" + page);
      } else if (status && page) {
        response = await axios.get(
          ADMIN_PRODUCT_BOOSTER + "?status=" + status + "&page=" + page
        );
      } else {
        response = await axios.get(ADMIN_PRODUCT_BOOSTER);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const productBoosterSearch = createAsyncThunk(
  "/productBoosterSearch",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.get(
          `/admin/product-booster-search?search=${arg}`
        );
      } else {
        response = await axios.get(ADMIN_PRODUCT_BOOSTER_SEARCH);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchAdminBoosterProduct = createAsyncThunk(
  "/fetchAdminBoosterProduct",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.get(FETCH_ADMIN_BOOSTER_PRODUCT + arg);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchBanner = createAsyncThunk(
  "/fetchBanner",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      if (arg?.id) {
        response = await axios.get(BANNER_LIST + "/" + arg.id);
        return response.data;
      } else if (!arg) {
        response = await axios.get(BANNER_LIST);
        return response.data;
      } else if (arg.status) {
        response = await axios.get(BANNER_LIST + "?status=" + arg.status);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchBannerstatistics = createAsyncThunk(
  "/fetchBannerstatistics",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      if (arg?.id) {
        response = await axios.get(BANNER_STATISTICS + "/" + arg.id);
        return response.data;
      } else if (!arg) {
        response = await axios.get(BANNER_STATISTICS);
        return response.data;
      } else if (arg.status) {
        response = await axios.get(BANNER_STATISTICS + "?status=" + arg.status);
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateBoosterProductt = createAsyncThunk(
  "/updateBoosterProductt",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.put(UPDATE_BOOSTER_STATUS, arg);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addAdminBooster = createAsyncThunk(
  "/addAdminBooster",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.post(Add_ADMIN_BOOSTER, arg);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteAdminBooster = createAsyncThunk(
  "/deleteAdminBooster",
  async (id, { rejectWithValue }) => {
    try {
      var response = null;

      response = await axios.delete(`/admin/product-booster/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addHeroSection = createAsyncThunk(
  "/addHeroSection",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.post(HERO_SECTION, arg);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateHeroSection = createAsyncThunk(
  "/updateHeroSection",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      var response = null;

      if (formData) {
        response = await axios.post(HERO_SECTION + "/" + id, formData);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getHeroSectionById = createAsyncThunk(
  "/getHeroSectionById",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.get(HERO_SECTION + "/" + arg);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateAdminBooster = createAsyncThunk(
  "/updateAdminBooster",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.put(UPDATE_ADMIN_BOOSTER + "/" + arg.id, arg);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchBoosterByAdmin = createAsyncThunk(
  "/fetchBoosterByAdmin",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;

      if (arg) {
        response = await axios.get(FETCH_BOOSTER_BY_ADMIN, arg);
      } else {
        response = await axios.get(FETCH_BOOSTER_BY_ADMIN);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// User-list-return-order-review-search
export const userListReturnProductSearch = createAsyncThunk(
  "userListReturnProductSearch",
  async ({ id, query }, { rejectWithValue }) => {
    try {
      // Encode the query parameter
      const encodedQuery = encodeURIComponent(query);

      // Construct the query parameter with both product_name and category_name
      const queryParam = `search=${encodedQuery}`;

      // Build the URL with the determined query parameter
      const res = await axios.get(
        `${USER_ORDERS_RETURN_SEARCH}/${id}?${queryParam}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// User-list-affiliate-product-search
export const userListAffiliateProductSearch = createAsyncThunk(
  "userListAffiliateProductSearch",
  async ({ id, query }, { rejectWithValue }) => {
    try {
      // Encode the query parameter
      const encodedQuery = encodeURIComponent(query);

      // Construct the query parameter with both product_name and category_name
      const queryParam = `search=${encodedQuery}`;

      // Build the URL with the determined query parameter
      const res = await axios.get(
        `${USER_ORDERS_AFFILIATE_PRODUCT_SEARCH}/${id}?${queryParam}`
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Premium Module -----------------------------------------------------------------

export const premiumProductCategoryList = createAsyncThunk(
  "/premiumProductCategoryList",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.get("/admin/premium-product-cateogry");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductListSearch = createAsyncThunk(
  "/premiumProductListSearch",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.get(`/admin/premium-product-search?search=${arg}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductList = createAsyncThunk(
  "/premiumProductList",
  async ({ status, page }, { rejectWithValue }) => {
    // console.log(status);
    try {
      var response = null;
      if (status && !page) {
        response = await axios.get("/admin/premium-product?status=" + status);
      } else if (!status && page) {
        response = await axios.get("/admin/premium-product?page=" + page);
      } else if (status && page) {
        response = await axios.get(
          "/admin/premium-product?status=" + status + "&page=" + page
        );
      } else {
        response = await axios.get("/admin/premium-product");
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductDelete = createAsyncThunk(
  "/premiumProductDelete",
  async (id, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.delete(`/admin/premium-product/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductBids = createAsyncThunk(
  "/premiumProductBids",
  async (id, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.get(`/admin/premium-product/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const premiumProductAdd = createAsyncThunk(
  "/premiumProductAdd",
  async (formData, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.post("/admin/premium-product", formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const UpdatePremiumProductController = createAsyncThunk(
  "/UpdatePremiumProductController",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.post(`/admin/premium-product/${id}`, formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Store Booster Module -------------------------------------------------------------------
export const listAllBoosters = createAsyncThunk(
  "/listAllBoosters",
  async ({ status, page }, { rejectWithValue }) => {
    try {
      var response = null;
      if (page && !status) {
        response = await axios.get(`/admin/store-booster?page=${page}`);
      } else if (!page && status) {
        response = await axios.get(`/admin/store-booster?status=${status}`);
      } else if (page && status) {
        response = await axios.get(
          `/admin/store-booster?status=${status}&page=${page}`
        );
      } else {
        response = await axios.get(`/admin/store-booster`);
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addStoreBoosterAdmin = createAsyncThunk(
  "/addStoreBoosterAdmin",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.post("/admin/store-booster", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const listBoosterPostedByAdmin = createAsyncThunk(
  "/listBoosterPostedByAdmin",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.get("/admin/store-booster-admin");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateBoosterPostedByAdmin = createAsyncThunk(
  "/updateBoosterPostedByAdmin",
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.post(`/admin/store-booster/${id}`, formData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const listStoreBoosterById = createAsyncThunk(
  "/listStoreBoosterById",
  async (id, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.get(`/admin/store-booster/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateStoreBoosterStatusByAdmin = createAsyncThunk(
  "/updateStoreBoosterStatusByAdmin",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.put("/admin/store-booster-status", arg);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const storeBoosterSearch = createAsyncThunk(
  "/storeBoosterSearch",
  async (arg, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.get(`/admin/store-booster-search?search=${arg}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const storeBoosterDelete = createAsyncThunk(
  "/storeBoosterDelete",
  async (id, { rejectWithValue }) => {
    try {
      var response = null;
      response = await axios.delete(`/admin/store-booster/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

// ********************* USER LISTS API CALLING END HERE *********************

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    addToken: (state, action) => {
      state.token = action.payload; // Set the token from the action payload
      localStorage.setItem("token", action.payload); // Store the token in localStorage
    },
    addUser: (state, action) => {
      state.user = localStorage.getItem("user");
    },
    logout: (state, action) => {
      state.token = null;
      localStorage.removeItem("token");
    },
    searchIT: (state, action) => {
      state.searchData = action.payload;
    },
    clearMessage: (state) => {
      state.message = null;
      state.error = null;
      state.status_code = null;
    },
    resetSignout: (state) => {
      state.signout = false;
    },
  },
  extraReducers: (builder) => {
    // for login
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.data = payload.data?.user;
        state.role = payload.data?.user?.role;
        state.token = payload.data?.token;
        state.message = payload.data?.message;
        state.status_code = payload.status;
        localStorage.setItem("token", payload.data?.token); // Store the token in localStorage
        localStorage.setItem("cookies", payload.data?.user?.role); // Store the token in localStorage
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.payload?.data?.message;
        state.loading = false;
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // admin forgot password
    builder
      .addCase(adminForgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminForgotPassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) {
          state.message = payload.message;
          state.data = payload;

          // console.log(payload);
        } else {
          // console.log(payload);
          state.message = payload.message;
        }
      })
      .addCase(adminForgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
        // console.log(action.payload);
      });
    // admin forgot password verify otp
    builder
      .addCase(adminForgotPasswordVerifyOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminForgotPasswordVerifyOTP.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.success) {
          state.message = payload.message;
          state.data = payload;
          // console.log(payload);
        } else {
          state.error = Object.values(payload.error)[0];
        }
      })
      .addCase(adminForgotPasswordVerifyOTP.rejected, (state, action) => {
        state.loading = false;
        if (action.payload.errors) {
          state.error = Object.values(action.payload.errors)[0][0];
        } else {
          state.error = action.payload.error;
        }
      });
    // admin password reset otp
    builder
      .addCase(adminPasswordReset.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminPasswordReset.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.data?.message;
        state.data = payload;
        // console.log(payload);
        state.status_code = payload.status;
      })
      .addCase(adminPasswordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.data.message;
        state.status_code = action.payload.status;
      });

    // for main categories get
    builder.addCase(mainCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mainCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;

      state.categories = action.payload;
    });
    builder.addCase(mainCategory.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    // for main adsStorecategory get
    builder.addCase(adsStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adsStore.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;

      state.categories = action.payload;
    });
    builder.addCase(adsStore.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });

    // for main auction get
    builder.addCase(auction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(auction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;

      state.categories = action.payload;
    });
    builder.addCase(auction.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });

    //for additional   main categories get
    builder.addCase(mainShopCategoriesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mainShopCategoriesList.fulfilled, (state, action) => {
      state.loading = false;
      state.mainShopCategories = action.payload;
    });
    builder.addCase(mainShopCategoriesList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(mainCategoryCreate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mainCategoryCreate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(mainCategoryCreate.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      if (action.payload?.errors) {
        state.error = Object.values(action.payload.errors)[0][0];
      } else {
        state.error = action.payload.error;
      }
    });

    builder.addCase(mainCategoryUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mainCategoryUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(mainCategoryUpdate.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.message;
    });

    // for main categories delete
    builder.addCase(mainCategoryDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(mainCategoryDelete.fulfilled, (state, { payload }) => {
      state.loading = false;
      // console.log(payload);
      state.message = payload.message;
      // state.data = payload;
    });
    builder.addCase(mainCategoryDelete.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteHotSpot.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteHotSpot.fulfilled, (state, { payload }) => {
      state.loading = false;
      // console.log(payload);
      state.message = payload.message;
      // state.data = payload;
    });
    builder.addCase(deleteHotSpot.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });
    // for main category search
    builder.addCase(mainCategorySearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(mainCategorySearch.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
      state.searchStatus = true;
      // console.log(payload);
    });
    builder.addCase(mainCategorySearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.searchStatus = false;
    });
    // for sub category search
    builder.addCase(subCategorySearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subCategorySearch.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subCategories = payload;
      // console.log(payload);
      state.searchStatus = true;
    });
    builder.addCase(subCategorySearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      state.searchStatus = false;
    });
    // for subsub category search
    builder.addCase(subSubCategorySearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subSubCategorySearch.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subSubCategories = payload;
      // console.log(payload);
      state.searchStatus = true;
    });
    builder.addCase(subSubCategorySearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      state.searchStatus = false;
    });

    // sub categories fetching
    builder.addCase(subCategoriesList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subCategoriesList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subCategories = payload;
    });
    builder.addCase(subCategoriesList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });
    // sub categories fetching by id
    builder
      .addCase(subCategoriesListByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(subCategoriesListByID.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload);
        state.subCategoriesByID = payload;
        state.subCategories = payload;
      })
      .addCase(subCategoriesListByID.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // sub categories create
    builder.addCase(subCategoriesCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subCategoriesCreate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.message = payload.message;
      // state.data = payload;
    });
    builder.addCase(subCategoriesCreate.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.message;
      // console.log(action);
    });

    builder.addCase(subCategoryUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(subCategoryUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(subCategoryUpdate.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.message;
    });

    // for sub categories delete
    builder.addCase(subCategoryDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subCategoryDelete.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.message = payload.message;
    });
    builder.addCase(subCategoryDelete.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });

    // sub-sub categories fetching
    builder.addCase(subSubCategoriesList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subSubCategoriesList.fulfilled, (state, { payload }) => {
      state.loading = false;
      // console.log(payload);
      state.subSubCategories = payload;
    });
    builder.addCase(subSubCategoriesList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });

    // sub-sub categories fetching aaaa
    builder.addCase(subSubCategoriesById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subSubCategoriesById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subSubCategories = payload;
    });
    builder.addCase(subSubCategoriesById.rejected, (state, action) => {
      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });

    // sub-sub categories create
    builder.addCase(subSubCategoriesCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subSubCategoriesCreate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.message = payload.message;
      // console.log("payload :", payload);
      // state.data = payload;
    });
    builder.addCase(subSubCategoriesCreate.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.message;
      // console.log(action);
    });

    builder.addCase(subSubCategoryUpdate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(subSubCategoryUpdate.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(subSubCategoryUpdate.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.message;
    });

    // for sub-sub categories delete
    builder.addCase(subSubCategoryDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(subSubCategoryDelete.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.message = payload.message;
    });
    builder.addCase(subSubCategoryDelete.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });

    // for offer-list fetching
    builder
      .addCase(allOffersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(allOffersList.fulfilled, (state, { payload }) => {
        state.loading = false;
        // // console.log(payload)
        state.data = payload;
      })
      .addCase(allOffersList.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // for offer-list search
    builder
      .addCase(allOffersListSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(allOffersListSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload);
        state.data = payload;
      })
      .addCase(allOffersListSearch.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // for offer fetching by id
    builder.addCase(viewOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(viewOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(viewOffer.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });

    // creating new offer
    builder.addCase(createNewOffer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createNewOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(createNewOffer.rejected, (state, action) => {
      state.error = action.payload.errors;
      // console.log(action.payload.errors);

      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
    });

    // update offer
    builder.addCase(updateOffer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(updateOffer.rejected, (state, action) => {
      state.error = action.payload.errors;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // for offer get by id
    builder.addCase(offerByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(offerByID.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(offerByID.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });

    // Offer-active
    builder
      .addCase(activeOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(activeOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // // console.log(payload)
        state.data = payload;
      })
      .addCase(activeOffers.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // Offer-upcoming
    builder
      .addCase(upcomingOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(upcomingOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // // console.log(payload)
        state.data = payload;
      })
      .addCase(upcomingOffers.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // all seller list
    builder
      .addCase(allSellerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(allSellerList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.sellerData = payload;
        // console.log(payload);
      })
      .addCase(allSellerList.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // all seller product view
    builder
      .addCase(sellerOfferProductsView.pending, (state) => {
        state.loading = true;
      })
      .addCase(sellerOfferProductsView.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
        state.data = payload;
        // console.log(payload);
      })
      .addCase(sellerOfferProductsView.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // seller offer accept
    builder.addCase(sellerOfferProductsAccept.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sellerOfferProductsAccept.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(sellerOfferProductsAccept.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    builder.addCase(bannerStatusChange.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(bannerStatusChange.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(bannerStatusChange.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    // seller offer reject
    builder.addCase(sellerOfferProductsReject.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sellerOfferProductsReject.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(sellerOfferProductsReject.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // Offer-ended
    builder
      .addCase(endedOffers.pending, (state) => {
        state.loading = true;
      })
      .addCase(endedOffers.fulfilled, (state, { payload }) => {
        state.loading = false;
        // // console.log(payload)
        state.data = payload;
      })
      .addCase(endedOffers.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // offer stats
    builder
      .addCase(offerStats.pending, (state) => {
        state.loading = true;
      })
      .addCase(offerStats.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        // console.log(payload);
      })
      .addCase(offerStats.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // seller registered
    builder
      .addCase(sellerRegistered.pending, (state) => {
        state.loading = true;
      })
      .addCase(sellerRegistered.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        // console.log(payload);
      })
      .addCase(sellerRegistered.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });
    // product registered
    builder
      .addCase(productRegistered.pending, (state) => {
        state.loading = true;
      })
      .addCase(productRegistered.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        // console.log(payload);
      })
      .addCase(productRegistered.rejected, (state, action) => {
        state.loading = false;
        const error = action.payload?.message;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
      });

    // create brand
    builder.addCase(createBrand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createBrand.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(createBrand.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // brands fetching
    builder.addCase(BrandsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(BrandsList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // // console.log(action.payload)
    });
    builder.addCase(BrandsList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    // brand delete
    builder.addCase(BrandDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(BrandDelete.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(BrandDelete.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    // brands by id
    builder.addCase(brandById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(brandById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // // console.log(action.payload)
    });
    builder.addCase(brandById.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    // update brand
    builder.addCase(updateBrand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateBrand.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
      // console.log(action.payload);
    });
    builder.addCase(updateBrand.rejected, (state, action) => {
      state.error = action.payload;
      // console.log(action);

      const error = action.payload?.error;
      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    // brand list search
    builder.addCase(brandListSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(brandListSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // // console.log(action.payload)
    });
    builder.addCase(brandListSearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      // state.error = action.payload;
    });
    // for mall list get
    builder.addCase(mallList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mallList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(mallList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getHeroSectionById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getHeroSectionById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.hero_section;
    });
    builder.addCase(getHeroSectionById.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload.hero_section;
    });
    builder.addCase(storeListById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(storeListById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(storeListById.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(mallRequestList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mallRequestList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(mallRequestList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(mallRequestDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mallRequestDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(mallRequestDetails.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(mallSearch.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mallSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(mallSearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(mallListGetByID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(mallListGetByID.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.mall_detail;
    });
    builder.addCase(mallListGetByID.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });
    // for mall  delete
    builder.addCase(mallDelete.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(mallDelete.fulfilled, (state, { payload }) => {
      state.loading = false;
      // console.log(payload);
      state.message = payload;
      // state.data = payload;
    });
    builder.addCase(mallDelete.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.error.message;
    });
    // for mall create
    builder.addCase(createMall.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createMall.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      // console.log(state.message);
    });
    builder.addCase(createMall.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
    });
    // mall-By-ID
    builder.addCase(mallByID.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(mallByID.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(mallByID.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    // update mall
    builder.addCase(updateMall.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateMall.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.message = action.payload.message;
      // console.log(action.payload.message);
      state.error = null;
    });
    builder.addCase(updateMall.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload || "Failed to update mall";
      state.message = null;
    });

    // create product booster
    builder.addCase(createProductBooster.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      createProductBooster.fulfilled,
      (state, { payload: { error, data } }) => {
        state.loading = false;
        if (error) {
          state.error = error;
        } else {
          state.data = data;
        }
      }
    );
    builder.addCase(createProductBooster.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
    });

    // for booster product get
    builder.addCase(productBooster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(productBooster.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(productBooster.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
      state.error = action.payload;
    });

    // shop-list
    builder.addCase(shopList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(shopList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(shopList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
    });

    builder
      .addCase(shopListSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(shopListSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(shopListSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.error = action.payload;
        state.data = [];
      });

    // shop - dissable | active
    builder
      .addCase(activeOrDissableShop.pending, (state) => {
        state.loading = true;
      })
      .addCase(activeOrDissableShop.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.error = payload.message;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(activeOrDissableShop.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // state.error = action.payload.message || "Login failed";
      });

    // shop-details-By-ID
    builder.addCase(shopDetailsById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(shopDetailsById.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(shopDetailsById.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-product-list
    builder.addCase(vendorProductList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorProductList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-product-list-search
    builder.addCase(vendorProductListSearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductListSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorProductListSearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-affiliate-product-list
    builder.addCase(vendorAffiliateProductList.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(vendorAffiliateProductList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorAffiliateProductList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-affiliate-product-list-saecrh
    builder.addCase(
      vendorAffiliateProductListSearch.pending,
      (state, action) => {
        state.loading = true;
      }
    );

    builder.addCase(
      vendorAffiliateProductListSearch.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(
      vendorAffiliateProductListSearch.rejected,
      (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false; // Changed from true to false
        // state.error = action.payload.message;
      }
    );

    // vendor-product-return
    builder.addCase(vendorProductReturn.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductReturn.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorProductReturn.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-product-return-search
    builder.addCase(vendorProductReturnSearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductReturnSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorProductReturnSearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-product-reviews
    builder.addCase(vendorProductReviews.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductReviews.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorProductReviews.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-vendorProductReviewsSearch-reviews
    builder.addCase(vendorProductReviewsSearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorProductReviewsSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorProductReviewsSearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    // vendor-order-list
    builder.addCase(vendorOrdersList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorOrdersList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorOrdersList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false;
    });

    // vendor-vendorOrdersListSearch
    builder.addCase(vendorOrdersListSearch.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(vendorOrdersListSearch.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(vendorOrdersListSearch.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });

    builder.addCase(getHeroSectionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getHeroSectionList.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.hero_sections;
    });
    builder.addCase(getHeroSectionList.rejected, (state, action) => {
      const error = action.payload?.error;

      if (error && error.includes("Unauthorized")) {
        state.signout = true;
      }
      state.loading = false; // Changed from true to false
      // state.error = action.payload.message;
    });
    // shop-order-view-details
    builder
      .addCase(ShopOrderViewDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(ShopOrderViewDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(ShopOrderViewDetails.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // state.error = action.payload.message || "Login failed";
      });

    // vendor Request list
    builder
      .addCase(vendorRequestsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorRequestsList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(vendorRequestsList.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // state.error = action.payload.message || "Login failed";
      });
    builder
      .addCase(vendorRequestsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorRequestsDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(vendorRequestsDetails.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // state.error = action.payload.message || "Login failed";
      });
    builder
      .addCase(updateVendorStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVendorStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(updateVendorStatus.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.error = action.payload.message;
      });
    builder
      .addCase(vendorRequestsDetailsSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(vendorRequestsDetailsSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(vendorRequestsDetailsSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // state.error = action.payload.message || "Login failed";
      });
    builder
      .addCase(approveMalls.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveMalls.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(approveMalls.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });
    builder
      .addCase(rejectMalls.pending, (state) => {
        state.loading = true;
      })
      .addCase(rejectMalls.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(rejectMalls.rejected, (state, action) => {
        const error = action.payload?.error;
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // ******************* USER LIST ADD CASES START HERE *******************

    // users-List
    builder
      .addCase(usersList.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersList.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user - dissable | active
    builder
      .addCase(activeOrDissableUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(activeOrDissableUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.error = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(activeOrDissableUser.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // state.error = action.payload.message || "Login failed";
      });

    // users-List-active-filter
    builder
      .addCase(usersListActiveFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListActiveFilter.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListActiveFilter.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // users-List-disable-filter
    builder
      .addCase(usersListDisableFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListDisableFilter.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListDisableFilter.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user - statistics
    builder
      .addCase(userProfileStatistics.pending, (state) => {
        state.loading = true;
      })
      .addCase(userProfileStatistics.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(userProfileStatistics.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user-list-order-placed
    builder
      .addCase(userListOrderPlaced.pending, (state) => {
        state.loading = true;
      })
      .addCase(userListOrderPlaced.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(userListOrderPlaced.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user-list-order-product-review
    builder
      .addCase(userListOrderProductReview.pending, (state) => {
        state.loading = true;
      })
      .addCase(userListOrderProductReview.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(userListOrderProductReview.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user-list-product-return
    builder
      .addCase(usersListProductReturn.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListProductReturn.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListProductReturn.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user-list-affiliate-product
    builder
      .addCase(usersListAffiliateProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListAffiliateProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListAffiliateProduct.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // user-list-order-view-details
    builder
      .addCase(usersListOrderViewDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListOrderViewDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListOrderViewDetails.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // users-List-search
    builder
      .addCase(usersListSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // users-List-order-placed-search
    builder
      .addCase(usersListOrderPlacedSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(usersListOrderPlacedSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload);
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(usersListOrderPlacedSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // users-List-order-product-review-search
    builder
      .addCase(userListOrderProductReviewSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        userListOrderProductReviewSearch.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          if (!payload.success) {
            state.data = payload;
          } else {
            state.message = payload.message;
          }
        }
      )
      .addCase(userListOrderProductReviewSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    builder
      .addCase(FetchAdminProductBooster.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchAdminProductBooster.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(FetchAdminProductBooster.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }

        state.loading = false;
        state.message = payload.message;
      });
    builder
      .addCase(productBoosterSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(productBoosterSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(productBoosterSearch.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.data = [];
        state.loading = false;
        state.message = payload.message;
      });

    builder
      .addCase(fetchAdminBoosterProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAdminBoosterProduct.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(fetchAdminBoosterProduct.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.data = [];
        state.loading = false;
        state.message = payload.message;
        state.error = payload.error;
      });
    builder
      .addCase(fetchBanner.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBanner.fulfilled, (state, { payload }) => {
        state.loading = false;

        // console.log(payload, "---payload");
        state.data = payload;
        // console.log(payload);
      })
      .addCase(fetchBanner.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.data = [];
        state.loading = false;
        state.message = payload.message;
        state.error = payload.error;
      });
    builder
      .addCase(fetchBannerstatistics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBannerstatistics.fulfilled, (state, { payload }) => {
        state.loading = false;

        // console.log(payload, "---payload");
        state.data = payload;
        // console.log(payload);
      })
      .addCase(fetchBannerstatistics.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.data = [];
        state.loading = false;
        state.message = payload.message;
        state.error = payload.error;
      });
    builder
      .addCase(updateBoosterProductt.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBoosterProductt.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(updateBoosterProductt.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.data = [];
        state.loading = false;
        state.message = payload.message;
      });
    builder
      .addCase(addAdminBooster.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAdminBooster.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(addAdminBooster.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        state.message = payload.message;
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    builder
      .addCase(deleteAdminBooster.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAdminBooster.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(deleteAdminBooster.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.message = payload.message;
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
        // console.log(Object.values(payload.errors)[0][0]);
      });

    builder
      .addCase(addHeroSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(addHeroSection.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(addHeroSection.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        state.message = payload.message;
        state.error = Object.values(payload.errors)[0][0];
        // console.log(Object.values(payload.errors)[0][0]);
      });
    builder
      .addCase(updateHeroSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateHeroSection.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          // state.data = payload;
          state.message = payload?.message;
        } else {
          state.message = payload?.message;
        }
        // console.log("payload:", "adminclice accepted", payload);
      })
      .addCase(updateHeroSection.rejected, (state, { payload }) => {
        const error = payload?.error;
        // console.log("payload:", "adminclice rejected", payload);
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        state.message = payload?.message;
        // state.error = Object.values(payload?.errors)[0][0]
        // // console.log(Object.values(payload?.errors)[0][0])
      });
    builder
      .addCase(fetchBoosterByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBoosterByAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(fetchBoosterByAdmin.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        state.message = payload.message;
      });
    builder
      .addCase(updateAdminBooster.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdminBooster.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(updateAdminBooster.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        // state.message = payload.message;
        state.error = Object.values(payload.errors)[0][0];
        // console.log(Object.values(payload.errors)[0][0]);
      });

    // users-List-order-product-Return-search
    builder
      .addCase(userListReturnProductSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(userListReturnProductSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          state.data = payload;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(userListReturnProductSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // users-List-Affiliate-product-search
    builder
      .addCase(userListAffiliateProductSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        userListAffiliateProductSearch.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          if (!payload.success) {
            state.data = payload;
          } else {
            state.message = payload.message;
          }
        }
      )
      .addCase(userListAffiliateProductSearch.rejected, (state, action) => {
        const error = action.payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
      });

    // premiumProductCategoryList
    builder
      .addCase(premiumProductCategoryList.pending, (state) => {
        state.loading = true;
      })
      .addCase(premiumProductCategoryList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(premiumProductCategoryList.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        // state.message = payload.message;
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });
    // premiumProductListSearch
    builder
      .addCase(premiumProductListSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(premiumProductListSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(premiumProductListSearch.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.data = [];
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // premiumProductList
    builder
      .addCase(premiumProductList.pending, (state) => {
        state.loading = true;
      })
      .addCase(premiumProductList.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          // state.error = Object.values(payload.error)[0]
          // // console.log(Object.values(payload.error)[0])
        }
      })
      .addCase(premiumProductList.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.data = [];
        if (payload?.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else if (payload?.error) {
          state.error = payload.message;
        }
      });

    // premiumProductDelete
    builder
      .addCase(premiumProductDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(premiumProductDelete.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
        }
      })
      .addCase(premiumProductDelete.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // premiumProductBids
    builder
      .addCase(premiumProductBids.pending, (state) => {
        state.loading = true;
      })
      .addCase(premiumProductBids.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(premiumProductBids.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        // state.data = [];
        state.loading = false;
        // state.message = payload.message;
        state.error = Object.values(payload.errors)[0][0];
        // console.log(Object.values(payload.errors)[0][0]);
      });

    // premiumProductAdd
    builder
      .addCase(premiumProductAdd.pending, (state) => {
        state.loading = true;
      })
      .addCase(premiumProductAdd.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(premiumProductAdd.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
        // // console.log(Object.values(payload.errors)[0][0])UpdatePremiumProductController
      });

    // UpdatePremiumProductController
    builder
      .addCase(UpdatePremiumProductController.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        UpdatePremiumProductController.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          if (!payload.success) {
            // console.log(payload, "---payload");
            state.data = payload;
            state.message = payload.message;
          } else {
            state.message = payload.message;
            state.error = Object.values(payload.error)[0];
            // console.log(Object.values(payload.error)[0]);
          }
        }
      )
      .addCase(
        UpdatePremiumProductController.rejected,
        (state, { payload }) => {
          const error = payload?.error;

          if (error && error.includes("Unauthorized")) {
            state.signout = true;
          }
          state.loading = false;
          if (payload.errors) {
            state.error = Object.values(payload.errors)[0][0];
          } else {
            state.error = payload.error;
          }
        }
      );

    // listAllBoosters
    builder
      .addCase(listAllBoosters.pending, (state) => {
        state.loading = true;
      })
      .addCase(listAllBoosters.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          // state.error = Object.values(payload.error)[0]
          // // console.log(Object.values(payload.error)[0])
        }
      })
      .addCase(listAllBoosters.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        // if(payload.errors){
        //   state.error = Object.values(payload.errors)[0][0]
        // } else {
        //   state.error = payload.error
        // }
      });

    // addStoreBoosterAdmin
    builder
      .addCase(addStoreBoosterAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStoreBoosterAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          // state.error = Object.values(payload.error)[0]
          // // console.log(Object.values(payload.error)[0])
        }
      })
      .addCase(addStoreBoosterAdmin.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // listBoosterPostedByAdmin
    builder
      .addCase(listBoosterPostedByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(listBoosterPostedByAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(listBoosterPostedByAdmin.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // updateBoosterPostedByAdmin
    builder
      .addCase(updateBoosterPostedByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBoosterPostedByAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(updateBoosterPostedByAdmin.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // listStoreBoosterById
    builder
      .addCase(listStoreBoosterById.pending, (state) => {
        state.loading = true;
      })
      .addCase(listStoreBoosterById.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(listStoreBoosterById.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // updateStoreBoosterStatusByAdmin
    builder
      .addCase(updateStoreBoosterStatusByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateStoreBoosterStatusByAdmin.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          // console.log(payload, "---payload");
          if (!payload.success) {
            // console.log(payload, "---payload");
            state.data = payload;
            state.message = payload.message;
          } else {
            state.message = payload.message;
            state.error = Object.values(payload.error)[0];
            // console.log(Object.values(payload.error)[0]);
          }
        }
      )
      .addCase(
        updateStoreBoosterStatusByAdmin.rejected,
        (state, { payload }) => {
          const error = payload?.error;

          if (error && error.includes("Unauthorized")) {
            state.signout = true;
          }
          state.loading = false;
          // console.log(payload);
          if (payload.errors) {
            state.error = Object.values(payload.errors)[0][0];
          } else {
            state.error = payload.error;
          }
        }
      );

    // storeBoosterSearch
    builder
      .addCase(storeBoosterSearch.pending, (state) => {
        state.loading = true;
      })
      .addCase(storeBoosterSearch.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(storeBoosterSearch.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.data = { StoreBooster: [] };
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    // storeBoosterDelete
    builder
      .addCase(storeBoosterDelete.pending, (state) => {
        state.loading = true;
      })
      .addCase(storeBoosterDelete.fulfilled, (state, { payload }) => {
        state.loading = false;
        // console.log(payload, "---payload");
        if (!payload.success) {
          // console.log(payload, "---payload");
          state.data = payload;
          state.message = payload.message;
        } else {
          state.message = payload.message;
          state.error = Object.values(payload.error)[0];
          // console.log(Object.values(payload.error)[0]);
        }
      })
      .addCase(storeBoosterDelete.rejected, (state, { payload }) => {
        const error = payload?.error;

        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        // console.log(payload);
        if (payload.errors) {
          state.error = Object.values(payload.errors)[0][0];
        } else {
          state.error = payload.error;
        }
      });

    //listStoreBoosterById
    // ******************* USER LIST ADD CASES START HERE *******************
  },
});

export const {
  addToken,
  addUser,
  logout,
  searchIT,
  clearMessage,
  resetSignout,
} = adminSlice.actions;
export default adminSlice.reducer; // Exporting the reducer directly without naming it
