import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

export default function ManagerList() {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/requests.json");
        const data = await response.json();
        setRequests(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <div className="flex items-center justify-between ">
        <BreadCrums
          breadCrum={[
            { name: "Role Managment", path: "/super-admin/role-managment" },
            {
              name: "Managers",
            },
          ]}
        />

        <Link
          to="/super-admin/role-managment/add-new-manager"
          className="bg-[#C19A6B] text-xs font-semibold tracking-wide rounded-xl py-2 md:py-[15px] px-5 md:px-9 text-white"
        >
          Add Manager
        </Link>
      </div>

      <div className="relative flex items-center w-full ">
        <img
          src="/assets/icons/manage-products/search.svg"
          alt=""
          className="absolute left-4 text-[#C19A6B]"
        />

        {/* search  */}
        <input
          className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm"
          type="search"
          placeholder="Search…"
        />
      </div>

      {/* table */}
      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-xs md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[15%] text-left">Image</span>
              <span className="w-[20%] text-left">Name</span>
              <span className="w-[20%] text-left">Email</span>
              <span className="w-[20%] text-left">Role</span>
              <span className="w-[25%] text-left">Action </span>
            </div>

            {/* Table Body */}
            {requests.slice(0, 5).map((req) => (
              <div
                key={req.id}
                className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
              >
                <span className="w-[15%] text-left ">
                  <img
                    src={req.image}
                    alt=""
                    className="rounded-[20px] w-7 h-7 md:w-10 md:h-10"
                  />
                </span>

                <span className="w-[20%] text-left  ">{req.shop_name}</span>

                <span className="w-[20%] text-left ">{req.email}</span>
                <div className="w-[20%] text-left">
                  <span className="px-[15px] py-[5px] rounded-lg border text-[#C19A6B] border-[#C19A6B] bg-[#FFFBF6] font-normal">
                    {req.role}
                  </span>
                </div>

                <div className="w-[25%] text-left flex items-center gap-2">
                  <Link to="/super-admin/role-managment/manager-list/edit-manager">
                    <img
                      className="w-7 h-7 md:w-9 md:h-9"
                      src="/assets/icons/editfull.svg"
                      alt=""
                    />
                  </Link>
                  <Link>
                    <img
                      className="w-7 h-7 md:w-9 md:h-9"
                      src="/assets/icons/manage-products/delete.svg"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
