import Transactions from "../../../../Components/My-Shop-Components/Finance-Management/Transactions/Transactions";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function TransactionsPage() {
  return (
    <>
      <MyShopLayout>
        <Transactions />
      </MyShopLayout>
    </>
  );
}
