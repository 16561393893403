import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import CategoryComponentModal from "./CategoryModalComponent";
import BreadCrums from "../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  getHeroSectionById,
  mainCategory,
  subCategoriesList,
  subSubCategoriesList,
  updateHeroSection,
} from "../../store/adminSlice";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Common/Loader";

function UpdateHeroSectionImageSelectionComponent() {
  const { id } = useParams();
  const [hotspotsData, setHotspotsDAta] = useState([]);
  const [inputData, setInputData] = useState({ title: "", description: "" });
  const {
    message,
    data,
    loading,
    subCategories,
    subSubCategories,
    categories,
  } = useSelector((state) => state.admin);
  const [file, setFile] = useState();
  const [position, setPotsition] = useState({ top: null, left: null });

  const navigate = useNavigate();
  const [reviewImage, setPreviewImage] = useState("/assets/images/Mask.svg");
  const [category, setCategory] = useState({
    category_id: "",
    subcategory_id: "",
    subsubcategory_id: "",
  });
  const input = useRef(null);
  const categotyModalRef = useRef(null);
  const categoryModalShow = () => {
    categotyModalRef.current.showModal();
  };

  const addPoint = (event) => {
    const container = document.querySelector(".hotspot-container");
    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;

    const topPositionPercent = Math.round(
      ((event.clientY - containerRect.top) / containerHeight) * 100
    );
    const leftPositionPercent = Math.round(
      ((event.clientX - containerRect.left) / containerWidth) * 100
    );
    const hotspotElement = document.createElement("span");
    hotspotElement.classList.add("hotspot-link");

    hotspotElement.style.top = topPositionPercent + "%";
    hotspotElement.style.left = leftPositionPercent + "%";
    container.appendChild(hotspotElement);

    const existingHotspots = document.querySelectorAll(".hotspot-link");

    existingHotspots.forEach(function (hotspot) {
      const top = hotspot.style.top;
      const left = hotspot.style.left;
      setPotsition({ ...position, top: top, left: left });
    });
  };

  useEffect(() => {
    renderHotspots();
  }, [hotspotsData]);

  function handleCtrlClick(event) {
    if (file) {
      if (event.ctrlKey) {
        addPoint(event);
        categoryModalShow();
      } else if (event.altKey) {
        if (event.target.classList.contains("hotspot-link")) {
          const top = parseInt(event.target.style.top);
          const left = parseInt(event.target.style.left);
          setHotspotsDAta((prevHotspots) =>
            prevHotspots.filter(
              (hotspot) => hotspot.top !== top || hotspot.left !== left
            )
          );
          event.target.remove();
        }
      }
    }
  }
  const renderHotspots = () => {
    const container = document.querySelector(".hotspot-container");
    // container.innerHTML = ""; // Clear existing hotspots
    hotspotsData.forEach((hotspot) => {
      const hotspotElement = document.createElement("span");
      hotspotElement.classList.add("hotspot-link");
      hotspotElement.style.top = hotspot.top + "%";
      hotspotElement.style.left = hotspot.left + "%";
      container.appendChild(hotspotElement);
    });
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file instanceof File) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => reject(error);
      }
    });
  };

  const handleChange = async (event) => {
    setFile(event.target.files[0]);
    const url = await readFileAsDataURL(event.target.files[0]);
    setPreviewImage(url);
    input.current.style.display = "none";
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    const baseUrl = "https://wizalot.bingtechs.dev";
    var image = reviewImage.replace(baseUrl, "");
    const formData = new FormData();
    if (inputData.title && inputData.description && hotspotsData && file) {
      formData.append("title", inputData.title);
      formData.append("description", inputData.description);
      formData.append("dot[]", JSON.stringify(hotspotsData));
      formData.append("image", image);
      formData.append("_method", "PUT");

      dispatch(updateHeroSection({ id, formData }));
    } else {
      toast.error("kindly Fill All Fields");
    }
  };
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/super-admin/hotspot-images");
    }

    dispatch(mainCategory("all"));
    dispatch(subCategoriesList("all"));
    dispatch(subSubCategoriesList("all"));
  }, [message]);

  useEffect(() => {
    if (data?.id) {
      setInputData({
        ...inputData,
        title: data.title,
        description: data.description,
      });
      setHotspotsDAta(data.dots);
      setFile(data.image);
      setPreviewImage(data.image);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getHeroSectionById(id));
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <div className="flex justify-between items-center text-xs md:text-base">
        <BreadCrums
          breadCrum={[
            {
              name: " Hero Section",
              path: "/super-admin/hotspot-images",
            },
            {
              name: "Update HotSpot",
            },
          ]}
        />

        <button
        disabled={loading}
          className={`flex justify-center items-center rounded-lg px-5 md:px-9 py-[10px] h-10 md:py-[14px] text-xs font-semibold tracking-widest text-white bg-[#C19A6B] ${
            loading ? "cursor-not-allowed" : ""
          } `}
          onClick={(e) => handleSubmit(e)}
        >
         {!loading ? (
            "Update"
          ) : (
            <span className="loading loading-spinner loading-md"></span>
          )}
        </button>
      </div>
      <div className="w-full mt-6 border border-[#EBF0ED]  rounded-xl px-3 py-2 heigh">
        <label className="form-control ">
          <div className="label">
            <span className="label-text font-bold tracking-wide">Title</span>
          </div>
          <input
            type="text"
            placeholder="Title"
            value={inputData.title}
            className="py-2 md:py-3 text-xs rounded-lg px-3 border border-[#EBF0ED] bg-[#FAFAFA] w-full focus:outline-none focus:border-[#EBF0ED]"
            onChange={(e) =>
              setInputData({ ...inputData, title: e.target.value })
            }
          />
        </label>
        <label className="form-control mt-3">
          <div className="label">
            <span className="label-text font-bold tracking-wide">
              Short Description
            </span>
          </div>
          <input
            type="Text"
            placeholder="Write your description"
            value={inputData.description}
            className="py-2 md:py-3 text-xs rounded-lg px-3 border border-[#EBF0ED] bg-[#FAFAFA] w-full focus:outline-none focus:border-[#EBF0ED]"
            onChange={(e) =>
              setInputData({ ...inputData, description: e.target.value })
            }
          />
        </label>
        <div
          class="filebutton h-auto mt-4 hotspot-container"
          onClick={handleCtrlClick}
        >
          <div className="flex justify-center  items-center">
            <img src={reviewImage} className="h-100" alt="preview" />
            {/* 
            <input
              ref={input}
              onChange={handleChange}
              type="file"
              className="preview"
              id="myfile"
              name="myfile"
            /> */}
          </div>
        </div>
      </div>
      <CategoryComponentModal
        setHotspotsDAta={setHotspotsDAta}
        hotspotsData={hotspotsData}
        categotyModalRef={categotyModalRef}
        category={category}
        setCategory={setCategory}
        position={position}
        subCategories={subCategories}
        categories={categories}
        subSubCategories={subSubCategories}
      />
    </div>
  );
}

export default UpdateHeroSectionImageSelectionComponent;
