import React from "react";
import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";

export default function RoleManagmentSetting() {
  return (
    <>
      <div className="xl:px-4">
        <div className="flex items-center justify-between gap-1 h-fit">
          <BreadCrums
            breadCrum={[
              { name: "Role Managment", path: "/super-admin/role-managment" },
              { name: "Setting", path: "/super-admin/role-managment/setting" },
            ]}
          />
          <Link
            // to="/super-admin/role-managment"
            className="bg-[#C19A6B] text-xs md:text-sm font-semibold tracking-wide rounded-xl py-2 md:py-[15px] px-5 md:px-9 text-white"
          >
            Save
          </Link>
        </div>

        {/*  */}
        <div className="my-5 flex flex-col md:flex-row  gap-4">
          <div className="w-full md:w-[30%] h-fit border border-[#EBF0ED] rounded-lg ">
            <div className="flex items-center justify-between py-[0.7rem] px-2">
              <div className="w-full  flex items-center gap-8 px-3">
                <span className="text-xs font-semibold text-[#6B6B6B]">
                  ROLES
                </span>
              </div>
              <div className="w-full">
                <span className="float-start text-xs font-semibold text-[#6B6B6B]">
                  ACTION
                </span>
              </div>
            </div>
            <hr />
            <div className="flex items-center justify-between py-[0.6rem] px-2">
              <div className="w-full  flex items-center gap-8 px-3">
                <span className="text-sm font-semibold text-[#18120F]">
                  Manager
                </span>
              </div>
              <div className="w-full flex gap-4">
                <Link to="">
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/edits.svg"
                    alt=""
                  />
                </Link>

                <Link to="">
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/More.svg"
                    alt=""
                  />
                </Link>
                <Link>
                  <img
                    className="w-7 h-7 md:w-9 md:h-9"
                    src="/assets/icons/delete1.svg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <hr />
          </div>
          <div className="w-full md:w-[70%]   border border-[#EBF0ED] py-[0.8rem] rounded-lg ">
            <h2 className="text-xs font-semibold text-[#6B6B6B] pl-2 md:pl-11 py-2">
              PERMISSIONS: MANAGER
            </h2>
            <hr />
            <div className="px-6 py-4">
              <h2 className="md:ml-5 mb-2 text-sm font-semibold text-[#18120F]">
                Set Permissions
              </h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>
              </div>
            </div>
            <hr />
            <div className="px-6 py-4">
              <h2 className="md:ml-5 mb-2 text-sm font-semibold text-[#18120F]">
                Set Permissions
              </h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>{" "}
                <div className="flex gap-2 text-xs font-[400] text-[#828282]">
                  <input type="checkbox" name="" id="" />
                  <span>Permission</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
