import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import RoleManagmentAddNew from "../../../Components/My-Shop-Components/Role-Managment-Components/RoleManagmentAddNew";

export default function RoleManagmentAddNewPage() {
  return (
    <>
      <MyShopLayout>
        <RoleManagmentAddNew />
      </MyShopLayout>
    </>
  );
}
