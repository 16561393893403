import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import ShopDetails from "../../../../Components/Shop-And-User-List/ShopList/ShopDetails";

export default function ShopDetailsPage() {
  return (
    <MyShopLayout>
      <ShopDetails />
    </MyShopLayout>
  );
}
