import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { userProfileStatistics } from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import LineChart from "../../charts/LineChart";
import BreadCrums from "../../Common/BreadCrums";

const dataYears = {
  2021: [
    { name: "January", uv: 2000, pv: 2400, amt: 2400 },
    { name: "February", uv: 1500, pv: 1398, amt: 2210 },
    { name: "March", uv: 1000, pv: 9800, amt: 2290 },
    { name: "April", uv: 2780, pv: 3908, amt: 2000 },
    { name: "May", uv: 1890, pv: 4800, amt: 2181 },
    { name: "June", uv: 2390, pv: 3800, amt: 2500 },
    { name: "July", uv: 3490, pv: 4300, amt: 2100 },
    { name: "August", uv: 800, pv: 2400, amt: 2400 },
    { name: "September", uv: 2000, pv: 1400, amt: 2200 },
    { name: "October", uv: 2780, pv: 3908, amt: 2000 },
    { name: "November", uv: 1890, pv: 4800, amt: 2181 },
    { name: "December", uv: 2490, pv: 4300, amt: 2100 },
  ],
  2022: [
    { name: "January", uv: 2200, pv: 2400, amt: 2400 },
    { name: "February", uv: 1700, pv: 1398, amt: 2210 },
    { name: "March", uv: 1200, pv: 9800, amt: 2290 },
    { name: "April", uv: 2980, pv: 3908, amt: 2000 },
    { name: "May", uv: 2090, pv: 4800, amt: 2181 },
    { name: "June", uv: 2590, pv: 3800, amt: 2500 },
    { name: "July", uv: 3690, pv: 4300, amt: 2100 },
    { name: "August", uv: 1000, pv: 2400, amt: 2400 },
    { name: "September", uv: 2200, pv: 1400, amt: 2200 },
    { name: "October", uv: 2980, pv: 3908, amt: 2000 },
    { name: "November", uv: 2090, pv: 4800, amt: 2181 },
    { name: "December", uv: 2690, pv: 4300, amt: 2100 },
  ],
  2023: [
    { name: "January", uv: 200, pv: 2400, amt: 2400 },
    { name: "February", uv: 500, pv: 1398, amt: 2210 },
    { name: "March", uv: 700, pv: 9800, amt: 2290 },
    { name: "April", uv: 580, pv: 3908, amt: 2000 },
    { name: "May", uv: 690, pv: 4800, amt: 2181 },
    { name: "June", uv: 790, pv: 3800, amt: 2500 },
    { name: "July", uv: 890, pv: 4300, amt: 2100 },
    { name: "August", uv: 900, pv: 2400, amt: 2400 },
    { name: "September", uv: 2200, pv: 1400, amt: 2200 },
    { name: "October", uv: 2980, pv: 3908, amt: 2000 },
    { name: "November", uv: 2090, pv: 4800, amt: 2181 },
    { name: "December", uv: 2690, pv: 4300, amt: 2100 },
  ],
  // Add more years as needed
};

export default function UserProfileStats() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);

  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    dispatch(userProfileStatistics(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && data?.data) {
      setUserDetails(data?.data);
    }
  }, [loading, data]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          { name: "User", path: "/super-admin/users-list/" },
          {
            name: "User Profile States",
            path: `/super-admin/users-list/user-profile-stats/${id}`,
          },
        ]}
      />

      {/* user info  */}
      <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
        {/* user image and name  */}
        <div className="flex items-center gap-2">
          <span className="">
            <img
              src={userDetails?.image}
              alt=""
              className="w-14 h-14 rounded-full"
            />
          </span>
          <span className=" flex flex-col">
            <span className="font-semibold text-base">
              {userDetails?.customer}
            </span>
            <span className="font-semibold text-xs text-[#6B6B6B]">
              {userDetails?.role === "0" ? "User" : ""}
            </span>
          </span>
        </div>

        {/* user details  */}
        <div className=" w-full flex flex-col md:flex-row justify-between items-center md:gap-16">
          {/* 1st  */}
          <div className=" w-full flex flex-col">
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Full Name :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.customer}
              </span>
            </div>
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Mobile :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.number}
              </span>
            </div>
            <div className=" w-full flex justify-between  py-2 border-b border-[#EBF0ED] md:border-0">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase ">
                E-mail :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.email}
              </span>
            </div>
          </div>

          {/* 2nd  */}
          <div className=" w-full flex flex-col">
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Social Security Number :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.social_security_number}
              </span>
            </div>
            <div className=" w-full flex justify-between  py-2 border-b border-[#EBF0ED] md:border-0">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Location :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.location}
              </span>
            </div>
          </div>

          {/* 3rd */}
          <div className=" w-full flex flex-col">
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Instagram :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.instagram}
              </span>
            </div>
            <div className=" w-full flex justify-between border-b border-[#EBF0ED] py-2">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Tiktok :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.tiktok}
              </span>
            </div>
            <div className=" w-full flex justify-between  py-2">
              <span className="font-semibold text-xs  text-[#6B6B6B] uppercase">
                Twitter :
              </span>
              <span className="font-semibold text-xs">
                {userDetails?.twitter}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2">
        {/* first chart */}
        <div>
          <LineChart
            dataYears={dataYears}
            defaultYear="2021"
            chartTitle="Earnings"
            totalEarnings="$371,200"
            earningsChange="+12%"
            earningsChangeColor="#15CAB8"
            // boxShadow="0px 10px 10px 0px #AAAAAA0F" // Passing the shadow as a prop
          />
        </div>

        {/* second chart */}
        <div>
          <LineChart
            dataYears={dataYears}
            defaultYear="2021"
            chartTitle="Spendings"
            totalEarnings="$2,546"
            earningsChange="+12%"
            earningsChangeColor="#15CAB8"
            // boxShadow="0px 10px 10px 0px #AAAAAA0F" // Passing the shadow as a prop
          />
        </div>
      </div>

      <div className=" mt-6 flex justify-center">
        {/* first row */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-10 gap-y-6 xl:gap-12 ">
          {/* Orders Placed */}
          <div
            className="p-3 md:p-7 w-full md:w-[15rem] rounded-3xl"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Orders Placed
            </div>
            <div className="mt-5 flex justify-between items-center">
              <div className="text-2xl md:text-6xl font-bold">
                {data?.data?.orders}
              </div>
              <Link to={`/super-admin/users-list/order-placed/${id}`}>
                <img
                  className="w-9 h-9 md:w-11 md:h-11"
                  src="/assets/icons/forward-page.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>

          {/*  Products Reviewed */}
          <div
            className="p-3 md:p-7 w-full md:w-[15rem] rounded-3xl"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Products Reviewed
            </div>
            <div className="mt-5 flex justify-between items-center">
              <div className="text-2xl md:text-6xl font-bold">
                {data?.data?.ratings}
              </div>
              <Link to={`/super-admin/users-list/user-products-reviewed/${id}`}>
                <img
                  className="w-9 h-9 md:w-11 md:h-11"
                  src="/assets/icons/forward-page.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>

          {/*Products Returned */}
          <div
            className="p-3 md:p-7 w-full md:w-[15rem] rounded-3xl"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Products Returned
            </div>
            <div className="mt-5 flex justify-between items-center">
              <div className="text-2xl md:text-6xl font-bold">
                {data?.data?.ordersReturned}
              </div>
              <Link to={`/super-admin/users-list/user-products-returned/${id}`}>
                <img
                  className="w-9 h-9 md:w-11 md:h-11"
                  src="/assets/icons/forward-page.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>

          {/*  Affiliate Products */}
          <div
            className="p-3 md:p-7 w-full md:w-[15rem] rounded-3xl"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Affiliate Products
            </div>
            <div className="mt-5 flex justify-between items-center">
              <div className="text-2xl md:text-6xl font-bold">
                {data?.data?.affiliateProducts}
              </div>
              <Link
                to={`/super-admin/users-list/user-Affiliate-products/${id}`}
              >
                <img
                  className="w-9 h-9 md:w-11 md:h-11"
                  src="/assets/icons/forward-page.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
