import AccountStatement from "../../../../Components/My-Shop-Components/Finance-Management/Account-Statement/AccountStatement";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AccountStatementPage() {
  return (
    <>
      <MyShopLayout>
        <AccountStatement />
      </MyShopLayout>
    </>
  );
}
