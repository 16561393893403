import AdMarketPlaceUpdateSubCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Ad-Market-Place-Categories/Sub-Categories/AdMarketPlaceUpdateSubCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AdMarketPlaceUpdateSubCategoryPage() {
  return (
    <MyShopLayout>
      <AdMarketPlaceUpdateSubCategory />
    </MyShopLayout>
  );
}
