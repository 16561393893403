import MainShopCreateCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Manage-Main-Shop-Categories/Main-Categories/MainShopCreateCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function MainShopCreateCategoriesPage() {
  return (
    <MyShopLayout>
      <MainShopCreateCategory />
    </MyShopLayout>
  );
}
