import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import BreadCrums from "../../Common/BreadCrums";

export default function BannerAdSetting() {
  const [activeTab, setActiveTab] = useState("settings");
  const [packageType, setPackageType] = useState("");

  const packages = ["1 day", "2 day", "3 day"];

  const [packageDropdownOpen, setPackageDropdownOpen] = useState(false);

  const handlePackageSelect = (option) => {
    setPackageType(option);
    setPackageDropdownOpen(false);
  };

  const togglePackage = () => setPackageDropdownOpen(!packageDropdownOpen);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* breadcrumbs */}
        <div className="flex items-center  justify-between">
          <BreadCrums
            breadCrum={[
              { name: "Marketing Solutions", path: "/super-admin/banner-ads" },
              {
                name: "Banner Ad Setting",
                path: "/super-admin/banner-ads/banner-ad-setting",
              },
            ]}
          />

          <Link
            to="/super-admin/premium-products/create-offer"
            className="text-xs md:text-sm px-5 md:px-9 py-[7px] md:py-[10px] bg-[#C19A6B] text-white rounded-lg"
          >
            Save
          </Link>
        </div>
        {/* tabs */}
        <div className="flex items-center gap-3 text-xs md:text-sm">
          <Link
            to="/super-admin/banner-ads"
            onClick={() => handleTabClick("list")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "list"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            LIST
          </Link>
          <Link
            to="/super-admin/banner-ads/banner-ad-details"
            onClick={() => handleTabClick("stats")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "stats"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            STATS
          </Link>
          <Link
            to=""
            onClick={() => handleTabClick("settings")}
            className={`px-1 md:px-[10px] py-1 md:py-[6px] rounded-xl  font-[500] ${
              activeTab === "settings"
                ? "border border-[#C19A6B] text-[#C19A6B]"
                : "border border-[#EBF0ED] bg-[#FAFAFA] text-[#828282]"
            }`}
          >
            SETTINGS
          </Link>
        </div>
        <div className="w-full md:w-[350px] border border-[#EBF0ED] rounded-lg p-2 md:px-4 md:py-6">
          <h2 className="text-sm  md:text-xl font-semibold text-[#18120F]">
            Banner ad Settings
          </h2>
          <div className="relative flex flex-col gap-2 mt-3">
            <label
              className="text-[#18120F] text-xs font-[500]"
              htmlFor="budgetCategory"
            >
              Time Span
            </label>
            <div className="relative w-full">
              <input
                className={`bg-[#FAFAFA] cursor-pointer rounded-lg w-full py-1 md:py-2 px-4 outline-none`}
                type="text"
                readOnly
                placeholder="Time Span"
                value={packageType}
                onClick={togglePackage}
              />
              <RiArrowDropDownLine
                onClick={togglePackage}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-[#828282] w-6 h-6"
              />

              {packageDropdownOpen && (
                <div className="absolute z-10 bg-white border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                  {packages.map((option) => (
                    <div
                      key={option}
                      className="px-2 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handlePackageSelect(option)} // Updated onClick event
                    >
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="relative flex flex-col gap-2 mt-3">
            <label
              className="text-[#18120F] text-xs font-[500]"
              htmlFor="budgetCategory"
            >
              Minimum Amount
            </label>
            <div className="relative w-full">
              <input
                className={`bg-[#FAFAFA]  rounded-lg w-full py-1 md:py-2 px-4 outline-none`}
                type="text"
                placeholder="$100"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
