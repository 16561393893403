import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import OrdersManagement from "../../../../Components/My-Shop-Components/Orders-Management/OrdersManagement";

export default function OrderManagementPage() {
  return (
    <>
      <MyShopLayout>
        <OrdersManagement />
      </MyShopLayout>
    </>
  );
}
