import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import BreadCrums from "../../../Components/Common/BreadCrums";

export default function ProductBoostersPage() {
  const location = useLocation();

  const getBreadCrumbs = (pathname) => {
    switch (pathname) {
      case "/super-admin/product-boosters":
        return [
          {
            name: "Marketing Solutions",
            path: "/super-admin/product-boosters",
          },
          {
            name: "Booster Products Requests",
            path: "/super-admin/product-boosters",
          },
        ];
      case "/super-admin/product-boosters/stats":
        return [
          {
            name: "Marketing Solutions",
            path: "/super-admin/product-boosters",
          },
          {
            name: "Stats",
            path: "/super-admin/product-boosters/stats",
          },
        ];
      case "/super-admin/product-boosters/settings":
        return [
          {
            name: "Marketing Solutions",
            path: "/super-admin/product-boosters",
          },
          {
            name: "Settings",
            path: "/super-admin/product-boosters/settings",
          },
        ];
      default:
        return [
          {
            name: "Marketing Solutions",
            path: "/super-admin/product-boosters",
          },
        ];
    }
  };

  const breadcrumbs = getBreadCrumbs(location.pathname);

  return (
    <>
      <MyShopLayout>
        <div className="xl:px-4">
          <BreadCrums breadCrum={breadcrumbs} />

          {/* filter */}
          <div className="flex gap-4 ml-3 py-3 text-xs md:text-sm">
            <NavLink
              to="/super-admin/product-boosters"
              className={({ isActive }) =>
                isActive
                  ? "text-[#C19A6B] border border-[#C19A6B] font-medium  uppercase py-1 md:py-2 px-2 md:px-[10px] bg-[#FAFAFA] rounded-[10px]"
                  : "border border-[#EBF0ED] text-[#828282] font-medium  uppercase py-1 md:py-2 px-2 md:px-[10px] bg-[#FAFAFA] rounded-[10px]"
              }
              end
            >
              List
            </NavLink>

            <NavLink
              to="/super-admin/product-boosters/stats"
              className={({ isActive }) =>
                isActive
                  ? "text-[#C19A6B] border border-[#C19A6B] font-medium  uppercase py-1 md:py-2 px-2 md:px-[10px] bg-[#FAFAFA] rounded-[10px]"
                  : "border border-[#EBF0ED] text-[#828282] font-medium  uppercase py-1 md:py-2 px-2 md:px-[10px] bg-[#FAFAFA] rounded-[10px]"
              }
            >
              Stats
            </NavLink>

            <NavLink
              to="/super-admin/product-boosters/settings"
              className={({ isActive }) =>
                isActive
                  ? "text-[#C19A6B] border border-[#C19A6B] font-medium  uppercase py-1 md:py-2 px-2 md:px-[10px] bg-[#FAFAFA] rounded-[10px]"
                  : "border border-[#EBF0ED] text-[#828282] font-medium  uppercase py-1 md:py-2 px-2 md:px-[10px] bg-[#FAFAFA] rounded-[10px]"
              }
            >
              Setting
            </NavLink>
          </div>
        </div>

        <Outlet />
      </MyShopLayout>
    </>
  );
}
