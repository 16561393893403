import React from 'react'
import MyShopLayout from '../../../../Super-Admin-Dashboard-Layout/MyShopLayout'
import UpdateBrand from '../../../../Components/My-Shop-Components/Manage-Categories/Brands/UpdateBrand'

export default function UpdateBrandsPage() {
  return (
    <>
    <MyShopLayout >
        <UpdateBrand />
    </MyShopLayout>
    </>
  )
}
