import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import OfferStats from "../../../Components/My-Shop-Components/Offer-Managment-Components/OfferStats";

export default function OfferStatsPage() {
  return (
    <>
      <MyShopLayout>
        <OfferStats />
      </MyShopLayout>
    </>
  );
}
