import React from "react";
// import { IoIosTrendingUp } from "react-icons/io";
// import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import StoreStatsEarningChart from "../../Graphs/Store-Booster-Graphs/StoreStatsEarningChart";
import StoreStatsSpendingChart from "../../Graphs/Store-Booster-Graphs/StoreStatsSpendingChart copy";
import BreadCrums from "../../Common/BreadCrums";

export default function StoreStats() {
  // const [earningFilterValue, setEarningFilterValue] = useState("Current");
  // const [earningFilterDropdownOpen, setEarningFilterDropdownOpen] =
  //   useState(false);
  // const [boosterFilterValue, setBoosterFilterValue] = useState("Current");
  // const [boosterFilterDropdownOpen, setBoosterFilterDropdownOpen] =
  //   useState(false);

  // const graphSummaryDropDownValues = ["Current", "Months", "Years"];

  // const toggleEarningDropdown = () => {
  //   setEarningFilterDropdownOpen(!earningFilterDropdownOpen);
  // };

  // const handleEarningSelect = (value) => {
  //   setEarningFilterValue(value);
  //   setEarningFilterDropdownOpen(false);
  // };

  // const toggleBoosterDropdown = () => {
  //   setBoosterFilterDropdownOpen(!earningFilterDropdownOpen);
  // };

  // const handleBoosterSelect = (value) => {
  //   setBoosterFilterValue(value);
  //   setBoosterFilterDropdownOpen(false);
  // };

  return (
    <div className="xl:px-4 flex flex-col gap-6 pb-2">
      <BreadCrums
        breadCrum={[
          { name: "Marketing Solutions", path: "/super-admin/store-boosters" },
          { name: "Booster Store List", path: "/super-admin/store-boosters" },
          { name: "Stats", path: "" },
        ]}
      />

      {/* graphs  */}
      <div className="w-full flex flex-col md:flex-row justify-between gap-6 md:gap-12 items-center">
        {/* graph 1  */}
        <StoreStatsEarningChart />

        {/* graph 2  */}
        <StoreStatsSpendingChart />
      </div>

      <div className="flex  gap-4">
        <div className="flex flex-col gap-4 md:gap-6 text-left p-4  lg:p-7 shadow-lg md:shadow-sm rounded-lg md:min-w-52 max-w-full">
          <span className="font-semibold text-sm md:text-xl">
            Total Impressions
          </span>
          <span className="font-bold text-[40px] md:text-[76px] leading-[4rem]">
            63
          </span>
        </div>

        <div className="flex flex-col gap-4 md:gap-6 text-left p-4  lg:p-7 shadow-lg md:shadow-sm rounded-lg md:min-w-52 max-w-full">
          <span className="font-semibold text-sm md:text-xl">Total Clicks</span>
          <span className="font-bold text-[40px] md:text-[76px] leading-[4rem]">
            60
          </span>
        </div>

        <div className="flex flex-col gap-4 md:gap-6 text-left p-4  lg:p-7 shadow-lg md:shadow-sm rounded-lg md:min-w-52 max-w-full">
          <span className="font-semibold text-sm md:text-xl">Bought</span>
          <span className="font-bold text-[40px] md:text-[76px] leading-[4rem]">
            60
          </span>
        </div>
      </div>
    </div>
  );
}
