import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { shopDetailsById } from "../../../store/adminSlice";
import LineChart from "../../charts/LineChart";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

const dataYears = {
  2021: [
    { name: "January", uv: 2000, pv: 2400, amt: 2400 },
    { name: "February", uv: 1500, pv: 1398, amt: 2210 },
    { name: "March", uv: 1000, pv: 9800, amt: 2290 },
    { name: "April", uv: 2780, pv: 3908, amt: 2000 },
    { name: "May", uv: 1890, pv: 4800, amt: 2181 },
    { name: "June", uv: 2390, pv: 3800, amt: 2500 },
    { name: "July", uv: 3490, pv: 4300, amt: 2100 },
    { name: "August", uv: 800, pv: 2400, amt: 2400 },
    { name: "September", uv: 2000, pv: 1400, amt: 2200 },
    { name: "October", uv: 2780, pv: 3908, amt: 2000 },
    { name: "November", uv: 1890, pv: 4800, amt: 2181 },
    { name: "December", uv: 2490, pv: 4300, amt: 2100 },
  ],
  2022: [
    { name: "January", uv: 2200, pv: 2400, amt: 2400 },
    { name: "February", uv: 1700, pv: 1398, amt: 2210 },
    { name: "March", uv: 1200, pv: 9800, amt: 2290 },
    { name: "April", uv: 2980, pv: 3908, amt: 2000 },
    { name: "May", uv: 2090, pv: 4800, amt: 2181 },
    { name: "June", uv: 2590, pv: 3800, amt: 2500 },
    { name: "July", uv: 3690, pv: 4300, amt: 2100 },
    { name: "August", uv: 1000, pv: 2400, amt: 2400 },
    { name: "September", uv: 2200, pv: 1400, amt: 2200 },
    { name: "October", uv: 2980, pv: 3908, amt: 2000 },
    { name: "November", uv: 2090, pv: 4800, amt: 2181 },
    { name: "December", uv: 2690, pv: 4300, amt: 2100 },
  ],
  2023: [
    { name: "January", uv: 200, pv: 2400, amt: 2400 },
    { name: "February", uv: 500, pv: 1398, amt: 2210 },
    { name: "March", uv: 700, pv: 9800, amt: 2290 },
    { name: "April", uv: 580, pv: 3908, amt: 2000 },
    { name: "May", uv: 690, pv: 4800, amt: 2181 },
    { name: "June", uv: 790, pv: 3800, amt: 2500 },
    { name: "July", uv: 890, pv: 4300, amt: 2100 },
    { name: "August", uv: 900, pv: 2400, amt: 2400 },
    { name: "September", uv: 2200, pv: 1400, amt: 2200 },
    { name: "October", uv: 2980, pv: 3908, amt: 2000 },
    { name: "November", uv: 2090, pv: 4800, amt: 2181 },
    { name: "December", uv: 2690, pv: 4300, amt: 2100 },
  ],
  // Add more years as needed
};

export default function ShopDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);

  const [shopDetails, setShopDetails] = useState([]);

  useEffect(() => {
    dispatch(shopDetailsById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && data && data.data) {
      setShopDetails(data.data);

      // console.log(shopDetails);
    }
  }, [loading, data, shopDetails]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="xl:px-4">
        <BreadCrums
          breadCrum={[
            { name: "Shop List", path: "/super-admin/shop-list/" },
            {
              name: "Shop Details",
              path: `/super-admin/shop-list/shop-details/${id}`,
            },
          ]}
        />
      </div>
      <div className="xl:px-4 flex flex-col md:flex-row gap-4 justify-between w-full">
        {/* first chart */}

        <LineChart
          dataYears={dataYears}
          defaultYear="2021"
          chartTitle="Total Earnings"
          totalEarnings="$371,200"
          earningsChange="+12%"
          earningsChangeColor="#15CAB8"
          // boxShadow="0px 10px 10px 0px #AAAAAA0F" // Passing the shadow as a prop
        />

        {/* second chart */}

        <LineChart
          dataYears={dataYears}
          defaultYear="2021"
          chartTitle="Spendings on Boosters"
          totalEarnings="$2,546"
          earningsChange="+12%"
          earningsChangeColor="#15CAB8"
          // boxShadow="0px 10px 10px 0px #AAAAAA0F" // Passing the shadow as a prop
        />
      </div>
      <div className="xl:px-4 mt-6">
        {/* first row */}
        <div className="grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Products listed */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Products Listed
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl md:text-6xl font-bold">
                {shopDetails && shopDetails.product_count}
              </div>
              <Link
                to={`/super-admin/shop-list/shop-details/vendor-product-list/${id}`}
              >
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
            </div>
          </div>

          {/* affiliate products */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Affiliate Products
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl md:text-6xl font-bold">
                {shopDetails && shopDetails.affiliate_product_count}
              </div>
              <Link
                to={`/super-admin/shop-list/vendor-affilliate-product-list/${id}`}
              >
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
            </div>
          </div>

          {/* Orders Recived */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Orders Recived
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl md:text-6xl font-bold">
                {shopDetails && shopDetails.completed_order_count}
              </div>
              <Link to={`/super-admin/shop-list/vendor-order-list/${id}`}>
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
            </div>
          </div>

          {/* Products Returned */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Products Returned
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl md:text-6xl font-bold">
                {shopDetails && shopDetails.returned_order_count}
              </div>
              <Link to={`/super-admin/shop-list/products-returnd/${id}`}>
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
            </div>
          </div>

          {/* Total Reviews */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Total Reviews
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl md:text-6xl font-bold">
                {shopDetails && shopDetails.review_count}
              </div>
              <Link to={`/super-admin/shop-list/products-reviews/${id}`}>
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
            </div>
          </div>

          {/* Bundles */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Bundles
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl  md:text-6xl font-bold">{shopDetails && shopDetails.bundle_count}</div>
              <div>
              <Link to="">
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
              </div>
            </div>
          </div>

          {/* Reels Uploaded */}
          <div
            className="p-4 md:p-7 w-full xl:w-[15rem] rounded-3xl flex flex-col justify-between gap-6"
            style={{ boxShadow: "0px 5.95px 35.67px 0px #AAAAAA0F" }}
          >
            <div className="text-sm md:text-lg font-bold tracking-wide">
              Reels Uploaded
            </div>
            <div className=" flex justify-between items-center">
              <div className="text-3xl md:text-6xl font-bold">{shopDetails && shopDetails.reels_count}</div>
              <div>
              <Link to="">
                <img
                  src="/assets/icons/forward-page.svg"
                  alt=""
                  className="w-8 h-8 md:w-10 md:h-10"
                />
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
