import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  FetchAdminProductBooster,
  productBoosterSearch,
} from "../../../store/adminSlice";
import moment from "moment";
import { imagePath } from "../../../functions/URLString";

export default function BoosterProductRequests() {
  const [activeRole, setActiveRole] = useState("");
  const [search, setSearch] = useState("");
  const [requestsTwo, setRequestsTwo] = useState([]);
  const productBoosterList = useSelector((state) => state.admin.data);
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (productBoosterList.pagination && productBoosterList?.pagination.total) {
      let totalLength = productBoosterList.pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
    setRequestsTwo(productBoosterList);
  }, [productBoosterList]);

  useEffect(() => {
    dispatch(FetchAdminProductBooster({ status: activeRole }));
    setCurrentPage(1);
  }, [activeRole]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(FetchAdminProductBooster({ status: activeRole, page }));
    }
  };

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }
    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            disabled={loading ? true : false}
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      {/* search */}
      <div className="flex justify-between gap-5">
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-xs md:text-sm,"
            type="search"
            placeholder="Search..."
            value={search}
            onChange={(e) => {
              dispatch(productBoosterSearch(e.target.value));
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>

      {/* filter  */}
      <div className="flex gap-1 md:gap-6 text-[9px] md:text-sm">
        <NavLink
          onClick={() => {
            handleRoleChange("");
          }}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          All Boosters
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {requestsTwo?.data &&
              requestsTwo?.boostersPendingCount +
                requestsTwo?.boostersApprovedCount +
                requestsTwo?.boostersRejectedCount +
                requestsTwo?.boostersPausedCount}
            {/* {requestsTwo?.pagination?.total} */}
          </span>
        </NavLink>

        <NavLink
          onClick={() => handleRoleChange("pending")}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "pending" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          Pending
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "pending" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {requestsTwo?.data && requestsTwo?.boostersPendingCount}
          </span>
        </NavLink>

        <NavLink
          onClick={() => handleRoleChange("active")}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "active" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          Approved
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "active" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {requestsTwo?.data && requestsTwo?.boostersApprovedCount}
          </span>
        </NavLink>

        <NavLink
          onClick={() => handleRoleChange("rejected")}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "rejected"
              ? "text-[#C19A6B]"
              : "hover:text-[#C19A6B]"
          }`}
        >
          Rejected
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "rejected"
                ? "border-[#C19A6B]"
                : "border-[#EBF0ED]"
            }`}
          >
            {requestsTwo?.data && requestsTwo?.boostersRejectedCount}
          </span>
        </NavLink>

        <NavLink
          onClick={() => handleRoleChange("paused")}
          className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
            activeRole === "paused" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          Paused
          <span
            className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg ${
              activeRole === "paused" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            {requestsTwo?.data && requestsTwo?.boostersPausedCount}
          </span>
        </NavLink>
      </div>

      {/* table */}

      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[14%] text-left">Vendors</span>

              <span className=" w-[12%] text-left">Category</span>

              <span className=" w-[12%] text-left">Booster Location</span>

              <span className=" w-[12%] text-left">Booster Price</span>

              <span className=" w-[25%] text-left ">Booster Up Time</span>

              <span className=" w-[12%] text-left ">Status</span>

              <span className="  w-[12%] text-left ">ACTION</span>
            </div>

            {/* Table Data Row */}
            {requestsTwo?.data?.length > 0 ? (
              requestsTwo.data.map((product) => (
                <div
                  key={product.id}
                  className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                >
                  <div className="w-[14%]  flex items-center gap-2">
                    {" "}
                    <span className=" ">
                      <img
                        src={product.shop_picture}
                        alt=""
                        className="w-6 h-6 md:w-10 md:h-10 rounded-full"
                      />
                    </span>
                    <span className=" font-semibold text-[#18120F]  ">
                      {product.name}
                    </span>
                  </div>

                  <span className="w-[12%]">{product.category_name}</span>

                  <span className="w-[12%]">
                    {product.country_name + " " + product.city_name}
                  </span>

                  <span className="w-[12%]">${product.price}</span>

                  <div className="w-[25%] flex gap-2 items-center">
                    <span className="text-[#15CA32] font-medium  border border-[#15CA32] bg-[#F6FFF7] py-[3px] px-2 rounded-[10px]">
                      {moment(product.start_date).format("DD MMMM YYYY")}
                    </span>{" "}
                    to{" "}
                    <span className="text-[#E2362F] font-medium  border border-[#E2362F] bg-[#FFF3F4] py-[3px] px-2 rounded-[10px]">
                      {moment(product.end_date).format("DD MMMM YYYY")}
                    </span>
                  </div>

                  <div className="w-[12%]">
                    <span
                      className={` font-medium py-1 md:py-2 px-2 md:px-3 rounded-lg ${
                        product.status === "1"
                          ? "text-[#15CA32] bg-[#F6FFF7] border border-[#15CA32]"
                          : product.status === "0"
                          ? "text-[#FEC600] bg-[#FFFBF0] border border-[#FEC600]"
                          : product.status === "Paused"
                          ? "text-[#44A6E9] bg-[#F1F7FF] border border-[#44A6E9]"
                          : "text-[#E2362F] bg-[#FFF3F4] border border-[#E2362F]"
                      }`}
                    >
                      {product.status === "1"
                        ? "Active"
                        : product.status === "0"
                        ? "Pending"
                        : "Rejected"}
                    </span>
                  </div>

                  <div className="flex items-center gap-3 w-[12%] text-left">
                    <Link
                      to={
                        "/super-admin/product-boosters/booster-details/" +
                        product.id
                      }
                    >
                      <img
                        src="/assets/icons/manage-products/view.svg"
                        alt=""
                        className="w-7 h-7 md:w-9 md:h-9"
                      />
                    </Link>
                    <Link to="/super-admin/product-boosters/booster-product-details">
                      <img
                        src="/assets/icons/view-details.svg"
                        alt=""
                        className="w-7 h-7 md:w-9 md:h-9"
                      />
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <tr>
                <td>
                  <p>No Result Found</p>
                </td>
              </tr>
            )}
          </div>
        </div>
      </div>
      {productBoosterList?.data && search === "" ? (
        <div className="flex justify-between items-center my-4">
          <span className="text-sm text-[#6B6B6B] font-semibold">
            {/* SHOWING {data.from +"-"+ data.to} OF {data.total}  */}
          </span>
          <div className="flex items-center justify-between w-fit gap-2 h-[40px] bg-white rounded-lg px-2">
            {/* Previous Button */}
            <div
              className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                currentPage === 1 ? "opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <svg
                className={`${
                  currentPage === 1
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {/* Page Numbers */}
            <div className="flex items-center gap-2">
              {
                <Pagination
                  totalNumberOfPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              }
            </div>
            {/* Next Button */}
            <div
              className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                currentPage === totalPages ? "opacity-50" : ""
              }`}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <svg
                className={`${
                  currentPage === totalPages
                    ? "text-gray-400 cursor-not-allowed"
                    : "text-blue-500"
                }`}
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#828282"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
