import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import MallManagmentAllMall from "../../../Components/My-Shop-Components/Mall-Managment-Components/MallManagmentAllMall";

export default function MallManagmentallMallPage() {
  return (
    <>
      <MyShopLayout>
        <MallManagmentAllMall />
      </MyShopLayout>
    </>
  );
}
