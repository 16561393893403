import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import ProductReviews from "../../../../Components/Shop-And-User-List/ShopList/ProductReviews";

export default function ProductReviewsPage() {
  return (
    <>
      <MyShopLayout>
        <ProductReviews />
      </MyShopLayout>
    </>
  );
}
