// login, forgot, otp routes
export const ADMIN_LOGIN = "/admin/login"
export const ADMIN_FORGOT_PASSWORD = "/admin/forgot-password"
export const ADMIN_FORGOT_PASSWORD_VERIFY_OTP = "/verify-otp"
export const ADMIN_PASSWORD_RESET = "/admin/reset-password"


// brands
export const ADMIN_BRANDS_LIST = "/admin/brands"
export const ADMIN_BRANDS_LIST_SEARCH = "/admin/brands-search"
export const ADMIN_BRAND_DELETE = "admin/brands"
export const ADMIN_CREATE_BRAND = "admin/brands"
export const ADMIN_UPDATE_BRAND = "admin/brands"
export const ADMIN_BRAND_BY_ID = "admin/brands"


// User List Routes
export const USER_ORDERS_PRODUCT_RETURN = "admin/users-order-return/";
export const USER_ORDERS_PRODUCT_AFFILIATE = "admin/user-affiliates/";
export const USER_ORDERS_PLACE_DETAILS = "admin/user-order-details/";
export const USER_LISTS_ACTIVE_FILTER = "/userlist"
export const USER_LISTS_DISABLED_FILTER = "/user-toadmin-disabled-list"
export const USER_ORDERS_PLACED_SEARCH = "admin/user-order-search"
export const USER_ORDERS_PRODUCT_REVIEW_SEARCH = "admin/user-review-search"
export const ADMIN_PRODUCT_BOOSTER="/admin/product-booster"
export const ADMIN_PRODUCT_BOOSTER_SEARCH="/admin/product-booster-search"
export const FETCH_ADMIN_BOOSTER_PRODUCT="/admin/product-booster/"
export const UPDATE_BOOSTER_STATUS="/admin/product-booster-status"
export const Add_ADMIN_BOOSTER="/admin/product-booster"
export const UPDATE_ADMIN_BOOSTER="/admin/product-booster"
export const FETCH_BOOSTER_BY_ADMIN="/admin/product-booster-admin"
export const USER_ORDERS_RETURN_SEARCH = "admin/users-order-return-search"
export const USER_ORDERS_AFFILIATE_PRODUCT_SEARCH = "admin/user-affiliates-search"
export const UPDATE_MALL_LIST = "/update-mall-list";
export const SEARCH_MALL_LIST = "/admin/mall-search";
export const MALL_REQUEST_TO_ADMIN = "/admin/mall-request";
export const MALL_REQUEST_TO_ADMIN_DETAILS = "/admin/mall-request/";
export const MALL_ACCEPT_ADMIN = "/mall-accept-admin";
export const MALL_REJECT_ADMIN = "/mall-reject-admin";
export const HERO_SECTION = "/admin/hero-section";
export const VENDOR_DISABLED = "/vendor-dissable";
export const STORE_LIST = "/admin/mall-stores/";


// offer-list Routes
export const ALL_OFFERS_LIST_SEARCH = "admin/offers-search";
export const ALL_OFFERS_LIST = "admin/offers";
export const ADMIN_OFFER_VIEW = "admin/offers";
export const ALL_SELLER_LIST = "/offer-show-vendor-list/";
export const PENDING_SELLER_LIST = "/offer-show-vendor-pending";
export const APPROVED_SELLER_LIST = "/offer-show-vendor-accept";
export const REJECTED_SELLER_LIST = "/offer-show-vendor-reject";
export const VIEW_SELLER_PRODUCTS = "/admin/offer-vendor-products/";
export const VIEW_SELLER_PRODUCTS_ACCEPT = "/offer-accept-status";
export const VIEW_SELLER_PRODUCTS_REJECT = "/offer-reject-status";
export const OFFER_STATS = "admin/offers-statistics";
export const SELLER_REGISTERED = "admin/offer-sellers/";
export const PRODUCT_REGISTERED = "admin/offer-products/";

// user-list ----- waqas
export const ADMIN_USERS = "/admin/users";
export const ADMIN_USERS_SEARCH = "admin/user-search"
export const ADMIN_USER_BY_ID = "/admin/user-statistics"

//banner
export const BANNER_LIST = "/admin/banners"
export const BANNER_STATUS="/admin/banner-status/"
export const BANNER_STATISTICS="/admin/banner-statistics"



