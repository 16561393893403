import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import AddNewManager from "../../../Components/My-Shop-Components/Role-Managment-Components/AddNewManager";

export default function AddNewManagerPage() {
  return (
    <>
      <MyShopLayout>
        <AddNewManager />
      </MyShopLayout>
    </>
  );
}
