import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearMessage,
  mainCategory,
  premiumProductAdd,
} from "../../../store/adminSlice";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

import moment from "moment";
import { toast } from "react-toastify";
import BreadCrums from "../../Common/BreadCrums";

export default function CreateOffer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories, loading, error, message } = useSelector(
    (state) => state.admin
  );
  const [selectedParentCategory, setSelectedParentCategory] = useState("");
  const [tempSelectedParentCategory, setTempSelectedParentCategory] =
    useState("");
  const [isParentCategoryDropdownOpen, setIsParentCategoryDropdownOpen] =
    useState(false);

  const [title, setTitle] = useState("");
  const [minimumPrice, setMinimumPrice] = useState("");

  // State variables for registration
  const [regstartdate, setRegstartdate] = useState("");
  const [regenddate, setRegenddate] = useState("");
  const [regstarttime, setRegstarttime] = useState("");
  const [regendtime, setRegendtime] = useState("");

  // State variables for live events
  const [livestartdate, setLivestartdate] = useState("");
  const [liveenddate, setLiveenddate] = useState("");
  const [livestarttime, setLivestarttime] = useState("");
  const [liveendtime, setLiveendtime] = useState("");

  useEffect(() => {
    dispatch(mainCategory());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (message === "Premium product created successfully") {
      toast.success(message);
      navigate(-1);

      setRegstartdate("");
      setRegenddate("");
      setRegstarttime("");
      setRegendtime("");
      setLivestartdate("");
      setLiveenddate("");
      setLivestarttime("");
      setLiveendtime("");
      setMinimumPrice("");
      setTitle("");
      setSelectedParentCategory("");
    }
    dispatch(clearMessage());
  }, [message, error, dispatch, navigate]);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters
    setMinimumPrice(value ? `$${value}` : ""); // Add dollar sign if there is a value
  };

  const handleParentCategorySelect = (categoryId, name) => {
    setIsParentCategoryDropdownOpen((prevState) => !prevState);
    setTempSelectedParentCategory(categoryId.toString());
    setSelectedParentCategory(name);
  };

  const toggleParentCategoryDropdown = () => {
    setIsParentCategoryDropdownOpen((prevState) => !prevState);
  };

  const handleDateChange = (date, setStateFunction) => {

    // const date = event.target.value;
    const formattedDate = moment(date).format("DD/MM/YY");
    setStateFunction(date);
  };

  const handleTimeChange = (time, setStateFunction) => {
    // const time = event.target.value; // Get the time string from the event
    const formattedTime = moment(time, "HH:mm").format("h:mm A"); // Format the time for logging purposes
    // console.log("Selected time:", formattedTime);
    setStateFunction(time); // Set the state with the original time string
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      regstartdate === "" ||
      regenddate === "" ||
      regstarttime === "" ||
      regendtime === "" ||
      livestartdate === "" ||
      liveenddate === "" ||
      livestarttime === "" ||
      liveendtime === "" ||
      title === "" ||
      minimumPrice === "" ||
      selectedParentCategory === ""
    ) {
      toast.error("Please fill all fields");
    } else {
      const formData = new FormData();

      formData.append(
        "regstartdate",
        moment(regstartdate).format("YYYY-MM-DD")
      );
      formData.append("regenddate", moment(regenddate).format("YYYY-MM-DD"));
      formData.append(
        "regstarttime",
        moment(regstarttime, "HH:mm").format("h:mm A")
      );
      formData.append(
        "regendtime",
        moment(regendtime, "HH:mm").format("h:mm A")
      );
      formData.append(
        "livestartdate",
        moment(livestartdate).format("YYYY-MM-DD")
      );
      formData.append("liveenddate", moment(liveenddate).format("YYYY-MM-DD"));
      formData.append(
        "livestarttime",
        moment(livestarttime, "HH:mm").format("h:mm A")
      );
      formData.append(
        "liveendtime",
        moment(liveendtime, "HH:mm").format("h:mm A")
      );

      formData.append("title", title);
      formData.append("category_id", tempSelectedParentCategory);
      formData.append("minimum_price", minimumPrice.replace(/[^0-9.]/g, ""));

      dispatch(premiumProductAdd(formData));
    }
  };
  // console.log(regstartdate);
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* Header */}
        <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-0 justify-between">
          <BreadCrums
            breadCrum={[
              {
                name: "Marketing Solutions",
                path: "/super-admin/premium-products",
              },
              {
                name: "Premium Product Category List",
                path: "/super-admin/premium-products",
              },
              {
                name: "Offer",
                path: "/super-admin/premium-products/single-active-offer",
              },
              {
                name: "New Offer",
                path: "/super-admin/premium-products/create-offer",
              },
            ]}
          />
          <div className="flex justify-end">
            <button
              disabled={loading ? true : false}
              onClick={handleSubmit}
              className="text-xs md:text-sm px-5 md:px-9 py-[7px] md:py-[10px] bg-[#C19A6B] text-white rounded-lg"
            >
              Create Offer
            </button>
          </div>
        </div>

        <form action="" onSubmit={handleSubmit} encType="multipart/form-data">
          {/* 1st */}
          <div className="w-full border border-[#EBF0ED] px-2 md:px-4 py-2 md:py-8 rounded-xl">
            <div className="relative flex flex-col gap-2">
              <label
                className="text-sm md:text-lg font-semibold text-[#18120F]"
                // htmlFor="budgetCategory"
              >
                Select Category
              </label>
              <div className="relative w-full">
                <div className="w-full flex justify-between items-center bg-[#FAFAFA]  border border-[#EBF0ED]  rounded-lg ">
                  <input
                    onClick={toggleParentCategoryDropdown}
                    type="text"
                    name="main-category"
                    readOnly
                    value={selectedParentCategory}
                    placeholder="Select Category"
                    className="bg-[#FAFAFA] w-full py-2 md:py-3 px-3   outline-[#EBF0ED] text-xs text-[#6B6B6B] placeholder:text-xs placeholder:text-[#6B6B6B]"
                  />
                  {isParentCategoryDropdownOpen ? (
                    <RiArrowDropUpLine
                      className="!w-8 !h-8 mr-1 cursor-pointer text-[#6B6B6B]"
                      onClick={toggleParentCategoryDropdown}
                    />
                  ) : (
                    <RiArrowDropDownLine
                      className="!w-8 !h-8 mr-1 cursor-pointer text-[#6B6B6B]"
                      onClick={toggleParentCategoryDropdown}
                    />
                  )}
                </div>

                {isParentCategoryDropdownOpen && (
                  <div className="absolute z-10 bg-[#FAFAFA] border border-[#EBF0ED] text-[#828282] rounded-lg mt-2 w-full">
                    {categories &&
                      categories.categories.map((category) => (
                        <div
                          key={category}
                          className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                          onClick={() =>
                            handleParentCategorySelect(
                              category.id,
                              category.name
                            )
                          }
                        >
                          {category.name}
                        </div>
                      ))}
                    <div
                      key={""}
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleParentCategorySelect("None")}
                    >
                      None
                    </div>
                  </div>
                )}
              </div>
            </div>

            <h2 className="text-sm md:text-lg pt-2 font-semibold text-[#18120F]">
              Premium Product
            </h2>

            <div className="w-full flex flex-col md:flex-row items-center gap-2 md:gap-5 ">
              <div className="w-full">
                <label
                  className="text-xs font-[500] text-[#18120F] "
                  htmlFor=""
                >
                  Title
                </label>
                <input
                  className="w-full border outline-none text-xs border-[#EBF0ED] bg-[#FAFAFA] p-2 md:p-3 rounded-lg"
                  type="text"
                  placeholder="title.."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label
                  className="text-xs font-[500] text-[#18120F] "
                  htmlFor=""
                >
                  Minimum Price
                </label>
                <input
                  className="w-full text-xs outline-none border border-[#EBF0ED] bg-[#FAFAFA] p-2 md:p-3 rounded-lg"
                  type="text"
                  placeholder="$100"
                  value={minimumPrice}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* <div className="flex flex-col gap-1 mt-2">
              <h2 className="text-[12px] font-[500] text-[#18120F] ">
                Recurring
              </h2>
              <div className="flex gap-3 items-center">
                <input type="radio" /> Yes
                <input type="radio" /> No
              </div>
            </div> */}
          </div>
          {/* 2nd */}
          <div className="flex flex-col md:flex-row gap-5 my-6">
            <div className="w-full border border-[#EBF0ED] px-2 md:px-4 py-2 md:py-8 rounded-xl">
              <div className="flex flex-col gap-2">
                <h2 className="text-sm md:text-lg font-semibold text-[#18120F]">
                  Offer Registration Time
                </h2>
                <div className="flex w-full   gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Date
                    </h2>
                    <input
                      value={regstartdate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="reg_start_date"
                      onChange={(date) =>
                        handleDateChange(date.target.value, setRegstartdate)
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Time
                    </h2>
                    <input
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      value={regstarttime}
                      onChange={(time) =>
                        handleTimeChange(time.target.value, setRegstarttime)
                      }
                      type="time"
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Date
                    </h2>
                    <input
                      value={regenddate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="reg_start_date"
                      onChange={(date) =>
                        handleDateChange(date.target.value, setRegenddate)
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Time
                    </h2>
                    <input
                      value={regendtime}
                      onChange={(time) =>
                        handleTimeChange(time.target.value, setRegendtime)
                      }
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="reg_end_time"
                      type="time"
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full border border-[#EBF0ED] px-2 md:px-4 py-2 md:py-8 rounded-xl">
              <div className="flex flex-col gap-2">
                <h2 className="text-sm md:text-lg font-semibold text-[#18120F]">
                  Offer Live Time
                </h2>
                <div className="flex w-full   gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Date
                    </h2>
                    <input
                      value={livestartdate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_start_date"
                      onChange={(date) =>
                        handleDateChange(date.target.value, setLivestartdate)
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      Start Time
                    </h2>
                    <input
                      value={livestarttime}
                      onChange={(time) =>
                        handleTimeChange(time.target.value, setLivestarttime)
                      }
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_start_time"
                      type="time"
                      id=""
                    />
                  </div>
                </div>

                <div className="flex w-full  gap-2">
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Date
                    </h2>
                    <input
                      value={liveenddate}
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_end_date"
                      onChange={(date) =>
                        handleDateChange(date.target.value, setLiveenddate)
                      }
                      type="date"
                      id=""
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-xs font-[500] text-[#18120F] ">
                      End Time
                    </h2>
                    <input
                      value={liveendtime}
                      onChange={(time) =>
                        handleTimeChange(time.target.value, setLiveendtime)
                      }
                      className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                      name="live_end_time"
                      type="time"
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
