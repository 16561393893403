import HeroSectionAllHotspotImagesPage from "../Pages/My-Shop/Hero-Section/HeroSectionAllHotspotImagesPage";

const hotspotRoutes = [

    {
        path: "/super-admin/hotspot-images",
        element: < HeroSectionAllHotspotImagesPage /> ,
    },
  

]

export default hotspotRoutes;