import VendorRequestDetails from "../../../Components/My-Shop-Components/Vender-Requests/VendorRequestDetails";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function VendorRequestDetailsPage() {
  return (
    <>
      <MyShopLayout>
        <VendorRequestDetails />
      </MyShopLayout>
    </>
  );
}
