import CreateBrand from "../../../../Components/My-Shop-Components/Manage-Categories/Brands/CreateBrand";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function CreateBrandsPage() {
  return (
    <MyShopLayout>
      <CreateBrand />
    </MyShopLayout>
  );
}
