import React from "react";
// import { IoIosTrendingUp } from "react-icons/io";
// import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import CategoriesEarningChart from "../../../../Graphs/Categories-Page-Graphs/CategoriesEarningChart";
import BreadCrums from "../../../../Common/BreadCrums";

export default function MainCategoryDetails() {
  // const [summaryValues, setSummaryValues] = useState("Current");
  // // console.log(summaryValues)
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const graphSummaryDropDownValues = ["Current", "Months", "Years"];

  // const toggleDropdown = () => {
  //   setIsDropdownOpen(!isDropdownOpen);
  // };

  // const handleSelect = (value) => {
  //   setSummaryValues(value);
  //   setIsDropdownOpen(false);
  // };

  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Main Category",
            path: "/super-admin/main-categories/",
          },
          {
            name: "Main Category Details",
            path: "/super-admin/main-categories/details-category",
          },
        ]}
      />

      {/* graph  */}
      <div className="w-full flex flex-col md:flex-row gap-8">
        {/* graph  */}
        <CategoriesEarningChart />

        {/* listed product and categories  */}
        <div className="flex justify-between md:justify-start md:flex-col gap-4 w-full md:w-1/3">
          <div className="flex flex-col gap-1 text-left p-4 md:p-7 shadow-sm rounded-lg">
            <span className="font-semibold text-xs text-nowrap md:text-xl">
              Total Products Listed
            </span>
            <span className="font-bold text-5xl md:text-[76px] leading-[4rem]">
              683
            </span>
          </div>

          <div className="flex flex-col gap-1 text-left p-4 md:p-7 shadow-sm rounded-lg">
            <span className="font-semibold text-xs text-nowrap md:text-xl">
              Total Sub-Categories
            </span>
            <span className="font-bold text-5xl md:text-[76px] leading-[4rem]">
              6
            </span>
          </div>
        </div>
      </div>

      {/* top product table  */}
      <div className=" flex flex-col gap-4 px-4 md:px-8 py-4  shadow-md rounded-lg ">
        <p className="font-semibold text-xl">Top Products</p>
        <div className=" xl:px-4 flex justify-center  overflow-auto">
          <div className="bg-white w-full rounded-lg  overflow-hidden overflow-x-auto">
            <div className="min-w-[668px] md:w-full">
              {/* table head  */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm  text-[#6B6B6B] font-semibold justify-between uppercase">
                <span className="text-left w-2/5">Product</span>

                <span className="w-20 text-left">Sold</span>
                <span className="w-24 text-left">Price</span>
                <span className="w-28 text-left">Profit</span>
              </div>

              {/* body  */}

              <div className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 ">
                <span className=" w-2/5 flex gap-6 items-center text-left">
                  <span>
                    <img
                      src="/assets/images/living-room.png"
                      alt=""
                      className="w-11 h-11 rounded-lg"
                    />
                  </span>
                  <span className="flex flex-col gap-1">
                    <p className="font-semibold text-xs md:text-base">
                      Category Name
                    </p>
                    <p className="font-semibold text-xs md:text-base text-[#6B6B6B]">
                      Category: Cloths
                    </p>
                  </span>
                </span>
                <span className="w-20 text-left">10</span>
                <span className="w-24 text-left">$123</span>
                <span className="w-28 text-left">$1234</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
