import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import UserList from "../../../../Components/Shop-And-User-List/User-List/UserList";

export default function UserListPage() {
  return (
    <MyShopLayout>
      <UserList />
    </MyShopLayout>
  );
}
