import React from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ProtectedRoute from "../ProtectedRoute";

export default function Root() {
  return (
    <>
      <main>
        <div className="flex w-full flex-col">
        <ToastContainer
          theme='dark'
        />

          <Outlet />

        </div>
      </main>
    </>
  );
}
