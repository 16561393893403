import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import OfferEarningCharts from "../../Graphs/Offer-Page-Graph/OfferStatsBarChart";
import BreadCrums from "../../Common/BreadCrums";
import { offerStats } from "../../../store/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";

export default function OfferStats() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(offerStats(id));
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-8 mb-10">
        <BreadCrums
          breadCrum={[
            {
              name: "Offer",
              path: "/super-admin/offer-managment/",
            },
            {
              name: "Offer Stats",
            },
          ]}
        />

        {/* graph */}
        <div className=" w-full">
          <OfferEarningCharts />
        </div>
        {/* buttons */}
        <div className="flex justify-between md:justify-start gap-2 md:gap-8">
          <div className="p-2 md:p-7 rounded-lg shadow-md flex flex-col justify-between">
            <h2 className="font-semibold text-xs md:text-[19px] text-nowrap text-[#18120F]">
              Sellers Registered
            </h2>
            <div className="flex items-center justify-between md:min-w-[200px]">
              <span className="text-[#18120F] font-bold text-[30px] md:text-[76px] ">
                {!loading && data?.Sellers_Registered}
              </span>
              <Link
                to={`/super-admin/offer-managment/sellers-registered/${id}`}
                className="p-2 md:p-[11px] rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img
                  className="w-4 h-4 md:w-5 md:h-5"
                  src="/assets/icons/forward1.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="p-2 md:p-7 rounded-lg shadow-md flex flex-col justify-between">
            <h2 className="font-semibold text-xs md:text-[19px] text-nowrap text-[#18120F]">
              Products Registered
            </h2>
            <div className="flex items-center justify-between md:min-w-[200px] max-w-full">
              <span className="text-[#18120F] font-bold text-[30px] md:text-[76px] ">
                {!loading && data?.Products_Registered}
              </span>
              <Link
                to={`/super-admin/offer-managment/products-registered/${id}`}
                className="p-2 md:p-[11px] rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img
                  className="w-4 h-4 md:w-5 md:h-5"
                  src="/assets/icons/forward1.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="p-2 md:p-7 rounded-lg shadow-md flex flex-col justify-between">
            <h2 className="font-semibold text-xs md:text-[19px] text-nowrap text-[#18120F]">
              Products Sold
            </h2>
            <div className="flex items-center justify-between md:min-w-[200px]">
              <span className="text-[#18120F] font-bold text-[30px] md:text-[76px] ">
                {!loading && data?.Products_Sold}
              </span>
            </div>
          </div>
        </div>
        {/* cards */}
        <div className="flex flex-col md:flex-row gap-[25px]">
          <div className="w-full flex flex-col gap-4 p-2 md:px-8 md:py-4 shadow-md rounded-lg">
            <p className="font-semibold text-sm md:text-xl">Top Products</p>
            <table className="w-full">
              <thead>
                <tr className="flex w-full justify-between items-center text-xs md:text-base   gap-4  p-1  font-bold  text-black">
                  <th className="text-left w-3/5">Product</th>
                  <th className="w-14 text-left">Sold</th>
                  <th className="w-14 text-left">Price</th>
                  <th className="w-14 text-left">Profit</th>
                </tr>
              </thead>
              {/* top products  */}
              <tbody className="w-full">
                {!loading &&
                  data?.Top_Orders?.map((item) => (
                    <tr className="flex w-full justify-between items-center p-1 gap-2 font-semibold text-sm">
                      <td className=" w-3/5 flex  gap-1 md:gap-6 items-center text-left">
                        <span>
                          <img
                            src={item.product_image}
                            alt=""
                            className="w-7 h-7 md:w-11 md:h-11 rounded-lg"
                          />
                        </span>
                        <span className="flex flex-col ">
                          <p className="font-semibold text-xs md:text-base">
                            {item.product_name}
                          </p>
                          <p className="font-semibold text-xs md:text-base text-[#6B6B6B]">
                            Category: {item.category_name}
                          </p>
                        </span>
                      </td>
                      <td className="w-14 text-left">{item.quantity}</td>
                      <td className="w-14 text-left">${item.amount}</td>
                      <td className="w-14 text-left">${item.Profit}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {/* 2nd card */}
          <div className="w-full flex flex-col gap-4 p-2 md:px-8 md:py-4 shadow-md rounded-lg">
            <p className="font-semibold text-sm md:text-xl">Top Sellers</p>
            <table className="w-full">
              <thead>
                <tr className="flex w-full justify-between items-center   gap-4  p-1  font-bold text-xs md:text-base text-black">
                  <th className="text-left w-3/5">Seller</th>
                  <th className="w-20 text-left text-nowrap">Product Sold</th>
                  <th className="w-14 text-left">Profit</th>
                </tr>
              </thead>
              {/* top products  */}
              <tbody className="w-full">
                {!loading &&
                  data?.Top_Sellers?.map((item) => (
                    <tr className="flex w-full justify-between items-center p-1 gap-2 font-semibold text-sm">
                      <td className=" w-3/5 flex gap-1 md:gap-6 items-center text-left">
                        <span>
                          <img
                            src={item.image}
                            alt=""
                            className="w-7 h-7 md:w-11 md:h-11 rounded-lg"
                          />
                        </span>
                        <p className="font-semibold text-xs md:text-base">
                          {item.vendor_name}
                        </p>
                      </td>
                      <td className="w-20 text-left">{item.product_sold}</td>
                      <td className="w-14 text-left">${item.profit}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
