import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  clearMessage,
  sellerOfferProductsAccept,
  sellerOfferProductsReject,
  sellerOfferProductsView,
} from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import { imagePath } from "../../../functions/URLString";
import { toast } from "react-toastify";

const OfferManagmentViewProduct = () => {
  const { id } = useParams();
  // Inside the component
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get("status");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading, error, message } = useSelector((state) => state.admin);
  const [sellerProducts, setSellerProducts] = useState([]);

  // pagination-state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  // pagination Function components
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    // console.log(totalNumberOfPages);
    const pages = [];

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalNumberOfPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(totalNumberOfPages, startPage + 2);
      } else if (endPage === totalNumberOfPages) {
        startPage = Math.max(1, endPage - 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 rounded ${
            currentPage === i
              ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
              : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
          }`}
        >
          {i}
        </button>
      );
    }
    // console.log(pages);
    return <div className="pagination flex gap-2">{pages}</div>;
  }

  // pagination data handling + handle page change
  useEffect(() => {
    if (data?.pagination && data?.pagination?.pagination?.total) {
      let totalLength = data?.pagination.pagination.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(sellerOfferProductsView({ arg1: "page", arg2: page, arg3: id }));
    }
  };

  // pagination index variable
  const totalOffersCount = data?.pagination?.pagination?.total || 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalOffersCount);

  useEffect(() => {
    dispatch(sellerOfferProductsView(id));
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data && data.products) {
      let seller_products_list = data.products;

      setSellerProducts(seller_products_list);
    }
  }, [data, loading]);

  const handleAccept = () => {
    const offerId = localStorage.getItem("offer_id");
    dispatch(sellerOfferProductsAccept({ offer_id: offerId, vendor_id: id }));
  };
  const handleReject = () => {
    const offerId = localStorage.getItem("offer_id");
    dispatch(sellerOfferProductsReject({ offer_id: offerId, vendor_id: id }));
  };

  // handling messages and navigation
  useEffect(() => {
    if (message) {
      if (
        message === "Offer accepted successfully" ||
        message === "Offer rejected successfully"
      ) {
        toast.success(message);
        navigate("/super-admin/offer-managment/");
      }
    }

    if (error) {
      // Check if error is an object with nested error structure
      if (typeof error === "object" && Object.keys(error).length > 0) {
        const firstKey = Object.values(error)[0];
        if (Array.isArray(firstKey) && firstKey.length > 0) {
          toast.error(firstKey[0]);
        } else {
          toast.error("An unknown error occurred.");
        }
      }
      // Check if error is a string message
      else if (typeof error === "string") {
        toast.error(error);
      }
      // Handle other cases if necessary
      else {
        toast.error("An unknown error occurred.");
      }
    }
    dispatch(clearMessage());
  }, [error, message, loading]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* Header */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              { name: "Offers", path: "/super-admin/offer-managment/" },
              { name: "View Products" },
            ]}
          />
          {!loading && data && data.products?.length > 0 && (
            <div className="flex gap-2">
              {status == 0 && (
                <>
                  <button
                    onClick={handleReject}
                    className="px-5 md:px-9 py-2 md:py-3 flex items-center justify-center rounded-lg text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
                  >
                    Reject
                  </button>
                  <button
                    onClick={handleAccept}
                    className="px-5 md:px-9 py-2 md:py-3 flex items-center justify-center rounded-lg text-white bg-[#C19A6B] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
                  >
                    Approve
                  </button>
                </>
              )}
              {/* {status === "approved" && (
                <button
                  onClick={handleReject}
                  className="px-5 md:px-9 py-2 md:py-3 flex items-center justify-center rounded-lg text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
                >
                  Reject
                </button>
              )}
              {status === "rejected" && (
                <button
                  onClick={handleAccept}
                  className="px-5 md:px-9 py-2 md:py-3 flex items-center justify-center rounded-lg text-white bg-[#C19A6B] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
                >
                  Approve
                </button>
              )} */}
            </div>
          )}
        </div>

        {/* table */}
        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[20%] text-left">PRODUCTS</span>

                <span className="w-[20%] text-left">STOCK</span>
                <span className="w-[20%] text-left">CATEGORY</span>

                <span className="w-[20%] text-left">CURRENT PRICE</span>
                <span className="w-[20%] text-left">OFFER PRICE</span>
              </div>

              {/* Table Data Row */}
              {loading ? (
                <div className="flex justify-center py-4">
                  <Loader />
                </div>
              ) : sellerProducts.length > 0 ? (
                sellerProducts.map((item) => {
                  return (
                    <>
                      <div className="flex text-xs md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b">
                        <div className="w-[20%] text-left">
                          <img
                            src={item.image}
                            alt="Product"
                            className="w-7 h-7 md:w-10 md:h-10 rounded-lg mr-4"
                          />
                          <div>
                            <p className="text-sm tracking-wide pb-1  text-[#18120F] font-semibold ">
                              {item.name}
                            </p>
                          </div>
                        </div>

                        <span className="w-[20%] text-left">
                          {item.quantity}
                        </span>
                        <span className="w-[20%] text-left">
                          {item.category_name}
                        </span>

                        <span className="w-[20%] text-left">${item.price}</span>
                        <span className="w-[20%] text-left">
                          ${item.offer_price}
                        </span>
                      </div>
                    </>
                  );
                })
              ) : (
                <tr className="text-center font-bold tracking-wide py-4">
                  <td colSpan={6}>No Seller Products Founds</td>
                </tr>
              )}
            </div>
          </div>
        </div>

        {/* pagination */}
        {/* Pagination start here */}
        {!loading && data && data.products?.length > 10 ? (
          <>
            <div className="flex justify-between items-center my-4">
              <span className="text-sm text-[#6B6B6B] font-semibold">
                SHOWING {startIndex + 1}-{endIndex} OF {totalOffersCount}{" "}
                RESULTS
              </span>
              <div className="flex items-center gap-3 bg-white rounded-lg px-2">
                {/* Previous Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <svg
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Page Numbers */}
                <div className="flex items-center gap-2">
                  <Pagination
                    totalNumberOfPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </div>
                {/* Next Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === totalPages ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <svg
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* Pagination end here */}
      </div>
    </>
  );
};

export default OfferManagmentViewProduct;
