import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  vendorAffiliateProductList,
  vendorAffiliateProductListSearch,
} from "../../../store/adminSlice";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";

const VendorAffiliateProductList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.admin);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(vendorAffiliateProductList(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (search != "") {
      dispatch(vendorAffiliateProductListSearch({ id: id, search: search }));
    } else {
      dispatch(vendorAffiliateProductList(id));
    }
  }, [search, dispatch, id]);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            { name: "Shop List", path: "/super-admin/shop-list/" },
            {
              name: "Shop Details",
              path: -1,
            },
            {
              name: " Vendor Affiliate Product List",
              path: `/super-admin/shop-list/vendor-affilliate-product-list/${id}`,
            },
          ]}
        />

        {/* search */}
        <div className="relative flex items-center w-full">
          <img
            className="absolute left-3"
            src="/assets/icons/search.svg"
            alt=""
          />

          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs"
            type="search"
            placeholder="Search Products..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}

              <div className=" flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                {" "}
                <span className="w-[25%] text-left">PRODUCT </span>
                <span className="w-[15%] text-left">PRICE</span>
                <span className="w-[15%] text-left">COMMISSION</span>
                <span className="w-[15%] text-left">STOCK</span>
                <span className="w-[15%] text-left">TOTAL EARNING</span>
                <span className="w-[15%] text-left">ACTION</span>
              </div>

              {/* Table Body */}
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.data?.affiliate_products &&
                data.data.affiliate_products.length > 0 ? (
                data.data.affiliate_products.map((prodct) => (
                  <div
                    key={prodct.product_id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="mt-2  flex gap-3 font-[600] pl-6 w-[25%] text-left">
                      <img
                        src={prodct.image}
                        className="w-10 h-10 text-center rounded-lg"
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span>{prodct.name}</span>
                        <span className="text-[#6B6B6B] text-sm font-[500]">
                          Category: {prodct.category_name}
                        </span>
                      </div>
                    </div>
                    <span className=" w-[15%] text-left">${prodct.price}</span>
                    <div className=" w-[15%] text-left">
                      <span>
                        ${prodct.commission === null ? "0" : prodct.commission}
                      </span>
                    </div>
                    <span className=" w-[15%] text-left">
                      {prodct.quantity}
                    </span>

                    <span className=" w-[15%] text-left">
                      ${prodct.total_earning}
                    </span>
                    <div className="w-[15%] text-left">
                      <div className="flex gap-2 justify-center">
                        <Link
                          // to="/offer-managment/view-product"
                          className="bg-[#FAFAFA] flex items-center justify-center rounded-xl p-2 border border-[#EBF0ED]"
                        >
                          <img src="/assets/icons/view-b.svg" alt="" />
                        </Link>

                        {/* <div className="bg-[#FAFAFA] rounded-xl p-2 border border-[#EBF0ED]">

                          <img src="/assets/icons/delete-b.svg" alt="" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                "No Affiliate Products found."
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorAffiliateProductList;
