import EditManager from "../../../Components/My-Shop-Components/Role-Managment-Components/EditManager";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function EditManagerPage() {
  return (
    <>
      <MyShopLayout>
        <EditManager />
      </MyShopLayout>
    </>
  );
}
