import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

import OfferManagmentOffers from "../../../Components/My-Shop-Components/Offer-Managment-Components/OfferManagmentOffers";

export default function OfferManagmentOffersPage() {
  return (
    <>
      <MyShopLayout>
        <OfferManagmentOffers />
      </MyShopLayout>
    </>
  );
}
