import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import PremiumOffers from "../../../Components/My-Shop-Components/PremiumProducts-Components/PremiumOffers";

export default function PremiumOffersPage() {
  return (
    <>
      <MyShopLayout>
        <PremiumOffers />
      </MyShopLayout>
    </>
  );
}
