import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import ProductsReturnd from "../../../../Components/Shop-And-User-List/ShopList/ProductsReturnd";

export default function ProductsReturndPage() {
  return (
    <>
      <MyShopLayout>
        <ProductsReturnd />
      </MyShopLayout>
    </>
  );
}
