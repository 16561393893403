import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { loginUser, clearMessage, resetSignout } from "../store/adminSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, status_code } = useSelector(
    (state) => state.admin
  );

  const [passwordVisible, setPasswordVisible] = useState(false);
  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email === "" && password === "") {
      toast.error("Please enter email and password");
    } else if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address");
    } else {
      dispatch(loginUser({ email, password }));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);
        if (status_code === 200) {
          navigate("/super-admin/dashboard");
        }
      }
      if (error) {
        toast.error(error);
      }
      dispatch(clearMessage());
    }
    dispatch(resetSignout());
  }, [loading, message, error, dispatch, navigate, status_code]);

  // if someone come to the login page we are clearing it existing token and other details
  useEffect(() => {
    localStorage.removeItem("cookies");
    localStorage.removeItem("token");
  }, []);

  return (
    <div className=" h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Login to Account
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Login to your Wizalot account!
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <input
              type="email"
              placeholder="mike.tyson@mail.com"
              className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <div className="flex flex-col gap-1 mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"

                placeholder="********"

                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </div>
          <div className="flex justify-end mt-2 ">
            <Link
              className="text-[#C19A6B] text-sm md:text-base"
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </div>

          <button
            disabled={loading}
            onClick={handleLogin}
            className="bg-[#C19A6B] w-full rounded-lg py-3 h-12 md:p-4 mt-[2rem] text-white font-bold tracking-wider flex justify-center items-center"
          >
            {!loading ? (
              "Login"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
