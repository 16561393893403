import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  bannerStatusChange,
  clearMessage,
  fetchBanner,
} from "../../../store/adminSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { imagePath } from "../../../functions/URLString";

export default function BannerAdSingleRequest() {
  const { id } = useParams();
  const { loading, message, error, data } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchBanner({ id }));
  }, []);
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearMessage());
      navigate("/super-admin/banner-ads");
    } else if (error) {
      toast.error(error);
    }
  }, [message, error, data]);
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-3">
        {/* breadcrumbs */}
        <div className="flex flex-col gap-2 md:gap-0 md:flex-row md:items-center justify-between">
          <BreadCrums
            breadCrum={[
              { name: "Marketing Solutions", path: "/super-admin/banner-ads" },
              {
                name: "Banner Ad Request",
                path: "/super-admin/banner-ads",
              },
              {
                name: "Banner Details",
                path: "/super-admin/banner-ads/banner-ad-single-request",
              },
            ]}
          />

          <div className="flex justify-end items-center gap-5">
            <Link
              // to="/super-admin/premium-products/create-offer"
              onClick={(e) =>
                dispatch(bannerStatusChange({ id, status: "reject" }))
              }
              className="text-xs md:text-sm px-5 md:px-9 py-[7px] md:py-[10px] bg-[#FAFAFA] text-[#828282] border border-[#EBF0ED] rounded-lg"
            >
              Reject
            </Link>
            <Link
              onClick={(e) =>
                dispatch(bannerStatusChange({ id, status: "approve" }))
              }
              className="text-xs md:text-sm px-5 md:px-9 py-[7px] md:py-[10px] bg-[#C19A6B] text-white rounded-lg"
            >
              Approve
            </Link>
          </div>
        </div>
        {/* hero */}
        <div className="border flex flex-col md:flex-row gap-2 border-[#EBF0ED] rounded-xl px-4 py-4 md:py-8 md:mt-4">
          <div className="w-full items-center md:items-start">
            <img src={imagePath + data?.vendorsBooster?.image} alt="" />
          </div>
          <div className="w-full flex items-center md:items-start flex-col gap-1 md:gap-2 py-2 px-2 md:px-4">
            <div className="flex flex-col md:gap-2">
              <h2 className="font-semibold text-sm md:text-lg">
                Bussiness Name
              </h2>
              <span className="text-xs md:text-sm">
                {data?.vendorsBooster?.name}
              </span>
            </div>
            <div className="flex flex-col md:gap-2">
              <h2 className="font-semibold text-sm md:text-lg">
                Bussiness Location
              </h2>
              <span className="text-xs md:text-sm">
                {data?.vendorsBooster?.name}
              </span>
            </div>
            <div className="flex flex-col md:gap-2">
              <h2 className="font-semibold text-sm md:text-lg">Link</h2>
              <a className="underline text-xs md:text-sm text-blue-600">
                {data?.vendorsBooster?.link}
              </a>
            </div>
          </div>
          <div className="w-full flex items-center justify-center md:justify-start pl-5 md:border-l md:border-l-[#EBF0ED]">
            <div className="flex flex-col  gap-2">
              <div className="flex flex-col gap-2">
                <h2 className="font-semibold text-center md:text-start">
                  Banner Up Time
                </h2>
                <div className="md:mr-7  flex gap-2 justify-center  ">
                  <span className="bg-[#F6FFF7] text-xs md:text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl">
                    {moment(
                      data?.vendorsBooster?.start_date,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("D MMMM YYYY")}
                  </span>
                  <span>to</span>
                  <span className="bg-[#FFF3F4] text-xs md:text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl">
                    {moment(
                      data?.vendorsBooster?.end_date,
                      "YYYY-MM-DD HH:mm:ss"
                    ).format("D MMMM YYYY")}
                  </span>
                </div>
              </div>
              <div className="flex items-center md:items-start justify-center md:flex-col gap-1 md:py-2 ">
                <h2 className="font-semibold">Price</h2>
                <span className="w-[48px] border rounded-xl text-xs md:text-sm px-[3px] py-[3px] md:py-2 text-[#828282] bg-[#FAFAFA] border-[#EBF0ED]">
                  $ {data?.vendorsBooster?.price}
                </span>
              </div>
              <div className="flex items-center md:items-start justify-center md:flex-col gap-2">
                <h2 className="font-semibold text-sm md:text-lg">
                  Target Location
                </h2>
                <span className="text-xs md:text-sm">
                  {" "}
                  {data?.vendorsBooster?.ad_country_id +
                    ", " +
                    data?.vendorsBooster?.ad_city_id}
                </span>
              </div>
              <div className="flex items-center md:items-start  justify-center md:justify-start md:flex-col gap-2">
                <h2 className="font-semibold text-sm md:text-lg">
                  {data?.vendorsBooster?.display_Onhome == 1 ? "Home" : ""}
                </h2>
                <span className="text-xs md:text-sm">
                  {data?.vendorsBooster?.display_Onhome == 1 ? "Home" : ""}
                  {data?.vendorsBooster?.sub_category_id}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
