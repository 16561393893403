import AuctionUpdateCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Auction-Categories/Main-Categories/AuctionUpdateCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AuctionUpdateCategoryPage() {
  return (
    <MyShopLayout>
      <AuctionUpdateCategory />
    </MyShopLayout>
  );
}
