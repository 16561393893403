import SuperAdminAdressesPage from "../Pages/My-Shop/Super-Admin-Profile-page/SuperAdminAdressesPage";
import SuperAdminInformationPage from "../Pages/My-Shop/Super-Admin-Profile-page/SuperAdminInformationPage";
import SuperAdminProfileSettingPage from "../Pages/My-Shop/Super-Admin-Profile-page/SuperAdminProfileSettingPage";

const superAdminInfoRoutes = [{

        path: "/super-admin/profile/",
        children: [

            {
                index: true,
                element: < SuperAdminProfileSettingPage /> ,
            },
            {
                path: "/super-admin/profile/adresses",
                element: < SuperAdminAdressesPage /> ,
            },
            {
                path: "/super-admin/profile/information",
                element: < SuperAdminInformationPage /> ,
            },

        ]

    },

]


export default superAdminInfoRoutes;