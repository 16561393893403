import BreadCrums from "../../Common/BreadCrums";
import SingleBannerAdsClicksBarCharts from "../../Graphs/Banner-Ads-Graphs/SingleBannerAdsClicksBarChart";
import SingleBannerAdsImpressionBarCharts from "../../Graphs/Banner-Ads-Graphs/SingleBannerAdsImpressionBarChart";

export default function SinleBannerAdDetails() {
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          { name: "Marketing Solutions", path: "/super-admin/banner-ads" },
          {
            name: "Banner Ad",
            path: "/super-admin/banner-ads",
          },
          {
            name: "Banner Ads Details",
            path: "/super-admin/banner-ads/single-banner-ad-details",
          },
        ]}
      />

      {/* graph  */}
      <div className="w-full flex flex-col md:flex-row gap-8">
        {/* graph  */}
        <SingleBannerAdsImpressionBarCharts />
        <SingleBannerAdsClicksBarCharts />
      </div>
    </div>
  );
}
