import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { allOffersList, allOffersListSearch } from "../../../store/adminSlice";
import moment from "moment";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";

export default function OfferManagmentOffers() {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.admin);

  localStorage.removeItem("offer_id");

  const [search, setSearch] = useState("");
  const [activeRole, setActiveRole] = useState("All");
  const [offersList, setOffersList] = useState([]);

  // pagination-state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    dispatch(allOffersList({}));
  }, [dispatch]);

  useEffect(() => {
    if (search) {
      dispatch(allOffersListSearch(search));
    } else {
      dispatch(allOffersList({}));
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (!loading && data && data.offers) {
      let offers_list = data.offers;

      setOffersList(offers_list);
    }
  }, [data, loading]);

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };

  // pagination Function components
  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    // console.log(totalNumberOfPages);
    const pages = [];

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-2 py-1 rounded ${
            currentPage === i
              ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
              : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
          }`}
        >
          {i}
        </button>
      );
    }
    // console.log(pages);
    return <div className="pagination flex gap-2">{pages}</div>;
  }

  // pagination data handling + handle page change
  useEffect(() => {
    if (data?.pagination_urls && data?.pagination_urls.total) {
      let totalLength = data?.pagination_urls.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      dispatch(allOffersList({ page: page, status: activeRole }));
    }
  };

  // pagination index varibale
  const totalOffersCount = data?.pagination_urls?.total || 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalOffersCount);

  return (
    <>
      <div className="md:px-4 flex flex-col gap-4 ">
        {/* Header */}

        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              {
                name: "Marketing Solutions",
                path: "/super-admin/offer-managment/",
              },
              { name: "Offers", path: "/super-admin/offer-managment/" },
            ]}
          />

          {/* <div> */}
          <Link
            className="px-5 py-[7px] md:px-9 md:py-[10px] flex items-center justify-center rounded-lg text-white bg-[#C19A6B] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
            to="/super-admin/offer-managment/create-offer"
          >
            Create Offers
          </Link>
          {/* </div> */}
        </div>
        {/* search */}
        <div>
          <div className="relative flex items-center w-full md:px-2">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-2 md:left-5"
            />
            <input
              className="w-full py-2 md:py-3 px-7 md:px-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
              type="search"
              placeholder="Search Offers by name..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        {/* filter */}

        <div className="flex gap-2 md:gap-6 md:py-5 text-xs md:text-sm">
          <NavLink
            onClick={() => {
              handleRoleChange("All");
              dispatch(allOffersList({}));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ALL OFFERS
            <span
              className={` font-semibold p-1 md:p-[7px]   bg-[#FAFAFA] border   rounded-lg ${
                activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data.counts && data.counts.all_offers
                ? data.counts.all_offers
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              handleRoleChange("active");
              dispatch(allOffersList({ status: "active" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "active"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            ACTIVE
            <span
              className={` font-semibold p-1 md:p-[7px]   bg-[#FAFAFA] border   rounded-lg ${
                activeRole === "active"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data.counts && data.counts.active
                ? data.counts.active
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              handleRoleChange("upcoming");
              dispatch(allOffersList({ status: "upcoming" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "upcoming"
                ? "text-[#C19A6B]"
                : "hover:text-[#C19A6B]"
            }`}
          >
            UPCOMING
            <span
              className={` font-semibold p-1 md:p-[7px]   bg-[#FAFAFA] border   rounded-lg ${
                activeRole === "upcoming"
                  ? "border-[#C19A6B]"
                  : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data.counts && data.counts.upcoming
                ? data.counts.upcoming
                : 0}
              )
            </span>
          </NavLink>
          <NavLink
            onClick={() => {
              handleRoleChange("ended");
              dispatch(allOffersList({ status: "ended" }));
            }}
            className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
              activeRole === "ended" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
            }`}
          >
            ENDED
            <span
              className={`font-semibold p-1 md:p-[7px]   bg-[#FAFAFA] border   rounded-lg ${
                activeRole === "ended" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
              }`}
            >
              (
              {!loading && data && data.counts && data.counts.ended
                ? data.counts.ended
                : 0}
              )
            </span>
          </NavLink>
        </div>
        {/* table */}

        <div className="flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[15%] text-left">OFFERS NAME</span>
                <span className="w-[10%] text-left">DISPLAY</span>
                <span className="w-[10%] text-left">STATUS</span>
                <span className="w-[10%] text-left">OFF%</span>
                <span className="w-[18%] text-left">
                  REGISTRATION CLOSES IN
                </span>
                <span className="w-[18%] text-left">DATE & TIME</span>
                <span className="w-[18%] text-left">ACTION</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : offersList.length > 0 ? (
                offersList.map((item) => {
                  // Convert start and end dates to moment objects
                  const startDate = moment(item.livestartdate);
                  const endDate = moment(item.liveenddate);
                  // Get the current date
                  const currentDate = moment();

                  // Check if current date is between start and end dates
                  const isActive = currentDate.isBetween(startDate, endDate);
                  const isUpcoming = currentDate.isBefore(startDate);
                  const isEnded = currentDate.isAfter(endDate);

                  // Determine status based on current date
                  let status;
                  if (isActive) {
                    status = "Active";
                  } else if (isUpcoming) {
                    status = "Upcoming";
                  } else if (isEnded) {
                    status = "Ended";
                  }
                  return (
                    <div
                      key={item.id}
                      className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                    >
                      <div className="w-[15%] text-left flex gap-2 items-center">
                        <img
                          className="w-7 h-7 md:w-[72px] md:h-[59px] rounded-lg"
                          src={item.image}
                          alt=""
                        />
                        <span> {item.title}</span>
                      </div>

                      <span className="w-[10%] text-left ">
                        {item.page_type}
                      </span>
                      <div className="w-[10%] text-left">
                        <span
                          className={`${
                            status === "Active"
                              ? "bg-[#F6FFF7] text-[8px] md:text-sm px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl"
                              : status === "Upcoming"
                              ? "bg-[#F1F7FF] text-[8px] md:text-sm px-2 py-[0.189rem] border border-[#44A6E9] text-[#44A6E9] rounded-xl"
                              : "bg-[#FFF3F4] text-[8px] md:text-sm px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl"
                          }`}
                        >
                          {status}
                        </span>
                      </div>
                      <span className="w-[10%] text-left ">
                        {item.percentage}
                      </span>
                      <div className="w-[18%] text-left">
                        <span className="text-[8px] md:text-xs px-2 py-[0.189rem] bg-[#FAFAFA] border border-[#EBF0ED] rounded-xl text-[#828282]">
                          {item.registration_status}
                        </span>
                      </div>
                      <div className="w-[18%] flex gap-2 justify-center items-center mt-3">
                        <span className="bg-[#F6FFF7] text-[8px] text-nowrap md:text-xs px-2 py-[0.189rem] border border-[#15CA32] text-[#15CA32] rounded-xl">
                          {startDate.format("D MMM YYYY")}
                        </span>
                        <span className="text-[8px] md:text-xs">to</span>
                        <span className="bg-[#FFF3F4] text-[8px] text-nowrap md:text-xs px-2 py-[0.189rem] border border-[#E2362F] text-[#E2362F] rounded-xl">
                          {endDate.format("D MMM YYYY")}
                        </span>
                      </div>
                      <div className="w-[18%] text-left">
                        <div className="flex gap-2 justify-center ">
                          <Link
                            to={`/super-admin/offer-managment/view-offer/${item.id}`}
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/view.svg"
                              alt=""
                            />
                          </Link>

                          <Link
                            to={`/super-admin/offer-managment/offer-stats/${item.id}`}
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/Edit1.svg"
                              alt=""
                            />
                          </Link>
                          <Link
                            to={`/super-admin/offer-managment/edit-offer/${item.id}`}
                          >
                            <img
                              className="w-7 h-7 md:w-9 md:h-9"
                              src="/assets/icons/editfull.svg"
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <tr className="text-center font-bold tracking-wide py-4">
                  <td colSpan={6}>No Offers Found Found</td>
                </tr>
              )}
            </div>
          </div>
        </div>

        {search || loading ? (
          <></>
        ) : (
          <>
            {/* Pagination start here */}
            <div className="flex justify-between items-center my-4">
              <span className="text-sm text-[#6B6B6B] font-semibold">
                SHOWING {startIndex + 1}-{endIndex} OF {totalOffersCount}{" "}
                RESULTS
              </span>
              <div className="flex items-center gap-3 bg-white rounded-lg px-2">
                {/* Previous Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <svg
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Page Numbers */}
                <div className="flex items-center gap-2">
                  {
                    <Pagination
                      totalNumberOfPages={totalPages}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                    />
                  }
                </div>
                {/* Next Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === totalPages ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <svg
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* Pagination end here */}
          </>
        )}
      </div>
    </>
  );
}
