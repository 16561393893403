import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import BannerAdDetails from "../../../Components/My-Shop-Components/BannerAd-Components/BannerAdDetails";

export default function BannerAdDetailsPage() {
  return (
    <>
      <MyShopLayout>
        <BannerAdDetails />
      </MyShopLayout>
    </>
  );
}
