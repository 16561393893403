import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import BreadCrums from "../../Common/BreadCrums";
import EarningBarGraph from "../../Graphs/Dashboard-Graphs/EarningBarGraph";
import NewShopGraph from "../../Graphs/Dashboard-Graphs/NewShopGraph";
import NewCustomerGraph from "../../Graphs/Dashboard-Graphs/NewCustomerGraph";
import ProductsSoldGraph from "../../Graphs/Dashboard-Graphs/ProductsSoldGraph";

export default function Dashboard() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/viewoffer.json");
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Dashboard",
            path: "/super-admin/dashboard",
          },
        ]}
      />

      {/* first div */}
      <div className="w-full flex flex-col md:flex-row gap-6 ">
        <div className="w-full md:w-[80%]">
          <EarningBarGraph />
        </div>
        <div className="w-full md:w-[20%] flex gap-2 md:gap-0  md:flex-col justify-between">
          <div className="p-2 md:p-5 rounded-lg shadow-sm w-full flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold  text-sm lg:text-base text-[#18120F]">
                Shop Requests
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/shoprequest.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between lg:min-w-[150px]">
              <span className="text-[#18120F] font-bold text-[35px] lg:text-[50px] xl:text-[64px] ">
                250
              </span>
              <Link
                to="/super-admin/vendor-requests/"
                className="p-1 lg:p-[11px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>{" "}
          <div className="p-2 md:p-5 rounded-lg shadow-sm w-full flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-sm lg:text-base  text-[#18120F]">
                Mall Requests
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/mallrequest.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between lg:min-w-[150px]">
              <span className="text-[#18120F] font-bold text-[35px] lg:text-[50px] xl:text-[64px] ">
                205
              </span>
              <Link
                to="/super-admin/mall-managment/mall-request"
                className="p-1 lg:p-[11px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* cards */}
      <div className="flex flex-col md:flex-row gap-2 md:gap-[25px] mt-4">
        <div className="w-full flex flex-col gap-1 lg:gap-4 px-2 md:px-4 lg:px-8 py-2 md:py-4 shadow-sm rounded-lg">
          <p className="font-semibold text-base lg:text-xl">Top Categories</p>
          <table className="w-full">
            <thead>
              <tr className="flex w-full justify-between items-center text-sm md:text-xs lg:text-base  gap-4  md:p-1  font-bold  text-black">
                <th className="text-left w-20 md:w-16 lg:w-20 ml-[55px]  lg:ml-16">
                  Category
                </th>
                <th className="w-14 text-left text-nowrap">Products Sold</th>
                <th className="w-14 text-left md:text-center">Profit</th>
              </tr>
            </thead>
            {/* body  */}
            <tbody className="w-full">
              {orders.slice(0, 3).map((item) => (
                <tr className="flex w-full justify-between items-center py-2 gap-2 font-semibold text-xs md:text-[8px] lg:text-sm">
                  <td className=" w-3/5 flex gap-6 items-center text-left">
                    <span>
                      <img
                        src={item.img}
                        alt=""
                        className="w-9 h-9 lg:w-11 lg:h-11 rounded-lg"
                      />
                    </span>
                    <span className="flex flex-col gap-1">
                      <p className="font-semibold ">{item.name}</p>
                    </span>
                  </td>
                  <td className="w-32 text-left">{item.commission}</td>
                  <td className="w-14 text-left">${item.commission}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* 2nd card */}
        <div className="w-full flex flex-col gap-1 lg:gap-4 px-2 md:px-4 lg:px-8 py-2 md:py-4 shadow-sm rounded-lg">
          <p className="font-semibold text-base lg:text-xl">Top Sellers</p>
          <table className="w-full">
            <thead>
              <tr className="flex w-full justify-between items-center   gap-4  md:p-1  font-bold text-sm md:text-xs lg:text-base text-black">
                <th className="text-left w-20 md:w-16 ml-[55px] md:ml-16">
                  Seller
                </th>
                <th className="w-14 text-left text-nowrap">Products Sold</th>
                <th className="w-14 text-left md:text-center">Profit</th>
              </tr>
            </thead>
            {/* body  */}
            <tbody className="w-full">
              {orders.slice(0, 3).map((item) => (
                <tr className="flex w-full justify-between items-center py-2 gap-2 font-semibold text-xs md:text-[8px] lg:text-sm">
                  <td className=" w-3/5 flex gap-6 items-center text-left">
                    <span>
                      <img
                        src={item.img}
                        alt=""
                        className="w-9 h-9 lg:w-11 lg:h-11 rounded-lg"
                      />
                    </span>
                    <span className="flex flex-col gap-1">
                      <p className="font-semibold ">{item.name}</p>
                    </span>
                  </td>
                  <td className="w-32 text-left">{item.commission}</td>
                  <td className="w-14 text-left">${item.commission}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* two graphs */}
      <div className="w-full flex flex-col md:flex-row gap-6">
        <div className="w-full">
          <NewCustomerGraph />
        </div>
        <div className="w-full">
          <NewShopGraph />
        </div>
      </div>
      {/* buttons - graph */}
      <div className="w-full flex flex-col lg:flex-row gap-6">
        <div className="w-full lg:w-[70%] grid grid-cols-2 md:grid-cols-3 gap-6">
          <div className="w-full xl:w-[258px] p-4 lg:p-7 rounded-lg shadow-sm flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold  text-sm lg:text-base text-[#18120F]">
                Total Users
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/totaluser.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between ">
              <span className="text-[#18120F] font-bold text-[30px]  xl:text-[60px] ">
                444k
              </span>
              <Link
                to="/super-admin/users-list/"
                className="p-1 lg:p-[10px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>{" "}
          <div className="w-full xl:w-[222px] p-4 md:p-7 rounded-lg shadow-sm flex flex-col  justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-sm md:text-base  text-[#18120F]">
                Total Shops
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/shoprequest.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between ">
              <span className="text-[#18120F] font-bold text-[30px]  xl:text-[60px]  ">
                1.2k
              </span>
              <Link
                to="/super-admin/shop-list/"
                className="p-1 lg:p-[10px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>
          <div className="w-full xl:w-[175px] p-4 lg:p-7 rounded-lg shadow-sm flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold  text-[#18120F] text-sm md:text-base text-nowrap">
                Total Malls
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/mallrequest.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between ">
              <span className="text-[#18120F] font-bold text-[30px]  xl:text-[60px]  ">
                1k
              </span>
              <Link
                to="/super-admin/mall-managment"
                className="p-1 lg:p-[10px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img className="" src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>
          <div className="w-full xl:w-[224px] p-4 lg:p-7 rounded-lg shadow-sm flex flex-col justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-wrap text-sm md:text-base  text-[#18120F] md:text-nowrap">
                Total Categories
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/totalcat.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between ">
              <span className="text-[#18120F] font-bold text-[30px]  xl:text-[60px]  ">
                20
              </span>
              <Link
                to="/super-admin/main-categories/"
                className="p-1 lg:p-[10px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>{" "}
          <div className="w-full xl:w-[194px] p-4 lg:p-7 rounded-lg shadow-sm flex flex-col  justify-between">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold  text-sm lg:text-base text-[#18120F] text-nowrap">
                Active Offers
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/activeoffer.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between ">
              <span className="text-[#18120F] font-bold text-[30px]  xl:text-[60px]  ">
                10
              </span>
              <Link
                to="/super-admin/offer-managment/"
                className="p-1 lg:p-[10px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]"
              >
                <img src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>
          <div className="w-full xl:w-[237px] p-4 lg:p-7 rounded-lg shadow-sm flex flex-col justify-between">
            <div className="w-full flex items-center justify-between">
              <h2 className="font-semibold text-sm md:text-base text-[#18120F] text-wrap md:text-nowrap">
                Customer returns
              </h2>
              <img
                className="w-6 h-6 lg:w-9 lg:h-9"
                src="/assets/icons/return.svg"
                alt=""
              />
            </div>

            <div className="flex items-center justify-between ">
              <span className="text-[#18120F] font-bold text-[30px]  xl:text-[60px]  ">
                10
              </span>
              <Link className="p-1 lg:p-[10px] rounded-lg lg:rounded-xl bottom-0 items-end justify-end bg-[#C19A6B]">
                <img className="" src="/assets/icons/forward1.svg" alt="" />
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-[40%]">
          <ProductsSoldGraph />
        </div>
      </div>
    </div>
  );
}
