import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function OrdersManagement() {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/order-management.json");
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Pagination control
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, orders.length);
  const paginatedProducts = orders.slice(startIndex, endIndex);

  const shouldShowPagination = orders.length > itemsPerPage && totalPages > 1;

  const paginationIndexes = [];
  if (totalPages <= 3) {
    for (let i = 1; i <= totalPages; i++) {
      paginationIndexes.push(i);
    }
  } else {
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      paginationIndexes.push(i);
    }
  }

  return (
    <>
      <div className="px-5 w-full flex flex-col gap-4">
        {/* name */}
        <div className="flex items-center gap-2">
          <img src="/assets/icons/manage-products/bars.svg" alt="" />
          <h2 className="text-2xl font-bold">Order</h2>
        </div>

        <div className="flex items-center justify-between gap-4 w-full">
          <div className="flex flex-col gap-2 bg-[#C19A6B] p-6 rounded-xl w-full ">
            <p className="font-semibold text-xs uppercase text-white tracking-widest">
              Order Statuses
            </p>

            <div className="flex justify-between py-4 px-6 bg-white rounded-xl gap-2 w-full">
              <span className="flex flex-col items-center  border-r-2 border-[#EBF0ED] px-6 w-full">
                <p className="font-extrabold text-3xl">2k</p>
                <p className="font-semibold text[9px] text-[#6B6B6B] w-20">
                  All Orders
                </p>
              </span>
              <span className="flex flex-col items-center  border-r-2 border-[#EBF0ED] px-6">
                <p className="font-extrabold text-3xl">28</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Pending
                </p>
              </span>
              <span className="flex flex-col items-center  border-r-2 border-[#EBF0ED] px-6">
                <p className="font-extrabold text-3xl">87</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Completed
                </p>
              </span>
              <span className="flex flex-col items-center px-6">
                <p className="font-extrabold text-3xl">22</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Progress
                </p>
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-2 bg-[#C19A6B] p-6 rounded-xl w-full">
            <p className="font-semibold text-xs uppercase text-white tracking-widest">
              failed orders
            </p>

            <div className="flex justify-between py-4 px-6 bg-white rounded-xl gap-2 w-full">
              <span className="flex flex-col items-center  border-r-2 border-[#EBF0ED] px-6">
                <p className="font-extrabold text-3xl">2k</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Abanded
                </p>
              </span>
              <span className="flex flex-col items-center  border-r-2 border-[#EBF0ED] px-6">
                <p className="font-extrabold text-3xl">28</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Returned
                </p>
              </span>
              <span className="flex flex-col items-center  border-r-2 border-[#EBF0ED] px-6">
                <p className="font-extrabold text-3xl">87</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Canceled
                </p>
              </span>
              <span className="flex flex-col items-center px-6">
                <p className="font-extrabold text-3xl">22</p>
                <p className="font-semibold text[9px] text-[#6B6B6B]">
                  Damaged
                </p>
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between gap-4 mt-3">
          <div className="relative flex items-center w-full ">
            <img
              src="/assets/icons/manage-products/search.svg"
              alt=""
              className="absolute left-3 text-[#C19A6B]"
            />

            <input
              className="w-full h-[38px] pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
              type="search"
              placeholder="Search Order …"
            />
          </div>

          <div className="flex items-center justify-center gap-2 p-4 rounded-lg w-[87px] h-[38px] bg-[#FAFAFA] border border-[#EBF0ED] ">
            <span>
              <img
                src="/assets/icons/order-management/export.svg"
                alt=""
                className="max-w-[9px] h-[18px]"
              />
            </span>
            <span className="font-semibold text-xs text-[#828282]">Export</span>
          </div>
        </div>

        <div className="flex items-center justify-between mt-2">
          <div className="flex gap-6">
            <div className="flex items-center gap-2 text-[#828282] hover:text-[#C19A6B] uppercase">
              <Link
                to="/dashboard/orders"
                className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]"
              >
                All Orders
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px] bg-[#EBF0ED] border-[0.75px]  rounded-[5.75px]">
                <span className="text-[10.56px] ">(90)</span>
              </span>
            </div>

            <div className="flex items-center gap-2 text-[#828282] hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm ">Delivered</Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  bg-[#EBF0ED] border-[0.75px]   rounded-[5.75px]">
                <span className="text-[10.56px]">(27)</span>
              </span>
            </div>

            <div className="flex items-center gap-2 text-[#828282]  hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]">
                Pickup
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  border-[0.75px] bg-[#EBF0ED] rounded-[5.75px]">
                <span className="text-[10.56px] ">(27)</span>
              </span>
            </div>
            <div className="flex items-center gap-2 text-[#828282]  hover:text-[#C19A6B]  uppercase">
              <Link className="font-semibold text-sm text-[#828282] hover:text-[#C19A6B]">
                Cancelled
              </Link>
              <span className="flex items-center justify-center w-[30px] h-[27px]  border-[0.75px] bg-[#EBF0ED] rounded-[5.75px]">
                <span className="text-[10.56px] ">(27)</span>
              </span>
            </div>
          </div>

          <div className="flex items-center justify-center gap-2 p-4 rounded-lg w-[86px] h-[21px] bg-[#FAFAFA] border border-[#EBF0ED] ">
            <span>
              <img src="/assets/icons/order-management/filters.svg" alt="" />
            </span>
            <span className="font-semibold text-xs text-[#828282]">Filter</span>
          </div>
        </div>

        {/* table */}
        <table className="w-full bg-white  border border-[#EBF0ED] border-separate rounded-lg mt-2">
          {/* Table Header */}
          <thead className=" w-full border-b border-gray-300 ">
            <tr className="flex w-full justify-between items-center gap-4  p-4  text-[#6B6B6B] font-semibold text-[12px] ">
              <span className="flex items-center gap-4">
                <input type="checkbox" className="" />
                <th className="w-[100px] text-left">ORDER ID</th>
              </span>
              <th className="w-[110px] text-left">CUSTOMER</th>
              <th className="w-[100px] text-left">AMOUNT</th>
              <th className="w-[110px] text-left">PAYMENT METHOD</th>
              <th className="w-[100px] text-left">ORDER DATE</th>
              <th className="w-[110px] text-left">PAYMENT STATUS</th>
              <th className="w-[110px] text-left">ORDER STATUS</th>
              <th className="w-[100px] text-left">ACTION</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody className="w-full">
            {paginatedProducts.map((order) => (
              <tr
                key={order.id}
                className="flex w-full justify-between items-center gap-4  p-4 border-t  font-semibold text-sm "
              >
                <span className="flex items-center gap-4">
                  <input type="checkbox" className="" />
                  <td className="w-[100px] ">{order.orderId}</td>
                </span>
                <td className="w-[110px]  ">{order.customer}</td>
                <td className="w-[100px] ">${order.amount} </td>
                <td className="w-[110px]  ">{order.paymentMethod}</td>
                <td className="w-[100px]">{order.orderDate}</td>
                <td className="w-[110px]">
                  <span
                    // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                    className={`${
                      order.paymentStatus === "Completed"
                        ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                        : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                    }`}
                  >
                    {order.paymentStatus}
                  </span>
                </td>

                <td className="w-[110px]">
                  <span
                    // className="bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                    className={`${
                      order.orderStatus === "Completed"
                        ? "bg-[#F6FFF7] text-sm py-1 px-3 border border-[#15CA32] text-[#15CA32] rounded-lg"
                        : order.orderStatus === "New"
                        ? "bg-[#F1F7FF] text-sm py-1 px-3 border border-[#44A6E9] text-[#44A6E9] rounded-lg"
                        : order.orderStatus === "In Progress"
                        ? "bg-[#FFFBF0] text-sm py-1 px-3 border border-[#FEC600] text-[#FEC600] rounded-lg"
                        : "bg-[#FFF3F4] text-sm py-1 px-3 border border-[#E2362F] text-[#E2362F] rounded-lg"
                    }`}
                  >
                    {order.orderStatus}
                  </span>
                </td>

                <td className="w-[100px] ">
                  <div className="flex gap-2 ">
                    <Link>
                      <img
                        src="/assets/icons/manage-products/view.svg"
                        alt=""
                      />
                    </Link>

                    <Link>
                      <img
                        src="/assets/icons/manage-products/edit.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        {shouldShowPagination && (
          <div className="flex justify-between items-center my-4">
            <span className="text-sm text-[#6B6B6B] font-semibold">
              SHOWING {startIndex + 1} - {endIndex} OF {orders.length} RESULTS
            </span>
            <div className="flex items-center justify-between w-[265px] h-[40px] bg-white rounded-lg px-2">
              {/* Previous Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === 1 ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <svg
                  className={`${
                    currentPage === 1
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 13L1 7L7 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* Page Numbers */}
              <div className="flex items-center gap-2">
                {paginationIndexes.map((page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={`px-2 py-1 rounded ${
                      currentPage === page
                        ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                        : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
              {/* Next Button */}
              <div
                className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                  currentPage === totalPages ? "opacity-50" : ""
                }`}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <svg
                  className={`${
                    currentPage === totalPages
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-blue-500"
                  }`}
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 13L7 7L1 1"
                    stroke="#828282"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
