import React, { useState } from "react";
import BreadCrums from "../../Common/BreadCrums";

export default function ProductManagmentAddProduct() {
  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");
  const [productTags, setProductTags] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Updated states
  const [modelDropdownOpen, setModelDropdownOpen] = useState(false);
  const [usesStateDropdownOpen, setUsesStateDropdownOpen] = useState(false);

  // Define the number of image slots
  const initialImageSlots = Array(7).fill(null);
  const [imageSlots, setImageSlots] = useState(initialImageSlots);

  // Initialize states for dropdown visibility and selected values
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);

  // Toggle functions for dropdown visibility
  const toggleCountryDropdown = () =>
    setCountryDropdownOpen(!countryDropdownOpen);

  // Function to handle image upload
  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSlots = [...imageSlots];
        newImageSlots[index] = e.target.result; // Save the image URL
        setImageSlots(newImageSlots); // Update the state
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = (index) => {
    const newImageSlots = [...imageSlots];
    newImageSlots[index] = null;
    setImageSlots(newImageSlots);
  };
  // Labels for the image slots
  const labels = [
    { text: "Cover Image", required: true },
    { text: "Alt Image", required: true },
    { text: "Image 1", required: false },
    { text: "Image 2", required: false },
    { text: "Image 3", required: false },
    { text: "Image 4", required: false },
    { text: "Image 5", required: false },
  ];

  // ...
  const productCategoryOptions = [
    "Electronics",
    "Clothing",
    "Accessories",
    "Books",
    "Furniture",
  ];
  const productSubCategoryOptions = [
    "Mobile Phones",
    "Laptops",
    "Shirts",
    "Pants",
    "Chairs",
  ];
  const modelOptions = ["Model A", "Model B", "Model C"];
  const usesStateOptions = ["New", "Used", "Refurbished"];

  // Functions for toggling dropdowns
  const toggleModelDropdown = () => setModelDropdownOpen(!modelDropdownOpen);
  const toggleUsesStateDropdown = () =>
    setUsesStateDropdownOpen(!usesStateDropdownOpen);

  // Functions to select a value from dropdowns
  const selectModel = (model) => {
    setModelDropdownOpen(false);
  };

  const selectUsesState = (usesState) => {
    setUsesStateDropdownOpen(false);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCategorySelect = (category) => {
    setProductCategory(category);
    setDropdownOpen(false);
  };

  const handleSubCategorySelect = (subCategory) => {
    setProductSubCategory(subCategory);
  };

  const handleTagAdd = (event) => {
    if (event.key === "Enter") {
      const newTag = event.target.value.trim();
      if (newTag && !productTags.includes(newTag)) {
        setProductTags([...productTags, newTag]);
      }
      event.target.value = "";
    }
  };

  const handleTagDelete = (tag) => {
    setProductTags(productTags.filter((t) => t !== tag));
  };
  return (
    <>
      <div className="px-3 w-full">
        {/* head */}
        <div className="flex justify-between ">
          <BreadCrums
            breadCrum={[
              {
                name: "Create Product",
                path: "/super-admin/product-managment/",
              },
            ]}
          />

          <div className="flex gap-3">
            <button className="px-6 py-3  text-center text-xs text-[#828282] bg-[#FAFAFA] border border-[#EBF0ED] rounded-lg">
              Draft
            </button>
            <button className="px-6 py-3   text-xs text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg">
              Publish
            </button>
          </div>
        </div>

        <div className="py-5 flex gap-4">
          {/* Left Side -------------------------------- */}
          <div className="w-1/2">
            <div className="flex flex-col gap-4">
              {/* description */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-lg font-semibold">
                  Description
                </h1>
                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex flex-col ga-2">
                    <label
                      className="text-[#6B6B6B] ml-3 font-semibold"
                      htmlFor="product name "
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                      type="text"
                      name="product name"
                      id=""
                      placeholder="Enter product name "
                    />
                  </div>
                  <div className="flex flex-col ga-2">
                    <label
                      className="text-[#6B6B6B] ml-3 font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] h-16 placeholder: rounded-lg w-full py-3 px-5 outline-none"
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Enter product description "
                    />
                  </div>
                </div>
              </div>
              {/* description */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-lg font-semibold">
                  Affilliate
                </h1>
                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex items-center gap-2 text-xs font-semibold text-[#6B6B6B]">
                    <span>Available For Affilliate</span>
                    <input type="radio" name="" id="" />
                    Yes
                    <input type="radio" name="" id="" />
                    No
                  </div>
                </div>
              </div>
              {/* CATEGORIES */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-lg font-semibold">
                  Product Categories
                </h1>

                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  {/* Product Category Dropdown */}
                  <div className="relative flex flex-col gap-2">
                    <label
                      className="text-[#6B6B6B] ml-3 font-semibold"
                      htmlFor="productCategory"
                    >
                      PRODUCT CATEGORY
                    </label>
                    <div className="relative w-full">
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5 outline-none"
                        type="text"
                        value={productCategory}
                        readOnly
                        onClick={toggleDropdown}
                        placeholder="Select category"
                      />
                      <svg
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                        onClick={toggleDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {dropdownOpen && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {productCategoryOptions.map((option) => (
                            <div
                              key={option}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => handleCategorySelect(option)}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Product Tags */}
                  <div className="flex flex-col gap-2">
                    <label
                      className="text-[#6B6B6B] ml-3 font-semibold"
                      htmlFor="productTags"
                    >
                      PRODUCT TAGS
                    </label>
                    <div className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5 outline-none">
                      {productTags.map((tag) => (
                        <span
                          key={tag}
                          className="bg-gray-200 text-gray-700 rounded-lg px-3 py-1 inline-flex items-center gap-2 mr-2"
                        >
                          {tag}
                          <button
                            onClick={() => handleTagDelete(tag)}
                            className="text-red-500"
                          >
                            ✕
                          </button>
                        </span>
                      ))}
                      <input
                        type="text"
                        onKeyPress={handleTagAdd}
                        className="bg-transparent outline-none w-auto inline-flex"
                        placeholder="Press Enter to add tags"
                      />
                    </div>
                  </div>
                  {/* desc */}
                  <div className="flex flex-col ga-2">
                    <label
                      className="text-[#6B6B6B] ml-3 font-semibold"
                      htmlFor="product description"
                    >
                      PRODUCT SHORT DESCRIPTION
                    </label>
                    <textarea
                      className="bg-[#FAFAFA] h-16 placeholder: rounded-lg w-full py-3 px-5 outline-none"
                      type="text"
                      name="product description"
                      id=""
                      placeholder="Add short description for product"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side ------------------------------- */}
          <div className="w-1/2">
            <div className="flex flex-col gap-4">
              {/* Product gallery */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-lg font-semibold">
                  Product Gallery
                </h1>
                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  <div className=" grid grid-cols-5 gap-4">
                    {imageSlots.map((image, index) => (
                      <div key={index} className="flex flex-col items-center">
                        <div
                          className={`relative w-[90px] h-[90px] border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0] `}
                        >
                          {image ? (
                            <div className="w-full h-full relative">
                              <img
                                src={image}
                                alt={`Uploaded-${index}`}
                                className="w-full h-full object-cover rounded-lg"
                              />
                              {/* Delete Button */}
                              <button
                                className="absolute top-1 right-1 bg-white border border-[#EBF0ED] rounded-full w-4 h-4 flex justify-center items-center"
                                onClick={() => handleImageDelete(index)}
                              >
                                <svg
                                  width="7"
                                  height="7"
                                  viewBox="0 0 7 7"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.9748 1.00017L1.00002 5.975M1 1L5.9748 5.9748"
                                    stroke="black"
                                    stroke-width="1.07"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          ) : (
                            <label className="cursor-pointer flex flex-col bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                              <input
                                type="file"
                                accept="image/*"
                                className="hidden"
                                onChange={(e) => handleImageUpload(e, index)}
                              />
                              <span>+</span>
                            </label>
                          )}
                        </div>
                        {/* Label with red star for required images */}
                        <div className="text-sm text-gray-500 mt-2">
                          {labels[index].text}
                          {labels[index].required && (
                            <span className="text-red-500 ml-1">*</span> // Red star for required images
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* Product Price */}
              <div className="flex flex-col gap-2">
                <h1 className="text-[#18120F] text-lg font-semibold">
                  Price & Stock
                </h1>
                <div className="border flex flex-col gap-5 py-4 px-3 border-[#EBF0ED] rounded-lg">
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col ga-2">
                      <label
                        className="text-[#6B6B6B]  font-semibold"
                        htmlFor="price"
                      >
                        PRICE $
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                        type="text"
                        name="price"
                        id=""
                        placeholder="0 "
                      />
                    </div>
                    <div className="w-full flex flex-col ga-2">
                      <label
                        className="text-[#6B6B6B]  font-semibold"
                        htmlFor="price"
                      >
                        SALE PRICE%
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                        type="text"
                        name="price"
                        id=""
                        placeholder="0 "
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col ga-2">
                      <label
                        className="text-[#6B6B6B]  font-semibold"
                        htmlFor="price"
                      >
                        SALE FROM
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                        type="date"
                        name="price"
                        id=""
                        placeholder="0 "
                      />
                    </div>
                    <div className="w-full flex flex-col ga-2">
                      <label
                        className="text-[#6B6B6B]  font-semibold"
                        htmlFor="price"
                      >
                        SALE TO
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                        type="date"
                        name="price"
                        id=""
                        placeholder="0 "
                      />
                    </div>
                  </div>
                  <div className="flex gap-2 items-center">
                    <div className="w-full flex flex-col ga-2">
                      <label
                        className="text-[#6B6B6B]  font-semibold"
                        htmlFor="price"
                      >
                        STOCK QUANTITY
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                        type="text"
                        name="price"
                        id=""
                        placeholder="0 "
                      />
                    </div>
                    <div className="w-full flex flex-col ga-2">
                      <label
                        className="text-[#6B6B6B]  font-semibold"
                        htmlFor="price"
                      >
                        STOCK THRESHOLD
                      </label>
                      <input
                        className="bg-[#FAFAFA] rounded-lg w-full py-2 px-5   outline-none "
                        type="text"
                        name="price"
                        id=""
                        placeholder="0 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
