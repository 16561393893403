import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearMessage,
  mainCategory,
  subCategoriesCreate,
} from "../../../../../store/adminSlice";
import { toast } from "react-toastify";
import BreadCrums from "../../../../Common/BreadCrums";

export default function AuctionCreateSubCategory() {
  const [categoryName, setCategoryName] = useState("");
  const { error, message, loading, categories } = useSelector(
    (state) => state.admin
  );
  const [headCategory, setHeadCategory] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (
      error ===
      "An error occurred while creating the subcategory: The name field format is invalid."
    ) {
      toast.error("The name field format is invalid.");
    }
    if (
      error ===
      "An error occurred while creating the subcategory: The name has already been taken."
    ) {
      toast.error("The name has already been taken.");
    }
    if (message === "Subcategory created successfully") {
      toast.success(message);
      navigate(-1);
    }
    // console.log(error, message);
    dispatch(clearMessage());
  }, [error, message]);

  useEffect(() => {
    dispatch(mainCategory());
  }, []);

  useEffect(() => {
    if (categories.categories) {
      setHeadCategory(
        categories.categories.find((item) => parseInt(item.id) === parseInt(id))
      );
    }
  }, [categories]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (categoryName === "") {
      toast.error("Enter category name");
    } else {
      formData.append("category_id", id.toString());
      formData.append("name", categoryName);
      formData.append("type", "auction");

      dispatch(subCategoriesCreate(formData));
    }
  };

  return (
    <form className="xl:px-4 flex flex-col gap-4 w-full">
      {/* top bar */}
      <div className="flex flex-col md:flex-row gap-3 justify-between md:items-center w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Auction",
              path: "/super-admin/auction-categories/",
            },
            {
              name: "Main Category",
              path: -1,
            },
            {
              name: "create Category",
              path: "",
            },
          ]}
        />

        <div className="w-full md:w-fit flex justify-end">
          {/* submit btn  */}
          {loading ? (
            <button
              disabled={loading ? true : false}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              <p className="animate-bounce">Loading</p>
            </button>
          ) : (
            <button
              disabled={loading ? true : false}
              onClick={handleSubmit}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              Publish
            </button>
          )}
        </div>
      </div>

      {/* create form  */}
      <div className="flex flex-col gap-2">
        <p className="font-semibold text-lg">Create Category</p>

        <div className="  border border-[#EBF0ED] p-4 gap-10 rounded-lg">
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Category Name
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="text"
                name="name"
                id=""
                placeholder="Name..."
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
