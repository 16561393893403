import OrderPlacedPage from "../Pages/My-Shop/Shop-And-User-List/User-List/OrderPlacedPage";
import UserAffiliateProductsPage from "../Pages/My-Shop/Shop-And-User-List/User-List/UserAffiliateProductsPage";
import UserListOrderViewPage from "../Pages/My-Shop/Shop-And-User-List/User-List/UserListOrderViewPage";
import UserListPage from "../Pages/My-Shop/Shop-And-User-List/User-List/UserListPage";
import UserProductsReturnedPage from "../Pages/My-Shop/Shop-And-User-List/User-List/UserProductsReturnedPage";
import UserProductsReviewedPage from "../Pages/My-Shop/Shop-And-User-List/User-List/UserProductsReviewedPage";
import UserProfileStatsPage from "../Pages/My-Shop/Shop-And-User-List/User-List/UserProfileStatsPage";

const userListRoutes = [

    {
        path: "/super-admin/users-list/",
        children: [{
                index: true,
                element: < UserListPage /> ,
            },
            {
                path: "/super-admin/users-list/order-placed/:id",
                element: < OrderPlacedPage /> ,
            },
            {
                path: "/super-admin/users-list/user-list-order-view/:id",
                element: < UserListOrderViewPage /> ,
            },
            {
                path: "/super-admin/users-list/user-products-reviewed/:id",
                element: < UserProductsReviewedPage /> ,
            },
            {
                path: "/super-admin/users-list/user-products-returned/:id",
                element: < UserProductsReturnedPage /> ,
            },
            {
                path: "/super-admin/users-list/user-Affiliate-products/:id",
                element: < UserAffiliateProductsPage /> ,
            },
            {
                path: "/super-admin/users-list/user-profile-stats/:id",
                element: < UserProfileStatsPage /> ,
            },
        ],
    },
    
]

export default userListRoutes;