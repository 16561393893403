import HeroSectionImageSelection from "../Pages/Hero-section/HeroSectionImageSelection";
import UpdateHeroSectionImageSelection from "../Pages/Hero-section/UpdateHeroSectionImageSelection";

const heroSectionRoutes = [
  {
    path: "/super-admin/hero-section-image-selection",
    element: <HeroSectionImageSelection />,
  },
  {
    path: "/super-admin/update-hotspot-images/:id",
    element: < UpdateHeroSectionImageSelection /> ,
},
];

export default heroSectionRoutes;
