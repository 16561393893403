import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import Dashboard from "../../../Components/My-Shop-Components/Dash-Board/Dashboard";

export default function DashboardPage() {
  return (
    <>
      <MyShopLayout>
        <Dashboard />
      </MyShopLayout>
    </>
  );
}
