import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";

import SuperAdminInformation from "../../../Components/My-Shop-Components/Super-Admin-Profile-Components/SuperAdminInformation";

export default function SuperAdminInformationPage() {
  return (
    <>
      <MyShopLayout>
        <SuperAdminInformation />
      </MyShopLayout>
    </>
  );
}
