import React from "react";
import MyShopLayout from "../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import CreateOffer from "../../../Components/My-Shop-Components/PremiumProducts-Components/CreateOffer";

export default function CreateOfferPage() {
  return (
    <>
      <MyShopLayout>
        <CreateOffer />
      </MyShopLayout>
    </>
  );
}
