import Brands from "../../../../Components/My-Shop-Components/Manage-Categories/Brands/Brands";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function BrandsPage() {
  return (
    <MyShopLayout>
      <Brands />
    </MyShopLayout>
  );
}
