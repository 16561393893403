import BrandsPage from "../Pages/My-Shop/Manage-Products-Page/Brands-Pages/BrandsPage";
import CreateBrandsPage from "../Pages/My-Shop/Manage-Products-Page/Brands-Pages/CreateBrandsPage";
import UpdateBrandsPage from "../Pages/My-Shop/Manage-Products-Page/Brands-Pages/UpdateBrandsPage";



const brandsRoutes = [

    {
                path: "/super-admin/brands/",
                children: [

                  {
                    index: true,
                    element: <BrandsPage />,
                  },
                  {
                    path: "/super-admin/brands/create-brands",
                    element: <CreateBrandsPage />,
                  },
                  {
                    path: "/super-admin/brands/update-brands/:id",
                    element: <UpdateBrandsPage />,
                  },

                ],
    },

]

export default brandsRoutes;