import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import {
  clearMessage,
  mainShopCategoriesList,
  offerByID,
  subCategoriesList,
  updateOffer,
} from "../../../store/adminSlice";
import { imagePath } from "../../../functions/URLString";

import BreadCrums from "../../Common/BreadCrums";

export default function OfferManagmentEditOffer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, mainShopCategories, subCategories, loading, error, message } =
    useSelector((state) => state.admin);
  const [updateData, setUpdateData] = useState(null);
  const [mainCat, setMainCat] = useState(null);
  const [subCat, setSubCat] = useState(null);

  const [dropdown, setDropdown] = useState(false);
  const [subCatdropdown, setSubCatDropdown] = useState(false);

  const inputRef = useRef(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    dispatch(offerByID(id));
    dispatch(mainShopCategoriesList());
    dispatch(subCategoriesList());
  }, [dispatch, id]);

  useEffect(() => {
    if (!loading && data && mainShopCategories && subCategories) {
      setUpdateData(data.offer);
      setMainCat(mainShopCategories?.categories);
      setSubCat(subCategories.subcategories);
    }
  }, [loading, data, mainShopCategories, subCategories]);

  // Handle category selection
  const handleCategorySelect = (categoryId) => {
    const selectedCategory = mainCat.find(
      (category) => category.id === categoryId
    );
    if (selectedCategory) {
      handleInputChange("category_id", selectedCategory.id);
    }
    setDropdown(false);
  };
  // Handle sub category selection
  const handleSubCategorySelect = (categoryId) => {
    const selectedSubCategory = subCat.find(
      (category) => category.id === categoryId
    );
    if (selectedSubCategory) {
      handleInputChange("sub_category_id", selectedSubCategory.id);
    }
    setSubCatDropdown(false);
  };

  const handleInputChange = (name, value) => {
    if (
      name === "regstarttime" ||
      name === "regendtime" ||
      name === "livestarttime" ||
      name === "liveendtime"
    ) {
      value = formatTimeString(value);
    } else if (name === "regstartdate" || name === "regenddate") {
      value = moment(value).format("YYYY-MM-DD"); // Format date as YYYY-MM-DD
    }

    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file); // Update the image state with the new file
  };

  // display home state
  const [dropdownControl, setDropdownControl] = useState("yes");
  // main cat dropdown
  const toggleDropdown = () => {
    if (dropdownControl === "no") {
      setDropdown(!dropdown);
      setSubCatDropdown(false);
    }
  };
  // sub cat dropdown
  const toggleSubCatDropdown = () => {
    if (dropdownControl === "no") {
      setSubCatDropdown(!subCatdropdown);
      setDropdown(false);
    }
  };

  // Format Date object into time string
  const formatTimeString = (date) => {
    return moment(date, "HH:mm").format("HH:mm");
  };

  // form submitting
  const validateFormData = (data) => {
    const requiredFields = [
      "title",
      "description",
      "percentage",
      "regstartdate",
      "regenddate",
      "regstarttime",
      "regendtime",
      "livestartdate",
      "liveenddate",
      "livestarttime",
      "liveendtime",
    ];

    if (dropdownControl === "yes") {
    } else {
      requiredFields.push("category_id", "sub_category_id");
    }

    for (let field of requiredFields) {
      if (!data[field]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updateDataFields = {
      title: updateData.title,
      description: updateData.description,
      percentage: updateData.percentage,
      regstartdate: updateData.regstartdate,
      regenddate: updateData.regenddate,
      regstarttime: updateData.regstarttime,
      regendtime: updateData.regendtime,
      livestartdate: updateData.livestartdate,
      liveenddate: updateData.liveenddate,
      livestarttime: updateData.livestarttime,
      liveendtime: updateData.liveendtime,
      category_id: updateData.category_id,
      sub_category_id: updateData.sub_category_id,
    };

    if (!validateFormData(updateDataFields)) {
      toast.error("Please fill all inputs");
      return;
    }

    const formData = new FormData();
    formData.append("title", updateData.title);
    formData.append("description", updateData.description);

    if (dropdownControl === "yes") {
      formData.append("onHome", 1);
    } else {
      formData.append("onHome", 0);
      formData.append("category_id", updateData.category_id);
      formData.append("sub_category_id", updateData.sub_category_id);
    }

    formData.append("percentage", updateData.percentage);
    formData.append("regstartdate", updateData.regstartdate);
    formData.append("regenddate", updateData.regenddate);
    formData.append("regstarttime", updateData.regstarttime);
    formData.append("regendtime", updateData.regendtime);
    formData.append("livestartdate", updateData.livestartdate);
    formData.append("liveenddate", updateData.liveenddate);
    formData.append("livestarttime", updateData.livestarttime);
    formData.append("liveendtime", updateData.liveendtime);

    if (image) {
      formData.append("image", image);
    } else {
      // formData.append("image", updateData.image);
    }

    formData.append("id", id);
    formData.append("_method", "PUT");

    dispatch(updateOffer(formData));
  };

  useEffect(() => {
    if (message === "Offer updated successfully") {
      toast.success(message);
      navigate("/super-admin/offer-managment/");
    }
    if (error) {
      if (typeof error === "object" && Object.keys(error).length > 0) {
        Object.keys(error).forEach((key) => {
          if (Array.isArray(error[key]) && error[key].length > 0) {
            error[key].forEach((errorMessage) => {
              toast.error(errorMessage); // Display each error message
            });
          } else {
            toast.error("An unknown error occurred."); // Fallback for unexpected error structure
          }
        });
      } else if (typeof error === "string") {
        toast.error(error); // Display error message if it's a string
      } else {
        toast.error("An unknown error occurred."); // Fallback for unexpected error types
      }
    }
    dispatch(clearMessage());
  }, [error, message, loading, dispatch]);

  return (
    <>
      <form
        method="PUT"
        onSubmit={handleSubmit}
        className=" md:px-4 flex flex-col gap-2"
      >
        {/* Header */}
        <div className="flex justify-between items-center">
          <BreadCrums
            breadCrum={[
              { name: "Offers", path: "/super-admin/offer-managment/" },
              {
                name: "Edit Offers",
                path: "/super-admin/offer-managment/edit-offer/",
              },
            ]}
          />

          <button
            type="submit"
            className="px-5 py-[7px] md:px-9 md:py-[10px] text-nowrap flex items-center justify-center rounded-lg text-white bg-[#C19A6B] border border-[#EBF0ED] text-center font-[600] text-xs md:text-sm"
          >
            {!loading ? "Update Offers" : "Validating"}
          </button>
        </div>
        {/* offers detail */}
        <div className="border border-[#EBF0ED] rounded-xl px-4 py-4 md:py-8">
          <div>
            <h2 className="text-base md:text-lg font-semibold text-[#18120F]">
              Offer Details
            </h2>
          </div>
          {/* first */}
          <div className="flex flex-col md:flex-row gap-5">
            {/* left */}
            <div className="w-full flex flex-col gap-3">
              <div>
                <h2 className="text-xs font-[500] text-[#18120F] ">
                  Offer Title
                </h2>
                <input
                  type="text"
                  name="title"
                  value={updateData && updateData.title}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                  className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-xs font-[500] p-2 md:p-3"
                  placeholder="Black Friday"
                />
              </div>
              <div>
                <h2 className="text-[12px] font-[500] text-[#18120F] ">
                  Offer Description
                </h2>
                <textarea
                  name="description"
                  value={updateData && updateData.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                  className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA]  text-xs font-[400] px-3 pt-3 pb-2 md:pb-6"
                  placeholder="Enter Offer Description"
                />
              </div>
              <div>
                <h2 className="text-[#18120F] text-xs font-semibold">
                  Display on Home
                </h2>
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="dropdownControl"
                      checked={dropdownControl === "yes"}
                      onChange={() => setDropdownControl("yes")}
                    />
                    <label className="text-sm md:text-base">Yes</label>
                  </div>
                  <div className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="dropdownControl"
                      checked={dropdownControl === "no"}
                      onChange={() => setDropdownControl("no")}
                    />
                    <label className="text-sm md:text-base">No</label>
                  </div>
                </div>
              </div>

              {/* main category */}
              <h2 className="text-[12px] font-[500] text-[#18120F] ">
                Category
              </h2>
              <div className="flex items-center gap-3">
                <div>
                  <div className="relative flex flex-col gap-2">
                    <div className="relative w-full">
                      <input
                        className={`bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none text-xs ${
                          dropdownControl === "yes"
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        type="text"
                        name="main_category"
                        value={
                          updateData &&
                          mainShopCategories?.categories?.find(
                            (category) => category.id == updateData.category_id
                          )?.name
                        }
                        readOnly
                        onClick={toggleDropdown}
                        placeholder="Select Category"
                        disabled={dropdownControl === "yes"}
                      />
                      <svg
                        className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 ${
                          dropdownControl === "yes"
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={toggleDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {dropdown && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {mainCat &&
                            mainCat.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCategorySelect(option.id)}
                              >
                                {option.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* sub category */}
                <div>
                  <div className="relative flex flex-col gap-2">
                    <div className="relative w-full">
                      <input
                        className={`bg-[#FAFAFA] rounded-lg w-full py-2 px-3 outline-none text-xs ${
                          dropdownControl === "yes"
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        type="text"
                        name="sub_category"
                        value={
                          updateData &&
                          subCat &&
                          subCat.find(
                            (category) =>
                              category.id == updateData.sub_category_id
                          )?.name
                        }
                        readOnly
                        onClick={toggleSubCatDropdown}
                        placeholder="Select Sub Category"
                        disabled={dropdownControl === "yes"}
                      />
                      <svg
                        className={`absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500 ${
                          dropdownControl === "yes"
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={toggleSubCatDropdown}
                        width="11"
                        height="6"
                        viewBox="0 0 11 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                          fill="#6B6B6B"
                        />
                      </svg>

                      {subCatdropdown && (
                        <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                          {subCat &&
                            subCat.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() =>
                                  handleSubCategorySelect(option.id)
                                }
                              >
                                {option.name}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="text-[12px] font-[500] text-[#18120F] ">
                  Offer Off Percentage
                </h2>
                <input
                  type="number"
                  name="percentage"
                  onChange={(e) =>
                    handleInputChange("percentage", e.target.value)
                  }
                  className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] text-xs font-[400] p-2 md:p-3"
                  placeholder="10%"
                  value={updateData && updateData.percentage}
                />
              </div>
            </div>
            {/* right */}
            <div className="w-full">
              <h2 className="text-[0.75rem] font-[500] text-[#18120F] ">
                Upload Banner Image
              </h2>
              <div onClick={handleImageClick} className="relative">
                {image ? (
                  <img
                    className="w-full rounded-lg"
                    src={URL.createObjectURL(image)}
                    alt=""
                  />
                ) : (
                  <img
                    className="w-full rounded-lg"
                    src={updateData && imagePath + updateData.image}
                    alt=""
                  />
                )}
                <input
                  ref={inputRef}
                  onChange={handleImageChange}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* 2nd */}
        <div className="flex flex-col md:flex-row gap-5 my-6">
          <div className="w-full border border-[#EBF0ED] px-4 py-4 md:py-8 rounded-xl">
            <div className="flex flex-col gap-2">
              <h2 className="text-base md:text-lg font-semibold text-[#18120F]">
                Offer Registration Time
              </h2>
              <div className="flex w-full   gap-2">
                <div className="w-full">
                  <h2 className="text-xs font-[500] text-[#18120F]">
                    Start Date
                  </h2>
                  <input
                    value={moment(
                      updateData?.regstartdate,
                      "YYYY-MM-DD"
                    ).format("YYYY-MM-DD")}
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="reg_start_date"
                    onChange={(e) =>
                      handleInputChange("regstartdate", e.target.value)
                    }
                    type="date"
                    id=""
                  />
                </div>
                <div className="w-full">
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Start Time
                  </h2>
                  <input
                    value={
                      updateData &&
                      moment(updateData?.regstarttime, "HH:mm").format("HH:mm")
                    }
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="regstarttime"
                    onChange={(e) =>
                      handleInputChange("regstarttime", e.target.value)
                    }
                    type="time"
                    id=""
                  />
                </div>
              </div>

              <div className="flex w-full  gap-2">
                <div className="w-full">
                  <h2 className="text-[12px] font-[500] text-[#18120F] ">
                    End Date
                  </h2>
                  <input
                    value={moment(updateData?.regenddate, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )}
                    style={{ outline: "none" }}
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="regenddate"
                    onChange={(e) =>
                      handleInputChange("regenddate", e.target.value)
                    }
                    type="date"
                    id=""
                  />
                </div>
                <div className="w-full">
                  <h2 className="text-[12px] font-[500] text-[#18120F] ">
                    End Time
                  </h2>
                  <input
                    value={
                      updateData &&
                      moment(updateData?.regendtime, "HH:mm").format("HH:mm")
                    }
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="regendtime"
                    onChange={(e) =>
                      handleInputChange("regendtime", e.target.value)
                    }
                    type="time"
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full border border-[#EBF0ED] px-4 py-4 md:py-8 rounded-xl">
            <div className="flex flex-col gap-2">
              <h2 className="text-base md:text-lg font-semibold text-[#18120F]">
                Offer Live Time
              </h2>
              <div className="flex w-full   gap-2">
                <div className="w-full">
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Start Date
                  </h2>
                  <input
                    value={moment(
                      updateData?.livestartdate,
                      "YYYY-MM-DD"
                    ).format("YYYY-MM-DD")}
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="livestartdate"
                    onChange={(e) =>
                      handleInputChange("livestartdate", e.target.value)
                    }
                    type="date"
                    id=""
                  />
                </div>
                <div className="w-full">
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Start Time
                  </h2>
                  <input
                    value={
                      updateData &&
                      updateData &&
                      moment(updateData.livestarttime, "HH:mm").format("HH:mm")
                    }
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="reg_start_time"
                    onChange={(e) =>
                      handleInputChange("livestarttime", e.target.value)
                    }
                    type="time"
                    id=""
                  />
                </div>
              </div>

              <div className="flex w-full  gap-2">
                <div className="w-full">
                  <h2 className="text-[12px] font-[500] text-[#18120F] ">
                    End Date
                  </h2>
                  <input
                    value={moment(updateData?.liveenddate, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )}
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="liveenddate"
                    onChange={(e) =>
                      handleInputChange("liveenddate", e.target.value)
                    }
                    type="date"
                    id=""
                  />
                </div>
                <div className="w-full">
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    End Time
                  </h2>
                  <input
                    value={
                      updateData &&
                      moment(updateData.liveendtime, "HH:mm").format("HH:mm")
                    }
                    className="bg-[#FAFAFA] border border-[#EBF0ED] text-xs rounded-lg w-full p-2 md:p-3 mx-auto outline-none"
                    name="liveendtime"
                    onChange={(e) =>
                      handleInputChange("liveendtime", e.target.value)
                    }
                    type="time"
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
