import React from "react";
import MyShopLayout from "../../../../Super-Admin-Dashboard-Layout/MyShopLayout";
import VendorAffiliateProductList from "../../../../Components/Shop-And-User-List/ShopList/VendorAffiliateProductList";

export default function VendorAffilliateProductListPage() {
  return (
    <>
      <MyShopLayout>
        <VendorAffiliateProductList />
      </MyShopLayout>
    </>
  );
}
