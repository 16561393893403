import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearMessage,
  mainCategory,
  subCategoriesCreate,
} from "../../../../../store/adminSlice";
import { toast } from "react-toastify";
import BreadCrums from "../../../../Common/BreadCrums";

export default function AdMArketPlaceCreateSubCategory() {
  const initialImageSlots = Array(1).fill(null);
  const [imageSlots, setImageSlots] = useState(initialImageSlots);
  const [categoryName, setCategoryName] = useState("");
  const { error, message, loading, categories } = useSelector(
    (state) => state.admin
  );
  const [headCategory, setHeadCategory] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const labels = [{ text: "Category Image", required: true }];

  useEffect(() => {
    if (
      error ===
      "An error occurred while creating the subcategory: The name field format is invalid."
    ) {
      toast.error("The name field format is invalid.");
    }
    if (
      error ===
      "An error occurred while creating the subcategory: The name has already been taken."
    ) {
      toast.error("The name has already been taken.");
    }
    if (message === "Subcategory created successfully") {
      toast.success(message);
      navigate(-1);
    }
    // console.log(error, message);
    dispatch(clearMessage());
  }, [error, message]);

  useEffect(() => {
    dispatch(mainCategory());
  }, []);

  useEffect(() => {
    if (categories.categories) {
      setHeadCategory(
        categories.categories.find((item) => parseInt(item.id) === parseInt(id))
      );
    }
  }, [categories]);

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSlots = [...imageSlots];
        newImageSlots[index] = e.target.result;
        setImageSlots(newImageSlots);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageDelete = (index) => {
    const newImageSlots = [...imageSlots];
    newImageSlots[index] = null;
    setImageSlots(newImageSlots);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (categoryName === "") {
      toast.error("Enter category name");
    } else {
      if (!imageSlots[0]) {
        toast.error("Select image!");
      } else {
        formData.append("category_id", id.toString());
        formData.append("name", categoryName);
        formData.append("type", "add_store");
        formData.append("image", dataURLtoFile(imageSlots[0], "image.png"));

        dispatch(subCategoriesCreate(formData));
      }
    }
    // console.log(imageSlots, categoryName);
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <form
      className="xl:px-4 flex flex-col gap-4 w-full"
      id="uploadForm"
      enctype="multipart/form-data"
      method="post"
    >
      {/* top bar */}
      <div className="flex flex-col md:flex-row gap-3 justify-between md:items-center w-full">
        <BreadCrums
          breadCrum={[
            {
              name: "Ad Market Place",
              path: "/super-admin/ad-market-place-categories/",
            },
            {
              name: "Main Category",
              path: -1,
            },
            {
              name: "create Category",
              path: "",
            },
          ]}
        />
        <div className="w-full md:w-fit flex justify-end">
          {/* submit btn  */}
          {loading ? (
            <button
              disabled={loading ? true : false}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              <p className="animate-bounce">Loading</p>
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              disabled={loading ? true : false}
              className="py-2 md:py-[15px] px-5 md:px-8 bg-[#C19A6B]  rounded-lg text-white  font-semibold text-xs md:text-sm tracking-widest"
            >
              Publish
            </button>
          )}
        </div>
      </div>

      {/* create form  */}
      <div className="flex flex-col gap-2">
        <p className="font-semibold text-lg">Create Category</p>

        <div className="flex flex-col md:flex-row border border-[#EBF0ED] p-4 gap-10 md:gap-4 lg:gap-10 rounded-lg">
          <div className="w-full flex flex-col gap-4">
            {/* name  */}
            <div className="flex flex-col gap-1 w-full">
              <label
                className="text-[#6B6B6B]  font-semibold text-xs uppercase"
                htmlFor="product name "
              >
                Category Name
              </label>
              <input
                className="bg-[#FAFAFA] rounded-lg w-full py-2 px-3   outline-[#EBF0ED] border border-[#EBF0ED] placeholder:text-xs placeholder:text-[#6B6B6B]"
                type="text"
                name="name"
                id=""
                placeholder="Name..."
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>
          </div>

          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#6B6B6B]  font-semibold text-xs">
                ICON AND IMAGES
              </h1>

              <div className=" grid grid-cols-2  gap-4 md:gap-2 lg:gap-4 w-full md:w-fit">
                {imageSlots.map((image, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center w-full md:w-28"
                  >
                    <div
                      className={`relative w-full h-[90px] border rounded-lg flex justify-center items-center border-dashed border-[#E0E0E0] `}
                    >
                      {image ? (
                        <div className="w-full h-full relative">
                          <img
                            src={image}
                            alt={`Uploaded-${index}`}
                            className="w-full h-full object-cover rounded-lg"
                          />
                          {/* Delete Button */}
                          <button
                            className="absolute top-1 right-1 bg-white border border-[#EBF0ED] rounded-full w-4 h-4 flex justify-center items-center"
                            onClick={() => handleImageDelete(index)}
                          >
                            <svg
                              width="7"
                              height="7"
                              viewBox="0 0 7 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.9748 1.00017L1.00002 5.975M1 1L5.9748 5.9748"
                                stroke="black"
                                stroke-width="1.07"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <label className="cursor-pointer flex w-full h-full items-center justify-center">
                          <input
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={(e) => handleImageUpload(e, index)}
                          />
                          <span className="flex bg-[#D9D9D9] rounded-full w-4 h-4 items-center justify-center text-white">
                            +
                          </span>
                        </label>
                      )}
                    </div>
                    {/* Label with red star for required images */}
                    <div className="text-sm font-normal text-gray-500 mt-2">
                      {labels[index].text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
