import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

export default function CategoryComponentModal({
  subSubCategories,
  categories,
  subCategories,
  categotyModalRef,
  position,
  setHotspotsDAta,
  hotspotsData,
  category,
  setCategory,
}) {
  const resetCategoryAndCloseModal = () => {
    setCategory({ category_id: 0, subcategory_id: 0, subsubcategory_id: 0 });
    categotyModalRef.current.close();
  };

  const addPoint = (event) => {
    if (category.category_id) {
      toast.success("HotSpot Point Added Success Full!");
      setHotspotsDAta([
        ...hotspotsData,
        {
          top: parseInt(position.top),
          left: parseInt(position.left),
          category_id: category.category_id,
          subcategory_id: category.subcategory_id,
          subsubcategory_id: category.subsubcategory_id,
        },
      ]);
      resetCategoryAndCloseModal();
    } else {
      toast.error("Atleast Select Main Category!");
      resetCategoryAndCloseModal();
    }
  };

  return (
    <>
      <dialog
        ref={categotyModalRef}
        id="add_new_address_modal_form"
        className="modal "
      >
        <div className="modal-box bg-white max-w-3xl rounded-md">
          <form method="dialog">
            <button
              type="button"
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={resetCategoryAndCloseModal}
            >
              ✕
            </button>
          </form>
          <h3 className="font-bold text-lg tracking-wider">Add Action Point</h3>

          <div className="">
            <div className="py-3">
              <form className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label
                      htmlFor="field1"
                      className="block text-md font-bold text-gray-600"
                    >
                      Main Category <span className="red-star">★</span>
                    </label>
                    <select
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                      value={category.category_id}
                      onChange={(e) =>
                        setCategory({
                          ...category,
                          category_id: e.target.value,
                        })
                      }
                    >
                      <option value="0">none</option>
                      {categories?.categories?.length > 0 &&
                        categories?.categories.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="field2"
                      className="block text-md font-bold text-gray-600"
                    >
                      Sub Category
                    </label>

                    <select
                      value={category.subcategory_id}
                      placeholder="None"
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                      onChange={(e) =>
                        setCategory({
                          ...category,
                          subcategory_id: e.target.value,
                        })
                      }
                    >
                      <option value="0">none</option>
                      {subCategories?.subcategories?.length > 0 &&
                        subCategories?.subcategories.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label
                      htmlFor="field3"
                      className="block text-md font-bold text-gray-600"
                    >
                      Sub Sub Category
                    </label>
                    <select
                      className="mt-1 py-2 px-4 w-full border focus:outline-none rounded"
                      value={category.subsubcategory_id}
                      onChange={(e) => {
                        setCategory({
                          ...category,
                          subsubcategory_id: e.target.value,
                        });
                      }}
                    >
                      <option value="0">none</option>
                      {subSubCategories?.subSubCategories?.length > 0 &&
                        subSubCategories?.subSubCategories.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>
                  </div>
                </div>

                <div className="flex justify-end">
                  <div
                    className="mt-6 cursor-pointer py-3 px-16 bg-[#C19A6B] rounded-md text-white font-[500] tracking-wider"
                    onClick={(e) => addPoint(e)}
                  >
                    Add Point
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
}
