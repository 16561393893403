import AuctionCreateSubCategory from "../../../../../Components/My-Shop-Components/Manage-Categories/Auction-Categories/Sub-Categories/AuctionCreateSubCategory";
import MyShopLayout from "../../../../../Super-Admin-Dashboard-Layout/MyShopLayout";

export default function AuctionCreateSubCategoryPage() {
  return (
    <MyShopLayout>
      <AuctionCreateSubCategory />
    </MyShopLayout>
  );
}
