import StoreBoosterDetails from "../Components/My-Shop-Components/Store-Boosters/StoreBoosterDetails";
import StoreBoosterUpdateSettings from "../Components/My-Shop-Components/Store-Boosters/StoreBoosterUpdateSettings";
import StoreBoostersList from "../Components/My-Shop-Components/Store-Boosters/StoreBoostersList";
import StoreBoosterSettings from "../Components/My-Shop-Components/Store-Boosters/StoreBoostersSettings";

import StoreBoostersStats from "../Components/My-Shop-Components/Store-Boosters/StoreBoostersStats";
import StoreBoostersPage from "../Pages/My-Shop/Store-Booster-Pages/StoreBoostersPage";
import StoreStatsPage from "../Pages/My-Shop/Store-Booster-Pages/StoreStatsPage";

const storeBoosterRoutes = [
  {
    path: "/super-admin/store-boosters",

    element: <StoreBoostersPage />,
    children: [
      {
        index: true,
        element: <StoreBoostersList />,
      },
      {
        path: "/super-admin/store-boosters/booster-details/:id",
        element: <StoreBoosterDetails />,//sad
      },
      {
        path: "/super-admin/store-boosters/stats",
        element: <StoreBoostersStats />,
      },
      {
        path: "/super-admin/store-boosters/settings",
        element: <StoreBoosterSettings />,
      },
      {
        path: "/super-admin/store-boosters/settings/update/:id",
        element: <StoreBoosterUpdateSettings />,
      },
    ],
  },

  {
    path: "/super-admin/store-boosters/store-stats",
    element: <StoreStatsPage />,
  },
];

export default storeBoosterRoutes;
