import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { imagePath } from "../../../functions/URLString";
import Loader from "../../Common/Loader";
import BreadCrums from "../../Common/BreadCrums";
import { toast } from "react-toastify";
import {
  clearMessage,
  shopList,
  shopListSearch,
  updateVendorStatus,
} from "../../../store/adminSlice";

const ShopListShops = () => {
  const [activeRole, setActiveRole] = useState("All");
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const { data, loading, message, error } = useSelector((state) => state.admin);

  useEffect(() => {
    if (error) {
      toast.success(error);
    }
    if (message && message !== "Premium products retrieved successfully") {
      toast.success(message);
    }
    dispatch(clearMessage());
  }, [error, message, dispatch]);

  useEffect(() => {
    if (search === "") {
      dispatch(shopList({}));
    } else {
      dispatch(shopListSearch(search));
    }
  }, [search, dispatch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeRole]);

  useEffect(() => {
    if (data?.pagination && data?.pagination?.total) {
      let totalLength = data?.pagination?.total;
      let totalPages = Math.ceil(totalLength / totalItemPerPages);
      setTotalPages(totalPages);
    }
  }, [data, search, loading]);

  const handleRoleChange = (status) => {
    setActiveRole(status);
    if (status === "All") {
      dispatch(shopList({}));
    } else {
      dispatch(shopList({ status: status }));
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleActiveOrDisable = (status, id) => {
    dispatch(updateVendorStatus({ id, status })).then(() => {
      dispatch(shopList({}));
      setActiveRole("All");
    });
    // console.log(id, status);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalItemPerPages = 10;
  const [totalPages, setTotalPages] = useState();

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      if (activeRole === "All") {
        dispatch(shopList({ page: page }));
      } else {
        dispatch(shopList({ page: page, status: activeRole }));
      }
    }
  };

  function Pagination({ totalNumberOfPages, currentPage, handlePageChange }) {
    const pages = [];
    let tempEnd =
      totalNumberOfPages - currentPage > 2
        ? currentPage + 2
        : totalNumberOfPages;
    let tempStart = 0;
    if (totalNumberOfPages > 3) {
      tempStart =
        totalNumberOfPages - currentPage < 2
          ? totalNumberOfPages - 3
          : currentPage - 1;
    }

    for (let i = 1; i <= totalNumberOfPages; i++) {
      pages.push(i);
    }

    return (
      <>
        {pages.slice(tempStart, tempEnd).map((i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`px-2 py-1 rounded ${
              currentPage === i
                ? "bg-[#C19A6B] text-white h-10 w-10 rounded-xl border border-[#EBF0ED]"
                : "h-10 w-10 bg-[#FAFAFA] rounded-xl border border-[#EBF0ED]"
            }`}
          >
            {i}
          </button>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[{ name: "Shop List", path: "/super-admin/shop-list/" }]}
        />

        {/* Search and Add Product */}
        <div className="relative flex items-center w-full">
          <img
            src="/assets/icons/manage-products/search.svg"
            alt=""
            className="absolute left-3"
          />
          <input
            className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-xs md:text-sm"
            type="search"
            placeholder="Search Shops..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* Filter */}
        <div className="flex items-center justify-between md:mt-2">
          <div className="flex gap-3 md:gap-6 text-xs md:text-sm">
            <NavLink
              onClick={() => handleRoleChange("All")}
              className={`flex items-center font-semibold gap-1 md:gap-2 text-[#828282] uppercase ${
                activeRole === "All" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
              }`}
            >
              All Shops
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg xl:rounded-xl ${
                  activeRole === "All" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
                }`}
              >
                {Array.isArray(data.data) ? data.total_vendor_count : 0}
              </span>
            </NavLink>

            <NavLink
              onClick={() => handleRoleChange("active")}
              className={`flex items-center font-semibold gap-1 md:gap-2 text-[#828282] uppercase ${
                activeRole === "active"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              Active Shops
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg xl:rounded-xl ${
                  activeRole === "active"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {Array.isArray(data.data) ? data.active_vendor_count : 0}
              </span>
            </NavLink>

            <NavLink
              onClick={() => handleRoleChange("disabled")}
              className={`flex items-center font-semibold  gap-1 md:gap-2 text-[#828282] uppercase ${
                activeRole === "disabled"
                  ? "text-[#C19A6B]"
                  : "hover:text-[#C19A6B]"
              }`}
            >
              Disabled Shops
              <span
                className={` font-semibold py-1 md:py-2 px-1 md:px-[10px] bg-[#FAFAFA] border rounded-lg xl:rounded-xl ${
                  activeRole === "disabled"
                    ? "border-[#C19A6B]"
                    : "border-[#EBF0ED]"
                }`}
              >
                {Array.isArray(data.data) ? data.disabled_vendor_count : 0}
              </span>
            </NavLink>
          </div>
        </div>

        {/* Table */}
        <div className=" flex justify-center border-gray-500 overflow-auto">
          <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
            <div className="min-w-[768px] md:min-w-[1024px] lg:w-full">
              {/* Table Header */}
              <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
                <span className="w-[16%] text-left uppercase">Image</span>
                <span className="w-[16%] text-left uppercase">Shop Name</span>
                <span className="w-[16%] text-left uppercase">Email</span>
                <span className="w-[16%] text-left uppercase">Owner Name</span>
                <span className="w-[16%] text-left uppercase">Status</span>
                <span className="w-[16%] text-left uppercase">Action</span>
              </div>

              {/* Table Body */}

              {loading ? (
                <tr>
                  <td colSpan="6">
                    <div className="flex justify-center py-4">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.data && data?.data?.length > 0 ? (
                data.data.map((shop) => (
                  <div
                    key={shop.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[16%] text-left">
                      <img
                        src={shop.image}
                        alt=""
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <span className="w-[16%] text-left">{shop.store_name}</span>
                    <span className="w-[16%] text-left">{shop.email}</span>
                    <span className="w-[16%] text-left">{shop.name}</span>
                    <div className="w-[16%] text-left">
                      <span
                        className={`${
                          shop.status.toLowerCase() === "active"
                            ? "py-[5px] px-[15px] border tracking-wide border-green-500 text-green-500 bg-green-100 rounded-[10px]"
                            : "py-[5px] px-[15px] border border-red-500 text-red-500 bg-red-100 rounded-[10px]"
                        }`}
                      >
                        {capitalizeFirstLetter(shop.status)}
                      </span>
                    </div>

                    <div className=" w-[16%] text-left flex gap-2">
                      <Link
                        to={`/super-admin/shop-list/shop-details/${shop.user_id}`}
                      >
                        <img
                          className="w-7 h-7 md:w-9 md:h-9 "
                          src="/assets/icons/manage-products/view.svg"
                          alt=""
                        />
                      </Link>
                      {shop.status.toLowerCase() === "active" ? (
                        <span
                          title="disable"
                          className="cursor-pointer"
                          disabled={loading ? true : false}
                          onClick={() =>
                            handleActiveOrDisable("disable", shop.user_id)
                          }
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9 "
                            src="/assets/icons/bulk.svg"
                            alt=""
                          />
                        </span>
                      ) : (
                        <span
                          title="active"
                          disabled={loading ? true : false}
                          className="cursor-pointer"
                          onClick={() =>
                            handleActiveOrDisable("accept", shop.user_id)
                          }
                        >
                          <img
                            className="w-7 h-7 md:w-9 md:h-9 "
                            src="/assets/icons/make-active.svg"
                            alt=""
                          />
                        </span>
                      )}

                      {/* <Link>
                          <img src="/assets/icons/delete1.svg" alt="" />
                        </Link> */}
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center font-bold tracking-wide">
                  No shops Found
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          {!loading && data && data?.data?.length > 0 ? (
            <div className="flex justify-between items-center my-4">
              <span className="text-sm text-[#6B6B6B] font-semibold">
                {/* SHOWING {data.from +"-"+ data.to} OF {data.total}  */}
              </span>
              <div className="flex items-center justify-between w-fit gap-2 h-[40px] bg-white rounded-lg px-2">
                {/* Previous Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === 1 ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <svg
                    className={`${
                      currentPage === 1
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 13L1 7L7 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* Page Numbers */}
                <div className="flex items-center gap-2">
                  {
                    <Pagination
                      totalNumberOfPages={totalPages}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                    />
                  }
                </div>
                {/* Next Button */}
                <div
                  className={`h-10 w-10 cursor-pointer bg-[#FAFAFA] flex justify-center items-center rounded-xl border border-[#EBF0ED] ${
                    currentPage === totalPages ? "opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <svg
                    className={`${
                      currentPage === totalPages
                        ? "text-gray-400 cursor-not-allowed"
                        : "text-blue-500"
                    }`}
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="#828282"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ShopListShops;
