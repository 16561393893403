import { MdKeyboardArrowRight } from "react-icons/md";

import PremiumProductOfferStatsBarCharts from "../../Graphs/Premium-Product-Graphs/PremiumProductOffersStatsBarChart";
import BreadCrums from "../../Common/BreadCrums";

export default function OfferStats() {
  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        <BreadCrums
          breadCrum={[
            {
              name: "Marketing Solutions",
              path: "/super-admin/premium-products",
            },
            {
              name: "Premium Product Category List",
              path: "/super-admin/premium-products",
            },
            {
              name: "Offer",
              path: "/super-admin/premium-products/single-active-offer",
            },
            {
              name: "Stats",
              path: "/super-admin/premium-products/offer-stats",
            },
          ]}
        />

        {/*  */}

        <div className=" flex flex-col gap-4">
          {/* graph  */}
          <div className="w-full flex flex-col md:flex-row gap-8">
            {/* graph  */}
            <div className="w-full md:w-3/4 flex flex-col gap-3">
              <PremiumProductOfferStatsBarCharts />
            </div>
            {/* listed product and categories  */}
            <div className="flex  md:flex-col  gap-4">
              <div className="w-full flex flex-col gap-2 md:gap-7 lg:gap-10 text-left p-2  md:p-4 lg:p-7 shadow-md rounded-lg">
                <span className="font-semibold text-sm md:text-base  lg:text-xl">
                  Total Bidding Amount
                </span>
                <div className="flex items-center md:items-end justify-between md:gap-10">
                  <span className="font-bold text-[35px] lg:text-[50px] leading-[4rem]">
                    $683k
                  </span>
                  <span className="p-2 md:p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1">
                    <MdKeyboardArrowRight className="w-4 h-4 md:w-5 md:h-5" />
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-2 md:gap-7 lg:gap-10 text-left p-2 md:p-4 lg:p-7 shadow-md rounded-lg">
                <span className="font-semibold text-sm md:text-base lg:text-xl">
                  Highest Bid
                </span>
                <div className="flex items-center md:items-end justify-between md:gap-10">
                  <span className="font-bold text-[35px]  lg:text-[50px] leading-[4rem]">
                    $213
                  </span>
                  <span className="p-2 md:p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1">
                    <MdKeyboardArrowRight className="w-4 h-4 md:w-5 md:h-5" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
